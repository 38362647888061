import { FontAwesome5 } from "@expo/vector-icons";
import moment from "moment";
import { HStack, View, Text, Avatar, Button, Icon, Badge } from "native-base";
import React from "react";
import { Image, Pressable } from 'react-native'
import { getUserProfile } from "../utils/views";
import TripDetailsViewWithPreview from "./TripDetailsViewWithPreview";
import TEXT from '../translations.json'
import TripOverviewText from "./TripOverviewText";
import Config from "../constants/Config";
import { getCountString, getTripImage, showAlert } from "../utils";
import GroupedAvatars from "./GroupedAvatars";

export default function TripRow(props){

  const handleItemPress = (item) => {
    // showAlert("pressed me")
    if (props.isViewOnly) { 
      return;
    }

    if (props.onItemPress) { 
      props.onItemPress(item);
    }  else {
      props.navigation.navigate("TripDetail", { tripId: item.uuid })
    }
  }

  const handleChatPress = (item) => {
    if (props.isViewOnly) {
      return;
    }
    if (props.fakeData && props.onItemPress) { 
      props.onItemPress(item);
    } else {
      props.navigation.navigate("ChatScreen", {tripId: item.id});
    }
  }

  const detailContainsLink = () => {
    if (!props.trip) { 
      return false;
    }
    if (props.alwaysShowTripPhoto) { 
      return false;
    }
    return props.trip.details.includes("https");
  }

  const handleProfileClick = (item) => {
    if (props.isViewOnly) { 
      return;
    }
    props.navigation.navigate("PublicProfile", { username: item.creator.username })
  }

  const item = props.trip;
  const onItemPress = () => handleItemPress(item)
  const avatars = item.interest_users ? <>{item.interest_users.map((subItem) => getUserProfile(subItem, { size: 'xs' }))}</> : null
  return <Pressable 
  // mt={5} mb={5} 
  onPress={onItemPress} style={{ marginTop: 5 * 4, marginBottom: 5 * 4, backgroundColor: 'white', ...props.containerStyle}}>
    <HStack space={2} flexWrap={"wrap"}> 
    {item.my_interest && <Badge mb={3} rounded={"full"} colorScheme={item.my_interest.level ==="going" ? "green" : "coolGray"}>{item.my_interest.level === "going" ? `You're going` : "You're not sure"}</Badge>}
    {!item.my_interest && item.interested && <Badge mb="3" colorScheme={"lightBlue"} rounded={"full"} w={120}>You're interested</Badge>}
    {item.joinable == false && <Badge mb="3" colorScheme={"danger"} rounded={"full"} w={120}>Full trip</Badge>}
    {item.visibility === "UNLISTED" && <Badge mb="3" colorScheme={"indigo"}>Unlisted</Badge>}
    </HStack>

    {props.fakeData && <Badge rounded={"full"} mb={3} colorScheme={"lightBlue"} w={280}>This is not real and just for demonstration..</Badge>}
    <HStack space={2} >
      <Pressable onPress={() => handleProfileClick(item)}>{getUserProfile(item.creator)}</Pressable>
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} w="100%" h="100%">
        <View flex={1}>
          <TripOverviewText __text={props.__text} trip={item}></TripOverviewText>
          <Text fontSize="xs">{moment(item.created_at).fromNow()}</Text>
        </View>
      </View>
    </HStack>
    <TripDetailsViewWithPreview data={item.details}
      hideImage={props.hidePreviewImage}
      hidePreview={props.hidePreview}
      onImageLoad={props.onImageLoad ? props.onImageLoad() : () => null}
      parsedTextProps={{ numberOfLines: props.detailNumberOfLines ?? 4 }}
      parsedTextStyle={{ fontSize: 18, marginBottom: 0, marginTop: 15 }}></TripDetailsViewWithPreview>
    
    {!detailContainsLink() && <Image 
      onLoad={props.onImageLoad ? props.onImageLoad() : () => null} 
      style={{width: '100%', height: 200, ...props.imageStyle}} 
      source={{uri: getTripImage(item)}} />}


    {item.interest_users?.length > 0  && !props.hideInterest && <HStack space={2} mt="2">
      <GroupedAvatars users={item.interest_users}/>
      {item.interest_count > 0 && <Text colorScheme={"coolGray"} underline>{item.interest_count} {getCountString("other", item.interest_count)} interested</Text>}
    </HStack>}

    {!props.hideButtons && <HStack space={2} mt="2">
      <Button variant="subtle" colorScheme={"rose"} onPress={() => handleItemPress(item)}>View more</Button>
      <Button variant="subtle" colorScheme={"coolGray"} onPress={() => handleChatPress(item)}>Comment</Button>
    </HStack>}
  </Pressable>
}
