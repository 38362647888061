import { createElement } from "react-native"

const WebDatePicker = (props) => {
    return createElement('input', {
        type: 'date',
        value: props.date ? props.date?.toISOString().split("T")[0] : "",
        onChange: (event) => {
            let value = event.target.value
            if (!value) { 
                props.onChange(null)
            } else { 
                props.onChange(new Date(event.target.value))
            }
        },
        onFocus: (event) => event.nativeEvent.target.defaultValue = "",
        style: { height: 30, padding: 5, border: "2px solid #677788", borderRadius: 5, width: 250 }
    })
}

export default WebDatePicker;