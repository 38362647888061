import Layout from "../components/Layout";
import { View as ReactView } from 'react-native'
import React, { useState, useRef } from "react";
import { Badge, Heading, HStack, Text, Button, Icon, View } from "native-base";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import TEXT from '../translations.json'
import { getRandomTheme, webOrNative } from "../utils";
import { Entypo, FontAwesome } from "@expo/vector-icons";
import PlacesAutoComplete from "./PlacesAutoComplete";


export default function WishlistView(props) {

    // const [placeMap, setPlaceMap] = useState(props.mapData ? props.mapData : {})
    const ref = useRef();


    function removePlace(place) {
        const updatedMap = { ...props.mapData }
        delete updatedMap[place]
        if (props.onPlaceMapChange) { 
            props.onPlaceMapChange(updatedMap)
        }
    }

    async function handleComplete() {
        if (props.handleComplete) { 
            props.handleComplete(props.mapData);
        } else { 
            props.navigation.navigate("Root")
        }
    }

    var places = Object.keys(props.mapData)

    return <View w="100%">
        <Text fontSize={"lg"} mt="4">{props.title ? props.title : "What are some places on your travel bucket list?"}</Text>
        <ReactView style={{ width: '100%', flexDirection: 'row', marginBottom: 20, marginTop: 10 }} >
            <PlacesAutoComplete
                shouldClear={props.shouldClear ?? true}
                selectedPlace={props.selectedPlace}
                mapData={props.mapData}
                maxLimit={props.maxLimit}
                selectFirstPhoto={props.selectFirstPhoto}
                onPlaceMapChange={props.onPlaceMapChange}
                onPlaceSelected={props.onPlaceSelected}
                onPhotosLoaded={props.onPhotosLoaded ?? null}
            />
        </ReactView>

        {!props.hidePlacesList && <HStack flexWrap={"wrap"} mb="2">
            {places.map((item) => <Button onPress={() => removePlace(item)} rightIcon={<Icon as={Entypo} name="cross"></Icon>} mr={2} mt={2} variant="subtle" colorScheme={getRandomTheme()}>{item}</Button>)}
        </HStack>}

        {props.showContinue && <View mt="4">
            {webOrNative(() => <Button onPress={handleComplete}>Complete</Button>, () => <Button onPress={handleComplete}>Complete</Button>)}
        </View>}
    </View>
}