import React, { useRef } from "react";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import Config from "../constants/Config";
import TEXT from '../translations.json'


export default function PlacesAutoComplete(props) {
    const ref = useRef();

    function getMapData() { 
        if (props.mapData) { 
            return props.mapData
        } 
        return {}
    }

    const handleClear = () => {
        if (props.shouldClear && ref?.current) {
            const obj: any = ref.current;
            obj.clear();
            obj.setAddressText("")
        }
    }

    return <GooglePlacesAutocomplete
        ref={ref}
        textInputProps={{
            placeholderTextColor: 'gray',
            value: props.selectedPlace ? props.selectedPlace : null,
            style: { backgroundColor: 'white', width: '100%', paddingTop: 10, paddingBottom: 10, paddingLeft: 20, borderRadius: 10, borderWidth: 1, borderColor: 'lightgray' }
        }}
        styles={{
            textInput: { color: "#5d5d5d", fontColor: 'black', fontSize: 24 },
            textInputContainer: {
                backgroundColor: 'b'
            },
        }}
        fetchDetails={true}
        placeholder={props.placeholder ? props.placeholder : TEXT.EnterAPlace + "..."}
        keyboardShouldPersistTaps={props.persisTaps ?? "always"}
        onPress={(data, details = null) => {
            if (props.maxLimit) {
                let count = Object.keys(getMapData()).length
                if (count >= props.maxLimit) {
                    console.log("MAX LIMIT REACHED!") 
                    handleClear();
                    return;
                }
            }
            console.log("I'VE BEEN PRESSED");
            // @ts-ignore
            console.log(details);
            let photos = details.photos;
            if (props.onPhotosLoaded) { 
                props.onPhotosLoaded(photos)
            }
            var photoRef = null;
            if (photos) { 
                if (props.selectFirstPhoto) { 
                    photoRef = photos[0].photo_reference;
                } else {
                    let photosWithRatio = photos.map((item) => {
                        const existingData =  { ...item }
                        const height = existingData["height"]
                        const width = existingData["width"]
                        const ratio = width / height
                        existingData["ratio"] = ratio
                        return existingData
                    })
                    photosWithRatio.sort((a, b) => b["ratio"] - a["ratio"])

                    console.log("SELECTED PHOTO")
                    console.log(photosWithRatio[0])

                    if (photosWithRatio.length > 0) {
                        photoRef = photosWithRatio[0].photo_reference;
                    }
                }
            }
   
            const viewPortJson = details.geometry.viewport;
            const bbox = `${viewPortJson.southwest.lng},${viewPortJson.southwest.lat},${viewPortJson.northeast.lng},${viewPortJson.northeast.lat}`;
            console.log(bbox)
            const placesData = { ...getMapData() }
            const placeObj = { 
                bbox: bbox, 
                photos: photos,
                location: details.geometry.location, 
                placeid: details.place_id, 
                photoReference: photoRef,
                picture_url: Config.googleMapsPhotoRef.replace("{}", photoRef), 
                title: data.description,
                address: details.formatted_address
            }
            placesData[data.description] = placeObj
            console.log('ref is ', ref)
            if (props.onPlaceMapChange) {
                console.log("ON PLACE MAP CHANGED...");
                props.onPlaceMapChange(placesData)
            }
            if (props.onPlaceSelected) {
                props.onPlaceSelected(placeObj);
            }
            handleClear();
        }}
        query={{
            key: 'AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac',
            language: 'en',
            location: props.location,
            radius: props.radius
        }}
        requestUrl={{
            useOnPlatform: 'web', // or "all"
            url: `${BaseApiConfigProvider.getEnvUrl()}/proxy/?json=true&url=https://maps.googleapis.com/maps/api`, // or any proxy server that hits https://maps.googleapis.com/maps/api
            headers: {
                Authorization: null, // if required for your proxy
            },
        }}
    />

}