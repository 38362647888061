import { FlatList, Heading, Icon, Input, Pressable, ScrollView, View, Image, Text } from "native-base";
import React, { useCallback, useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import UsersList from "../components/UsersList";
import lodash from "lodash";
import { textMatches } from "../utils";
import { Feather } from "@expo/vector-icons";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import { Keyboard, Linking, Platform } from "react-native";
import { useSelector } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import * as WebBrowser from 'expo-web-browser';
import { Screens } from "../constants/Screens";
import TrendingVC from "../controllers/TrendingVC";


export default function SearchScreen(props) {

    const usersApi = new GenericViewSetAPI("user");
    const config = useSelector((state: any) => state.config)

    const [searchQuery, setSearchQuery] = useState("");
    const [users, setUsers] = useState([]);
    const [usersResp, setUsersResp] = useState(null);
    const [page, setPage] = useState(1);

    const DEBOUBCE_DELAY = 500;

    const fetchUsers = async (val) => {
        console.log(val)
        if (val == "") {
            setUsers([]);
            setUsersResp(null);
            setPage(1);
            return;
        }

        const searchUsersResp = await usersApi.query({ q: val });
        console.log(searchUsersResp);
        if (searchUsersResp && !searchUsersResp.error) {
            const users = searchUsersResp.results;
            setUsers(users);
            setUsersResp(searchUsersResp);
            setPage(1);
        }
    }

    const handler = useCallback(lodash.debounce(fetchUsers, DEBOUBCE_DELAY), []);


    const handleTextChange = (value) => {
        if (textMatches(value, "alphanumeric", null)) {
            setSearchQuery(value);
            handler(value);
        }
    }

    useFocusEffect( React.useCallback(() => {
        AnalyticsManager.logEvent(events.USER_VIEW_SEARCH);
        let isActive = true;
        return () => {
          isActive = false;
        };
      }, []))

    const onUserPressed = (user) => {
        Keyboard.dismiss()
        props.navigation.navigate("PublicProfile", { username: user.username });
    }

    const trendingPlaces = config?.trending_places
    return <Layout scrollView={Platform.OS === "web"}>
        <Input mt={3} size="md" w="100%" variant="filled" placeholder="Search by username/handle or name..." value={searchQuery} onChangeText={(val) => handleTextChange(val)}
            InputLeftElement={<Icon as={Feather} name="search" ml="2" size="4" />} />

            {!searchQuery && <View height="100%" w="100%"><TrendingVC /></View>}
        <UsersList users={users} onPress={onUserPressed} containerProps={{ mt: 5 }} />
    </Layout>

}