import { View, Image, Heading, Text, Center } from 'native-base'
import React from 'react'
import TripRow from './TripRow'

const SocialTripRow = React.forwardRef((props: any, ref) => {

    const handleImageLoad = () => {
        setTimeout(() => {
            props.onImageLoad()
        }, 500)
    }

    return <View collapsable={false} ref={ref}>
        <Image mb={3} source={require("../assets/images/fulltrip_text_white.png")} style={{height: 50, resizeMode: 'contain'}}></Image> 
        {/* <Heading textAlign={"center"} color="white" mb="5" style={{fontSize: 28}} w="100%" mt="2">I'm planning a trip, join in!</Heading> */}
        
        <Center>
            <View maxW={"70%"} bg="white" rounded={"lg"} mb="1" p={1}>
                <Text flexWrap={"wrap"} color="rose.400" textAlign="center" fontSize={"sm"} fontWeight="semibold">{`Go back to fulltrip, copy your link and cover this with it `}</Text>
            </View>
        </Center>  
        <TripRow 
            hidePreview
            hidePreviewImage
            alwaysShowTripPhoto
            hideInterest hideButtons containerStyle={{padding: 8, borderRadius: 5}} trip={props.trip} __text={{numberOfLines: 3}} detailNumberOfLines={2} onImageLoad={handleImageLoad}/>
    </View>
})

export default SocialTripRow