import { View } from "native-base";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { getPlaceFromData, showAlert } from "../utils";
import WebView from "react-native-webview";
import { Screens } from "../constants/Screens";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { ActivityIndicator } from "react-native";
import { LoadingModal } from "../components/LoadingModal";


export default function ReactiveWebScreen(props) {
    const tripsApi = new GenericViewSetAPI("trip")

    const url = props.route.params?.url;
    const title = props.route.params?.title;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.navigation.setOptions({
            title: ""
        })
    })

    const onMessage = async (data) => {
        const parsedData = JSON.parse(data);
        if (parsedData.type === "alert") {
            showAlert(parsedData.message);
        } else if (parsedData.type === "close") {
            props.navigation.goBack();
        } else if (parsedData.type === "add_place") {
            const place = parsedData.place;
            console.error(place)
            const geom = place.geometry.location;
            const containsResp = await tripsApi.query({point: `${geom.lng},${geom.lat}`})
            // console.error(containsResp)
            if (containsResp && !containsResp.error) { 
                const photoRef = place.photos.length > 0 ? place.photos[0].photo_reference : null
                const convertPlace = getPlaceFromData(place, photoRef, place.name)
                if (containsResp.results.length > 0) {
                    const trip = containsResp.results[0]
                    props.navigation.navigate(Screens.TripEvents, {tripId: trip.uuid, selectedPlaceObj: convertPlace})
                } else  {
                    props.navigation.navigate(Screens.CreateModal, {place: convertPlace, photos: place.photos})
                }
            }
        }
    }

    return <>
            <LoadingModal visible={loading} />
            <WebView 
            style={{marginTop: 20}}
            javaScriptEnabled
            scalesPageToFit={true}
            androidLayerType="hardware"
            onLoad={() => setLoading(false)}
            onMessage={(e) => onMessage(e.nativeEvent.data)}
            source={{uri: url}}/>
    </>
}