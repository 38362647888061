import { createStore } from 'redux'

const exampleInitialState = {
  user: null,
  guest: null,
  token: null,
  config: null, 
  targetScreen: null, 
  theme: null,
  createdTrip: null,
  createdTripId: null, 
  onboardTripId: null,
  onboard: {
    userInviteSecret: null,
    tripSecret: null,
    addedFriend: false
  }
}

export const actionTypes = {
    SET_USER: 'SET_USER', 
    SET_TOKEN: 'SET_TOKEN', 
    SET_TARGET_SCREEN: 'SET_TARGET_SCREEN',
    SET_CONFIG: 'SET_CONFIG',
    SET_THEME: 'SET_THEME',
    SET_CREATED_TRIP_ID: 'SET_CREATED_TRIP_ID', 
    SET_CREATED_TRIP: 'SET_CREATED_TRIP',
    SET_ONBOARD_TRIP_ID: 'SET_ONBOARD_TRIP_ID',
    SET_USER_ADDED_ONBOARD_FRIEND: 'SET_USER_ADDED_ONBOARD_FRIEND',
    SET_GUEST: 'SET_GUEST'
}

// REDUCERS
export const reducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return Object.assign({}, state, {
        user: action.user,
      })
    case actionTypes.SET_AUTH:
      return Object.assign({}, state, { 
        auth: action.auth
      })
    case actionTypes.SET_TARGET_SCREEN:
      return Object.assign({}, state, { 
        screen: action.screen
      })
    case actionTypes.SET_CONFIG:
      return Object.assign({}, state, {
        config: action.config
      })
    case actionTypes.SET_THEME:
        return Object.assign({}, state, {
            theme: action.theme
        })
    case actionTypes.SET_CREATED_TRIP_ID:
      return Object.assign({}, state, {
        createdTripId: action.createdTripId
      })
    case actionTypes.SET_ONBOARD_TRIP_ID:
      return Object.assign({}, state, {
        onboardTripId: action.onboardTripId
      })
    case actionTypes.SET_CREATED_TRIP:
      return Object.assign({}, state, {
        createdTrip: action.createdTrip
      })
    case actionTypes.SET_USER_ADDED_ONBOARD_FRIEND: 
      return Object.assign({}, state, {
        onboard: {
          ...state.onboard,
          addedFriend: action.addedFriend
        }
      })
    case actionTypes.SET_GUEST:
      return Object.assign({}, state, {
        guest: action.guest
      })
    default:
      return state
  }
}

export function setUser(user) { 
  if (user) { 
    user.username = user.username.toLowerCase()
  }
  return  {type: actionTypes.SET_USER, user: user }
}

export function setAuth(auth) { 
  return {type: actionTypes.SET_AUTH, auth: auth}
}

export function updateTheme(theme) { 
  return {type: actionTypes.SET_THEME, theme: theme }
}

export function setGuest(guest) { 
  return {type: actionTypes.SET_GUEST, guest: guest }
}

export function setConfig(config) { 
  return { type: actionTypes.SET_CONFIG, config: config}
}

export function setTargetScreen(screen) { 
    return { type: actionTypes.SET_TARGET_SCREEN, screen: screen }
}

export function clearTargetScreen() {
    return { type: actionTypes.SET_TARGET_SCREEN, screen: null }
}

export function setCreatedTripId(createdTripId) { 
  return { type: actionTypes.SET_CREATED_TRIP_ID, createdTripId: createdTripId }
}

export function setCreatedTrip(createdTrip) { 
  return { type: actionTypes.SET_CREATED_TRIP, createdTrip: createdTrip }
}

export function setOnboardTripId(onboardTripId) { 
  return { type: actionTypes.SET_ONBOARD_TRIP_ID, onboardTripId: onboardTripId }
}

export function setOnboardUserAddedFriend(addedFriend){
  return { type: actionTypes.SET_USER_ADDED_ONBOARD_FRIEND, addedFriend: addedFriend }
}

export function initializeStore (initialState = exampleInitialState) {
  return createStore(
    reducer,
    initialState
  )
}