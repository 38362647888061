import { useEffect, useState } from "react";
import { Dimensions } from "react-native";


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

type ViewType = "mobile" | "desktop"

export default function useViewType(desktopHeight = 1000) { 
    const [dimensions, setDimensions] = useState({ window, screen });

    useEffect(() => {
      const subscription = Dimensions.addEventListener(
        "change",
        ({ window, screen }) => {
          // console.log("window")
          // console.log(window)
          // console.log("screen")
          // console.log(screen)
          setDimensions({ window, screen });
        }
      );
      return () => subscription?.remove();
    });
    
    if (dimensions.screen.width >= desktopHeight) { 
        return "desktop"
    } else {
        return "mobile"
    }
}