import { Button, Heading } from "native-base";
import Layout from "../components/Layout";
import { Platform, View as ReactView } from 'react-native'
import React, { useEffect, useRef, useState } from "react";
import WishlistView from "../components/WishlistView";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import { useSelector } from "react-redux";
import { Screens } from "../constants/Screens";

export default function TripOnboardScreen(props) { 

    const onboardAddedFriend = useSelector((state: any) => state.onboard.addedFriend)

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [placePhotos, setPlacePhotos] = useState(null);

    const ref = useRef();

    const map = {};

    useEffect(() => {
        AnalyticsManager.logEvent(events.USER_VIEWED_TRIP_ONBOARD);
    }, [])

    function handleContinue(placeObj) { 
        AnalyticsManager.logEvent(events.USER_SELECTED_TRIP_ONBOARD);
        setSelectedPlace(placeObj);
    }

    function handleCreateTrip() { 
        AnalyticsManager.logEvent(events.USER_CREATE_TRIP_ONBOARD);
        AnalyticsManager.logEvent(events.USER_ONBOARD_FINISH);
        if (Platform.OS === "web") {
            props.navigation.replace(Screens.CreateModal,{place: selectedPlace, photos: placePhotos})
        } else { 
            props.navigation.replace(Screens.Root, {
                screen: Screens.Home,
                params:  {destinationData: {page: Screens.CreateModal, params: {place: selectedPlace, photos: placePhotos}}}
            })
        }
    }

    function handleSkip() { 
        AnalyticsManager.logEvent(events.USER_SKIP_TRIP_ONBOARD);
        AnalyticsManager.logEvent(events.USER_ONBOARD_FINISH);
        if (Platform.OS === "web") { 
            props.navigation.navigate(Screens.Trips)            
        } else { 
            props.navigation.navigate(Screens.Root, { screen: Screens.Home,  params: { profileBranchOpenWait: onboardAddedFriend ? 1000 : 0 }})            
        }
    }

    return <Layout>
       <WishlistView title="Great! Where are you considering or planning a trip to?" selectedPlace={selectedPlace ? selectedPlace.title : null} 
        mapData={map} shouldClear={false} onPhotosLoaded={(photos) => setPlacePhotos(photos) }
       onPlaceSelected={(placeObj) => handleContinue(placeObj)}></WishlistView>
       <Button colorScheme={"rose"} w="100%" mb="2" onPress={() => handleCreateTrip()}>Create trip</Button>
       <Button colorScheme="coolGray" w="100%" variant="subtle" onPress={handleSkip}>Skip</Button>
    </Layout>
}