import AsyncStorage from "@react-native-async-storage/async-storage";
import { Heading, Text, Image, Button, View } from "native-base";
import React, { useEffect, useState } from "react";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import SimpleModal from "./SimpleModal";


export default function NewProductModal(props) { 

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false);

    const key = "announcements"


    useEffect(() => {
        if (!props.hide) {
            setVisibility()
        }
    }, [])

    const setVisibility = async () => { 
        try {
			const value = await AsyncStorage.getItem(key);
			if (value === null || value === undefined) { 
				setVisible(true);
			} else { 
				const currentValue = JSON.parse(value)
				if (!currentValue || currentValue[props.productKey] === null || currentValue[props.productKey] === undefined) { 
					setVisible(true)
				}
			}
		} catch (error) { 
			console.log("Error when fetching last chat view time")
		}
    }

    const onExit = async () => {
        setLoading(true);
	    const val = {}
		val[props.productKey] = true
		await AsyncStorage.setItem(key, JSON.stringify(val))
		AnalyticsManager.logEvent(events.USER_CLOSED_ANNOUNCEMENT)
        setVisible(false);
        setLoading(false);
    }
    
    return <SimpleModal isOpen={visible} onClose={() => null} size="xl"
        notClosable hideHeader
        >
            <View h={400} flexDir="row" flex={1} justifyContent="center" alignItems={"center"}>
                <View>
                <Heading  mb={4} textAlign="center">{props.title}</Heading>
                
                {props.imageUrl && <Image h="150" mb={3} source={{uri: props.imageUrl}} resizeMode="contain"/>}

                <Text textAlign={"center"} mb={4}>{props.body}</Text>

                <Button  onPress={onExit} isLoading={loading}>Got it</Button>
            </View>
            </View>

    </SimpleModal>
}