

const fakeUsers = [
    {
        id: -1,
        first_name: "Your friend",
        username: "your_friend",
        picture_url: "https://roam-public-images.s3.amazonaws.com/public_images/user_1.png"
    },
    {
        id: -2,
        first_name: "Your other friend",
        username: "your_other_friend",
        picture_url: "https://roam-public-images.s3.amazonaws.com/public_images/user_2.png"
    }
]

export default {
    fakeTrip: {
        id: -1,
        uuid: "FAKE_DATA",
        interested: false,
        interest_count: 1,
        interest_users: [fakeUsers[1]],
        phase: 'THINKING',
        photo_reference: "AW30NDy-kmgEtDhNPWYBl2heszSbrVGO3d6qO3ujMIc5lQfermM9c7Y44jazEj7KnPyj81HKYGu0Mhicm0O7GTgbOzR-xTzOI2HpL9ZdfcMFxbEnZNDE9n4l0CKTJPA8e0BPJgJRu6zhVKRruJP18j8oFTaXobMPeW0skWlQmyRzLnUYTbf-",
        place: 'Bora-Bora, French Polynesia',
        lat: -16.5058512, 
        lng: -151.7422329,
        details: "I've always wanted to go to Bora Bora, and I'm making this the year I finally do it! Looking for a mix of relation and high octane activities like snorkeling and kayaking! https://www.youtube.com/watch?v=SdI3BCe6iNs",
        published: true,
        creator: {
            id: -1,
            first_name: "Your friend",
            username: "your_friend",
            picture_url: "https://roam-public-images.s3.amazonaws.com/public_images/user_1.png"
        }
    },

    fakeUsers: fakeUsers,

    fakeCommentsResp: {
        results: [ {
            creator: fakeUsers[0],
            message: "I'm ready! Got all my swimming and beach gear packed!"
        },
        {
            creator: fakeUsers[1],
            message: "I mean I don't know about you, but I'll be enjoying the views more than anything else!"
        }]
    },

    fakeTripEvents : {
        results: [{
            "address": "BP 123 Vaitape - Nunue Bora Bora, 98730, French Polynesia",
            "description": "Heard great things about the food + views here so I made reservations for 8pm!",
            "end_at": null,
            "id": 6,
            "lat": -151.7591713,
            "lng": -16.4890584,
            "picture_url": "https://maps.googleapis.com/maps/api/place/photo?photo_reference=ARywPAJ_46do5471YwrTy7jbbTibwue4xBaTrUfBH2wQL9dDkeOm_kVdff4sfNeHfQZzQwYcAAO4qkQq-gM83k48z_Zr8Ytd5BRT5RM1K98yV337cUT7B7rymp5SKsSEPDjGcMrbLnE6dybB9hN0Is4l-KlThVqgt4ERJ8sQZ9v9b0di4qju&key=AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac&maxwidth=1000",
            "place": "Bora Bora Yacht Club",
            "start_at": null,
            "title": "Bora Bora Yacht Club",
            "trip_id": 14,
          },
          {
            "address": "F756+GR9, Bora-Bora, French Polynesia",
            "description": "This beach looks beautiful! Definitely want to check it out while there!",
            "end_at": null,
            "id": 7,
            "lat": -151.7378875,
            "lng": -16.5412092,
            "picture_url": "https://maps.googleapis.com/maps/api/place/photo?photo_reference=ARywPAKaX7yIf8QJGrTTATldYczW-AddHbnyGtoRQ3hAL_hIzFmjmAgwxE-X2NLQm0jxpWiai8YhHObNvguekPV8W3nivjibLCpQPVvMFQiCcnoQDW2PegfUjz-lltaOvfgrHX0sXc76Bz8IESe0cNmnbvdljwe62X5SN5lHBumdP5nfa_mt&key=AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac&maxwidth=1000",
            "place": "Matira Beach",
            "start_at": null,
            "title": "Matira Beach",
            "trip_id": 14,
          }
        ]
    }

}