import { Heading, Text, Image, View, Button } from "native-base"
import { useEffect, useState } from "react";

export default function SimpleCarousel(props) { 

    const [index, setIndex] = useState(0);

    const { items } = props;

    function handleNext() {
        if (index === items.length - 1) {
            props.onComplete();
        } else { 
            setIndex(index + 1)
        }
    }

    const view = <>
        <View>
            <Image h="300" source={items[index].image} key={items[index].title} resizeMode="contain" mb={1}/>
            <Heading mb={2} textAlign="center">{items[index].title}</Heading>
            <Text textAlign="center" >{items[index].body}</Text>
        </View>

        <View w="100%" mt={3}>
            {!props.buttonRender && <Button variant="ghost" onPress={handleNext}>Next</Button>}
            {props.buttonRender && props.buttonRender(handleNext)}
        </View>
    </>

    return view
}