
import { Heading, View, Text, Button, FlatList, HStack, Input, Icon, Divider, AlertDialog } from 'native-base'
import React, { useEffect, useRef, useState } from 'react'
import * as Contacts from 'expo-contacts';
import Layout from '../components/Layout';
import GenericViewSetAPI from '../api/GenericViewSetAPI';
import { copyAndDeleteKey, copyAndSetMap, generateUUID, showAlert } from '../utils';
import SimpleUserView from '../components/SimpleUserView';
import ProfilePicture from '../components/ProfilePicture';
import { ActivityIndicator, Pressable } from 'react-native';
import { AntDesign, Feather } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import BranchMetrics from '../utils/BranchManager';
import { setOnboardTripId, setOnboardUserAddedFriend } from '../store';
import { AnalyticsManager, events } from '../utils/AnalyticsManager';
import FriendRequestCell from '../components/FriendRequestCell';
import { LoadingModal } from '../components/LoadingModal';
import BaseApiConfigProvider from '../api/BaseApiConfigProvider';

function ProfileFollowRow(props) { 
    const user = props.user
    const name = props.name ?? user?.first_name
    const data = props.data
    if (!data?.phoneNumbers && props.hideNoPhone) { 
        return null
    }

    return <View w="100%" h="60" >
            <HStack w="100%" flexDir="row" alignItems={"center"} justifyContent="space-between" >
                <Pressable onPress={() => {
                    if (!user) {
                        return;
                    }
                    props.navigation.navigate("PublicProfile", {username: user.username})}
                    }>
                <HStack space={3}>
                    {user && <ProfilePicture user={user} />}
                    <View>
                        <Text>{name}</Text>
                        {user && <Text fontSize="xs">@{user.username}</Text>}
                    </View>
                </HStack>
                </Pressable>
                <Button isLoading={props.isLoading} isDisabled={props.isDisabled} onPress={() => props.onPress(user)} leftIcon={<AntDesign name="adduser" size={18} color="white" />} colorScheme={user ? "rose" : "coolGray"}>{props.buttonText}</Button>
            </HStack>
        </View>
}


export default function ContactsScreen(props) { 

    const mainUser = useSelector((state: any) => state.user)
    const onboarding = props.route.params?.onboarding 
    const tripInvite = props.route.params?.context === "trip_invite"
    const trip = props.route.params?.trip

    const [stage, setStage] = useState(0)
    const [contacts, setContacts] = useState<any>(null);
    const [existingContactMap, setExistingContactMap] = useState({})
    const [searchQuery, setSearchQuery] = useState(null)
    const phoneNumbersMap = {}
    const [contactsBank, setContactsBank] = useState(null);
    const [loadingMap, setLoadingMap] = useState({});
    const [doneMap, setDoneMap] = useState({})
    const [didSearch, setDidSearch] = useState(false)
    const [loadingContacts, setLoadingContacts] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const dialogRef = useRef(null);

    const [currentInviteData, setCurrentInviteData] = useState(null);

    const dispatch = useDispatch();

    const [friendUser, setFriendUser] = useState(null);
    
    const [friendSuggestions, setFriendSuggestions] = useState([])
    const [friendRequests, setFriendRequests] = useState([])

    const [saving, setSaving] = useState(false);

    const [finishedLoading, setFinishedLoading]  = useState(false);

    const accMobileApi = new GenericViewSetAPI('account_mobile');
    const frApi = new GenericViewSetAPI("friend_request");
    const tripInviteApi = new GenericViewSetAPI("trip_invite");

    const tripApi = new GenericViewSetAPI("trip")
    const userApi = new GenericViewSetAPI("user");


    const handleDeepLink = async (page, pageParams) => {
        console.log("Triggering deep link with", page, pageParams)
        AnalyticsManager.logEvent(events.BRANCH_DEEP_LINK_TRIGGER, {page: page, pageParams: JSON.stringify(pageParams)});
        if (page === "PublicProfile" && pageParams.username) { 
            return await getUser(pageParams.username);
        } else if (page === "TripDetail" && pageParams.tripId) { 
            dispatch(setOnboardTripId(pageParams.tripId))
            return await getTripAndSetUser(pageParams.tripId)
        }
    }

    useEffect(() => {
        props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={() => {
           setSaving(true)
        }}>Continue</Button>})
        getInitialData()

    }, [])

    const didAddFriend = () => {
        dispatch(setOnboardUserAddedFriend(true))
    }

    useEffect(() => {
        if (saving) { 

            if (!onboarding) { 
                setSaving(false)
                props.navigation.goBack()
                return;
            }


            const noActivity =  !friendUser && friendRequests.length == 0 && friendSuggestions.length == 0
            // If user clicks continue and it's at the end (stage = 2) or there is no activity -- go to next screen
            if (noActivity || stage === 2) { 
                if (didSearch) { 
                    AnalyticsManager.logEvent(events.USER_SEARCH_CONTACT)
                }
                // console.error("NO ACTIVITY --- GOING TO ONBOARD NOTIFICATION");
                props.navigation.replace(props.route.params?.dest ?? "OnboardNotification")
            } else {
                var nextStage = stage + 1;
                if (stage === 0 && !noActivity) { 
                    nextStage = 2
                }
                setStage(nextStage)
                setSaving(false)
            }
        }
    }, [saving])


    const getTripAndSetUser = async (tripId) => {
        let tripResp = await tripApi.nonStandard("GET", `${tripId}/uuid`, null, {
            expansions: ['creator', 'trip_interest_users', 'trip_aggregation_counts']
        })
        console.warn(tripResp);
        if (tripResp && !tripResp.error) {
            setFriendUser(tripResp.creator);
            return tripResp.creator;
        } else {
            showAlert("Sorry we couldn't load your friends social profile. Try again later!");
            return null
        }
    }

    const getUser = async (username) => {
        let userResp = await userApi.query({ username: username.toLowerCase(), expansions: ["friend_status", "trip_aggregation_counts"] });
        console.log('get user resp ', userResp)
        if (userResp && !userResp.error && userResp.results) {
            console.log(userResp)
            setFriendUser(userResp.results[0])
            return userResp.results[0]
        } else {
            showAlert("Sorry we couldn't load your friends social profile. Try again later!");
            return null;
        }
    }

    const getFriendSuggestions = async (username) => {
        var url = "friend_suggestions"
        const params: any = {}
        if (username) { 
            params.username = username
        }
        const userFriendsResp = await userApi.nonStandard("GET", url, null, params)
        console.log('friend_suggestion: ', userFriendsResp)
        if (userFriendsResp && !userFriendsResp.error) { 
            const users = userFriendsResp.results
            setFriendSuggestions(users);
        } 
    }

    const getInitialData = async () => {
        setFinishedLoading(false);
        if (onboarding) { 
            const installParams = await BranchMetrics.getInstallParams();
            const {page, pageParams} = BranchMetrics.parseAndReturnLinkParams(installParams);
            var user = await handleDeepLink(page, pageParams)
            console.error("USER IS ", user)
            if (!user) { 
                if (__DEV__ || BaseApiConfigProvider.isStage()) { 
                    user = await handleDeepLink("PublicProfile", {username: "jaytravels", context: 'invite'})
                }
            }
            console.warn(user)
            var username = null
            if (user) { 
                username = user.username
            }

            getFriendSuggestions(username);
            getFriendRequests()
        }

        const { status } = await Contacts.getPermissionsAsync();
        if (status === "granted") { 
            getContactsAccess();
        }
        setFinishedLoading(true)
    }

    const getFriendRequests = async() => { 
        const friendReqResp = await frApi.query({ sent_to_me: true, expansions: ["requestor"] });
        console.log("friend reqs: ", friendReqResp)
        if (friendReqResp && !friendReqResp.error) {
            setFriendRequests(friendReqResp.results)
        }
    }

    const getContactsAccess = async () => {
        setLoadingContacts(true)
        AnalyticsManager.logEvent(events.USER_CLICK_CONTACT_BTN)
        const { status } = await Contacts.requestPermissionsAsync();
        if (status === 'granted') {
          AnalyticsManager.logEvent(events.USER_CONTACT_ACCESS_GRANTED)
          const { data } = await Contacts.getContactsAsync({
            fields: [Contacts.Fields.PhoneNumbers],
          });

          setContactsBank(data)
          await findUsersOnFullTrip(data)
        } else { 
            AnalyticsManager.logEvent(events.USER_CONTACT_ACCESS_REJECTED)
            showAlert("We can't find your friends on Fulltrip without access to your contacts")
        }
        setLoadingContacts(false);
    }

    const findUsersOnFullTrip = async (data) => {
        // console.log(data)
        data.map((user) => user.phoneNumbers?.map((number) => {
            var mobile = number.digits
            if (!mobile && number.number) { 
                mobile = convertFormattedPhoneNumberToDigits(number.number)
            }
            if (mobile.length === 10) { 
                mobile = `+1${mobile}`
            } else { 
                if (!mobile.includes("+")) { 
                    mobile = `+${mobile}`
                }
            }
            number.formattedNumber = mobile;
            phoneNumbersMap[mobile] = user
        }))
        
        const numbers = Object.keys(phoneNumbersMap);
        // console.log(numbers)
        const body = {
            numbers: numbers
        }
        const response = await accMobileApi.create(body, { expansions: ['friend_status']});
        console.log('user account number resp ', response)
        if (response && !response.error) { 
            const usersMap = response;
            const existingUserPhones = Object.keys(usersMap)
            const uniqueExistingUserIdsMap = {}
            existingUserPhones.map((number) => {
                let contact = phoneNumbersMap[number]
                if (contact) { 
                    uniqueExistingUserIdsMap[contact.id] = usersMap[number]
                }
            })
            setExistingContactMap(uniqueExistingUserIdsMap);
            const fullTripContacts = data.filter((contact: any) => uniqueExistingUserIdsMap[contact.id])
            const nonFulltripContacts = data.filter((contact: any) => !uniqueExistingUserIdsMap[contact.id])
            const finalizedContacts = [...fullTripContacts, ...nonFulltripContacts]

            // console.log(nonFulltripContacts)
            setContacts(finalizedContacts)
            setStage(1)
        } else { 
            showAlert("Sorry, something went wrong when finding friends on fulltrip. Please try again")
            setStage(0);
        }
    }


    const createInvite = async (id, targetId, mobile, type="fr") => { 
        const body: any = {requestor: mainUser.id}
        didAddFriend();
        setLoadingMap(copyAndSetMap(id, true, loadingMap))

        const eventBody: any = {source: 'contacts_screen'}

        if (targetId) { 
            if (type === "fr") { 
                body.target = targetId
            } else if (type === "trip") { 
                body.user = targetId
            }
        } else { 
            eventBody.invite = true
            body.mobile = mobile
            AnalyticsManager.logEvent(events.USER_INVITE_CONTACT, {type: type})
        }
        console.warn(body)
        
        if (type === "fr") {
            AnalyticsManager.logEvent(events.USER_ADD_FRIEND, eventBody); 
        } else {
            body.trip = trip.id
            body.guest_mobile = body.mobile
        }
        
        const api = type === "fr" ? frApi : tripInviteApi;

        const frResponse = await api.create(body)
        if (frResponse && !frResponse.error) {
            setDoneMap(copyAndSetMap(id, true, doneMap));
        } else { 
            console.log(frResponse)
            showAlert(`Sorry something went wrong when sending friend request to your friend`)
        }
        setLoadingMap(copyAndDeleteKey(id, loadingMap))
    }

    const createFriendRequest = async (id, targetId, mobile) => {
        createInvite(id, targetId, mobile, "fr");
    }
    
    useEffect(() => {
        if (searchQuery) { 
            if (!didSearch) { 
                setDidSearch(true);
            }
            const filteredContacts = contactsBank.filter((contact) => contact.name?.includes(searchQuery))
            setContacts(filteredContacts)
        } else { 
            setContacts(contactsBank)
        }
    }, [searchQuery])

    const handleFrResponse = async (frId: string, accepted: boolean) => {
        if (accepted) { 
            didAddFriend()
        }
        const now = Date()
        AnalyticsManager.logEvent(events.USER_RESPOND_TO_FRIEND_REQUEST, {source: 'contacts'})
        const reqBody = { accepted: accepted }

        const respondResp = await frApi.nonStandard("POST", `${frId}/respond`, reqBody);
        if (respondResp.error) {
            showAlert("Something went wrong when responding to the friend request. Please try again!");
        }
        console.log(respondResp)
        await getFriendRequests()
    }


    const renderRequestItems = (item) => {
        if (item === 0) {
            if (friendRequests.length === 0) { 
                return null;
            }
            return <>
                <Heading fontSize="xl" mb={3}>Your friend requests</Heading>
                {friendRequests && friendRequests.map((fr) => <FriendRequestCell fr={fr} navigation={props.navigation} handleFrResponse={handleFrResponse}/>)}
            </>
        } 
        if (item === 1) {
            if (!friendUser && friendSuggestions.length == 0) { 
                return null;
            }
            return <>
                <Heading fontSize="xl" mb={3}>People we think you might know</Heading>
                {friendUser && <ProfileFollowRow user={friendUser} isLoading={loadingMap[friendUser.id]} navigation={props.navigation} isDisabled={doneMap[friendUser.id]} buttonText={"Add friend"} onPress={() => createFriendRequest(friendUser.id, friendUser.id, null)}/>}
                {friendSuggestions && friendSuggestions.map((usr) => <ProfileFollowRow navigation={props.navigation} isLoading={loadingMap[usr.id]} isDisabled={doneMap[usr.id]} user={usr} buttonText={"Add friend"} onPress={() => createFriendRequest(usr.id, usr.id, null)}/> )}
            </>
        }
    }


    function convertFormattedPhoneNumberToDigits(formattedNumber) {
        return formattedNumber.replace(/[^0-9]/g, '');
    }

    async function createTripInvite(id, targetId, mobile) { 
        createInvite(id, targetId, mobile, "trip")
    }

    function handleInvitePress(item, user, name) { 
        if (tripInvite) { 
            setCurrentInviteData({"type": "trip_invite", "id": item.id, "userId": user?.id, "mobile": item.phoneNumbers[0].formattedNumber, name: name})
        } else { 
            setCurrentInviteData({"type": "fr", "id": item.id, "userId": user?.id, "mobile": item.phoneNumbers[0].formattedNumber, name: name})
        }
        setConfirmDialogOpen(true)
    }

    function handleInviteConfirmation() { 
        const {id, userId, mobile, type} = currentInviteData;
        if (type === "trip_invite") { 
            createTripInvite(id, userId, mobile)
        } else if (type === "fr") { 
            createFriendRequest(id, userId, mobile)
        }
        setCurrentInviteData(null);
        setConfirmDialogOpen(false);
    }

    function getActionText(accExists) { 
        if (tripInvite) {
            return accExists ? "Invite friend" : "Invite friend"
        } else { 
            return accExists ? "Add travel friend" : "Invite friend"
        }
    }

    function closeConfirmModal() { 
        setCurrentInviteData(null);
        setConfirmDialogOpen(false)
    }

    return <Layout>
        <LoadingModal loading={!finishedLoading}></LoadingModal>
        {stage == 0 && <View mt="5"><Heading>{tripInvite ? "Invite your friends to the Trip" : "Find your friends on Fulltrip"}</Heading>
        {/* <Text mb={1} mt={1}>Discover new travel opportunities and see where your travel friends are considering/planning a trip to!</Text> */}
        <Text mb={1} mt={1} fontSize="md">{tripInvite ? "We'll take care of sending reminders + updates for you!" : "See where your friends are planning trips to and join in!"}</Text>
        <Text mt="1" fontSize="2xs">We won't ever contact people in your contact list without your permission</Text>
        <View mt="3">
            <Button colorScheme={"rose"} onPress={() => getContactsAccess()} isLoading={loadingContacts}>Open your contacts</Button>
        </View>
        </View>} 

        {stage == 1 && <>
            <Input mt={3} size="md" w="100%" variant="filled" placeholder="Search by name" value={searchQuery} onChangeText={(val) => setSearchQuery(val)}
            InputLeftElement={<Icon as={Feather} name="search" ml="2" size="4" />} />
            <FlatList mt="4" data={contacts} w="100%" 
                keyboardShouldPersistTaps="always"
                renderItem={({item, index} : any) => <ProfileFollowRow hideNoPhone data={item} isLoading={loadingMap[item.id]} user={existingContactMap[item.id]} navigation={props.navigation} isDisabled={doneMap[item.id]}
                buttonText={doneMap[item.id] ? "Invite sent" : getActionText(existingContactMap[item.id])} name={item.name} onPress={(user) => handleInvitePress(item, user, item.name)}/>
            }
            />
        </>}

        {stage === 2 && <>
            <FlatList mt="4" data={[0, 1]} w="100%" 
            renderItem={(item) => renderRequestItems(item.item)} 
            />
        </>}

        <AlertDialog leastDestructiveRef={dialogRef} isOpen={confirmDialogOpen} onClose={closeConfirmModal}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Confirm invite</AlertDialog.Header>
                <AlertDialog.Body>
                    {`Are you sure you want to invite ${existingContactMap[currentInviteData?.id]?.first_name ?? currentInviteData?.name}?`}
                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={closeConfirmModal} ref={dialogRef}>
                            Cancel
                        </Button>
                        <Button colorScheme="danger" onPress={handleInviteConfirmation}>
                            Send invite
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>

    </Layout>
}