import { Entypo, FontAwesome } from "@expo/vector-icons";
import { Button, Icon, Input, Modal, useClipboard, View, Text, Pressable, HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Linking, Platform } from "react-native";
import { useSelector } from "react-redux";
import Colors from "../constants/Colors";
import { isInstagramInstalled, showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import ReactNativeShare from "../utils/ReactNativeShare";

export default function SocialShareModal(props) {

    const user = useSelector((s: any) => s.user)
    const [finishedIGCheck, setFinishedIGCheck] = useState(false);
    const [hasIG, setHasIG] = useState(false);
    const [igPhase, setIgPhase] = useState(0);

    const {
        value,
        onCopy
      } = useClipboard();

    useEffect(() => {
        handleIGChecking();
    }, [])


    useEffect(() => { 
        if (props.isOpen) { 
        }
    }, [props.isOpen, hasIG])


    const handleIGChecking = async () => {
        const isInstaInstalled = await isInstagramInstalled();
        setHasIG(isInstaInstalled);
        setFinishedIGCheck(true);
    }

    const handleClose = () => {
        setIgPhase(0);
        if (props.onClose) { 
            props.onClose()
        }
    }

    const handleLinkShare = () => { 
        AnalyticsManager.logEvent(events.USER_SHARED_LINK, { link_type: props.linkType, source: props.source, share_type: 'link' });
        props.onLinkShare();
        // props.onClose();
    }

    const handleCopy = () => {
        onCopy(`${props.link}`)
        showAlert("Link copied!")
    }

    function handleShareIgClick() { 
        if (props.showCopyLink && igPhase == 0) { 
            setIgPhase(1)
        } else { 
            handleShareIG();
        }
    }

    const handleShareIG = async () => { 
        AnalyticsManager.logEvent(events.USER_SHARED_LINK, { link_type: props.linkType, source: props.source, share_type: 'instragram-stories' });
        await ReactNativeShare.shareToIG(props.uri, Colors.fulltripTheme);
        if (!props.showCopyLink) { 
            props.onClose()
        }
    }

    const onInvitePress = () => {
        AnalyticsManager.logEvent(events.USER_PRESS_SHARE_INVITE, { source: props.source });
        if (props.onInvitePress) { 
            props.onInvitePress()
        }
    }


    const getChildView = () => { 
        if (igPhase == 1 || Platform.OS === "web") {
            return <>
            <Text mb={2}>Your link to copy</Text>
            <Input mb={3}
                value={props.link}
                isReadOnly
                InputRightElement={
                    <Button colorScheme={"coolGray"} variant="subtle"  rounded="none" h="full" onPress={handleCopy}>Copy</Button>
                } 
            />
            {Platform.OS !== "web" && <Button mb="3" variant="subtle" isLoading={!props.uri} isLoadingText={"Share to Close Friends Story"} onPress={() => handleShareIgClick()} leftIcon={<Icon as={Entypo} name="instagram"></Icon>} rounded={"xl"} colorScheme="fuchsia">Share to Close Friends Story</Button>}
            </>
        } else { 
            return <>
            {props.onInvitePress && <Pressable colorScheme={"coolGray"} bg="coolGray.700" pt={2} pb={2} rounded="lg" mb={2} onPress={onInvitePress}>
                <HStack w="100%" justifyContent={"center"} space={2}>
                    <Icon as={FontAwesome} name="send" mt={1} color="white" />
                    <Text textAlign={"center"} color="white" fontSize="lg" >Send invite</Text>
                </HStack>
            <Text textAlign={"center"} color="white">Comes with reminders + updates</Text>
            </Pressable>}

        <Button leftIcon={<Icon as={Entypo} name="share-alternative" />} onPress={() => handleLinkShare()} rounded={"xl"} variant="subtle" colorScheme={"coolGray"} mb={2}>Share link with friends</Button>
        {!finishedIGCheck && !props.hideIG && <ActivityIndicator></ActivityIndicator>}
        {finishedIGCheck && hasIG && !props.hideIG && <View>
           <Button mb="3" variant="subtle" isLoading={!props.uri} isLoadingText={"Share to Close Friends Story"} onPress={() => handleShareIgClick()} leftIcon={<Icon as={Entypo} name="instagram"></Icon>} rounded={"xl"} colorScheme="fuchsia">Share to Close Friends Story</Button>
        </View>}</>
        }
    }

    return <Modal isOpen={props.isOpen} onClose={handleClose} size="xl" minW="300" w="100%">
        <Modal.Content maxH={300} >
            <Modal.CloseButton />
            <Modal.Header>{props.title ?? "Share"}</Modal.Header>
            <Modal.Body w="100%" bg="white">
                <View>
                    {props.getSocialView ? props.getSocialView() : <View h="200"></View>}
                    <View style={{position: 'absolute', top: 0, right: 0, zIndex: 4, height: '100%', width: '100%'}} bgColor="white">
                        {getChildView()}
                    </View>
                </View>
            </Modal.Body>
            {props.renderFooter ? props.renderFooter() : null}
        </Modal.Content>
    </Modal>
}