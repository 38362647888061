import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { Provider } from 'react-redux';
import { initializeStore, setConfig } from './store';
import { NativeBaseProvider, Box } from "native-base";
import GenericViewSetAPI from './api/GenericViewSetAPI';
import BaseApiConfigProvider from './api/BaseApiConfigProvider';
import { setUser } from './store';
import * as Sentry from "sentry-expo";
import Constants from 'expo-constants'
import Config from './constants/Config';
import { getSentryReleaseString } from './utils';
import Toast, { BaseToast, InfoToast } from 'react-native-toast-message';

if (!__DEV__) { 
  console.log = () => null
  console.warn = () => null
}

Sentry.init({
  dsn: 'https://737a5238b8344a63b56c5e07641fa5f7@o4504325256249344.ingest.sentry.io/4504325257691136',
  enableInExpoDevelopment: false,
  debug: __DEV__,
  release: getSentryReleaseString(),
  dist: Config.internalVersion, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production=
  environment: Constants.expoConfig.extra ? Constants.expoConfig.extra.appEnv : '-'
});

const store = initializeStore()

const toastConfig = {
  info: (props) => (
    <InfoToast
      {...props}
      contentContainerStyle={{ paddingVertical: 10 }}
      text1Style={{
        fontSize: 18,
      }}
      text2Style = {{
        fontSize: 15
      }}
      text2NumberOfLines={2}
    />
  )
}

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [apiFinished, setApiFinished] = useState(false)

  useEffect(() => {
    async function maybeGetUser() {
      await BaseApiConfigProvider.loadUserEnvPreference();
      
      const userApi = new GenericViewSetAPI("user")
      const configApi = new GenericViewSetAPI("config")

      const token = await BaseApiConfigProvider.loadUserToken();
      console.log('App.tsx token is ', token)
      if (token) {
        const userInfo = await userApi.nonStandard("GET", "me");
        console.log('me response is ', userInfo);
        if (!userInfo.error) {
          store.dispatch(setUser(userInfo));
        } else {
          // await BaseApiConfigProvider.removeUserToken();
        }
      } else { 
        console.log("No token found,")
      }
      const userConfig = await configApi.query({});
      console.log(userConfig)
      if (userConfig && !userConfig.error) { 
        store.dispatch(setConfig(userConfig));
      }
      setApiFinished(true);
    }
    maybeGetUser()
  }, [])


  const statusBar = <StatusBar style={"dark"} />

  if (!isLoadingComplete || !apiFinished) {
    return null;
  } else {
    return (
      <Provider store={store}>
        
        <NativeBaseProvider>
          <SafeAreaProvider>
            {statusBar}
            <Navigation colorScheme={colorScheme} />
          </SafeAreaProvider>
        </NativeBaseProvider>
        <Toast config={toastConfig}/>
      </Provider>
    );
  }
}
