import { Entypo } from "@expo/vector-icons";
import { HStack, Icon, View } from "native-base";
import React, { useState } from "react";


export default function NumberOfStars(props) { 

    const getStars = () => {
        const newStars = []
        for (var i = 0; i < Math.floor(props.rating); i++) { 
            newStars.push(i)
        }
        return newStars
    }


    return <HStack>
      {getStars().map((item) => <Icon as={Entypo} name="star" color="yellow.400"/>)}  
    </HStack>

}