import { Button, Heading, Text, View, Image, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import { getErrorString, showAlert } from "../utils";
import * as WebBrowser from 'expo-web-browser';
import ReactNativeWebViewModal from "../components/ReactNativeWebViewModal";
import { LoadingModal } from "../components/LoadingModal";
import { useDispatch } from "react-redux";
import { setUser } from "../store";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";

export default function PaymentsOnboardScreen(props) { 

    const moreDetails = props.route.params?.moreDetails;

    const stripeApi = new GenericViewSetAPI("stripe");
    const userApi = new GenericViewSetAPI("user");
    const dispatch = useDispatch()

    const [index, setIndex] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [modalUrl, setModalUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [meLoading, setMeLoading] = useState(false)


    const items = [
        {
            title: "Trip deposits from Fulltrip",
            body: "The easiest way to pay for your group trip, so you can focus on planning an amazing experience",
            // image: require("../assets/images/Group_630.png")
            image: "https://roam-public-images.s3.amazonaws.com/system_images/Group+630.png"
        }, 
        {
            title: "Share your trip link and collect payments",
            body: "Let your friends pay through whatever method they want. Money will become available in your account 3 days after payment is made",
            // image: require("../assets/images/social_trip_link.png")
            image: "https://roam-public-images.s3.amazonaws.com/system_images/social_trip_link.png"

        },
        {
            title: "We take care of the boring stuff",
            body: "Automatic refunding when people cancel, and we'll take care of reminding people who have not completed payments over and over again :) ",
            // image: require("../assets/images/money_due.png")
            image: "https://roam-public-images.s3.amazonaws.com/system_images/money_due.png"
        }
    ]

    const getViewItemForIndex = (index) => { 
        const view = <React.Fragment>
            <Heading mb={3} textAlign="center">{items[index].title}</Heading>
            <Image h="300" source={{uri: items[index].image}} resizeMode="contain" mb={1}/>
            <Text textAlign="center" >{items[index].body}</Text>
        </React.Fragment>

        return view
    }

    useEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button variant="ghost" onPress={() => {
                // AnalyticsManager.logEvent(events.MOBILE_VERIFY_CANCEL)
                props.navigation.goBack()
            }}>Cancel</Button> 
        })

        AnalyticsManager.logEvent(events.USER_OPEN_PAYMENT_ONBOARD)

    }, [])

    const onboardToConnect = async () => {
        setLoading(true)
        AnalyticsManager.logEvent(events.USER_CLICK_START_PAYMENT_ONBOARD)
        const resp = await stripeApi.nonStandard("POST", "connect_account", {});
        if (resp && !resp.error) { 
            setModalUrl(resp.link)
            setOpenModal(true)
        } else {    
            const errorMsg = getErrorString(resp.error, "Sorry, we could create your payment account. Please try again later")
            showAlert(errorMsg);
        }
        setLoading(false)
    }
    

    const refreshUser = async () => {
        AnalyticsManager.logEvent(events.USER_FINISH_PAYMENT_ONBOARD)
        const meResp = await userApi.nonStandard("GET", "me")
        if (meResp && !meResp.error) { 
            dispatch(setUser(meResp))
            console.log("Updated user, now going back...")
            props.navigation.goBack()
        } else { 
            if (meResp) {
                const errorStr = getErrorString(meResp.error, "Sorry we couldn't connect to your payments account. Try again")
                showAlert(errorStr)
            } else { 
                showAlert("Sorry something went wrong. Make sure you are connected to the internet and try again")
            }
        }
    }

    return <Layout scrollView>
        {!moreDetails && <View mt={7} mb={3}>
            {getViewItemForIndex(index)}
        </View>}

        {moreDetails && <View mt={7} mb={3}>
            <Heading mb={3}>Looks like we need more details</Heading>
            <Text>Before you can start accepting trip deposits, we need to collect more information from you! This should only take a few minutes of your time.</Text>
            </View>}

        <View w="100%" mt={3}>
            {!moreDetails && index <= items.length-2 && <Button variant="ghost" onPress={() => setIndex(index + 1)}>Next</Button>}
            <Button onPress={onboardToConnect} colorScheme="rose" isLoading={loading}>{moreDetails ? "Continue" : "Get started"}</Button>

            <View mt={3}>
                {!moreDetails && <Pressable onPress={() => WebBrowser.openBrowserAsync("https://www.onfulltrip.com/terms")}><Text textAlign={"center"} mt={3} fontSize="xs" >By agreeing creating a payment account, you agree to our <Text underline>Payments Terms of Service</Text></Text></Pressable>}
                <Pressable onPress={() => WebBrowser.openBrowserAsync("https://www.onfulltrip.com/faq")}><Text textAlign={"center"} mt={3} fontSize="xs" ><Text underline>View frequently asked questions</Text></Text></Pressable>
            </View>
        </View>

        <ReactNativeWebViewModal successIdent="https://www.onfulltrip.com/connect/finish"  hideExit isOpen={openModal} url={modalUrl} onClose={() => refreshUser()}/>
        <LoadingModal isOpen={meLoading} />
    </Layout>
}