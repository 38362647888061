import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { Button, Text } from 'native-base';
import TEXT from '../translations.json'

WebBrowser.maybeCompleteAuthSession();

export default function App(props) {
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    expoClientId: '922173562427-to8dru0caigp5kelvtggfr3vgkbcid2c.apps.googleusercontent.com',
    iosClientId: '157158916744-30ecvc93mnnd11umc4otapqnjespc91q.apps.googleusercontent.com',
    androidClientId: '157158916744-ki7shbntcidvg5ku2qj5fpurd1qsh4t4.apps.googleusercontent.com',
    webClientId: '157158916744-mcbo14i09b1v72cc9rtbhs27ivsvrskn.apps.googleusercontent.com'
  });

  React.useEffect(() => {
    props.onGoogleResponse(response);
  }, [response]);

  return (
    <Button {...props.buttonProps} onPress={() => promptAsync()} variant="subtle" backgroundColor={`${props.themeColor ? props.themeColor : 'rose'}.400`}><Text bold color={`${props.themeColor  ? props.themeColor : 'rose'}.900`}>{TEXT.continueWithGoogle}</Text></Button> 
  );
}