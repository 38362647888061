import { Modal, Image, Heading, Text, View, Button, Badge, Link } from "native-base";
import React from "react";
import { getDatesString, openBrowserLinkForPlaceId } from "../utils";
import { A } from '@expo/html-elements';
import { Pressable } from "react-native";
import * as WebBrowser from 'expo-web-browser'

export default function TripEventModal(props) { 

    const event = props.event;
    if (!event) { 
        return null
    }
    return <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl" minH={"400"}>
         <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>{event.place}</Modal.Header>
            <Modal.Body w="100%">

            {event.picture_url && <Image mb={2} source={{ uri: event.picture_url }} height={200} resizeMode="cover" />}

            {event.submitted && !event.published && <Badge colorScheme={"info"} w="100" mt={2} mb={1}>Submitted</Badge>}

            {/* <Heading>{event.place}</Heading> */}
            {event.start_at && <Text color={"coolGray.700"}>{getDatesString(event.start_at, event.end_at)}</Text>}
            {event.address && <Pressable onPress={() => WebBrowser.openBrowserAsync(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.address)}`)}><Text underline mb={3} mt={1} >{event.address}</Text></Pressable>}
            {event.place_id && <Button mb="2" variant={"ghost"} onPress={() => openBrowserLinkForPlaceId(event.place_id, event.address)}>Learn more</Button>}

            {event.description && <View><Heading size="md" mb={1} mt={2}>Details</Heading><Text fontSize="md">{event.description}</Text></View>}
            </Modal.Body>
        </Modal.Content>

    </Modal>
}


