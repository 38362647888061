import { Heading, Text } from "native-base"
import React, { useState } from "react"
import { Linking, StyleSheet, Image } from "react-native";
import ParsedText from "react-native-parsed-text"
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import { LinkPreview } from "./LinkPreview"
import * as WebBrowser from 'expo-web-browser';

function handleUrlPress(url, matchIndex /*: number*/) {
    WebBrowser.openBrowserAsync(url);
  }

export default function (props) {
    const [urlText, setURLText] = useState(null)

    function getRenderText(matchingString) { 
        setURLText(matchingString)
        if (props.doNotRenderText) {
            return null
         }  else if (props.renderText) { 
            return props.renderText(matchingString)
         } else { 
            return <Text>{matchingString}</Text>
         }
    }

    function getDefaultStyle() { 
        if (props.doNotRenderText) { 
            return {display: 'none'}
        } else { 
            return {}
        }
    }

    return <><ParsedText {...props.parsedTextProps} style={{...getDefaultStyle(), ...props.parsedTextStyle}} 
    parse={
        [
            { type: 'url', style: styles.url, onPress: handleUrlPress, renderText: (matchingString, matches) => getRenderText(matchingString) }
        ]
    }
    >
        {props.data}
    </ParsedText>

    <LinkPreview text={urlText} 
        renderLinkPreview={props.hidePreview ? () => null : null}
        renderImage={props.hideImage ? () => null : null}
        // renderImage={(image) => <Image source={{uri: image.url}} style={{width: '100%', height: 200, ...props.imageStyle}} onLoad={() => props.onLoadImage ? props.onLoadImage() : () => null}/>} 
        containerStyle={{ marginBottom: 10, marginTop: 10 }} proxyUrl={`${BaseApiConfigProvider.getEnvUrl()}/proxy/?url={}`} renderText={() => null}
        renderTitle={props.hidePreview? () => null : (title) => {
            return <Heading fontSize={"lg"} bold>{title}</Heading>
        }}
        renderDescription={props.hidePreview? () => null : (description) => {
            return <Text numberOfLines={2}>{description}</Text>
        }}
    /> 
    </>
}


const styles = StyleSheet.create({
    url: {
      color: 'blue',
      textDecorationLine: 'underline',
      flexShrink: 1,
    }
  
  });