import AsyncStorage from '@react-native-async-storage/async-storage';
import { Container, View, Center, Button, Text, Image, Box, Heading, Divider, Badge, ScrollView, AlertDialog, Icon, Actionsheet } from 'native-base';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ActivityIndicator, ImageBackground, Linking, Platform, Pressable, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import GenericViewSetAPI from '../api/GenericViewSetAPI';
import { Entypo, EvilIcons, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import TEXT from "../translations.json"
import BaseApiConfigProvider from '../api/BaseApiConfigProvider'
import React from 'react';
import Layout, { CenterLayout } from '../components/Layout';
import ProfilePicture from '../components/ProfilePicture';
import { useFocusEffect } from '@react-navigation/native';
import { showAlert, getRandomTheme, shareLink, getImageFromRef, getVersionString, buildURLQuery, getCountString, getErrorString } from '../utils';
import TripRow from '../components/TripRow';
import useViewType from '../hooks/useViewType';
import { AnalyticsManager, events } from '../utils/AnalyticsManager';
import BranchMetrics from '../utils/BranchManager';
import SocialShareModal from '../components/SocialShareModal';
import SocialProfileView from '../components/SocialProfileView';
import WebLink from '../components/WebLink';
import * as Sentry from 'sentry-expo';
import Blocklist from '../utils/BlockList';
import AddFriendListRowVC from '../controllers/AddFriendListRowVC';
import * as WebBrowser from 'expo-web-browser';
import StripeConnectDashboardVC from '../controllers/StripeConnectDashboardVC';
import { Screens } from '../constants/Screens';
import FakeData from '../constants/Data';


type DeletionType = "friend" | "account"

export default function ProfileScreen(props) {
    var userApi = new GenericViewSetAPI("user");
    var friendRequestApi = new GenericViewSetAPI("friend_request");
    var friendApi = new GenericViewSetAPI("friend");
    var tripApi = new GenericViewSetAPI("trip");
    const stripeApi = new GenericViewSetAPI("stripe");

    const username = props.route.params?.username;
    const context = props.route.params?.context;

    const privateUser = useSelector((state: any) => state.user)
    const cancelRef = React.useRef(null);
    const viewType = useViewType();
    const userProfileRef = useRef();

    const [user, setUser] = useState(null);
    const [isMyProfile, setIsMyProfile] = useState(true)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [primaryActionLoading, setPrimaryActionLoading] = useState(false);
    const [secondaryActionLoading, setSecondaryActionLoading] = useState(false);
    const [trips, setTrips] = useState(null);
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const [showShareView, setShowShareView] = useState(false);
    const [shareUri, setShareUri] = useState(null);
    const [showSocialShareModal, setShowSocialShareModal] = useState(false);
    const [socialPictureLoaded, setSocialPicturedLoaded] = useState(false);
    const [deletionType, setDeletionType] = useState<DeletionType>("friend")

    const [deepLinkUrl, setDeepLinkUrl] = useState(`https://4vq5c.app.link/profile?${buildURLQuery({page: 'PublicProfile', url: `PublicProfile?username=${username}&context=${context}`, pageParams:{username: username}})}`)

    async function potentiallyGetUser(canUpdate) {
        var username = null;
        console.log(props)
        if (props.route.params) {
            username = props.route.params.username;
            if (canUpdate) {
                setIsMyProfile(false);
            }
        }

        var userResp = null;
        if (username) {
            userResp = await userApi.query({ username: username.toLowerCase(), expansions: ["friend_status", "friend_count", "trip_aggregation_counts", "travel_wishlist"] });
        } else {
            userResp = await userApi.getById(privateUser.id, { expansions: ["friend_count", "trip_aggregation_counts", "travel_wishlist"] });
        }
        var userObj = null;
        if (userResp && !userResp.error) {
            if (userResp.results) {
                userObj = userResp.results[0];
            } else {
                userObj = userResp;
            }
        } else {
            showAlert("Something went wrong when loading the user profile. Please try again");
            return;
        }

        console.log(userObj)
        if (userObj && privateUser && userObj.id == privateUser.id) {
            if (canUpdate) {
                setIsMyProfile(true);
            }
            AnalyticsManager.setUserProperties({number_of_friends: userObj.friend_count})
        }
        if (canUpdate && userObj) {
            userObj.username = userObj.username.toLowerCase()
            setUser(userObj);
            props.navigation.setOptions({title: userObj.username})
        }

        if (privateUser && userObj) {
            fetchUserActivity(userObj);
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            let isActive = true;
            console.log("FETCHING USER ON FOCUS EFFECT");
            AnalyticsManager.logEvent(events.USER_VIEWED_PROFILE);
            potentiallyGetUser(isActive)
            return () => {
                isActive = false;
            };
        }, [])
    );

    const awaitRef = ref => new Promise(resolve => {
        const getElement = () => {
          if(ref) {
            resolve(ref);
          } else {
            requestAnimationFrame(getElement);
          }
        };
        getElement();
      });

    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button leftIcon={<Icon as={EvilIcons} name="gear" size="lg" />} variant="link" onPress={() => setShowSettingsModal(true)}></Button>
        })
    }, [props.navigation])

    async function fetchUserActivity(targetUser) {
        const tripResp = await tripApi.query({ user_id: targetUser.id, expansions: ["creator", "trip_interest_count", "trip_interest_users"] });
        console.log(tripResp)
        if (!tripResp.error) {
            setTrips(tripResp.results)
        }
    }

    const onClose = () => {
        setIsDeleteDialogOpen(false);
    }

    const createVerificationRequest = async () => { 
        const resp = await stripeApi.nonStandard("POST", "verification", {});
        if (resp && !resp.error) { 
            WebBrowser.openBrowserAsync(resp.url);
        } else { 
            const errorMsg = getErrorString(resp.error, "Sorry, we could create a verificationr request. Please try again later")
            showAlert(errorMsg);
        }
    }

    const onboardToConnect = async () => {
        const resp = await stripeApi.nonStandard("POST", "connect_account", {});
        if (resp && !resp.error) { 
            WebBrowser.openBrowserAsync(resp.link)
        } else { 
            const errorMsg = getErrorString(resp.error, "Sorry, we could create your payment account. Please try again later")
            showAlert(errorMsg);
        }
    }

    const logout = async () => {
        await BaseApiConfigProvider.removeUserToken();
        props.navigation.replace("Login");
    }

    const shareProfile = async (username: string) => {
        AnalyticsManager.logEvent(events.USER_CLICK_SHARE_PROFILE, {page: 'profile'})
        var link = `${BaseApiConfigProvider.getWebUrl()}/user/${username}`;
        if (isMyProfile) { 
            link += "?type=invite"
        }
        await shareLink(link);
    }

    const addFriend = async () => {
        setPrimaryActionLoading(true);
        if (!privateUser) {
            // return
        } else {
            AnalyticsManager.logEvent(events.USER_ADD_FRIEND, {page: 'profile'});
            if (user.friend_status !== "none" && user.friend_status !== "awaiting_response") {
                console.log("Adding user when users are already friends..doing nothing.")
            } else {
                const friendReq = await friendRequestApi.create({ requestor: privateUser.id, target: user.id });
                console.log(friendReq);
                if (!friendReq.error) {
                    props.navigation.replace("PublicProfile", { username: user.username })
                } else {
                    showAlert("Something went wrong while attempting to add user. Please try again");
                }
            }
        }
        setPrimaryActionLoading(false);
    }

    const deleteFriend = async () => {
        setSecondaryActionLoading(true);
        const deleteReq = await friendApi.nonStandard("DELETE", "delete_by", null, { username: user.username });
        console.log(deleteReq)
        if (!deleteReq.error) {
            props.navigation.replace("PublicProfile", { username: user.username })
        } else {
            showAlert("Something went wrong while removing user. Please try again");
        }
        setSecondaryActionLoading(false);
    }

    const handelDelete = async() => { 
        if (deletionType == "friend") { 
            AnalyticsManager.logEvent(events.USER_DELETED_FRIEND);
            await deleteFriend();
        } else if (deletionType == "account") { 
            AnalyticsManager.logEvent(events.USER_DELETED_ACCOUNT);
            const deleteAccResp = await userApi.delete(privateUser.id);
            if (deleteAccResp && !deleteAccResp.erorr) { 
                showAlert("Your account has been deleted! Take care!");
                logout();
            } else { 
                showAlert("Something went wrong while attempting to delete your account. Try again later");
            }
        }
    }

    const handleShareProfile = async () => {
        AnalyticsManager.logEvent(events.USER_CLICK_SHARE_PROFILE, {page: 'profile', isMyProfile: isMyProfile})
        setShowShareView(true);
        setShowSocialShareModal(true);
    }

    useEffect(() => {
        async function getLink(){
            if (Platform.OS === "web") { 
                const url = await BranchMetrics.createDeepLinkOnly("PublicProfile", { username: user.username }, `PublicProfle/${user.username}`);
                setDeepLinkUrl(url)
            }
        }
        if (user) { 
            // getLink();
        }
    }, [user])

    const captureProfileView = async () => {
        console.log(shareUri)
        const uri = await getImageFromRef(userProfileRef);
        setShareUri(uri);
        // We hide the share view after we store the URI.
        setShowShareView(false);
    }

    const getAddFriendButton = () => {
        var text = TEXT.addFriend;
        if (user.friend_status && user.friend_status === "awaiting_response") { 
            text = "Confirm friend request";
        }
        const buttonView = <Button onPress={addFriend} isLoading={primaryActionLoading} rounded={"lg"} leftIcon={<AntDesign name="adduser" size={24} color="white" />} w="200" colorScheme={"rose"}>{text}</Button>
        if (Platform.OS === "web") { 
            if (!deepLinkUrl) { 
                return null;
            }
            return <WebLink href={deepLinkUrl} target="_parent">{buttonView}</WebLink>
        } else { 
            return buttonView
        }
    }

    useEffect(() => {
        if (showShareView) {
            if (privateUser.picture_url && !socialPictureLoaded) { 
                return;
            }
            setTimeout(() => {
                captureProfileView();
            }, 300)
        }
    }, [showShareView, socialPictureLoaded, privateUser])


    const handleEditProfile = () => {
        props.navigation.navigate("EditProfile")
    }

    const maybeWrapAggregations = (view) => {
        if (Platform.OS === "web") { 
          return <WebLink href={deepLinkUrl} target="_parent">{view}</WebLink>
        // return view
        } else { 
            return view
        }
    }


    return <Layout scrollView viewProps={{ pt: 10 }}>
        {!user && <ActivityIndicator></ActivityIndicator>}
        {user &&
            <Box width="100%" pb="10" bg="white">
                <Box bg="white">
                    <View bg="white">
                        {user && <ProfilePicture user={user} avatarProps={{ size: 'xl' }}></ProfilePicture>}
                        <Box flexDir={"row"} w="100%" justifyContent="space-between" alignItems={"center"} mb="2">
                            <Box maxW={"50%"}>
                                <Heading size="xl" pt="5">{user.first_name}</Heading>
                                <Text flexWrap={"wrap"} fontSize={"lg"} mt="1" color="gray.700">@{user.username}</Text>
                            </Box>
                            <Box>
                                {!isMyProfile && <>
                                    {(!user.friend_status || user.friend_status == "none" || user.friend_status == "awaiting_response") && getAddFriendButton()}
                                    {user.friend_status == "friend_requested" && <Button disabled rounded={"lg"} w="200" colorScheme={"coolGray"}>{"Requested"}</Button>}
                                    {/* {user.friend_status == "awaiting_response" && <Button rounded={"lg"} onPress={addFriend} w="200" isLoading={primaryActionLoading} colorScheme={"rose"}>{"Confirm friend request"}</Button>} */}
                                    {user.friend_status == "friend" && <>
                                        <Button onPress={() => {
                                            setDeletionType("friend");
                                            setIsDeleteDialogOpen(true)
                                            }} rounded={"lg"} leftIcon={<AntDesign name="deleteuser" size={24} color="white" />} w="200" colorScheme={"coolGray"}>{TEXT.removeFriend}</Button>
                                        {Platform.OS !== "web" && <Button mt={1} leftIcon={<Entypo name="share-alternative" />} colorScheme="coolGray" variant={"subtle"} onPress={() => shareProfile(user.username)}>Share profile</Button>}

                                    </>}

                                </>}
                                {isMyProfile && <>
                                    <Button onPress={handleEditProfile} rounded={"lg"} leftIcon={<AntDesign name="edit" size={24} color="white" />} w="160" colorScheme={"rose"}>{TEXT.editProfile}</Button>
                                    {Platform.OS !== "web" && <Button mt={1} leftIcon={<Entypo name="share-alternative" />} colorScheme="coolGray" variant={"subtle"} onPress={handleShareProfile}>Share profile</Button>}
                                    {Platform.OS === "web" && <Button leftIcon={<Icon as={EvilIcons} name="gear" size="lg" />} variant="link" onPress={() => setShowSettingsModal(true)}>Settings</Button>}
                                </>}
                            </Box>
                        </Box>
                        <Box flex={"1"} mt="3" flexDir={"row"} justifyContent={"space-between"} w="100%">
                            <Box flex={"1"} flexDir={"row"} alignItems="center">
                                <Feather name="users" size={16} color="red" />
                                {user && <Pressable onPress={() => {
                                    AnalyticsManager.logEvent(events.USER_FOLLOWERS_LIST_CLICKED);
                                    if (Platform.OS === "web") { 
                                        showAlert("Create your account to see their followers")
                                        return;
                                    }
                                    props.navigation.push("UserList", {context: "followers", username: user.username} )}
                                    }><Text fontSize={"md"} color="gray.700" ml="1.5" fontWeight={"medium"}>{user.friend_count} travel {getCountString("friend", user.friend_count)}</Text></Pressable>}
                            </Box>
                            {/* <Box flex={"1"} flexDir={"row"} justifyContent="flex-end" alignItems={"center"}>
                            <Ionicons name="earth" size={18} color="red" />
                            {user && <Text fontSize={"md"} color="gray.700" ml="1.5" fontWeight={"medium"}>{user.countries_visited_count} {TEXT.countriesVisited}</Text>}
                        </Box> */}
                        </Box>

                        {!isMyProfile 
                        && (user.friend_status === "friend_requested" || user.friend_status === "friend") 
                        && <AddFriendListRowVC page="profile" collapsed={false} mt="6" navigation={props.navigation} username={user.username} titleProps={{fontSize: 'md', fontWeight: 'medium', color: 'gray.700'}} />}


                        {user.trip_aggregation_counts && maybeWrapAggregations(<Box w="100%" flexDir={"row"} mt="10" justifyContent="space-between" alignItems="center">
                            <Box flex={1}>
                                <Badge colorScheme="info" mb={"4"} _text={{'fontSize': '2xs'}}
                                >{TEXT.CONSIDERING}</Badge>
                                <Text textAlign={"center"}><Text color="black" fontSize={"xl"} height="100%">{user.trip_aggregation_counts.THINKING ?? 0}</Text> {getCountString("trip", user.trip_aggregation_counts.THINKING)}</Text>
                            </Box>

                            <Divider bg="gray.200" thickness="2" mx="2" orientation="vertical" />

                            <Box flex={1}>
                                <Badge colorScheme="success" mb={"4"} _text={{'fontSize': '2xs'}}>{TEXT.PLANNING}</Badge>
                                <Text textAlign={"center"}><Text color="black" fontSize={"xl"}>{user.trip_aggregation_counts.PLANNING ?? 0}</Text> {getCountString("trip", user.trip_aggregation_counts.PLANNING)}</Text>
                            </Box>

                            <Divider bg="gray.200" thickness="2" mx="2" orientation="vertical" />

                            <Box flex={1}>
                                <Badge colorScheme="rose" mb={"4"} _text={{'fontSize': '2xs'}}>{TEXT.BOOKING}</Badge>
                                <Text textAlign={"center"}><Text color="black" fontSize={"xl"}>{user.trip_aggregation_counts.BOOKING ?? 0}</Text> {getCountString("trip", user.trip_aggregation_counts.BOOKING )}</Text>
                            </Box>
                        </Box>)}

                        {isMyProfile && privateUser?.connect_acc_id && <View><StripeConnectDashboardVC useUnderlineText viewProps={{mt: '8'}}/></View> }

                        <Box pt="16">
                            <Heading>{TEXT.About}</Heading>
                            {user && <Text fontSize={"lg"} pt="2" color="gray.600">{user.bio ? user.bio : "..."}</Text>}
                        </Box>

                        {user.travel_wishes && <Box pt="16">
                            <Heading>{TEXT.travelGoals}</Heading>
                            <Container display="flex" flexWrap={"wrap"} w={"100%"} flexDir={"row"} mt="2">
                                {user.travel_wishes.length == 0 && <Text>....</Text>}
                                {user.travel_wishes.map((item) => <Badge mr={2} mt={2} colorScheme={getRandomTheme()}>{item.name}</Badge>)}
                            </Container>
                        </Box>}
                    </View>

                    <Box pt="16">
                        <Heading mb={2}>Upcoming trips</Heading>
                        <View mb="12">
                            {user.friend_status !== "friend" && !isMyProfile && privateUser && <Text>Add as friend to view their upcoming trips</Text>}
                            {!privateUser && <Text>Create an account and add me as a travel friend to see my upcoming trips and join in!</Text>}
                            {trips && trips.length == 0 && <Text>No activity...</Text>}
                            {trips && trips.map((item) => <TripRow trip={item} user={isMyProfile ? privateUser : user} navigation={props.navigation} />)}

                            {Platform.OS === "web" && !isMyProfile && <Box mt={3}>
                                <WebLink href={deepLinkUrl} target="_parent"><Button w="40%" colorScheme={"rose"} size="md">Add friend</Button></WebLink>
                            </Box>}
                        </View>

                    </Box>
                </Box>

            </Box>}

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={isDeleteDialogOpen} onClose={onClose}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                {deletionType === "friend" && <React.Fragment>
                    <AlertDialog.Header>{TEXT.DeleteFriend}</AlertDialog.Header>
                    <AlertDialog.Body>
                        {TEXT.YouWontSeeTripsAfterDelete}
                    </AlertDialog.Body>
                </React.Fragment>}

                {deletionType === 'account' && <React.Fragment>
                    <AlertDialog.Header>Delete your account</AlertDialog.Header>
                    <AlertDialog.Body>
                        Are you sure you want to delete your account? All of your data, including trips, comments and others will be lost forever.
                </AlertDialog.Body>
                </React.Fragment>}
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={onClose} ref={cancelRef}>
                            {TEXT.Cancel}
                        </Button>
                        <Button colorScheme="danger" onPress={handelDelete} isLoading={secondaryActionLoading}>
                            {TEXT.Delete}
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>

        {user && <SocialShareModal isOpen={showSocialShareModal} source="profile_screen" onClose={() => setShowSocialShareModal(false)} uri={shareUri} onLinkShare={() => shareProfile(privateUser.username)} />}

        <Actionsheet isOpen={showSettingsModal} onClose={() => setShowSettingsModal(false)}>
            <Actionsheet.Content>
            <Actionsheet.Item disabled>Settings: {getVersionString()}</Actionsheet.Item>

            {isMyProfile && privateUser?.connect_acc_id && <StripeConnectDashboardVC useUnderlineText viewProps={{mt: '8'}}
                getRender={(onPress) =>  <Actionsheet.Item startIcon={<Icon as={MaterialIcons} name="payments" mt={1} pr={0} />} onPress={() => {
                    setShowSettingsModal(false);
                    onPress();
                    }}>
                View payments dashboard</Actionsheet.Item>}
            /> }


            <Actionsheet.Item startIcon={<Icon as={AntDesign} name="edit" mt={1} pr={0} />} onPress={() => {
                setShowSettingsModal(false);
                handleEditProfile();
                }}>
            Edit profile</Actionsheet.Item>

            <Actionsheet.Item startIcon={<Icon as={Ionicons} name="person-add" mt={1} pr={0} />} onPress={() => {
                setShowSettingsModal(false);
                props.navigation.navigate(Screens.Contacts);
                }}>
            Find friends on Fulltrip</Actionsheet.Item>
            
            <Actionsheet.Item startIcon={<Icon as={MaterialIcons} name="logout" mt={1}/>} onPress={logout}>Logout</Actionsheet.Item>
            
            {user && !isMyProfile && !Blocklist.isBlocked(user.id) && <Actionsheet.Item onPress={() => {
                Blocklist.blockUser(user.id)
                showAlert("User successfully blocked!");
            }}>Block user</Actionsheet.Item>}
            
            {user && !isMyProfile && Blocklist.isBlocked(user.id) && <Actionsheet.Item onPress={() => {
                Blocklist.unblockUser(user.id);
                showAlert("User has been unblocked");
            }}>Unblock user</Actionsheet.Item>}

            {isMyProfile && <Actionsheet.Item startIcon={<Icon as={AntDesign} name="delete" mt={1} />} onPress={() => {
                setDeletionType("account");
                setIsDeleteDialogOpen(true)
            }}>Delete account</Actionsheet.Item>}

                {privateUser && privateUser.is_staff && <><Button variant="link" onPress={() => {
                            BaseApiConfigProvider.changeEnv("STAGE");
                        }}>Change env to stage</Button>
                        <Button variant="link" onPress={() => {
                            BaseApiConfigProvider.changeEnv("LOCAL");
                        }}>Change env to local</Button>
                         <Button variant="link" onPress={() => {
                            BaseApiConfigProvider.changeEnv("PROD");
                        }}>Change env to prod</Button>

                        <Button onPress={() => {
                            createVerificationRequest()
                        }}>Create verification request</Button>

                        <Button onPress={() => {
                            onboardToConnect()
                        }}>Create Stripe Connect Account</Button>

                        <Button variant="link" onPress={() => {
                            // The following example uses `captureException()` from Sentry.Native.* to capture errors:
                            try {
                                // your code
                                throw Error(`Something went wrong: ${getVersionString()}`)
                            } catch (error) {
                                if (Platform.OS == "web") { 
                                    Sentry.Browser.captureException(error);
                                } else {
                                    Sentry.Native.captureException(error);
                                }
                            }
                        }}>Cause exception.</Button>
                        
                        <Text textAlign={"center"}>{`${BaseApiConfigProvider.getEnvType()}`}</Text></>}
            </Actionsheet.Content>
      </Actionsheet>                   
        {user && <SocialProfileView user={user} ref={userProfileRef} onPictureLoad={() => setSocialPicturedLoaded(true)} visible={showShareView}></SocialProfileView>}
    </Layout>
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});