import moment from "moment";
import { HStack, Pressable, View, Text, Button, Avatar } from "native-base";
import React from "react";
import TEXT from '../translations.json'


export default function FriendRequestCell(props) {
    const item = props.fr
    return <HStack w="100%" space={3} mb={10}>
                <View >
                    <Pressable onPress={() => props.navigation.navigate('PublicProfile', { username: item.requestor.username })}><Avatar source={{ uri: item.requestor.picture_url }}></Avatar></Pressable>
                </View>
                <View flex={1}>
                    <Text flexWrap={"wrap"} fontSize="md"><Text color={"coolGray.600"} bold>{item.requestor.username}</Text> {TEXT.sentYouAFriendRequest}</Text>
                    <HStack space={2} mt="2" flex={1}>
                        <Button colorScheme={"rose"} onPress={() => props.handleFrResponse(item.id, true)}>{TEXT.accept}</Button>
                        <Button colorScheme={"coolGray"} onPress={() => props.handleFrResponse(item.id, false)}>{TEXT.reject}</Button>
                        <View flex={1} pl={6}>
                            <Text color={"coolGray.500"}>{moment(item.created_at).format('ll')}</Text>
                        </View>
                    </HStack>
                </View>
            </HStack>
}