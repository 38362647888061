import moment from "moment-timezone";
import { Text, View, Container, Center, Heading, HStack, Avatar, Divider, Button, Image, Badge, Pressable, Icon } from "native-base";
import colors from "native-base/lib/typescript/theme/base/colors";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, FlatList, Platform } from "react-native";
import ParsedText from "react-native-parsed-text";
import { useSelector } from "react-redux";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import ProfilePicture from "../components/ProfilePicture";
import { getUserAvatar, getUserProfile } from "../utils/views";
import { DesktopView } from "../components/ViewTypes";
import Layout from "../components/Layout";
import { useFocusEffect } from "@react-navigation/native";
import TEXT from '../translations.json'
import { getColorForTripPhase, getColorSchemeForPhase, getCountString, getDescriptiveStringForPhase, getTripImage, showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import GroupedAvatars from "../components/GroupedAvatars";
import * as WebBrowser from 'expo-web-browser';
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { Screens } from "../constants/Screens";

export default function TripsScreen(props) {

    const tripsApi = new GenericViewSetAPI("trip");
    const pageSize = 10;

    const user = useSelector((s: any) => s.user)
    const [tripsResp, setTripsResp] = useState(null);
    const [trips, setTrips] = useState(null);
    const [page, setPage] = useState(1);
    const [refreshing, setRefreshing] = useState(false);
    const [drafts, setDrafts] = useState(null);
    const [fullItems, setFullItems] = useState([]);
    const [sharing, setShareing] = useState(false);

    async function getMyTrips(canUpdate, page, overwrite = true) {
        const response = await tripsApi.query({ page_size: pageSize, page: page, user_id: user.id, include_interested: true, expansions: ['creator', 'trip_interest_count', 'trip_interest_users', 'chat', 'my_interest', "my_payment_status"] });
        if (canUpdate) {
            setTripsResp(response)
            console.log(response.results.map(x => x.my_payment_status))
            if (overwrite) {
                setTrips(response.results);
                setPage(1)
            } else {
                const currTrips = [...trips]
                currTrips.push(...response.results)
                setTrips(currTrips);
            }
        }
        if (response.error && page === 1) {
            showAlert("Something went wrong while getting your trips. Please try again later");
        }
    }

    useEffect(() => {
        if (sharing) { 
            AnalyticsManager.logEvent(events.USER_CLICKED_SHARE_CALENDAR)
            props.navigation.navigate(Screens.TripsShareScreen, { trips: trips })
            setShareing(false)
        }
    }, [sharing])

    useEffect(() => {
        // Add buttn tot he top right
        props.navigation.setOptions({
            headerRight: () => (
                <Button
                    // add share icon
                    leftIcon={<Icon as={Ionicons} name="share-outline" size="sm" />}
                    onPress={() => setShareing(true)}
                    colorScheme="coolGray"
                    rounded={"2xl"}
                    size="sm"
                    mr={2}
                >
                    Share
                </Button>
            ),
        })
    }, [])

    useEffect(() => {
        var tripsPtr = []
        var draftPtr = []

        if (trips) { 
            tripsPtr = trips
        }
        if (drafts) { 
            draftPtr = drafts
        }

        setFullItems([...draftPtr, ...tripsPtr])

    }, [trips, drafts])

    async function getAllItems(isActive, page) {
        getDrafts() 
        getMyTrips(isActive, page)
    }

    async function getDrafts() { 
        const response = await tripsApi.query({ draft: true, expansions: ['creator', 'trip_interest_count', 'trip_interest_users'] });
        console.error(response)
        if (response && !response.error) { 
            setDrafts(response.results)
        }
    }

    const hasItems = () => {
        return fullItems?.length > 0
    }

    function handleUrlPress(url, matchIndex /*: number*/) {
        WebBrowser.openBrowserAsync(url);
    }

    useFocusEffect(
        React.useCallback(() => {
            let isActive = true;
            AnalyticsManager.logEvent(events.USER_CLICKED_TRIPS_SCREEN);
            console.log("FETCHING ON FOCUS EFFECT");
            getAllItems(isActive, 1);
            
            return () => {
                isActive = false;
            };
        }, [])
    )


    async function handleLoadMore() {
        if (!tripsResp) {
            return;
        }
        console.log("LOADING MOREEE")
        const nextPage = page + 1;
        if (!tripsResp.next) {
            console.log("No need to attempt loading more")
            return
        }
        await getMyTrips(true, nextPage, false)
        setPage(nextPage);
    }

    async function refresh() {
        setRefreshing(true);
        await getAllItems(true, 1)
        setRefreshing(false)
    }


    const renderItem = (itemWrapper: any) => {
        if (!user) { 
            return null
        }
        const item = itemWrapper.item;
        const isMyTrip = item.creator_id == user.id
        const users = [item.creator, ...item.interest_users]
        const showAvatars = users.length > 1;

        return <Pressable mt={5} mb={5} onPress={() => props.navigation.navigate("TripDetail", { tripId: item.uuid })}>
            {!isMyTrip && <Text mb="5" underline>{item.my_interest?.level === "going" ? "You're going on this trip" : "You're not sure about this trip"}</Text>}

            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} flex={1} h="100%">
                <HStack space={2} flex={1}>
                    <Image source={{uri: getTripImage(item)}} height="150" width="120" style={{borderRadius: 5, resizeMode: 'cover'}}/>

                    <View flex={1}>
                        <HStack space={1}>
                            {item.my_payment_status === "paid" && <Badge size="xs" rounded="full" _text={{fontSize: 'xs'}} colorScheme={"rose"} mb="2">Deposit paid</Badge>}
                            {/* {item.my_interest && <Badge mb={3} rounded={"full"} colorScheme={item.my_interest.level ==="going" ? "green" : "coolGray"}>{item.my_interest.level === "going" ? `You're going` : "You're not sure"}</Badge>} */}
                            {!item.published && <Badge size="xs" rounded={"full"} _text={{fontSize: 'xs'}} maxW={"30%"} colorScheme={"amber"} mb="2">Draft</Badge>}
                            {item.visibility === "UNLISTED" && <Badge mb="2" rounded={"full"} colorScheme={"indigo"}>Unlisted</Badge>}
                            {item.published && <Badge rounded={"full"} size="xs" maxW={"50%"} _text={{fontSize: '2xs'}} colorScheme={getColorSchemeForPhase(item.phase)} mb="2">{getDescriptiveStringForPhase(item.phase).toUpperCase()}</Badge>}
                        </HStack>
                        {item.creator && item.interest_users && showAvatars && <HStack  display="flex" mt="1" flexDirection="row" alignItems={"center"}>
                            <GroupedAvatars users={[item.creator, ...item.interest_users]} avatarSize={"sm"} />
                            {item.interest_count > 0 && <Text pl="1" fontSize="sm" color={"coolGray.600"}>({item.interest_count}) {getCountString("other", item.interest_count)}</Text>}
                        </HStack> }
                        <Text numberOfLines={1} mt={1} color={"coolGray.700"} flexWrap={"wrap"} fontSize={"md"}>{TEXT.TripTo} <Text bold color="black">{item.place}</Text></Text>
                        {item.start_date && <Text fontSize={"sm"} mt={1} color={"coolGray.600"}>{moment(item.start_date).fromNow()}</Text>}

                        {item.chat && item.chat.latest_comment && <Pressable onPress={() => props.navigation.navigate("ChatScreen", {chatId: item.chat_id, title: item.place})}>
                            <Text numberOfLines={1} mt={1} fontSize="lg" color="coolGray.600">@{item.chat.latest_comment.creator.username.toLowerCase()}: {item.chat.latest_comment.message}</Text>
                        </Pressable>}
                    </View>
                </HStack>
            </View>
        </Pressable>

    }

    return <View bg="white" flex={1}>
        <Center w="100%">
            <Container maxW="800" w="90%">
                {Platform.OS === "web" && <>
                    <Heading size="xl" mt="4">Your travel calendar</Heading>
                    <HStack w="100%" space={2} mt={2}>
                        <Button size="sm" colorScheme="rose" onPress={() => props.navigation.navigate(Screens.CreateModal)} rounded={"2xl"}
                             leftIcon={<Icon as={AntDesign} name="plus" size="sm" />}>Add trip</Button>
                        <Button
                            // add share icon
                            leftIcon={<Icon as={Ionicons} name="share-outline" size="sm" />}
                            onPress={() => setShareing(true)}
                            colorScheme="coolGray"
                            rounded={"2xl"}
                            size="sm"
                            // mb={2}
                            // mt={2}
                            // mr={2}
                        >
                        Share calendar
                    </Button>
                </HStack>

                </>}
                {fullItems === null && <ActivityIndicator></ActivityIndicator>}
                {tripsResp && !hasItems() && <View mt="10">
                    <Heading>{TEXT.NoTrips}</Heading>
                    <Text>{TEXT.createTripOrShowInterest}</Text>
                </View>}
                {hasItems() && <FlatList
                    style={{width: '100%'}}
                    data={fullItems}
                    renderItem={renderItem}
                    ItemSeparatorComponent={Divider}
                    showsVerticalScrollIndicator={false}
                    onRefresh={refresh}
                    refreshing={refreshing}
                    onEndReachedThreshold={0.1}
                    onEndReached={() => handleLoadMore()}
                />}
            </Container>
        </Center>
    </View>
}

const styles = StyleSheet.create({
    url: {
        color: 'blue',
        textDecorationLine: 'underline',
    }

});