import { Button, FormControl, Heading, Input, Stack } from "native-base";
import { useState } from "react";
import ConstrainedInput from "../components/ConstrainedInput";
import Layout from "../components/Layout";
import WishlistView from "../components/WishlistView";
import { Screens } from "../constants/Screens";
import { setGuest } from "../store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch } from "react-redux";

export default function Persona(props) { 
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const handleComplete = async () => {
        // Save this information somehow...maybe generate random id?
        // go to CountriesVisited
        dispatch(setGuest({name: name, email: email}))
        await AsyncStorage.setItem("guest", JSON.stringify({name: name, email: email}));
        props.navigation.navigate(Screens.CountriesVisited);
    }

    return <Layout>
        <Heading mt={4}>Let's get started</Heading>
        
        <FormControl style={{ marginTop: 20 }}>
            <Stack>
                <FormControl.Label>Your name</FormControl.Label>
                <Input value={name}  onChangeText={(val) => setName(val)} />
                {/* <ConstrainedInput type="alphanumeric" value={name} inputProps={{ variant: "underlined", placeholder: "", size: "lg" }} onChangeText={(val) => setName(val)} /> */}
            </Stack>
        </FormControl>

        <FormControl style={{ marginTop: 20 }}>
                <FormControl.Label>Email </FormControl.Label>
                <Input value={email}  onChangeText={(val) => setEmail(val)} />
                <FormControl.HelperText>We'll send your wishlist picture here</FormControl.HelperText>
        </FormControl>

        <Button colorScheme={"rose"} mt={6} w="100%" onPress={handleComplete}>Continue</Button>
    </Layout>

}