import { Center, Container, ScrollView, View } from "native-base";
import React, { createElement, useEffect, useLayoutEffect } from "react";
import { Platform } from "react-native";
import { showAlert } from "../utils";
import Footer from "./Footer";


export default function Layout(props) {

    useLayoutEffect(() => {
        if (Platform.OS === "web") { 
            // showAlert("WE ON WEB BABEH
            const script = document.createElement("script")
            script.setAttribute('type', 'text/javascript');
            script.text = `
            window['_fs_run_in_iframe'] = true;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
            window['_fs_org'] = 'o-1GKD2Z-na1';
            window['_fs_namespace'] = 'FS';
            (function(m,n,e,t,l,o,g,y){
                if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                g.anonymize=function(){g.identify(!!0)};
                g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                g.log = function(a,b){g("log",[a,b])};
                g.consent=function(a){g("consent",!arguments.length||a)};
                g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                g.clearUserCookie=function(){};
                g.setVars=function(n, p){g('setVars',[n,p]);};
                g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                g._v="1.3.0";
            })(window,document,window['_fs_namespace'],'script','user');`

            const headList = document.getElementsByTagName("head");
            if (headList.length > 0) { 
                const head = headList[0]
                head.appendChild(script)
            }
        }
    }, [])

    var children = <><Center flex={1} h="100%" w="100%" minH={props.minH ?? 500}>
        <Container {...props.containerProps} maxW="700" w={props.containerW ? props.containerW : "90%"} flex={1} minH="100%">
            {props.children}
        </Container>
    </Center>
        {Platform.OS === "web" && !props.hideFooter && <Footer />}
    </>

    if (props.ignoreContainer) {
        children = <>
        {props.children}
        {Platform.OS === "web" && !props.hideFooter && <Footer />}
        </>
    }

    if (props.scrollView) {
        return <ScrollView bg="white" flex={1} {...props.viewProps} keyboardShouldPersistTaps="always">
            {children}
        </ScrollView>
    }
    return <View bg="white" flex={1} {...props.viewProps}>
        {children}
    </View>
}


export function CenterLayout(props) {
    return <><Center flex={1} h="100%" w="100%" minH={700}>
    <Container {...props.containerProps} maxW="700" w={props.containerW ? props.containerW : "90%"} flex={1} minH="100%">
        {props.children}
    </Container>
</Center>
    {Platform.OS === "web" && !props.hideFooter && <Footer />}
</>
}
