import { Button, Heading, Text, View, Image, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import { getErrorString, showAlert } from "../utils";
import * as WebBrowser from 'expo-web-browser';
import ReactNativeWebViewModal from "../components/ReactNativeWebViewModal";
import { LoadingModal } from "../components/LoadingModal";
import { useDispatch } from "react-redux";
import { setUser } from "../store";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import { Linking, Platform } from "react-native";
import useViewType from "../hooks/useViewType";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";

/**
 * Payments onboard modal that should only be used on WEB as it is probbaly COMPLETELY broken on mobile.
 */
export default function WebPaymentsOnboardVC(props) { 

    const moreDetails = props.moreDetails;

    const stripeApi = new GenericViewSetAPI("stripe");
    const userApi = new GenericViewSetAPI("user");
    const dispatch = useDispatch()
    const viewType = useViewType();

    const [index, setIndex] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [modalUrl, setModalUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [meLoading, setMeLoading] = useState(false)
    const [stripeLink, setStripeLink] = useState(null)

    useEffect(() => {
        if (stripeLink) { 
            if (Platform.OS === "web") { 
                window.open(stripeLink, "_blank")
            } else { 
                WebBrowser.openBrowserAsync(stripeLink)
            }
        }
    }, [stripeLink])


    const onboardToConnect = () => {
        var windowReference = null;
        if (Platform.OS === "web") { 
            windowReference = window.open(BaseApiConfigProvider.getWebUrl() + "/stripe_loading");
        }
        setLoading(true)
        AnalyticsManager.logEvent(events.USER_CLICK_START_PAYMENT_ONBOARD)
        stripeApi.nonStandard("POST", "connect_account", {}).then(resp => {
            if (resp.link) {
                if (windowReference) {
                    windowReference.location = resp.link;
                 } else {
                    WebBrowser.openBrowserAsync(stripeLink)
                 }
            } else if (resp.error) { 
                windowReference.close();
                const errorMsg = getErrorString(resp.error, "Sorry, we could create your payment account. Please try again later")
                showAlert(errorMsg);
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    

    const refreshUser = async () => {
        AnalyticsManager.logEvent(events.USER_FINISH_PAYMENT_ONBOARD)
        const meResp = await userApi.nonStandard("GET", "me")
        if (meResp && !meResp.error) {
            if (meResp.connect_acc_id) {  
                dispatch(setUser(meResp))
                console.log("Updated user, now going back...")
                props.onClose()
            } else {
                showAlert("Looks like your account hasn't finished being set up. Try again.")
            }
        } else { 
            if (meResp) {
                const errorStr = getErrorString(meResp.error, "Sorry we couldn't connect to your payments account. Try again")
                showAlert(errorStr)
            } else { 
                showAlert("Sorry something went wrong. Make sure you are connected to the internet and try again")
            }
        }
    }

    return <View>
        {!moreDetails && <View mt={7} mb={3}>
            <Heading mb={3} textAlign="center">Trip deposits from Fulltrip</Heading>
            {!props.hideImage && <Image maxH={"300"} source={{uri: "https://roam-public-images.s3.amazonaws.com/system_images/Group+630.png"}} resizeMode="contain" mb={1}/>}
            <Text textAlign="center" >The easiest way to handle payments for your group trip, so you can focus on planning an amazing experience</Text>
        </View>}

        {moreDetails && <View mt={7} mb={3}>
            <Heading mb={3}>Looks like we need more details</Heading>
            <Text>Before you can start accepting trip deposits, we need to collect more information from you! This should only take a few minutes of your time.</Text>
            </View>}

        <View w="100%" mt={3}>
            <Button onPress={() => onboardToConnect()} colorScheme="rose" isLoading={loading}>{moreDetails ? "Continue" : "Get started"}</Button>
            
            <Button variant="subtle" mt={2} colorScheme={"coolGray"}  onPress={() => refreshUser()} isLoading={meLoading}>I'm done</Button>

            <View mt={3}>
                {!moreDetails && <Pressable onPress={() => WebBrowser.openBrowserAsync("https://www.onfulltrip.com/terms")}><Text textAlign={"center"} mt={3} fontSize="xs" >By agreeing creating a payment account, you agree to our <Text underline>Payments Terms of Service</Text></Text></Pressable>}
                <Pressable onPress={() => WebBrowser.openBrowserAsync("https://www.onfulltrip.com/faq")}><Text textAlign={"center"} mt={3} fontSize="xs" ><Text underline>View frequently asked questions</Text></Text></Pressable>
            </View>
        </View>

        <LoadingModal isOpen={meLoading} />
    </View>
}