import { Heading, Modal, Text, View } from "native-base";
import React from "react";
import { getColorForTripPhase } from "../utils";


export default function TripPhaseModal(props) { 

    return <Modal isOpen={props.isOpen} size="lg" onClose={props.onClose}>
    <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Trip phases expained</Modal.Header>
        <Modal.Body>
            <View mb="3">
            <Text color={getColorForTripPhase("THINKING")} fontSize="lg">Considering</Text>
            <Text fontSize="sm" color={"gray.600"}>The trip is mainly an idea that someone is thinking about. It's very early.</Text>
            </View>

            <View mb="3">
            <Text color={getColorForTripPhase("PLANNING")} fontSize="lg">Planning</Text>
            <Text fontSize="sm" color={"gray.600"}>Itineraries are being planned, research is happening and the trip is being taken seriously. No financial commitment has been made.</Text>
            </View>

            <View mb="3">
            <Text color={getColorForTripPhase("BOOKING")} fontSize="lg">Booking</Text>
            <Text fontSize="sm" color={"gray.600"}>Money is being spent! Whether it's flights or booking hotels or even experiences. The trip has the go light and money is on the line!</Text>
            </View>
        </Modal.Body>
    </Modal.Content>
</Modal>
}