import { Badge, Button, FormControl, HStack, Icon, Input, Pressable, ScrollView, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import Layout from "../components/Layout";
import WishlistView from "../components/WishlistView";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Screens } from "../constants/Screens";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { loadGuestEngagement } from "../utils";
import { useFocusEffect } from "@react-navigation/native";
import { Platform } from "react-native";


export default function CountriesVisited(props) { 
    const engagementApi = new GenericViewSetAPI("engagement")
    const [countriesVisited, setCountriesVisited] = useState(0);
    const [selectedCommunities, setSelectedCommunities] = useState<any>({})
    const [communities, setCommunities] = useState([])
    const communityApi = new GenericViewSetAPI("community")
    const [placeMap, setPlaceMap] = useState({});
    const [finishLoading, setFinishLoading] = useState(false);
    const [anthem, setAnthem] = useState("");


    useEffect(() => {
        getCommunities()
    }, [])

    useFocusEffect(
        React.useCallback(() => {
            hydrateData();
            if (Platform.OS === "web") { 
                window.scrollTo(0, 0)
            }
        }, []))


    const hydrateData = async () => { 
        const engagement = await loadGuestEngagement(null);
        if (engagement) {
            if (engagement.places) { 
                // window.alert(JSON.stringify(engagement.places))
                const placesMap = engagement.places.reduce((acc, place) => {
                    acc[place.address] = place;
                    return acc;
                }, {})
                // window.alert(JSON.stringify(placesMap))
                setPlaceMap(placesMap)
            }

            if (engagement.communities) { 
                const selectedCommunities = engagement.communities.reduce((acc, community) => {
                    acc[community.id] = true;
                    return acc;
                }, {})
                setSelectedCommunities(selectedCommunities)
            }

            if (engagement.anthem) { 
                setAnthem(engagement.anthem);
            }
        } 
    }

    async function getCommunities() {
        const communityResp = await communityApi.query({});
        if (communityResp && !communityResp.error) {
            setCommunities(communityResp.results)
        }
    }

    const handleCommunitySelected = (community) => { 
        const selected = {...selectedCommunities}
        if (selected[community.id]) {
            delete selected[community.id]
        } else {
            selected[community.id] = community;
        }
        setSelectedCommunities(selected)
    }   

    const onFinishPress = async () => {
        console.log(placeMap)
        setFinishLoading(true)
        const guest = await AsyncStorage.getItem("guest")
        const engagement = {
            guest: JSON.parse(guest),
            anthem: anthem,
            places: Object.values(placeMap), 
            communities: Object.values(selectedCommunities)
        }
        await AsyncStorage.setItem("guestEngagement", JSON.stringify(engagement))
        await engagementApi.create({engagement: engagement})
        setFinishLoading(false)
        props.navigation.navigate(Screens.WishlistShare)
    }

    return <Layout>
        {/* { communities && <View mt={4}>
            <Text fontSize="xl">Travel communities to join</Text>
            <ScrollView horizontal mt={2}>
                {communities.map((community) => <Pressable style={{marginRight: 10}} onPress={() => handleCommunitySelected(community)}><Badge rounded={"full"} key={community.id} variant="subtle" colorScheme={selectedCommunities[community.id] ? "rose" : "coolGray"}>{community.name}</Badge></Pressable>)}
            </ScrollView>
            {}
        </View>} */}

        <FormControl style={{ marginTop: 20 }}>
                <Text fontSize={"lg"} mb={2}>Your summer anthem</Text>
                <Input placeholder="Catch flights, not feelings...etc" value={anthem} onChangeText={(val) => setAnthem(val)} />
        </FormControl>

        <WishlistView title="What are the top three places on your travel wish list?" mapData={placeMap} maxLimit={3} onPlaceMapChange={(map) => setPlaceMap(map) } selectFirstPhoto />

        <Button isLoading={finishLoading} onPress={onFinishPress}>Continue</Button>

    </Layout>
    
}