import { ScrollView, View, Text, Center, Button, HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import TripEventCell from "../components/TripEventCell";


export default function SuggestionResponseRowVC(props) { 

    const eventsApi = new GenericViewSetAPI("trip_event")

    const [events, setEvents] = useState(props.events ?? null)
    const [failedToLoad, setFailedToLoad] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!props.events) {
            getSubmittedEvents()
        }
    }, [])
    

    useEffect(() => {
        
        console.warn("refreshing")
        getSubmittedEvents()
    
    }, [props.refreshSignal])

    const getSubmittedEvents = async () => {
        setLoading(true)
        const resp = await eventsApi.query({trip_id: props.tripId, active_submission: true, expansions: ["creator"]});
        console.log('active_submissions_resp', resp)
        if (resp && !resp.error) { 
            setEvents(resp.results)
        } else { 
            setFailedToLoad(true)
        }
        setLoading(false)
    }


    if ((!events || events.length == 0) &&  props.hideIfEmpty) { 
        return null
    }

    return <View mb={2} w="100%">
        {/* <Text>Refreshing: {props.refreshSignal?.toString()}</Text> */}
        {props.renderTitle ? props.renderTitle() : null}
        {loading && <Center mt={1}><ActivityIndicator/></Center> }
        {failedToLoad && <Text mt={1}>Sorry, we couldn't load the submissions. Try again later</Text>}
        
        {!failedToLoad && events &&  events.length == 0 && <Text mt={1} color={"coolGray.700"}>No submissions</Text>}

        {!failedToLoad && events &&  events.length > 0 && <ScrollView horizontal>
            {events.map((item) => <View mb={4} >
                <TripEventCell mb={1} event={item} onPress={() => props.onPress ? props.onPress(item) : console.log("Event clicked")}/>
            </View> )}
        </ScrollView>}
    </View>
}