import { useEffect, useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import UsersList from "../components/UsersList";
import { showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";


export default function UserListScreen(props) { 

    var tripUuid = props.route?.params?.tripId;
    var username = props.route.params?.username; 
    var tripInterestLevel = props.route.params?.tripInterestLevel;
    const extraUsers = props.route.params?.extraUsers ?? [];
    const userApi = new GenericViewSetAPI("user")

    const [context, setContext] = useState(null);
    const [users, setUsers] = useState(null);
    const [usersResp, setUsersResp] = useState(null);
    const [page, setPage] = useState(1);

    async function getUsers(context, page) { 
        var endpoint = "followers"
        var id = username
        var queryParams: any =  {page: page}
        if (context === "trip") { 
            endpoint = "trip"
            id = tripUuid;
            if (tripInterestLevel) { 
                queryParams = {page: page, interest_level: tripInterestLevel}
            }
        }

        const userResp = await userApi.nonStandard("GET", `${id}/${endpoint}`, null, queryParams);
        if (userResp && !userResp.error) { 
            if (page <= 1) { 
                setUsers([...extraUsers, ...userResp.results]);
                setPage(1)
            } else { 
                const currUsers = users;
                setUsers([...currUsers, ...userResp.results])
            }
            setUsersResp(userResp);

        } else { 
            showAlert("Sorry, we could show the needed data. Please try again");
            props.navigation.goBack();
        }
    }

    async function loadMore() { 
        if (usersResp.next) { 
            const nextPage = page + 1;
            getUsers(context, nextPage)
            setPage(nextPage)
        } else { 
            console.log("End reached. Can't fetch more")
        }
    }

    useEffect(() => {
        var context = "trip"
        if (props.route.params) {
            context = props.route.params.context;
            var title = "Users"
            if (context === "followers") { 
               title = "Followers"
            } 

            props.navigation.setOptions({
                title: title
            })
        }
        getUsers(context, 1);
        setContext(context);
    }, [])

    return <Layout><UsersList users={users}
        onEndReached={loadMore} onPress={(user) => {
            AnalyticsManager.logEvent(events.USERLIST_USER_CLICK, {context: props.context})
            props.navigation.push("PublicProfile", {username: user.username}) 
        }}
    />
    </Layout>
}