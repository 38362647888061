import React, {forwardRef, useState} from "react";
import { ActivityIndicator, createElement, Platform } from "react-native";
import { WebView } from 'react-native-webview';


const UniversalIFrame = forwardRef(function UniversalIFRame(props: any, ref: any) { 

    const [loading, setIsLoading] = useState(true);

    if (Platform.OS === "web") { 
        const elem = createElement('iframe', {
            src: props.source,
            frameborder: "0",
            allowfullscreen: true,
            onLoad: () => setIsLoading(false),
            style: { minHeight: props.minHeight ?? 400, frameBorder: 0}
        })
        return <>
        {loading && <ActivityIndicator style={{marginTop: 20}}></ActivityIndicator>}
        {elem}
        </>

    } else { 
        const elem = <WebView
        scalesPageToFit={true}
        javaScriptEnabled
        ref={ref}
        scrollEnabled={false}
        onLoadEnd={() => setIsLoading(false)}
        onNavigationStateChange={props.onNavigationStateChange}
        // androidLayerType="hardware"
        source={{uri: props.source}}
    />
    return <>
        {loading && <ActivityIndicator style={{marginTop: 20}}></ActivityIndicator>}
        {elem}
    </>
    }
})

export default UniversalIFrame;