import { init, track, identify, Identify, setUserId } from '@amplitude/analytics-react-native';

import Config from "../constants/Config";

const events = {
  APP_OPEN: 'APP_OPEN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_REENGAGED_NOTIFICATION: 'USER_REENGAGED_NOTIFICATION',
  USER_ONBOARD_START: 'USER_ONBOARD_START',
  TRIP_PHASE_INFO_CLICK: 'TRIP_PHASE_INFO_CLICK',
  USER_REPORT_COMMENT: 'USER_REPORT_COMMENT',
  USER_CLICKED_SHARE_CALENDAR: 'USER_CLICKED_SHARE_CALENDAR',
  BRANCH_DEEP_LINK_TRIGGER: 'BRANCH_DEEP_LINK_TRIGGER',
  USER_VIEWED_TRIP_ONBOARD: 'USER_VIEWED_TRIP_ONBOARD', 
  USER_SELECTED_TRIP_ONBOARD: 'USER_SELECTED_TRIP_ONBOARD',
  USER_FOLLOWERS_LIST_CLICKED: 'USER_FOLLOWERS_LIST_CLICKED',
  USERLIST_USER_CLICK: 'USERLIST_USER_CLICKED',
  USER_CREATE_TRIP_ONBOARD: 'USER_CREATE_TRIP_ONBOARD',
  USER_SKIP_TRIP_ONBOARD: 'USER_SKIP_TRIP_ONBOARD',
  VERIFY_CODE_SENT: 'VERIFY_CODE_SENT',
  USER_DELETED_TRIP: 'USER_DELETED_TRIP',
  USER_PRESS_SHARE_INVITE: 'USER_PRESS_SHARE_INVITE',
  MOBILE_VERIFY_SHOW: 'MOBILE_VERIFY_SHOW',
  USER_OPEN_PAYMENT_ONBOARD : 'USER_OPEN_PAYMENT_ONBOARD',
  USER_CLICK_START_PAYMENT_ONBOARD: 'USER_CLICK_START_PAYMENT_ONBOARD',
  USER_FINISH_PAYMENT_ONBOARD: 'USER_FINISH_PAYMENT_ONBOARD',
  USER_REDIRECTED_MORE_DETAILS_PAYMENTS: 'USER_REDIRECTED_MORE_DETAILS_PAYMENTS',
  USER_REDIRECTED_ACCOUNT_NEEDED: 'USER_REDIRECTED_ACCOUNT_NEEDED',
  MOBILE_VERIFY_CANCEL: 'MOBILE_VERIFY_CANCEL',
  MOBILE_CODE_SENT: 'MOBILE_CODE_SENT',
  TRIP_DETAIL_TAB_CHANGE: 'TRIP_DETAIL_TAB_CHANGE',
  TRIP_CHECKOUT_STARTED: 'TRIP_CHECKOUT_STARTED',
  TRIP_CHECKOUT_COMPLETE: 'TRIP_CHECKOUT_COMPLETE',
  MOBILE_VERIFY_COMPLETE: 'MOBILE_VERIFY_COMPLETE',
  USER_DELETED_ACCOUNT: 'USER_DELETED_ACCOUNT',
  USER_DELETED_FRIEND: 'USER_DELETED_FRIEND',
  USER_ADD_FRIEND: 'USER_ADD_FRIEND',
  USER_CLICK_SHARE_TRAVEL_AD: 'USER_CLICK_SHARE_TRAVEL_AD',
  USER_RESPOND_TO_FRIEND_REQUEST: 'USER_RESPOND_TO_FRIEND_REQUEST',
  USER_INVITE_CONTACT: 'USER_INVITE_CONTACT',
  USER_CLICK_CONTACT_BTN: 'USER_CLICK_CONTACT_BTN',
  USER_SEARCH_CONTACT: 'USER_SEARCH_CONTACT',
  USER_CONTACT_ACCESS_GRANTED: 'USER_CONTACT_ACCESS_GRANTED',
  USER_CONTACT_ACCESS_REJECTED: 'USER_CONTACT_ACCESS_REJECTED',
  USER_ADDED_TRIP_EVENT_SUGGESTION: 'USER_ADDED_TRIP_SUGGESTION',
  USER_RESPONDED_USER_SUGGESTION: 'USER_RESPONDED_USER_SUGGESTION',
  USER_CHANGED_SUGGESTION_LOCATION: 'USER_CHANGED_SUGGESTION_LOCATION',
  USER_CLICKED_TRIP_EVENT_SUGGESTION: 'USER_CLICKED_TRP_EVENT_SUGGESTION',
  USER_CHANGED_SUGGESTION_CATEGORY: 'USER_CHANGED_SUGGESTION_CATEGORY',
  USER_ADDED_MANUAL_EVENT: 'USER_ADDED_MANUAL_EVENT',
  USER_NAVIGATED_TO_FLIGHTS_SEARCH: 'USER_NAVIGATED_TO_FLIGHTS_SEARCH',
  USER_UPDATED_TRIP_EVENT: 'USER_UPDATED_TRIP_EVENT',
  USER_DELETED_TRIP_EVENT: 'USER_DELETED_TRIP_EVENT',
  USER_ADDED_EVENT_TO_TRIP: 'USER_ADDED_EVENT_TO_TRIP',
  USER_CHANGED_TRIP_THUMBNAIL: 'USER_CHANGED_TRIP_PHOTO',
  USER_CLICKED_CHANGE_TRIP_PHOTO: 'USER_CLICKED_CHANGE_TRIP_PHOTO',
  USER_PUBLISHED_TRIP: 'USER_PUBLISHED_TRIP',
  USER_JOIN_COMMUNITY: 'USER_JOIN_COMMUNITY',
  USER_LEAVE_COMMUNITY: 'USER_LEFT_COMMUNITY',
  USER_CREATED_TRIP: 'USER_CREATED_TRIP',
  USER_CLICK_REJECT_INVITE: 'USER_CLICK_REJECT_INVITE',
  USER_CLICKED_TRIPS_SCREEN: 'USER_CLICKED_TRIPS_SCREEN',
  USER_UPDATED_TRIP: 'USER_UPDATED_TRIP',
  USER_EXIT_TRIP_DRAFT: 'USER_EXIT_TRIP_DRAFT',
  USER_UPDATE_TRIP_PHASE: 'USER_UPDATE_TRIP_PHASE',
  USER_ONBOARD_FINISH: 'USER_ONBOARD_FINISH',
  USER_LOADED_TRIP_DRAFT: 'USER_LOADED_TRIP_DRAFT',
  USER_CLICK_SHARE_PROFILE: 'USER_CLICK_SHARE_PROFILE',
  USER_CREATED_ACCOUNT: 'USER_CREATED_ACCOUNT',
  USER_CLICK_INSPIRATION: 'USER_CLICKED_INSPIRATION',
  USER_CLICK_TRENDING_PLACE: 'USER_CLICKED_TRENDING_PLACE',
  USER_VIEWED_WISHLIST: 'USER_VIEWED_WISHLIST',
  USER_VIEW_UPDATE_REQUIRED: 'USER_VIEWED_UPDATE_REQUIRED',
  USER_REFRESH_FIX_MIN_VERSION: 'USER_REFRESH_FIX_MIN_VERSION',
  USER_VIEWED_INBOX_SCREEN: 'USER_VIEWED_INBOX_SCREEN',
  USER_COMPLETE_WISHLIST: 'USER_COMPLETE_WISHLIST',
  USER_VIEWED_PROFILE: 'USER_VIEWED_PROFILE',
  USER_UPDATED_USERNAME: 'USER_UPDATED_USERNAME',
  USER_UPLOADED_IMAGE: 'USER_UPLOADED_IMAGE',
  USER_VIEWED_TRIP_DETAIL: 'USER_VIEW_TRIP_DETAIL', 
  USER_COMMENTED: 'USER_COMMENTED',
  NO_SUGGESTIONS_LOADED: 'NO_SUGGESTIONS_LOADED',
  USER_JOINED_CHAT: 'USER_JOINED_CHAT',
  NOT_PUBLISHED_DIALOG_CLOSED: 'NOT_PUBLISHED_DIALOG_CLOSED',
  USER_MUTED_CHAT: 'USER_MUTED_CHAT',
  USER_CLICKED_INTERESTED: 'USER_CLICKED_INTERESTED',
  USER_DENIED_NOTIFICATION: 'USER_DENIED_NOTIFICATION',
  USER_LOAD_ARE_YOU_SURE_NOTIFICATION: 'USER_LOAD_ARE_YOU_SURE_NOTIFICATION',
  USER_TURNED_ON_NOTIFICATION: 'USER_TURNED_ON_NOTIFICATIONS',
  USER_CANCEL_INTEREST: 'USER_CANCEL_INTEREST',
  USER_SHARED_TRIP: 'USER_SHARED_TRIP',
  USER_EXIT_TRIP_MODAL: 'USER_EXIT_TRIP_MODAL',
  USER_SHARED_PROFILE: 'USER_SHARED_PROFILE',
  USER_SHARED_LINK: 'USER_SHARED_LINK',
  USER_DOWNLOADED_CALENDER_IMAGE: 'USER_DOWNLOADED_CALENDER_IMAGE',
  USER_SHARED_APP: 'USER_SHARED_APP',
  USER_CLICKED_CHAT_TAB: 'USER_CLICKED_CHAT_TAB',
  USER_CLOSED_ANNOUNCEMENT: 'USER_CLOSED_ANNOUNCEMENT',
  USER_CLICKED_PROFILE: 'USER_CLICKED_PROFILE',
  USER_VIEWED_CELL_PROFILE: 'USER_VIEWED_CELL_PROFILE',
  USER_VIEWED_SIGNUP: 'USER_VIEWED_SIGNUP', 
  USER_VIEW_HOME: 'USER_VIEW_HOME',
  USER_VIEW_SEARCH: 'USER_VIEW_SEARCH',
  USER_SOCIAL_LOGIN: 'USER_SOCIAL_LOGIN',
  GUEST_SUBMITTED_INFO: 'GUEST_SUBMITTED_INFO',
  GUEST_CLICK_KEEP_ME_INFORMED: 'GUEST_CLICK_KEEP_ME_INFORMED',
  POST_WISHLIST_DOWNLOAD: 'POST_WISHLIST_DOWNLOAD',
  POST_WISHLIST_NO_THANKS: 'POST_WISHLIST_NO_THANKS',
  WEB_API_SHARE_COMPLETE: 'WEB_API_SHARE_COMPLETE',
  TRENDING_SCREEN_VIEW: 'TRENDING_SCREEN_VIEW',
  TRENDING_PLACE_CLICK: 'TRENDING_PLACE_CLICK',
  TRENDING_VIEW_ALL: 'TRENDING_VIEW_ALL',
  TRIP_NOT_PUBLISHED: 'TRIP_NOT_PUBLISHED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  SERVER_ERROR: 'SERVER_ERROR'
};

class Analytics {  
	private state = null;

    static instance = null;

	constructor() { 
		if (!Analytics.instance) { 
			this.state = { 
				initialized: false
			}
			Analytics.instance = this
		}

		return Analytics.instance
	}

	async initialize() { 
		if (__DEV__) { 
			return 
		}

		const API_KEY = Config.amplitudeApiKey;
		await init(API_KEY).promise;

		this.state.initialized = true
	}

	async maybeInitialize() { 
		if (!this.state.initialized) { 
			await this.initialize();
		}
	}

	async logEvent(event, properties=null) { 

			if (__DEV__) { 
				console.log("DEV, RETUNING")
				return 
			}
			
			await this.maybeInitialize();
			await track(event, properties).promise;
	}
	

	async setUserId(id) { 
		if (__DEV__) { 
			return;
		}
		if (!id) { 
			return 
		}
		await this.maybeInitialize();
		setUserId(id);
	}


	async setUserProperties(properties) { 
		if (__DEV__) { 
			return;
		}
		if (properties){
			await this.maybeInitialize();
			const identifyObj = new Identify();
      Object.keys(properties).map(key => identifyObj.set(key, properties[key])) 
			await identify(identifyObj).promise;
		}
	}

}

const AnalyticsManager = new Analytics();

export {AnalyticsManager, events}