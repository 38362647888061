import { Button, Modal } from "native-base";
import React from "react";
import { Linking } from "react-native";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";

export default function NotificationReminderModal(props) {

    const onTurnOnNotifications = () => { 
        AnalyticsManager.logEvent(events.USER_REENGAGED_NOTIFICATION)
        Linking.openSettings();
        props.onClose()
    }

    return <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
        <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>Turn on notifications?</Modal.Header>
            <Modal.Body w="100%">
                {props.body}
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={props.onClose}>
                  Keep off
                </Button>
                <Button colorScheme={"rose"} size="lg" onPress={onTurnOnNotifications}>
                  Turn on notifications
                </Button>
              </Button.Group>
            </Modal.Footer>
        </Modal.Content>
    </Modal>


}

// AnalyticsManager.logEvent(events.USER_REENGAGED_NOTIFICATION)
// 		Linking.openURL("app-settings:")