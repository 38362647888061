import { Input } from "native-base";
import { textMatches } from "../utils";


export default function ConstrainedInput(props) {

    const handleInput = (value) => { 
		if (!textMatches(value, props.type, props.regex)) {
			return;
		}
		props.onChangeText(value)
    }

    return <Input {...props.inputProps} value={props.value} onChangeText={(val) => handleInput(val)} />
}