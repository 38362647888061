import { useState } from "react";
import Layout from "../components/Layout"
import { Button, Heading, Input, Text, View}  from 'native-base'

export default function PersonaQuiz() { 
    const [currQuestionIndex, setCurrQuestionIndex] = useState(0);
    const [answersBank, setAnwersBank] = useState({})

    const questions = [
        {
            key: "countries_visited",
            q: "How many countries have you been to?",
            type: "input"
        }, 
        {
            key: "interest_choice_1",
            q: "Which one of these would you prefer?",
            type: "single-choice",
            choices: [
                "Beach day 🏖",
                "Multiple course food tasting 🥗🍛 ",
                "Hiking 🥾",
                "Shopping 🛍",
                "Museum 🖼",
                "Exploring the city on foot 🏙",
            ]
        }, 
        {
            key: "airport_time",
            q: "How early do you usually get to the airport?",
            type: "single-choice",
            choices: [
                "3 hours before departure",
                "2 hours before departure",
                "1 hour before",
                "30 minutes before",
            ]
        },
        {
            key: "accomodation_style",
            q: "What accomodation would you prefer on a trip?",
            type: "single-choice",
            choices: [
                "All-inclusive resort",
                "Airbnb in the city center",
                "Hostel in the city center",
                "4 star hotel in the city center",
                "5 star hotel in the city center",
            ]
        }, 
        {
            key: "timeline",
            q: "How far ahead do you usually take to plan a trip?",
            type: "single-choice",
            choices: [
                "Months", 
                "Weeks",
                "Days",
                "Hours"
            ]
        }, 
        {
            key: "group_travel",
            q: "When you are going on a trip with friends, you're most likely to be the one who",
            type: "single-choice",
            choices: [
                "Sends the deposit after it's asked for", 
                "Plans the itinerary and coordinates with everyone"
            ]
        }, 
        {
            key: "perfect_day", 
            q: "Which type of travel day would your prefer?",
            type: "single-choice",
            choices: [
                "Early morning with breakfast, then an adventure"
            ]
        },
        {
            key: "food_preference",
            q: "What type of trip dinner would you choose",
            type: "single-choice",
            choices: [
                "Street food",
                "Highly rated michellin star restaurant",
                "Highly rated local restaurant with beautiful decor",
            ]
        }, 
        {
            key: "night_out", 
            q: "What type of night out on a group trip would you prefer?",
            type: "single-choice",
            choices: [
                "Bar crawl",
                "Clubbing with a section",
                "Dinner and drinks at a speakeasy",
                "Swanky rooftop bar",
                "Wine tasting"
            ] 
        }
    ]

    const personas = [
        {
            name: "The Lux Traveler",
            description: "Luxury and ease is at the top of the list for you. Direct flights only. You probably have a credit card with lounge access, and you love getting to the airport early to take full advantage. You love staying in 4-5 star hotels, and you love to be pampered. You love to travel with your friends, but you also love to travel solo. You've been to many countries, and view travel as an absolute necessity every year.",
            idealAnswers: [null, 1, 0, "3,4", "0,1"]
        }, 
        {
            name: "The Deal Hunter Traveler",
            description: "Like a predator, you're constantly scanning travel sites, just waiting to strike. 3 layovers but only 10% of the normal price? Yes please. You don't care about the hotel, because the only time you'll be there is to sleep.",
            idealAnswers: [null, "0,4", 3, "1,2"]
        },
        {
            name: "The Addrenaline Junkie", 
            description: "If it doesn't get your heart racing, it's not worth doing. Sky diving, bungie jumping, and cliff diving are all on your list. You love to hike, and you love to explore. You're not afraid to get dirty, and you're not afraid to get lost. You're not afraid of anything.",
            idealAnswers: [null, 2]
        },
        {
            name: "The Foodie",
            description: "You love to eat. You love to try new foods, and you love to try new restaurants. You love to cook, and you love to learn about the culture through food. You're not afraid to try new things, and you're not afraid to eat street food. You're not afraid of anything.",
            idealAnswers: [null, 1]
        },
        {
            name: "The Culture Seeker",
            description: "You love to learn about the history of a place. You love to visit museums, and you love to visit historical sites. You love to learn about the culture, and you love to learn about the people. You're not afraid to try new things, and you're not afraid to eat street food. You're not afraid of anything.",
            idealAnswers: [null, 5]
        },
        {
            name: "The Partier",
            description: "10am? Shots. 4pm? Shots. You're the one hyping people up, pouring drinks and always up to hit the streets. You thrive in the underbelly of a city and the night is yours canvas to paint and explore.",
            idealAnswers: [null, null]
        }, 
        {
            name: "The Balanced traveler",
            description: "You have a bit of everything in you. You're up for adventures, but also don't mind resting. You like some luxury but also don't mind a cheap flight. You're up for a michellin star 5 course meal but also would be happy with some great street food",        
            choices: [null, 5]
        },
        {
            name: "The Planner",
            description: "You love to plan. You love to plan every detail of your trip, and you love to plan every detail of your day. You love to plan every detail of your life. You're not afraid to try new things, and you're not afraid to eat street food. You're not afraid of anything.",
            idealAnswers: []
        },
        {
            name: "The Spontaneous Traveler",
            description: "Weekend getaways, random cruises and exercusions. You've been bitten by the wanderlust bug and are always reach to go somewhere new and try something you've never done before.",
            idealAnswers: []
        }
    ]

    const storeInputInBank = (val) => {
        const existing = {...answersBank}
        const currQ = questions[currQuestionIndex]
        existing[currQ.key] = val;
        setAnwersBank(existing)
    }


    return <Layout>
        <Text mt={3}>Question {currQuestionIndex + 1} out of {questions.length}</Text>

        <Heading mt={2}>{questions[currQuestionIndex].q}</Heading>
        <View mt={3} w="100%">
            {questions[currQuestionIndex].type == "input" && <Input keyboardType="numeric" w="100%" value={answersBank[questions[currQuestionIndex].key] ?? ""} onChangeText={(val) => storeInputInBank(val)} />}
            {questions[currQuestionIndex].type == "single-choice" && questions[currQuestionIndex].choices.map((choice) => <Button mt={1} variant="subtle" colorScheme={"coolGray"} onPress={() => storeInputInBank(choice)}>{choice}</Button>)}
        </View>

        <View mt={4} w="100%">
            <Button onPress={() => setCurrQuestionIndex(currQuestionIndex + 1)}>Continue</Button>
        </View>
        </Layout>

}