/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';
import { Platform } from 'react-native';
import CountriesVisited from '../screens/CountriesVisited';
import PersonaQuiz from '../screens/PersonaQuiz';

const linking: LinkingOptions<any> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Login: 'login',
      PublicProfile: 'user/:username',
      PublicTripDetail: 'trip/:tripId',
      CreateModal: 'create_trip',
      TripDetail: 'trip',
      Search: 'search',
      CountriesVisited: 'countries_visited',
      PersonaQuiz: 'quiz',
      WishlistShare: 'wishlist_share',
      Trending: 'trending',
      Community: 'community'
      // NotFound: '*',
    },
  },
};

if (Platform.OS === "web") {
  Object.assign(linking.config.screens, { Home: 'home', Trips: 'trips', Inbox: 'inbox', Profile: 'profile', Persona: 'persona',
})
  linking.config.screens
} else {
  Object.assign(linking.config.screens, {
    TripOnboard: "trip_onboard",
    Root: {
      screens: {
        Home: {
          screens: {
            Home: 'home',
          },
        },
        Trips: {
          screens: {
            TabTwo: 'trips',
          },
        },

        Create: {
          screens: {
            Create: 'create'
          }
        },

        Inbox: {
          screens: {
            Inbox: 'inbox'
          }
        },
        Profile: {
          screens: {
            Profile: 'profile'
          }
        }
      },
    }
  })
}

export default linking;
