import { AlertDialog, Button } from "native-base";
import React, { useRef } from "react";


export default function SimpleAlertDialog(props) {

    const cancelRef = useRef(null);

    return <AlertDialog leastDestructiveRef={cancelRef} isOpen={props.isOpen} onClose={props.onClose}>
    <AlertDialog.Content>
      <AlertDialog.CloseButton />
      <AlertDialog.Header>{props.title ?? "Confirm"}</AlertDialog.Header>
      <AlertDialog.Body>{props.body}
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <Button.Group space={2}>
          {!props.hideCancel && <Button variant="unstyled" colorScheme="coolGray" onPress={props.onClose()} ref={cancelRef}>
            Cancel
          </Button>}
          <Button colorScheme="danger" onPress={props.onConfirm}>
            Continue
          </Button>
        </Button.Group>
      </AlertDialog.Footer>
    </AlertDialog.Content>
  </AlertDialog>

}