import { Divider, HStack, View, Text, Pressable } from "native-base";
import React from "react";
import { FlatList } from 'react-native'
import ProfilePicture from "./ProfilePicture";


export default function UsersList(props) {
    const renderUser = (item) => {
        const user = item.item;
        console.log(user)
        return <Pressable onPress={() => props.onPress(user)} bg="white" pt="2" pb="2">
            <HStack space={2} flexDirection={"row"} mb="2" w="100%">
                <ProfilePicture user={user}></ProfilePicture>
                <View>
                    <Text fontSize={"lg"}>{user.first_name}</Text>
                    <Text>@{user.username}</Text>
                </View>
            </HStack>
        </Pressable>
    }

    return <View bg="white" flex={1} {...props.containerProps} w="100%"><FlatList
        data={props.users}
        style={{width: '100%'}}
        renderItem={renderUser}
        keyboardShouldPersistTaps={"always"}
        showsVerticalScrollIndicator={false}
        onRefresh={() => props.onRefresh && props.onRefresh()}
        refreshing={props.refreshing ? props.refreshing : false}
        ItemSeparatorComponent={Divider}
        onEndReachedThreshold={0.1}
        onEndReached={() => props.onEndReached && props.onEndReached()}
    /></View>
}