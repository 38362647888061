import { Avatar } from "native-base"
import React from "react"
import { Platform } from "react-native"
import ProfilePicture from "../components/ProfilePicture"
import WebLink from "../components/WebLink"


export const getUserProfile = (user: any, props: any = {}) => {
    if (!user) {
        return null
    }

    return <ProfilePicture user={user} avatarProps={props}></ProfilePicture>
}

export const getUserAvatar = (user, props: any = {}) => { 
    const identifier = user.first_name ? user.first_name[0] : user.username[0]
    return <Avatar source={{uri: user.picture_url}} bg={"lightBlue.400"} {...props}>{identifier.toUpperCase()}</Avatar>
}

export function maybeWrapWithWtihWebLink(view, webLink) { 
    if (Platform.OS === "web") { 
        return <WebLink href={webLink} target="_blank" onClick={(e) => e.stopPropagation()}>{view}</WebLink>
    } else { 
        return view
    }
}

