import { Feather } from "@expo/vector-icons";
import { Badge, Box, Heading, View, Text, Divider, Container, Image, Center } from "native-base";
import React from "react";
import { getCountString, getRandomTheme } from "../utils";
import TEXT from "../translations.json"

const SocialProfileView = React.forwardRef((props: any, ref) => {

    const user = props.user
    if (!user || !user.id) { 
        console.error("Calling social profile view with no user")
        return null;
    }
    
    return <View ref={ref} w="100%" collapsable={false} bg="red" style={{display: props.visible ? null : 'none', ...props.containerStyle}}>
        <View w="100%">
            {!props.hideIGText && <View mb="5">
                <Image source={require("../assets/images/fulltrip_text_white.png")} style={{height: 50, resizeMode: 'contain'}}></Image> 
                <Heading textAlign={"center"} color="white" mb="5" style={{fontSize: 28}} w="100%" mt="2">See all the trips i'm planning and join in!</Heading>

                <View maxW={"100%"} bg="white" rounded={"lg"} mb="1">
                    <Text flexWrap={"wrap"} color="rose.400"  textAlign="center" fontSize={"lg"} fontWeight="semibold">{`Cover this with link to ${props.user.username.toLowerCase()}.onfulltrip.com`}</Text>
                </View>  
            </View>}
            <View rounded="md" p="2" pt="4" bg="white" alignItems={"center"}>
                <Image source={{uri: user.picture_url}} onLoad={() => {
                   if (props.onPictureLoad){
                    props.onPictureLoad()
                   }
                }} style={{height: 80, width: 80, borderRadius: 40, backgroundColor: '#60BBF3'}}></Image>
                <Box flexDir={"row"} w="100%" justifyContent="space-between" alignItems={"center"} mb="1">
                    <Box w="100%">
                        <Heading size="md" pt="5" textAlign={"center"}>{user.first_name}</Heading>
                        <Text flexWrap={"wrap"} textAlign="center" fontSize={"sm"} mt="1" color="gray.700">@{user.username}</Text>
                    </Box>
                    {/* {props.user.friend_count > 0 && <Box>
                        <>
                            <Box flex={"1"} flexDir={"row"} alignItems="center">
                                <Feather name="users" size={16} color="red" />
                                {props.user && <Text fontSize={"md"} color="gray.700" ml="1.5" fontWeight={"medium"}>{props.user.friend_count} {TEXT.travelFriends}</Text>}
                            </Box>
                        </>
                    </Box>} */}
                </Box>

                <Box w="100%" flexDir={"row"} mt="5" justifyContent="space-between" alignItems="center" mb="5">
                    <Box flex={1}>
                        <Badge colorScheme="info" mb={"4"} _text={{fontSize: "2xs"}}>{TEXT.CONSIDERING}</Badge>
                        <Text textAlign={"center"}><Text color="black" fontSize={"xl"} height="100%">{user.trip_aggregation_counts?.THINKING ?? 0}</Text> {getCountString("trip", user.trip_aggregation_counts?.THINKING)}</Text>
                    </Box>

                    <Divider bg="gray.200" thickness="2" mx="2" orientation="vertical" />

                    <Box flex={1}>
                        <Badge colorScheme="success" mb={"4"} _text={{fontSize: "2xs"}}>{TEXT.PLANNING}</Badge>
                        <Text textAlign={"center"}><Text color="black" fontSize={"xl"}>{user.trip_aggregation_counts?.PLANNING ?? 0}</Text> {getCountString("trip", user.trip_aggregation_counts?.PLANNING)}</Text>
                    </Box>

                    <Divider bg="gray.200" thickness="2" mx="2" orientation="vertical" />

                    <Box flex={1}>
                        <Badge colorScheme="rose" mb={"4"} _text={{fontSize: "2xs"}}>{TEXT.BOOKING}</Badge>
                        <Text textAlign={"center"}><Text color="black" fontSize={"xl"}>{user.trip_aggregation_counts?.BOOKING ?? 0}</Text> {getCountString("trip", user.trip_aggregation_counts?.BOOKING)}</Text>
                    </Box>
                </Box>

                {props.getBottomViewRender ? props.getBottomViewRender(user) : null}


                {/* <Box pt="8">
                    <Heading>{TEXT.About}</Heading>
                    {user && <Text fontSize={"lg"} pt="2" numberOfLines={2} color="gray.600" maxH={"200"}>{user.bio ? user.bio : "..."}</Text>}
                </Box>  */}

                {/* {user.travel_wishes && <Box pt="8">
                    <Heading>{TEXT.travelGoals}</Heading>
                    <Container display="flex" flexWrap={"wrap"} w={"100%"} flexDir={"row"} mt="1">
                        {user.travel_wishes.length == 0 && <Text>....</Text>}
                        {user.travel_wishes.slice(0, 4).map((item) => <Badge mr={2} mt={2} colorScheme={getRandomTheme()}>{item.name.split(",")[0].substring(0, 20)}</Badge>)}
                    </Container>
                </Box>} */}
            </View>
        </View>
    </View>
})

export default SocialProfileView