import { View, Badge } from "native-base";
import React from "react";

export default function BadgedIcon(props) {
    return <View>
        {props.children}
        {props.count > 0 && <Badge // bg="red.400"
            colorScheme="danger" rounded="full" zIndex={1} variant="solid" _text={{
                fontSize: 8
            }} style={{
                position: 'absolute', top: -4, right: props.right ?? -4,
                justifyContent: 'center', alignItems: 'center'
            }}>
            {props.count}
        </Badge>}
    </View>
}