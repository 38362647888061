import { Center, Container, Heading, View, Text, HStack, Avatar, Button, Divider, Pressable, Image } from "native-base";
import { useEffect, useState } from "react";
import { FlatList } from "react-native";
import { ActivityIndicator } from "react-native";
import { useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import moment from 'moment-timezone'
import TEXT from '../translations.json'
import { DesktopView } from "../components/ViewTypes";
import Layout from "../components/Layout";
import { useFocusEffect } from "@react-navigation/native";
import React from "react";
import { getTripImage, showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import BadgedIcon from "../components/BadgedIcon";
import FriendRequestCell from "../components/FriendRequestCell";

type InboxType = "friend_request" | "trip_interest" | "comment"

class ItemWithType {
    inboxType: InboxType
    obj: any
}


export default function InboxScreen(props) {
    const friendReqApi = new GenericViewSetAPI("friend_request")
    const eventApi = new GenericViewSetAPI("trip_event")
    const tripInvitesApi = new GenericViewSetAPI("trip_invite")

    const pageSize = 10;

    const user = useSelector((s: any) => s.user)
    const [page, setPage] = useState(1);
    const [sugPage, setSugPage] = useState(1)
    const [invitesPage, setInvitesPage] = useState(1)
    const [latestInviteResp, setLatestInviteResp] = useState(null);
    const [latestResp, setLatestResp] = useState(null);
    const [latestSugResp, setLastestSugResp] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [itemWithTypes, setItemWithTypes] = useState(null);
    const [submissions, setSubmissions] = useState(null)
    const [tripInvites, setTripInvites] = useState(null);
    const [tab, setSelectedTab] = useState("friend_request")

    async function getInbox(canUpdate, page = 1, overwrite = true) {
        var items = []
        if (!overwrite) {
            items = [...itemWithTypes]
        }
        const friendReqResp = await friendReqApi.query({ page_size: pageSize, page: page, sent_to_me: true, expansions: ["requestor"] });
        setLatestResp(friendReqResp);
        console.log(friendReqResp);
        if (friendReqResp && !friendReqResp.error) {
            for (var req of friendReqResp.results) {
                const item = new ItemWithType()
                console.log(req)
                item.obj = req;
                item.inboxType = "friend_request"
                items.push(item)
            }
        } else {
            showAlert("Something went wrong while loading your inbox. Try again!");
        }
        if (canUpdate) {
            console.log(items)
            setItemWithTypes(items)
            if (overwrite) { 
                setPage(1)
            }
        }
    }

    async function getActiveSuggestions(canUpdate, page = 1, overwrite = true) { 
        var items = []
        if (!overwrite) {
            items = [...submissions]
        }
        const activeSubmissions = await eventApi.query({all_my_active: true, page_size: pageSize, page: page, expansions: ["creator", "trip"]})
        console.log("submission resp", activeSubmissions)
        setLastestSugResp(activeSubmissions)
        if (activeSubmissions && !activeSubmissions.error) { 
            items = [...items, ...activeSubmissions.results]
        }

        if (canUpdate) {
            setSubmissions(items)
            if (overwrite) { 
                setSugPage(1)
            }
        }
    }

    async function getActiveTripInvites(canUpdate, page =1, overwrite = true) { 
        var items = []
        if (!overwrite) {
            items = [...tripInvites]
        }
        const activeInvitesResp = await tripInvitesApi.query({expansions: ["trip"]})
        console.log("invites resp", activeInvitesResp)
        setLatestInviteResp(activeInvitesResp)
        if (activeInvitesResp && !activeInvitesResp.error) { 
            items = [...items, ...activeInvitesResp.results]
        }

        if (canUpdate) {
            setTripInvites(items)
            if (overwrite) { 
                setInvitesPage(1)
            }
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            let isActive = true;
            console.log("FETCHING ON FOCUS EFFECT");
            AnalyticsManager.logEvent(events.USER_VIEWED_INBOX_SCREEN);
            getInbox(isActive)
            getActiveSuggestions(isActive)
            getActiveTripInvites(isActive)
            return () => {
                isActive = false;
            };
        }, [])
    );

    const handleFrResponse = async (frId: string, accepted: boolean) => {
        const now = Date()
        const reqBody = { accepted: accepted }
        AnalyticsManager.logEvent(events.USER_RESPOND_TO_FRIEND_REQUEST, {source: 'inbox'})
        const respondResp = await friendReqApi.nonStandard("POST", `${frId}/respond`, reqBody);
        if (respondResp.error) {
            showAlert("Something went wrong when responding to the friend request. Please try again!");
        }
        console.log(respondResp)
        getInbox(true);
    }


    const loadMore = async () => {
        if (!latestResp.next) { 
            return;
        }
        const newPage = page + 1;
        getInbox(true, newPage, false);
        setPage(newPage)
    }

    const loadMoreSug = async () => {
        if (!latestSugResp.next) { 
            return;
        }
        const newPage = sugPage + 1;
        getActiveSuggestions(true, newPage, false);
        setSugPage(newPage)
    }

    const loadMoreTripInvites = async () => { 
        if (!latestInviteResp.next) { 
            return;
        }
        const newPage = invitesPage + 1;
        getActiveTripInvites(true, newPage, false);
        setInvitesPage(newPage)
    }

    const refresh = async () => {
        setRefreshing(true);
        if (tab === "friend_request") {
            getInbox(true);
        } else if (tab === "suggestion") { 
            getActiveSuggestions(true)
        } else if (tab === "invites") {
            getActiveTripInvites(true);
        }
        setRefreshing(false);
    }

    const handleRenderItem = (itemWrapper: any) => {
        if (tab == "friend_request" && itemWrapper.item.inboxType === "friend_request") {
            const item = itemWrapper.item.obj;
            return <FriendRequestCell fr={item} navigation={props.navigation} handleFrResponse={handleFrResponse}/>
        }  else if (tab == "suggestion") {
            const item = itemWrapper.item;
            console.log('item is', item)
            return <View mb={4} mt={4}>
                {item.trip && <Text mb={3} fontSize="md">Trip to <Text bold>{item.trip.place}</Text></Text>}
                <HStack w="100%" space={3}>
                    <View >
                        <Pressable onPress={() => props.navigation.navigate('PublicProfile', { username: item.creator?.username })}><Avatar source={{ uri: item.creator?.picture_url }}></Avatar></Pressable>
                    </View>
                    <View flex={1}>
                        <Text flexWrap={"wrap"} fontSize="md"><Text color={"coolGray.600"} bold>{item.creator?.first_name}</Text> sent you a suggestion to visit <Text bold>{item.place}</Text></Text>
                        {/* <HStack space={2} mt="2" flex={1}>
                            <Button colorScheme={"rose"} onPress={() => handleFrResponse(item.id, true)}>{TEXT.accept}</Button>
                            <Button colorScheme={"coolGray"} onPress={() => handleFrResponse(item.id, false)}>{TEXT.reject}</Button>
                            <View flex={1} pl={6}>
                                <Text color={"coolGray.500"}>{moment(item.created_at).format('ll')}</Text>
                            </View>
                        </HStack> */}
                    </View>
                </HStack>
                <Button mt={4} variant="subtle" colorScheme={"info"} onPress={() => props.navigation.navigate("TripEvents", {selectedPlaceObj: item, tripId: item.trip?.uuid})}>View</Button>
            </View> 
        } else {
            return <></>;
        }
    }

    const renderTripInvite = (itemWrapper: any) => { 
        const item = itemWrapper.item;
        const trip = item.trip
        return <Pressable mt={5} mb={5} onPress={() => props.navigation.navigate("TripDetail", { tripId: trip.uuid })}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} flex={1} h="100%">
                <HStack space={2}>
                    <Image source={{uri: getTripImage(trip)}} height="150" width="120" style={{borderRadius: 5, resizeMode: 'cover'}}/>

                    <View flex={1}>
                        <Text mt={1} color={"coolGray.700"} flexWrap={"wrap"} fontSize={"md"}>You've been invited to the trip to <Text bold color="black">{trip.place}</Text></Text>
                        {item.start_date && <Text fontSize={"sm"} mt={1} color={"coolGray.600"}>{moment(trip.start_date).fromNow()}</Text>}
                    </View>
                </HStack>
            </View>
        </Pressable>
    }

    return <Layout>
        {/* <DesktopView><Heading size="xl" mb={4}>{TEXT.Inbox}</Heading></DesktopView> */}
        <HStack justifyContent={"space-around"} w="100%" mt={4} mb={0}>
            <Pressable onPress={() => setSelectedTab("friend_request")}>
                <BadgedIcon right={-25} count={itemWithTypes ? itemWithTypes.length : 0}><Text bold={tab == "friend_request"} fontSize="md">Friend requests</Text></BadgedIcon>
                {tab == "friend_request" && <Divider mt={1} bg="info.700"></Divider>}
            </Pressable>

            {/* <Text>|</Text> */}

            <Pressable onPress={() => setSelectedTab("invites")}>
               <BadgedIcon right={-25} count={tripInvites ? tripInvites.length : 0}><Text bold={tab == "invites"} fontSize="md">Invites</Text></BadgedIcon>
               {tab == "invites" && <Divider mt={1} bg="coolGray.800"></Divider>}
            </Pressable>

            <Pressable onPress={() => setSelectedTab("suggestion")}>
               <BadgedIcon right={-25} count={submissions ? submissions.length : 0}><Text bold={tab == "suggestion"} fontSize="md">Suggestions</Text></BadgedIcon>
               {tab == "suggestion" && <Divider mt={1} bg="coolGray.800"></Divider>}
            </Pressable>

        </HStack>
        {tab === "friend_request" && <>
        {itemWithTypes == null && <ActivityIndicator></ActivityIndicator>}
        {itemWithTypes !== null && itemWithTypes.length == 0 && <View w="100%" height="100%"  mt={2}>
                <Heading color={"gray.600"}>{TEXT.noMessages}</Heading>
                <Text color={"gray.600"}>{TEXT.friendReqWillShowHere}</Text>
        </View>}
        <FlatList
            style={{width: '100%'}}
            contentContainerStyle={{paddingTop: 13}}
            data={itemWithTypes}
            renderItem={handleRenderItem} 
            showsVerticalScrollIndicator={false}
            onRefresh={refresh}
            refreshing={refreshing}
            onEndReachedThreshold={0.1}
            onEndReached={() => loadMore()}>
        </FlatList>
        </>}

        {tab === "suggestion" && <>
        {submissions == null && <ActivityIndicator></ActivityIndicator>}
        {submissions !== null && submissions.length == 0 && <View mt={2} w="100%" height="100%" >
                <Heading color={"gray.600"}>No submitted suggestions</Heading>
                <Text color={"gray.600"}>Suggestions from friends will show here...</Text>
        </View>}
        <FlatList
            style={{width: '100%'}}
            contentContainerStyle={{paddingTop: 13}}
            data={submissions}
            renderItem={handleRenderItem}
            showsVerticalScrollIndicator={false}
            onRefresh={refresh}
            refreshing={refreshing}
            onEndReachedThreshold={0.1}
            ItemSeparatorComponent={Divider}
            onEndReached={() => loadMoreSug()}>
        </FlatList>
        </>}

        {tab === "invites" && <>
        {tripInvites == null && <ActivityIndicator></ActivityIndicator>}
        {tripInvites !== null && tripInvites.length == 0 && <View mt={2} w="100%" height="100%" >
                <Heading color={"gray.600"}>No trip invites</Heading>
                <Text color={"gray.600"}>Trip invites from friends will show here...</Text>
        </View>}

        <FlatList
            style={{width: '100%'}}
            contentContainerStyle={{paddingTop: 13}}
            data={tripInvites}
            renderItem={renderTripInvite}
            showsVerticalScrollIndicator={false}
            onRefresh={refresh}
            refreshing={refreshing}
            onEndReachedThreshold={0.1}
            ItemSeparatorComponent={Divider}
            onEndReached={() => loadMoreTripInvites()}>
        </FlatList>
        </>}
    </Layout>
}