import moment from "moment";
import { HStack, Pressable, Text, View, Image, Badge } from "native-base";
import React from "react";
import { getColorSchemeForPhase, getCountString, getDescriptiveStringForPhase, getTripImage } from "../utils";
import GroupedAvatars from "./GroupedAvatars";
import TEXT from '../translations.json'


export default function SocialTripRowCell(props) {

    const { trip, isMyTrip, myUserId } = props;
    const item = trip;
    const users = [item.creator, ...item.interest_users]
    const showAvatars = users.length > 1;
    users.map((item) => {
        if (item.id !== myUserId) {
            item.picture_url = null;
        }
        return item
    })
    return <Pressable style={{height: props.height}} mt={1} mb={1} minW="100%">
        {!isMyTrip && !props.ignoreInterest && <Text mb="5">{TEXT.youExpressedInterestIn}</Text>}

        <HStack height="100%" space={2}>
            <Image source={{uri: getTripImage(item)}} style={{ height: props.imageHeight ?? "100%", width:props.imageWidth ?? "100%",  borderRadius: 5, resizeMode: 'cover'}}/>
            <View flex={1} mt={1}>
                    {item.my_payment_status === "paid" && <Badge size="xs" rounded="full" _text={{fontSize: 'xs'}} maxW={"35%"} colorScheme={"rose"} mb="2">Deposit paid</Badge>}
                    {!item.published && <Badge size="xs" _text={{fontSize: 'xs'}} maxW={"30%"} colorScheme={"amber"} mb="2">Draft</Badge>}
                    {/* {item.published && <Badge size="xs" maxW={"50%"} _text={{fontSize: '2xs'}} colorScheme={getColorSchemeForPhase(item.phase)} mb="2">{getDescriptiveStringForPhase(item.phase).toUpperCase()}</Badge>} */}
                       
                    {item.creator && item.interest_users && showAvatars && <HStack  display="flex" flexDirection="row" alignItems={"center"}>
                        <GroupedAvatars users={[item.creator, ...item.interest_users]} avatarSize={"sm"} />
                        {item.interest_count > 0 && <Text pl="1" fontSize="sm" color={"coolGray.600"}>({item.interest_count}) {getCountString("other", item.interest_count)}</Text>}
                    </HStack> }
                    <Text numberOfLines={1} mt={1} color={"coolGray.700"} flexWrap={"wrap"} fontSize={"md"}>{TEXT.TripTo} <Text bold color="black">{item.place}</Text></Text>
                    {item.start_date &&  !props.hideDates && <Text fontSize={"sm"} mt={1} color={"coolGray.600"}>{moment(item.start_date).fromNow()}</Text>}

                    {item.chat && !props.hideComments &&  item.chat.latest_comment && <Pressable onPress={() => props.navigation.navigate("ChatScreen", {chatId: item.chat_id, title: item.place})}>
                        <Text numberOfLines={1} mt={1} fontSize="lg" color="coolGray.600">@{item.chat.latest_comment.creator.username.toLowerCase()}: {item.chat.latest_comment.message}</Text>
                    </Pressable>}
                </View> 
        </HStack>
        </Pressable>
}