/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Platform, Pressable } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import LoginScreen from '../screens/LoginScreen';
import LinkingConfiguration from './LinkingConfiguration';
import ProfileScreen from '../screens/ProfileScreen';
import InboxScreen from '../screens/InboxScreen';
import TripsScreen from '../screens/TripsScreen'
import TripDetailScreen from '../screens/TripDetailScreen';
import TripDraftScreen from '../screens/TripDraftScreen';
import HomeScreen from '../screens/HomeScreen';
import UserListScreen from '../screens/UserListScreen';
import useViewType from '../hooks/useViewType';
import { View, Text, HStack, Icon } from 'native-base';
import WebHeader from '../components/WebHeaderRight';
import WishlistScreen from '../screens/WishlistScreen';
import EditProfileScreen from '../screens/EditProfile';
import TripOnboardScreen from '../screens/TripOnboardScreen';
import ChatScreen from '../screens/ChatScreen';
import SearchScreen from '../screens/SearchScreen';
import { UpdateRequiredScreen } from '../screens/UpdateRequiredScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import OnboardFriendScreen from '../screens/OnboardFriendScreen';
import TripReviewScreen from '../screens/TripReviewScreen';
import TripEventsScreen from '../screens/TripEventsScreen';
import MobileVerificationScreen from '../screens/MobileVerificationScreen';
import WebScreen from '../screens/WebScreen';
import PaymentsOnboardScreen from '../screens/PaymentsOnboardScreen';
import ContactsScreen from '../screens/ContactsScreen';
import TripsShareScreen from '../screens/TripsShareScreen';
import ReactiveWebScreen from '../screens/ReactiveWeb';
import { Screens } from '../constants/Screens';
import CountriesVisited from '../screens/CountriesVisited';
import Persona from '../screens/Persona';
import PersonaQuiz from '../screens/PersonaQuiz';
import WishlistShareScreen from '../screens/WishlistShareScreen';
import TrendingScreen from '../screens/TrendingScreen';
import CommunityScreen from '../screens/CommunityScreen';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const NullComponent = () => null;

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<any>();

function RootNavigator() {
  const webHeader = () => Platform.OS == "web" ? {
    header: (props) => <WebHeader props={props}></WebHeader>
  } : null

  const nonDownloadHeader = () => Platform.OS == "web" ? {
    header: (props) => <WebHeader props={{...props, hideDownload: true}}></WebHeader>
  } : null
  return (
    <Stack.Navigator screenOptions={{headerBackTitleVisible: false, animation: Platform.OS === "android" ? "none" : "default"}} >
      <Stack.Screen name="Login" component={LoginScreen} options={{...webHeader(), title: 'Fulltrip'}}></Stack.Screen>
      {Platform.OS == "web" && <>
        <Stack.Screen name="Root" component={HomeScreen} options={webHeader} />

        <Stack.Screen  name="Trips" component={TripsScreen} options={webHeader}/>

        <Stack.Screen name="Search" component={SearchScreen} options={webHeader}></Stack.Screen>

        <Stack.Screen name="Profile" component={ProfileScreen} options={webHeader}/>

        <Stack.Screen name="CountriesVisited" component={CountriesVisited} options={nonDownloadHeader} />

        <Stack.Screen name="Persona" component={Persona} options={nonDownloadHeader} />
        
        <Stack.Screen name="WishlistShare" component={WishlistShareScreen} options={nonDownloadHeader} />

        <Stack.Screen name="PersonaQuiz" component={PersonaQuiz} options={nonDownloadHeader} />        
      </>}
      {Platform.OS !== "web" && <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />}
      <Stack.Screen name="Inbox" component={InboxScreen} options={webHeader}/>
      <Stack.Screen name="Web" component={ReactiveWebScreen} options={{headerTransparent: true, ...webHeader()}}/>
      <Stack.Screen name="MobileVerification" component={MobileVerificationScreen} options={{title: 'Verify',  ...webHeader}} />
      <Stack.Screen name="UpdateRequired" component={UpdateRequiredScreen} options={{headerShown: false}}/>
      <Stack.Screen name="Contacts" component={ContactsScreen} />
      <Stack.Screen name="Trending" component={TrendingScreen} options={webHeader}/>
      <Stack.Screen name="Community" component={CommunityScreen} options={webHeader}/>
      <Stack.Screen name="TripReview" component={TripReviewScreen} options={{title: 'Review trip', ...webHeader()}}></Stack.Screen>
      <Stack.Screen name="TripEvents" component={TripEventsScreen} options={{title: 'Trip itinerary', ...webHeader()}} />
      <Stack.Screen name="OnboardNotification" component={NotificationsScreen} options={{title: 'Notifications'}}/>
      <Stack.Screen name="OnboardFriend" component={OnboardFriendScreen} options={{title: 'Add Friend'}}/>
      <Stack.Screen name="PublicProfile" component={ProfileScreen} options={webHeader}></Stack.Screen>
      <Stack.Screen name="UserList" component={UserListScreen} options={webHeader}  />
      <Stack.Screen name="ChatScreen" component={ChatScreen} options={{title: 'Chat'}}></Stack.Screen>
      <Stack.Screen name="TripOnboard" component={TripOnboardScreen} options={ {title: 'First trip', ...webHeader()}}></Stack.Screen>
      <Stack.Screen name="Wishlist" component={WishlistScreen} options={ {title: 'Travel wishlist', ...webHeader()}}></Stack.Screen>
      <Stack.Screen name="EditProfile" component={EditProfileScreen} options={ {title: 'Edit Profile', ...webHeader()}}></Stack.Screen>
      <Stack.Screen name="PublicTripDetail" component={TripDetailScreen} options={{ headerShown: true, title: 'Trip', ...webHeader() }}></Stack.Screen>
      <Stack.Screen name="TripDetail" component={TripDetailScreen} options={{ headerShown: true, title: 'Trip', ...webHeader() }}></Stack.Screen>
      <Stack.Screen name="CreateModal" component={TripDraftScreen} options={{title: 'Create Trip', ...webHeader()}} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name={Screens.TripsShareScreen} component={TripsShareScreen} options={{title: 'Share your calendar',  ...webHeader}}/>
        <Stack.Screen name="Modal" component={ModalScreen} />
        <Stack.Screen name="MobileVerificationModal" component={MobileVerificationScreen} options={{title: 'Verify',  ...webHeader}} />
        <Stack.Screen name="PaymentsOnboard" component={PaymentsOnboardScreen} options={{title: 'Set up payments'}} />
      </Stack.Group>
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!', ...webHeader() }} />
    </Stack.Navigator>
    
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<any>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: "red",
        tabBarShowLabel: false
      }}>
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }) => ({
          title: 'Home',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="home" color={color} />
        })}
      />

      <BottomTab.Screen
        name="Trips"
        component={TripsScreen}
        options={{
          title: 'My Travel Calendar',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="calendar" size={24} color={color} />,
        }}
      />

      <BottomTab.Screen
        name="Create"
        component={NullComponent}
        options={{
          title: 'Create',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="plus-circle" color={color} />,
        }}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            e.preventDefault()
            navigation.push("CreateModal")
          },
        })}
      />

      <BottomTab.Screen
        name="Search"
        component={SearchScreen}
        options={{
          title: 'Search',
          // tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="inbox" color={color} />
          tabBarIcon: ({color}) => <FontAwesome name="search" color={color} size={28}/>
        }}
      />


      <BottomTab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: 'Profile',
          tabBarIcon: ({ color }) => <FontAwesomeTabBarIcon name="user" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function FontAwesomeTabBarIcon(props: {
  size?: number;
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={props.size ?? 30} style={{ marginBottom: -3 }} {...props} />;
}
