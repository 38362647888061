import React from 'react'
import { Text } from 'native-base'
import moment from 'moment';
import { getColorForTripPhase, getDescriptiveStringForPhase } from '../utils';

export default function TripOverviewText(props: any) { 
    const { trip } = props;


    function getAdditiveText() {
        if (!props.includeInterestCount) { 
            return <Text><Text bold>{`${trip?.creator?.first_name}`}</Text> is</Text>
        }
        if (trip.interest_count > 1) {
            return <Text><Text bold>{`${trip?.creator?.first_name} and ${trip.interest_count} others`}</Text> are</Text>
        } else if (trip.interest_count == 1) {
            return <Text><Text bold>{`${trip?.creator?.first_name} and ${trip.interest_count} other`}</Text> are</Text>
        } else {
            return <Text><Text bold>{`${trip?.creator?.first_name}`}</Text> is</Text>
        }
    }

    function getPlaceText() {
        if (props.hidePlaceAndTime) { 
            return <React.Fragment>this trip</React.Fragment>
        } else { 
            return <React.Fragment>a trip to <Text bold color="black">{trip.place}</Text>
            {trip.start_date && <Text mb="1" mt="1"> on {moment(trip.start_date).format('LL')}</Text>}</React.Fragment>
        }
    }

    return <Text {...props.__text} color={"coolGray.700"} style={{flexShrink: 1}} fontSize={"lg"} {...props.__text}>{getAdditiveText()}
    <Text style={{flexShrink: 1}} fontWeight="medium" color={getColorForTripPhase(trip.phase)}> {getDescriptiveStringForPhase(trip.phase)}</Text> {getPlaceText()}
    </Text>
}
