import moment from "moment-timezone"
import React from "react"
import { Pressable, Image, Text, Badge, Button, HStack, View } from "native-base"
import { useSelector } from "react-redux"
import ProfilePicture from "./ProfilePicture"

export default function TripEventCell(props) {

    const user = useSelector((state: any) => state.user)

    const itemData = props.event

    const isMine = () => { 
        return itemData.creator_id == user.id
    }
    return <Pressable pr="2" width="300" mb={props.mb ?? "6"} onPress={ () => props.onPress ? props.onPress(itemData) :  null}>
        {itemData.start_at && <Text fontSize="md" mb="2">{moment(itemData.start_at).format('ll')}</Text>}
        <View style={{borderWidth: 1}} borderColor="coolGray.300" borderRadius={"md"}>
            {itemData.picture_url && <Image source={{ uri: itemData.picture_url }} height={180} borderTopRadius={"md"} resizeMode="cover" />}
            <View pl="3" pb="2" pr="2" pt="2">
                {/* <Text>published: {itemData.published}</Text> */}
                <HStack space={1}>
                    {itemData.member_only && <Badge colorScheme={"info"} w="100" mt={0} rounded={"md"}>{"Member only"}</Badge>}
                    {(itemData.submitted && !itemData.published) && <Badge colorScheme={"info"} w="150" mt={0} rounded={"md"}>{"Awaiting response"}</Badge>}
                </HStack>
                <Text pt="2" fontSize={"md"} fontWeight="semibold">{itemData.title.split(0, 15)}</Text>
                {!itemData.description && <Text pt="1" fontSize={"xs"} numberOfLines={1}>{itemData.address}</Text>}
                {itemData.description && <Text pt="1" fontSize={"xs"} numberOfLines={2}>{itemData.description}</Text>}
                {itemData.submitted && itemData.creator && <HStack space={2} mt={2} alignItems="center">
                    <ProfilePicture user={itemData.creator}  avatarProps={{size: 'xs'}}/>
                    <Text fontSize={"xs"} color="coolGray.700">{itemData.creator.first_name} suggested</Text>
                </HStack>}
            </View>
        </View>
    </Pressable>
}