import React from "react";
import { createElement, Platform } from "react-native";
import { WebView } from 'react-native-webview';


export default function UniversalMapView(props) { 

    if (Platform.OS === "web") { 
        return createElement('iframe', {
            src: `https://www.google.com/maps/embed/v1/place?key=AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac&q=${props.place}`,
            frameborder: "0",
            allowfullscreen: true,
            style: { height: 400, pointerEvents: "none"}
        })

    } else { 
        return <WebView
        scalesPageToFit={true}
        javaScriptEnabled
        scrollEnabled={false}
        androidLayerType="hardware"
        source={{ html: `<iframe width="100%" height="100%" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac&q=${props.place}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>` }}
    />
    }

}