import { Entypo } from "@expo/vector-icons";
import { Button, Divider, FormControl, Input, Stack, TextArea, View, WarningOutlineIcon, Text, Pressable, HStack, Icon, ScrollView } from "native-base";
import React, { useEffect, useState, useLayoutEffect} from "react";
import { Alert, InputAccessoryView, Keyboard, View as ReactView, Platform, FlatList } from 'react-native'
import { KeyboardAwareFlatList, KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import UploadAPI from "../api/UploadAPI";
import ConstrainedInput from "../components/ConstrainedInput";
import ImagePicker from "../components/ImagePicker";
import Layout from "../components/Layout";
import { LoadingModal } from "../components/LoadingModal";
import WishlistView from "../components/WishlistView";
import AuthHelper from "../helper/AuthHelper";
import { setUser } from "../store";
import TEXT from '../translations.json'
import { getRandomTheme, showAlert, webOrNative } from "../utils";



export default function EditProfileScreen(props) {


    const userApi = new GenericViewSetAPI('user');
    const wishlistApi = new GenericViewSetAPI("travel_wishlist")
    const uploadApi = new UploadAPI()

    const inputAccessoryViewID = 'uniqueID';

    const privateUser = useSelector((s: any) => s.user)
    const [userName, setUserName] = useState(privateUser ? privateUser.username : "")
    const [bio, setBio] = useState(privateUser ? privateUser.bio : "")
    const [placesData, setPlacesData] = useState({})
    const [saving, setSaving] = useState(false) 
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null)
    const dispatch = useDispatch();

    async function getUser() { 
        const userResp = await userApi.getById(privateUser.id, { expansions: ["travel_wishlist"] })
        console.log('[Edit Profile] getUser resp', userResp);
        if (!userResp.error) { 
            console.log(userResp)
            const placeInfo = {...placesData}
            const startTravelWishlist = userResp.travel_wishes;
            for (var wish of startTravelWishlist) {
                placeInfo[wish.name] = {id: wish.id}
            }
            setPlacesData(placeInfo)
        }
    }
    
    async function updateUser() { 
        var imageUrl = null
        if (image) { 
            const uploadResp = await uploadApi.uploadImageV2(imageFile, "profile_picture");
            console.error(uploadResp)
            if (uploadResp.error) { 
                showAlert("Failed to upload your image. Please try again");
                setSaving(false)
                return;
            } else { 
                // console.error(`UPLOAD RESP IS `, uploadResp)
                imageUrl = uploadResp.url
            }
        }

        const updateBody : any = {username: userName, bio: bio}
        if (imageUrl) { 
            updateBody.picture_url = imageUrl
        }
        console.error(updateBody)
        const updatedUserResp = await userApi.update(privateUser.id, updateBody);
        console.log(updatedUserResp);
        if (updatedUserResp.error) {
            if (updatedUserResp.error.username) { 
                if (updatedUserResp.error.username.includes("user with this username already exists.")){
                    showAlert("User name is already taken. Please try another.");
                }
            } else { 
                showAlert("Something went wrong when updating your account");
            }
            setSaving(false)
            return;
        }

        const travelList = []
        for (var wishlistKey of Object.keys(placesData)) {
            const body = {...placesData[wishlistKey], name: wishlistKey}
            travelList.push(body)
        }

        const wishlistSyncResp = await wishlistApi.nonStandard("POST", "bulk_sync", {travel_list: travelList })
        if (!wishlistSyncResp.error) { 
            props.navigation.navigate("Profile");
        }
        
        const user = await AuthHelper.getUser(privateUser.token);
        if (user) { 
            dispatch(setUser(user))
        }

        setSaving(false)
    }

    useEffect(() => {
        getUser()
    }, [])

    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button isLoading={saving} variant="ghost" onPress={() => setSaving(true)}>Update</Button>
        })
    }, [props.navigation])

    useLayoutEffect(() => {
        if (saving) {
            updateUser()
        }

    }, [saving])

    function deletePlace(place)  {
        const updatedMap = {...placesData}
        delete updatedMap[place]
        setPlacesData(updatedMap)
    }

    function onImagePicked(image) {
        setImage(image);
    }

    function renderItem(item) {
        const val = item.item
        if (val === 0) {
            return <KeyboardAwareScrollView
            keyboardShouldPersistTaps={"always"}
                >
                <ImagePicker
                    backgroundImage={null}
                    onImageSelected={(val) => onImagePicked(val)}
                    onFileSelected={(val) => setImageFile(val)}
                    width={100}
                    height={100}
                    style={{ marginTop: 6 }}
                />

                <FormControl style={{ marginTop: 20 }}>
                    <Stack>
                        <FormControl.Label>{TEXT.username}</FormControl.Label>
                        <ConstrainedInput type="alphanumeric" value={userName} inputProps={{variant: "underlined", placeholder: ""}} onChangeText={(val) => setUserName(val)} />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="s" />}>
                            Atleast 6 characters are required.
                        </FormControl.ErrorMessage>
                    </Stack>
                </FormControl>

                <FormControl style={{ marginTop: 20 }}>
                    <Stack>
                        <FormControl.Label>{TEXT.About}</FormControl.Label>
                        <TextArea placeholder="" value={bio} onBlur={() => Keyboard.dismiss()} onChangeText={(val) => setBio(val)} autoCompleteType={undefined} inputAccessoryViewID={inputAccessoryViewID}/>
                    </Stack>
                </FormControl>

                <WishlistView title="Travel wishlist" hidePlacesList  mapData={placesData} onPlaceMapChange={(map) => setPlacesData(map)}></WishlistView>
                {webOrNative(() => <Button isLoading={saving} onPress={updateUser}>Continue</Button>, () => null)}
            </KeyboardAwareScrollView>
        } else if (val === 1) {
            return <HStack flexWrap={"wrap"}>
            {Object.keys(placesData).map((item) => <Button onPress={() => deletePlace(item)} rightIcon={<Icon as={Entypo} name="cross"></Icon>} mr={2} mt={2} variant="subtle" colorScheme={getRandomTheme()}>{item}</Button>)}
        </HStack>
        }
    }

    return <Layout>
        <FlatList data={[0, 1, 2]} renderItem={renderItem}
        style={{width: '100%', paddingTop: 10}}
        keyboardShouldPersistTaps={"always"}
        />
        <LoadingModal visible={saving}/>
        {Platform.OS == "ios" && <InputAccessoryView nativeID={inputAccessoryViewID}>
            <View w="100%" bg="white" display="flex" alignItems={"flex-end"}>
                <Button w="25%" variant="link" onPress={() => Keyboard.dismiss()}>Done</Button>
            </View>
        </InputAccessoryView>}
    </Layout>

}