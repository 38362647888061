import { Heading, View, Text, FlatList, Divider, ScrollView, Badge, Button, Pressable } from "native-base";
import Layout from "../components/Layout";
import { useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useState } from 'react'
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import moment from "moment-timezone";
import { openBrowserLinkForPlaceId } from "../utils";
import { Platform } from "react-native";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import TrendingVC from "../controllers/TrendingVC";

const generateMonthsData = () => {
    const data = []
    for (var i = 1; i <= 11; i++) { 
        const date = moment();
        const newData = date.add(i, "month")
        data.push({name: newData.format("MMM"), date: newData.toISOString()})
    }
    return data;
}

export default function TrendingScreen(props) { 
    const communityId = props.route.params?.community_id;
    const date = props.route.params?.date;

    const communityName = props.route.params?.communityName;

    return <Layout scrollView>
        <TrendingVC community_id={communityId} date={date} communityName={communityName}/>  
    </Layout>
}