import { Platform } from "react-native";
import { convertDataUrlToFile } from "../utils";
import BaseApiConfigProvider from "./BaseApiConfigProvider";
import BaseServer from "./BaseServer";


export default class UploadAPI extends BaseServer { 
    
    constructor() { 
		super(BaseApiConfigProvider.getEnvUrl())
	}

    async getImageUploadUrl() {
        return super.get("/upload/get_image_upload_url/")
    }

    async uploadImageV2(imageUri, context, url=null) {
        var data = {}
        if (!url) { 
            data = await this.getImageUploadUrl()
            console.log(data)
        }
        console.log('image uri is ', imageUri)
        const postData = new FormData();

        for (const key in data?.fields) {
            postData.append(key, data.fields[key]);
        }

        postData.append('file', imageUri);

        const resp = await this.simplePost(data.url, postData)
        if (!resp.error) { 
            return {url: `${data.url}${data.fields.key}`}
        } else { 
            return resp
        }
    }
}