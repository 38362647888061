import Layout from "../components/Layout";
import { Platform, Pressable, View as ReactView } from 'react-native'
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Badge, Heading, HStack, Text, Button, Icon, View, ScrollView } from "native-base";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import TEXT from '../translations.json'
import { getRandomTheme, showAlert, webOrNative } from "../utils";
import { Entypo, FontAwesome } from "@expo/vector-icons";
import WishlistView from "../components/WishlistView";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import BranchMetrics from "../utils/BranchManager";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardTripId } from "../store";
import { Screens } from "../constants/Screens";


export default function WishlistScreen(props) {
    const wishlistApi = new GenericViewSetAPI("travel_wishlist")
    const communityApi = new GenericViewSetAPI("community")

    const dispatch = useDispatch()
    const config = useSelector((state: any) => state.config)

    const [friendUsername, setFriendUsername] = useState(null);
    const [hasOnboardTrip, setHasOnboardTrip] = useState(false);
    const [friendInviteContext, setFriendInviteContext] = useState(null)
    const [placeMap, setPlaceMap] = useState({});
    const [saving, setSaving] = useState(false)
    const [communities, setCommunities] = useState(null)
    const [selectedCommunities, setSelectedCommunities] = useState<any>({})

    useEffect(() => {
        getCommunities()
    }, [])

    async function getCommunities() {
        const communityResp = await communityApi.query({});
        if (communityResp && !communityResp.error) {
            setCommunities(communityResp.results)
        }
    }

    async function updateUser() { 

        const travelList = []
        for (var wishlistKey of Object.keys(placeMap)) {
            const body = {...placeMap[wishlistKey], name: wishlistKey}
            travelList.push(body)
        }

        AnalyticsManager.logEvent(events.USER_COMPLETE_WISHLIST, {count: travelList.length});
        
        const wishlistSyncResp = await wishlistApi.nonStandard("POST", "bulk_sync", {travel_list: travelList })
        console.log(wishlistSyncResp)
        if (!wishlistSyncResp.error) { 
            if (Platform.OS === "web") { 
                props.navigation.replace(Screens.TripOnboard, {onboarding: true});
            } else { 
                props.navigation.replace(Screens.OnboardNotification, {onboarding: true});
            }
        }
        setSaving(false)
    }

    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button isLoading={saving} variant={"link"} onPress={() => setSaving(true)}>Continue</Button>
        })
        AnalyticsManager.logEvent(events.USER_VIEWED_WISHLIST);
    }, [props.navigation])

    useEffect(() => {
        if (saving) {
            updateUser()
        }

    }, [saving])

    async function handleCommunitySelected(community) {
        // showAlert("JOINING COMMUNITY");
        const newSelectedCommunities: any = {...selectedCommunities}
        if (newSelectedCommunities[community.id]) {
            AnalyticsManager.logEvent(events.USER_LEAVE_COMMUNITY)
            const update = await communityApi.nonStandard("POST", "membership", {community_id: community.id, action: "leave"})
            if (update && !update.error) { 
                delete newSelectedCommunities[community.id]
            }
        } else { 
            AnalyticsManager.logEvent(events.USER_JOIN_COMMUNITY)
            const update = await communityApi.nonStandard("POST", "membership", {community_id: community.id, action: "join"})
            if (update && !update.error) { 
                newSelectedCommunities[community.id] = true
            }
        }
        setSelectedCommunities(newSelectedCommunities);
    }

    return <Layout viewProps={{pt: 4}} >
        {config.show_communities && communities && <View h="60">
            <Text fontSize="xl">{config.communities_title ?? "Your interests"}</Text>
            <ScrollView horizontal mt={2}>
                {communities.map((community) => <Pressable style={{marginRight: 10}} onPress={() => handleCommunitySelected(community)}><Badge rounded={"full"} key={community.id} variant="subtle" colorScheme={selectedCommunities[community.id] ? "rose" : "coolGray"}>{community.name}</Badge></Pressable>)}
            </ScrollView>
            {}
        </View>}

        <WishlistView title="What are some places on your travel bucket list?" mapData={placeMap} onPlaceMapChange={(map) => setPlaceMap(map) }></WishlistView>
        {webOrNative(() => <Button isLoading={saving} onPress={updateUser}>Continue</Button>, () => null)}

        </Layout>

}