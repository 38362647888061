import { Heading, View, Text, HStack } from "native-base";
import React from "react";
import { ImageBackground } from "react-native";
import { useSelector } from "react-redux";
import Layout from "./Layout";
import ProfilePicture from "./ProfilePicture";
import SocialTripRowCell from "./SocialTripRowCell";
import { getColorSchemeForPhase, getCountString, getDescriptiveStringForPhase, getTripImage } from "../utils";
import MyImagePicker from "./ImagePicker";
import NativeImageBackground from "./NativeImageBackground";


export default function SocialTripsView(props) { 
    const user = useSelector((s: any) => s.user)
    const guest = useSelector((s: any) => s.guest)
    const config = useSelector((s: any) => s.config)


    const handleImageUpload = () => {
        if (props.onImageUploaded) { 
            props.onImageUploaded();
        }
    }

    return <View bg="white" pl={2} pr={2} mt="3" w="100%" rounded="md" minH="400">
        <View  w="100%"  mt={4}>
            <View flexDir={"row"} justifyContent="center" mb="3">
                {(!user && props.hideImagePicker || user ) && <ProfilePicture  user={user ?? guest ?  {id: -1, first_name: guest.name} : null} />}
                {!user && !props.hideImagePicker && <MyImagePicker useNative height={60} width={60} onImageSelected={handleImageUpload}/>}
            </View>

            <Text w="100%" textAlign={"center"} fontSize="md" mb={1}>My Travel {props.format ? "Wishlist" : "Calendar"}</Text>
            
            {props.anthem && <Text textAlign={"center"} mb={2} mt={1} fontSize={"sm"} >{`"${props.anthem}"`}</Text>}

            <Text fontSize="xs" textAlign={"center"} color="coolGray.800">{config.calendar_share_suffix ?? ""}{props.useWish ? "travelwish.co" : "onfulltrip.com"}</Text>
        </View>


        <View mt="4">
        {(!props.format || props.format == "list_cell") && props.trips.map((trip, index) => {
            return <SocialTripRowCell myUserId={user?.id ?? -1} trip={trip} ignoreInterest hideDates hideComments height={100} imageWidth={80}/>
        })}

        {props.format && props.format == "list_1" && props.trips.map((trip, index) => {
                return <NativeImageBackground uri={getTripImage(trip)} style={{height: 120, borderRadius: 40, marginLeft: -2, marginRight: -2}}>
                    <View style={styles.innerContainer} flex={1} justifyContent="flex-end">
                        <Text pl="2"  pb="2" mb={2} fontWeight={"bold"} color="white" fontSize={"md"}>{trip.place}</Text>
                    </View>
            </NativeImageBackground>})
        }


        {props.format && props.format == "box" && <HStack flex={1} space={2} maxW={"100%"} justifyContent={"center"} flexWrap={"wrap"} >
            {props.trips.map((trip, index) => {
                return <ImageBackground source={{uri: getTripImage(trip) }} style={{height: 200, width: 150, marginBottom: 10, justifyContent: 'flex-end', borderRadius: 20}}>
                    <Text pl="2" bgColor={"#000000c0"} pb="2" fontWeight={"bold"} color="white" fontSize={"md"}>{trip.place}</Text>
            </ImageBackground>})}
            </HStack>}
        </View>
    </View>
}


const styles = {
    imageBackground: {
      height: '100%',
      width: '100%'
    },
    innerContainer: {
      flex: 1,
      backgroundColor: 'rgba(0,0,0, 0.30)'
    },
};