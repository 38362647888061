import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import AuthHelper from '../helper/AuthHelper'
import { Platform, StyleSheet, InputAccessoryView, Keyboard } from 'react-native';
import { Heading, Center, Box, FormControl, Stack, Image, Input, WarningOutlineIcon, Button, Text, Container, TextArea, View, Link } from 'native-base'
import ImagePicker from "../components/ImagePicker";
import TEXT from '../translations.json'
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import UploadAPI from "../api/UploadAPI";
import GoogleLoginButton from "../components/GoogleLoginButton";
import { AuthSessionResult } from "expo-auth-session";
import BaseApiConfigProvider from '../api/BaseApiConfigProvider'
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store";
import { getErrorString, meetsMinimumAppVersion, sentryCaptureException, showAlert } from "../utils";
import Constants from "expo-constants";
import Layout from "../components/Layout";
// @ts-ignore
// import logo from '../assets/images/Roam.png'
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import ConstrainedInput from "../components/ConstrainedInput";
import * as AppleAuthentication from 'expo-apple-authentication';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Config from "../constants/Config";
import BranchMetrics from "../utils/BranchManager";
import * as Sentry from "sentry-expo";
import { Screens } from "../constants/Screens";
import MobileVerificationVC from "../controllers/MobileVerificationVC";
import SimpleCarousel from "../components/SimpleCarousel";


const items = [
    {
        title: "Save time and reduce stress",
        body: "Get popular suggestions for you trip plus a built in group chat that keeps everyone on the same page.",
        image: require("../assets/images/Group_626_new.png")
    }, 
    {
        title: "More commitment, less cancellations.",
        body: "Automated text alerts for flights and hotels to help you and your guests save money. Comes with reminders as the trip gets closer",
        image: require("../assets/images/money_due.png")
    },
    {
        title: "Collect deposits in seconds",
        body: "Share your trip link to start collecting deposits.\nRefunds are automatically handled for you",
        image: require("../assets/images/Group+630.png")
    }
]

export default function LoginScreen(props: any) {

    const userApi = new GenericViewSetAPI("user")
    const authApi = new GenericViewSetAPI("auth");
    const accountApi = new GenericViewSetAPI("account");
    const uploadApi = new UploadAPI()

    const inputAccessoryViewID = 'uniqueID';

    type Level = "intro" | "default" | "phone" | "email" | "token" | "profile";

    const dispatch = useDispatch();

    var targetScreen: string = null;

    const config = useSelector((state: any) => state.config)

    const [level, setLevel] = useState<Level>(Platform.OS == "web" ? "default" : "intro");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [image, setImage] = useState(null);
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("")
    const [imageFile, setImageFile] = useState(null);
    const [userId, setUserId] = useState(null);
    const [bio, setBio] = useState("");
    const [updatingUser, setIsUpdatingUser] = useState(false);
    
    const [appleName, setAppleName] = useState(null);

    useEffect(() => {
        if (props.route.params) {
            targetScreen = props.route.params.target;
        }
        const forced =  props.route.params?.forced;
        async function handleLoggedIn() {
            if (!meetsMinimumAppVersion(config)) {
                props.navigation.replace("UpdateRequired");
            }
            
            const loggedIn = await AuthHelper.isUserLoggedIn();
            if (loggedIn && !forced) {
                AnalyticsManager.setUserProperties({"usingApp": true})
                props.navigation.replace(Screens.Root);
            } else { 
                AnalyticsManager.logEvent(events.USER_VIEWED_SIGNUP)
            }
            if (targetScreen) {
                console.log(`Going to login then send user to: ${targetScreen}`)
            }
        }

        if (Platform.OS !== "web") { 
            AnalyticsManager.setUserProperties({intervalVersion: Config.internalVersion})
        }
        handleLoggedIn();    
    }, [])

    const onImagePicked = (val) => {
        console.log(val);
        setImage(val);
    }

   const handleLoginFinish = (user) => { 
        setUserId(user.id)
        setEmail(user.email)
        AnalyticsManager.setUserId(user.username);
        setImageUrl(user.image_url)
        setName(user.name);
        setLevel("profile");
   }

    const handleExternalAccountLogin = async (accountType: string, token: string, name: string = null) => {
        const isRunningInExpoGo = Constants.appOwnership === 'expo'
        const createAccountResult = await accountApi.create({ token: token, name: name, account_type: accountType, platform: Platform.OS, is_expo_go: isRunningInExpoGo });
        console.log(createAccountResult);
        if (!createAccountResult.error) {
            await BaseApiConfigProvider.saveUserToken(createAccountResult.token);
            dispatch(setUser(createAccountResult.user))

            if (createAccountResult.created) {
                const installParams = await BranchMetrics.getInstallParams();
                if (installParams) {
                    const {page, pageParams} = BranchMetrics.parseAndReturnLinkParams(installParams);
                    if (pageParams?.onboard_context) { 
                        AnalyticsManager.setUserProperties({onboardContext: pageParams.onboard_context})                        
                    }
                }

                handleLoginFinish(createAccountResult.user);
            } else {
                props.navigation.replace("Root");
            }
        }
    }

    const handleGoogleAuthResponse = async (response: AuthSessionResult) => {
        if (response?.type === 'success') {
            AnalyticsManager.logEvent(events.USER_SOCIAL_LOGIN);
            const { params } = response;
            await handleExternalAccountLogin('google', params.id_token)
        } else if (response?.type === "error") {
            sentryCaptureException(response.error)
            showAlert("Failed to login with google. Please try again!");
        }
    }

    const handleComplete = async () => {
        setIsUpdatingUser(true);
        const body: any = {}
        if (userName) {
            body.username = userName
        }
        if (bio) {
            body.bio = bio;
        }
        if (image) {
            const uploadResp = await uploadApi.uploadImageV2(imageFile, "profile_picture");
            console.log(uploadResp)
            if (uploadResp.error) {
                showAlert("Failed to upload your image. Please try again");
                setIsUpdatingUser(false);
                return;
            } else { 
                body.picture_url = uploadResp.url
            }
        }
        const updateResp = await userApi.update(userId, body);
        if (!updateResp.error) {
            console.log(updateResp);
            AnalyticsManager.logEvent(events.USER_CREATED_ACCOUNT);
            const userInfo = await userApi.nonStandard("GET", "me");
            dispatch(setUser(userInfo));

            if (config.onboard_mobile_phone && Platform.OS !== "web") { 
                props.navigation.replace("MobileVerification");
            } else { 
                props.navigation.replace("Wishlist");
            }

        } else {
            const errorStr = getErrorString(updateResp.error, "Failed to update your profile. Please try again later!");
            if (errorStr) {
                if (errorStr.includes("user with this username already exists.")) {
                    showAlert("User name is already taken. Please try another.");
                } else { 
                    showAlert(errorStr)
                }
            } else {
                console.warn(updateResp.error);
                showAlert("Failed to update your profile. Please try again later!");
            }
        }
        setIsUpdatingUser(false);
    }

    const handleAppleSignIn = async () => {
        try {
            const credential = await AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
            });
            // signed in
            console.log(credential);
            let name = credential.fullName.givenName
            if (name) {
                setAppleName(name);
                await AsyncStorage.setItem("name", `${credential.fullName.givenName} ${credential.fullName.familyName}`);
            } else { 
                if (!appleName) { 
                    const savedName = await AsyncStorage.getItem('name');
                    if (savedName) { 
                        name = savedName.split(' ')[0];
                    }
                } else {
                    name = appleName;
                }
            }

            await handleExternalAccountLogin("apple", credential.authorizationCode, name);
        } catch (e) {
            if (e.code === 'ERR_CANCELED') {
                // handle that the user canceled the sign-in flow
            } else {
                // handle other errors
                sentryCaptureException(e);
                showAlert("Something went wrong when signing in with Apple. Please try again");
            }
        }
    }



    return <Layout scrollView>

        {level === "intro" && <View mt={10} w="100%"><SimpleCarousel buttonRender={(handleNext) => <Button mt={3} colorScheme={"rose"} onPress={handleNext}>Continue</Button>} items={items} onComplete={() => setLevel("default")}/></View>}

        {level === "phone" && <View mt={3} w="100%"><MobileVerificationVC showName={Platform.OS === "web"} onFinish={async (mobileVerifResp) => {
                            await BaseApiConfigProvider.saveUserToken(mobileVerifResp.resp.token)
                            const foundUser = mobileVerifResp.resp.user
                            dispatch(setUser(foundUser))
                            if (mobileVerifResp.resp.created) {
                                handleLoginFinish(mobileVerifResp.resp.user)
                            } else { 
                                props.navigation.replace("Root");
                            }
                    }} /></View>}

        {level === "email" && <View mt={3} w="100%"><MobileVerificationVC isEmail showName onFinish={async (mobileVerifResp) => {
                            await BaseApiConfigProvider.saveUserToken(mobileVerifResp.resp.token)
                            const foundUser = mobileVerifResp.resp.user
                            dispatch(setUser(foundUser))
                            if (mobileVerifResp.resp.created) {
                                handleLoginFinish(mobileVerifResp.resp.user)
                            } else { 
                                props.navigation.replace("Root");
                            }
                    }} /></View>}

        {level === "default" && <View flex={1} w="100%" mt={4}>
            <Center flex={1} width="100%" mb="20" minHeight="100%">
                <Heading marginBottom={"2"} color={"black"}>{TEXT.welcomeToRoam}</Heading>
                <Text style={{ textAlign: 'center' }} color={"gray.500"}>{TEXT.talonMotto}</Text>
                <Text style={{ textAlign: 'center' }} mt={5} underline color={"gray.500"}>{TEXT.loginOrSignup}</Text>
                
                <View mt="10" w="90%">
                    
                    <Button colorScheme="coolGray" variant="subtle" mb={2} onPress={() => setLevel("email")} >Continue with email</Button>
                    {Platform.OS === "web" && <Button variant="subtle" onPress={() => setLevel("phone")} mb={2}>Continue with phone number</Button>}

                    {true && <><GoogleLoginButton themeColor="rose" onGoogleResponse={handleGoogleAuthResponse}></GoogleLoginButton>


                    <AppleAuthentication.AppleAuthenticationButton
                        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                        cornerRadius={5}
                        style={{ width: '100%', height: 44, marginTop: 10 }}
                        onPress={handleAppleSignIn}
                    /></>}



                </View>
                <View mt="20">
                    <Text fontSize="xs" textAlign={"center"} color={"gray.500"} mb={2} italic>Easily delete your account if it's not what you want</Text>
                    <Text fontSize="xs" textAlign={"center"} color={"gray.500"}>By creating an account, you agree with our <Link href="https://www.onfulltrip.com/terms">Terms of Service</Link> and <Link href="https://www.onfulltrip.com/privacy">Privacy Policy</Link></Text>
                </View>
            </Center>
        </View>}

        {
            level === "profile" && <Center marginTop={5} w="100%">
                <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps={"always"}
                    extraHeight={40}
                >
                    <Heading marginBottom={2} color={"gray.900"} textAlign="center">{TEXT.completeYourProfile}</Heading>

                    <ImagePicker
                        backgroundImage={null}
                        onImageSelected={(val) => onImagePicked(val)}
                        onFileSelected={(val) => setImageFile(val)}
                        width={100}
                        height={100}
                        style={{ marginTop: 10 }}
                    />
                    <Text mt="5"  w="100%" textAlign={'center'} color="gray.600">{TEXT.uploadPictureOfTravel}</Text>


                    <FormControl isRequired style={{ marginTop: 20 }}>
                        <Stack>
                            <FormControl.Label>{TEXT.username}</FormControl.Label>
                            <ConstrainedInput type="alphanumeric" value={userName} inputProps={{ variant: "underlined", placeholder: "", size: "lg" }} onChangeText={(val) => setUserName(val)} />

                            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="s" />}>
                                Atleast 6 characters are required.
                            </FormControl.ErrorMessage>
                        </Stack>
                    </FormControl>

                    <FormControl style={{ marginTop: 20 }}>
                        <Stack>
                            <FormControl.Label>{TEXT.About}</FormControl.Label>
                            <TextArea placeholder="" value={bio} size="md" onChangeText={(val) => setBio(val)} autoCompleteType={undefined} inputAccessoryViewID={inputAccessoryViewID} />
                        </Stack>
                    </FormControl>
                    <Button colorScheme={"rose"} marginTop={"10"} isLoading={updatingUser} onPress={() => handleComplete()}>{TEXT.complete}</Button>
                </KeyboardAwareScrollView>
            </Center>
        }

        {Platform.OS == "ios" && <InputAccessoryView nativeID={inputAccessoryViewID}>
            <View w="100%" bg="white" display="flex" alignItems={"flex-end"}>
                <Button w="25%" variant="link" onPress={() => Keyboard.dismiss()}>Done</Button>
            </View>
        </InputAccessoryView>}
    </Layout >
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});