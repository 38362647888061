import { Pressable, Text } from "native-base";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import { Platform } from "react-native";
import { openBrowserLinkForPlaceId, openLinkWebAware } from "../utils";

export default function(props) { 

    const handleTrendClick = (item) => {
        AnalyticsManager.logEvent(events.TRENDING_PLACE_CLICK, {place: item.place});
        openBrowserLinkForPlaceId(null, item.place, Platform.OS === "web")
        // openLinkWebAware("https://www.tripadvisor.com/Search?q=" + item.place)
    }

    const { item, index } = props;

    return <Pressable onPress={() => handleTrendClick(item)} {...props}>
                        <Text fontSize={"xs"} color="coolGray.500" mb={1}>{index + 1} - Trending</Text>
                        <Text fontSize="lg" fontWeight={"medium"}>{item.place}</Text>
                        </Pressable>
}