
import { GiftedChat } from 'react-native-gifted-chat'
import { View, Text} from 'native-base'
import { useFocusEffect } from '@react-navigation/native'
import React, { useEffect, useState } from 'react';
import GenericViewSetAPI from '../api/GenericViewSetAPI';
import { showAlert } from '../utils';
import CommentCell from '../components/CommentCell';
import { AnalyticsManager, events } from '../utils/AnalyticsManager';
import { useSelector } from 'react-redux';
import useInterval from '../hooks/useInterval';

export default function ChatScreen(props) {

    const tripId = props.route.params.tripId;
    const chatId = props.route.params.chatId;
    const title = props.route.params.title;
    const config = useSelector((state: any) => state.config)
    const [messages, setMessages] = useState(null);
    const [chatResp, setChatResp] = useState(null);
    const [loadingEarlier, setLoadingEarlier] = useState(false)
    const [currPage, setCurrPage] = useState(1)

    const commentsApi = new GenericViewSetAPI("comment");

    useEffect(() => {
        getTripComments();
        AnalyticsManager.logEvent(events.USER_JOINED_CHAT, {chat: chatId ? true : false});
        if (title) { 
            props.navigation.setOptions({
                title: title
            })
        }
    }, 
    [])

    useInterval(() => {
        getTripComments(1, false)
    }, config.chat?.pollTimeMs ?? 8000)


    const getIdQueryParams = () => { 
        const queryParams: any = {}
        if (tripId) { 
            queryParams.trip_id = tripId
        }
        if (chatId) { 
            queryParams.chat_id = chatId
        }
        return queryParams
    }

    async function getTripComments(page=1, overwrite=true) { 
        const queryParams = getIdQueryParams()
        // console.log(tripId);
        console.log('page ', page)

        const commentsResp = await commentsApi.query({...queryParams, page: page, expansions: ['creator']});
        // console.log('comments resp is ', commentsResp);
        if (commentsResp.error) {
            showAlert("Error getting trip comments");
            return;
        }
        if (overwrite) { 
            // console.log("OVER WRITING WITH RESP", commentsResp)
            setChatResp(commentsResp);
        }
        const convertedMessages = commentsResp.results.map((item) => commentToMessage(item));
        
        // console.log("EXISTING MESSAGES ", messages)
        if (!messages || messages.length === 0) { 
            setMessages(convertedMessages)
        } else { 
            const currMessageIds = messages.map((item) => item._id);
            const exampleMsg = messages[0]
            for (var message of convertedMessages) {
                if (!currMessageIds.includes(message._id)) {
                    console.log("looking at new message", message.comment.created_at)
                    console.log("comparing test msg", exampleMsg.comment.created_at);
                    const isAfter = message.comment.created_at > exampleMsg.comment.created_at
                    console.log('isAfter:', isAfter)
                    if (isAfter) {
                        setMessages(msgs => GiftedChat.append(msgs, message))
                    } else { 
                        setMessages(msgs => GiftedChat.prepend(msgs, message))
                    }
                }
            }
        }
    }

    const onLoadEarlier = async () => { 
        setLoadingEarlier(true);
        await getTripComments(currPage + 1, true);
        setCurrPage(currPage + 1)
        setLoadingEarlier(false)
    }

    const commentToMessage = (comment) => {
        let message = {
            _id: comment.id,
            text: comment.message,
            createdAt: comment.created_at,
            user: {
                _id: comment.creator.id,
                name: comment.creator.first_name,
                avatar: comment.creator.picture_url,

            },
            comment: comment
        }
        return message
    }

    const renderMessage = (item) => {
        // console.log(item.currentMessage);
        return <CommentCell comment={item.currentMessage.comment} viewProps={{pb: "6", pl: "3", w:'100%'}}></CommentCell>
    }

    const onSend = async (msg) => { 
        const queryParams = getIdQueryParams();
        AnalyticsManager.logEvent(events.USER_COMMENTED);
        const createdComment = await commentsApi.create({...queryParams, message: msg}, {expansions: ['creator']});
        // console.log(createdComment);
        if (createdComment.error) { 
            showAlert("Sorry, we failed to add your comment. Please try again!");
        } else { 
            setMessages(previousMessages => GiftedChat.append(previousMessages, [commentToMessage(createdComment)]))
        }
    }

    return <View style={{flex: 1, backgroundColor: 'white'}}>
        <GiftedChat 
            messages={messages}
            renderMessage={renderMessage}
            onSend={messages => onSend(messages[0].text)}
            loadEarlier={chatResp?.next != null && chatResp?.next !== undefined}
            onLoadEarlier={() => onLoadEarlier()}
            isLoadingEarlier={loadingEarlier}
        />
    </View>

}