import { HStack, View, Text, Button, Actionsheet } from "native-base";
import React, { useState } from "react";
import ProfilePicture from "./ProfilePicture";
import moment from "moment";
import { Feather } from "@expo/vector-icons";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import { showAlert } from "../utils";
import Blocklist from "../utils/BlockList";
import { useSelector } from "react-redux";
import TripDetailsViewWithPreview from "./TripDetailsViewWithPreview";

export default function CommentCell(props) {

    const user = useSelector((s: any) => s.user)
    const [commentActionsOpen, setCommentActionsOpen] = useState(false);
    const isMyComment =  user ? user.id === props.comment.creator.id : false
    if (!isMyComment && Blocklist.isBlocked(props.comment.creator.id)) { 
        return null;
    }
    return <View {...props.viewProps}>
        <HStack space={2} w="100%">
            <ProfilePicture user={props.comment.creator} avatarProps={{ size: 'md' }}></ProfilePicture>
            <View mb="4">
                <Text fontSize="lg">{props.comment.creator.first_name}</Text>
                <Text fontSize="sm" color="gray.700">@{props.comment.creator.username}</Text>
            </View>
        </HStack> 
        <HStack w="100%" justifyContent={"space-between"}>
            {/* <Text pb="4" w="90%">{props.comment.message}</Text> */}
            <View flex={20}>
                <TripDetailsViewWithPreview data={props.comment.message} 
                    parsedTextStyle={{ fontSize: 15, marginBottom: 8, marginTop: 8 }}>
                </TripDetailsViewWithPreview>
            </View>
            {!isMyComment && <Button flex={1} variant={"link"} onPress={() => setCommentActionsOpen(true)}><Feather name="more-vertical"  size={24}></Feather></Button>}
        </HStack> 
        <Text fontSize="xs" color={"gray.500"}>{moment(props.comment.created_at).fromNow()}</Text>

        {user && <Actionsheet isOpen={commentActionsOpen} onClose={() => setCommentActionsOpen(false)}>
            <Actionsheet.Content>
                <Actionsheet.Item onPress={() => {
                    AnalyticsManager.logEvent(events.USER_REPORT_COMMENT, {id: props.comment.id, text: props.comment.mesage});
                    showAlert("Thank you for reporting this comment. Our team will review and take appropriate action");
                    setCommentActionsOpen(false);
                }}>Report comment</Actionsheet.Item>
                <Actionsheet.Item onPress={() => {
                    Blocklist.blockUser(props.comment.creator.id)
                    showAlert("User has been successfully blocked")
                    setCommentActionsOpen(false)
                }}>Block user</Actionsheet.Item>
            </Actionsheet.Content>
        </Actionsheet>}
    </View>
}