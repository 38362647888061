import { Modal } from "native-base";


export default function SimpleModal(props) { 

    return <Modal isOpen={props.isOpen} onClose={props.onClose} size={props.size}>
        <Modal.Content>
        {!props.notClosable && <Modal.CloseButton />}
        {!props.hideHeader && <Modal.Header>{props.headerTitle}</Modal.Header>}
        <Modal.Body>
            {props.children}
        </Modal.Body>

        {props.getFooter && <Modal.Footer>
            {props.getFooter()}
            </Modal.Footer>}
        </Modal.Content>
    </Modal>
}