
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import Config from '../constants/Config'
// API URLs
const prod = 'https://roam-api-prod.fly.dev/api'
const stage = 'https://room-api-stage.fly.dev/api'
const local = 'http://192.168.1.176:8000/api'
// const local = 'http://192.168.254.142:8000/api'

// WEB URLS
const prodWeb = "https://www.onfulltrip.com"
const stageWeb = "https://stage.onfulltrip.com"
const localWeb = "http://localhost:3000"

const devOverride = false;

class BaseApiConfigProvider {

    constructor(envUrl, webUrl = null) {
        if (!envUrl) {
            const appEnv = __DEV__ ? "preview" : Constants.expoConfig.extra.appEnv;
            var defaultEnv = appEnv === "preview" ? stage :  prod;
            var defaultWebEnv = appEnv === "preview" ? stageWeb : prodWeb;
            if (devOverride && __DEV__) {
                defaultEnv = local
                defaultWebEnv = localWeb;
            }
            console.log('envUrl', defaultEnv)
            console.log('appEnv', appEnv)
            this.envUrl = defaultEnv;
            this.webUrl = defaultWebEnv;
        } else {
            this.envUrl = envUrl;
            this.webUrl = webUrl;
        }
        this.auth = null;
        this.appVersion = Config.internalVersion;
    }

    isStage() {
        return this.envUrl == stage
    }

    changeEnv(env) {
        if (env == 'STAGE') {
            this.envUrl = stage
            this.webUrl = stageWeb;
        } else if (env == 'PROD') {
            this.envUrl = prod
            this.webUrl = prodWeb
        } else {
            this.envUrl = local
            this.webUrl = localWeb;
        }
        this.saveEnv();
    }

    setEnvUrl(envUrl, webUrl=null) {
        this.envUrl = envUrl;
        if (webUrl) {
            this.webUrl = webUrl;
        }
        this.saveEnv();
    }

    getWebUrl() { 
        return this.webUrl;
    }

    getAppVersion() {
        return this.appVersion;
    }

    getEnvType() {
        if (this.envUrl === prod) {
            return 'prod'
        } else if (this.envUrl === stage) {
            return 'stage';
        } else {
            return 'local';
        }
    }

    getEnvUrl() {
        return this.envUrl;
    }

    setAuth(token) {
        this.auth = token;
    }

    getAuth() {
        return this.auth;
    }

    getTokenAuthStr() {
        console.log("AUTH =", this.auth)
        if (this.auth) { 
            return `Token ${this.auth}`
        } else { 
            return null
        }
    } 

    async loadUserEnvPreference() {
        return new Promise((res, rej) => {
            AsyncStorage.getItem('envUrl')
                .then((value) => {
                    console.log(`Saved user environment Url = ${value}`)
                    if (value != null) {
                        console.log("SETTING ENV URL FROM PREFERENCE");
                        this.setEnvUrl(value);
                        console.log(this.envUrl)
                    }
                    res(value);
                }).catch(error => rej(error))
        })
    }

    async loadUserToken() { 
        if (!this.auth) {
            const token = await AsyncStorage.getItem("token");
            console.log(`Discovered user auth: ${token}`);
            this.auth = token;
        }
        return this.auth
    }

    async saveUserToken(token) { 
        await AsyncStorage.setItem("token", token);
        console.log("SAVED USER TOKEN!")
        this.auth = token;
    }

    async removeUserToken() { 
        const token = await AsyncStorage.removeItem("token");
        this.auth = null;
    }

    saveEnv() {
        AsyncStorage.setItem('envUrl', this.envUrl)
        AsyncStorage.setItem('webUrl', this.webUrl);
    }

    async clearEnv() { 
        await AsyncStorage.removeItem('envUrl')
        await AsyncStorage.removeItem('webUrl');
    }
}

const configProvider = new BaseApiConfigProvider();
export default configProvider