
import moment from "moment-timezone";
import { Text, View, Container, Center, Heading, HStack, Modal, Avatar, Divider, Button, Image, Badge, Pressable, Icon } from "native-base";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, FlatList, Platform, Linking, SafeAreaView } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TEXT from '../translations.json'
import { getColorForTripPhase, getColorSchemeForPhase, getCountString, getDescriptiveStringForPhase, getTripImage, loadGuestEngagement, showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import GroupedAvatars from "../components/GroupedAvatars";
import * as WebBrowser from 'expo-web-browser';
import { AntDesign, EvilIcons, Ionicons } from "@expo/vector-icons";
import { Screens } from "../constants/Screens";
import { useDispatch, useSelector } from "react-redux";
import { setGuest } from "../store";
import Config from "../constants/Config";
import { useFocusEffect } from "@react-navigation/native";


export default function WishlistShareScreen(props) {

    const [places, setPlaces] = useState(props.route.params?.places ?? null)
    const [trips, setTrips] = useState([]);
    const [guest, putGuest] = useState(useSelector((state: any) => state.guest));
    const [sharing, setShareing] = useState(false);
    const [modalOpen, setIsModalOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [anthem, setAnthem] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (sharing) { 
            AnalyticsManager.logEvent(events.USER_CLICKED_SHARE_CALENDAR)
            props.navigation.navigate(Screens.TripsShareScreen, { trips: trips, isWishlist: true, anthem: anthem })
            setShareing(false)
        }
    }, [sharing])


    useFocusEffect(
        React.useCallback(() => {
        // Do something when the screen is focused
        if (Platform.OS === "web") { 
            window.scrollTo(0, 0)
        }
        hydrateData();
      }, []))

    const createTrips = (places) => {
        console.log(places)
        const trips = []
        for (var place of places) { 
         const trip = {
             id: -1,
             creator: {
                 id: -1
             },
             uuid: randomUUID(),
             place: place.address,
             start_date: place.start_date,
             end_date: place.end_date,
             phase: "PLANNING",
             photos: place.photos,
             published: true,
             interest_users: [],
             picture_url: place.picture_url,
         }
         trips.push(trip)
        }
        // console.log(trips)
        
        setTrips(trips);
    }

    useEffect(() => {
        if (places) {
            createTrips(places);
        }
    }, [])

    async function hydrateData() {
        const engagement = await loadGuestEngagement(guest);
        setPlaces(engagement.places);
        createTrips(engagement.places);
        if (engagement.anthem) {
            setAnthem(engagement.anthem)
        }
        if (engagement.guest) { 
            putGuest(engagement.guest);
            dispatch(setGuest(engagement.guest))
        }
    }

    function handleUrlPress(url, matchIndex /*: number*/) {
        WebBrowser.openBrowserAsync(url);
    }
    
    const closeModal = () => { 
        setIsModalOpen(false)
        setSelectedPlace(null);
    }

    const renderItem = (itemWrapper: any) => {
        const item = itemWrapper.item;
        var view = null;
        if (item.ad) {
           view = <View mt={3} mb={5}>
                <Heading size="md">My wishlist</Heading>
                <Text>From amazing itinerary suggestions, to flight and accomodation alerts, to text based invites to bring in friends, Fulltrip helps you plan better, fuller trips. </Text>
                <HStack space={2} mt={4}>
                    {/* <Button colorScheme={"rose"} size="lg" minW="100" variant={"subtle"} onPress={() => Linking.openURL("https://4vq5c.app.link/oDG2CPL8Fzb")}>Download</Button> */}
                    <Pressable onPress={() => Linking.openURL("https://4vq5c.app.link/oDG2CPL8Fzb")}><Text underline>Download</Text></Pressable>
                </HStack>
            </View>
            return <Center w="100%"><Container maxW="800" w="90%">
            {view}
        </Container></Center>
        }
        const isMyTrip = true
        const users = [item.creator]
        const showAvatars = users.length > 1;
        
        view =  <Pressable mt={5} mb={5} onPress={() => null}>
            {itemWrapper.index === 0 && <Heading size="lg" mb={4}>My travel wishlist</Heading> }
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} flex={1} h="100%">
                <HStack space={2} flex={1}>
                    <Image source={{uri: getTripImage(item)}} height="150" width="120" style={{borderRadius: 5, resizeMode: 'cover'}}/>

                    <View flex={1}>
                        <HStack space={1} mb={2}>
                            <Button size="sm" colorScheme={'coolGray'} rounded="full" variant="subtle" onPress={() => {
                                console.log(item)
                                setSelectedPlace(item)
                                setIsModalOpen(true)
                            }}>Change thumbnail</Button>
                            {/* {item.published && <Badge rounded={"full"} size="xs" maxW={"50%"} _text={{fontSize: '2xs'}} colorScheme={getColorSchemeForPhase(item.phase)} mb="2">{getDescriptiveStringForPhase(item.phase).toUpperCase()}</Badge>} */}
                        </HStack>
                        {item.creator && item.interest_users && showAvatars && <HStack  display="flex" mt="1" flexDirection="row" alignItems={"center"}>
                            <GroupedAvatars users={[item.creator, ...item.interest_users]} avatarSize={"sm"} />
                            {item.interest_count > 0 && <Text pl="1" fontSize="sm" color={"coolGray.600"}>({item.interest_count}) {getCountString("other", item.interest_count)}</Text>}
                        </HStack> }
                        <Text numberOfLines={1} mt={1} color={"coolGray.700"} flexWrap={"wrap"} fontSize={"md"}><Text bold color="black">{item.place}</Text></Text>
                    </View>
                </HStack>
            </View>
        </Pressable>

        return <Center w="100%"><Container maxW="800" w="90%">
            {view}
        </Container></Center>

    }

    return <View bg="white" flex={1}>
            <FlatList
                    style={{width: '100%'}}
                    data={trips}
                    renderItem={renderItem}
                    ItemSeparatorComponent={Divider}
                    showsVerticalScrollIndicator={false}
                />

        <SafeAreaView style={{ position: 'absolute', left: 0, right: 0, bottom: 0, zIndex: 2, borderColor: 'lightgray', backgroundColor: 'white', borderWidth: 1 }}>
            <Center w="100%" pt="2" pb="2">
                <Container maxW="800" w="90%">
                <HStack w="100%" flex={1} space={2}>
                    <Button
                        // add share icon
                        leftIcon={<Icon as={Ionicons} name="share-outline" size="sm" />}
                        onPress={() => setShareing(true)}
                        colorScheme="rose"
                        rounded={"2xl"}
                        size="md"
                        flex={2}
                        // mb={2}
                        // mt={2}
                        // mr={2}
                    >
                    Share wishlist
                </Button>

                <Button size="sm" flex={1} colorScheme="coolGray" variant="subtle" onPress={() => props.navigation.navigate(Screens.CountriesVisited)} rounded={"2xl"}
                    leftIcon={<Icon as={EvilIcons} name="gear" size="sm" />}>Edit wishlist</Button>
            </HStack>

                </Container>
            </Center>
        </SafeAreaView>

        <Modal isOpen={modalOpen} onClose={() => closeModal()} size="xl" zIndex={4}>
                    <Modal.Content >
                        <Modal.CloseButton />
                        <Modal.Header>Update</Modal.Header>
                        <Modal.Body>
                        <View flex={1} flexWrap="wrap" flexDir={"row"}>
                            {selectedPlace && selectedPlace.photos.map((item) => <Pressable onPress={() => {
                                    console.log("NEW PHOTO")
                                    console.log(item)
                                    const newPicUrl = Config.googleMapsPhotoRef.replace('{}', item.photo_reference)
                                    // setSelectedPhotoUrl(newPicUrl)
                                    selectedPlace.picture_url = newPicUrl;
                                    closeModal();
                                }}>
                                <Image source={{uri: Config.googleMapsPhotoRef.replace('{}', item.photo_reference)}} width="100" height="100" mr="1" mb="1"/>
                        </Pressable> )}
                        </View>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <Button colorScheme={"coolGray"} variant="subtle" onPress={() => {
                                    closeModal();
                                }}>
                                    Close
                                </Button>

                                <Button colorScheme="rose" onPress={() => closeModal()}>
                                    Done
                                </Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
    </View>
}

const styles = StyleSheet.create({
    url: {
        color: 'blue',
        textDecorationLine: 'underline',
    }

});

function randomUUID() {
    // generate random UUID
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-mixed-operators
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-mixed-operators,eqeqeq
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
