import { FontAwesome } from "@expo/vector-icons";
import { ScrollView, Text, View, Button, HStack, IconButton, Icon } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import SimpleUserView from "../components/SimpleUserView";
import { setOnboardUserAddedFriend } from "../store";
import { showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";


export default function AddFriendListRowVC(props) {

    const privateUser = useSelector((state: any) => state.user)
    const dispatch = useDispatch()
    const userApi = new GenericViewSetAPI("user");
    const friendRequestApi = new GenericViewSetAPI("friend_request")

    const [userFriends, setUserFriends] = useState(props.users ?? null);
    const [loadingMap, setLoadingMap] = useState({})
    const [doneMap, setDoneMap] = useState({})
    const [collapsed, setCollapsed] = useState( props.collapsed ?? true);
    const [friendsLoaded, setFriendsLoaded] = useState(false)

    const getFriendSuggestions = async () => {
        console.log("FETCHING FRIEND SUGGESTIONS");
        const query: any = {}
        if (props.username) { 
            query.username = props.username
        }
        const userFriendsResp = await userApi.nonStandard("GET", `friend_suggestions`, null, { ...query, expansions: ["trip_aggregation_counts", "friend_status"] })
        console.log(userFriendsResp)
        if (userFriendsResp && !userFriendsResp.error) {
            const users = userFriendsResp.results
            setUserFriends(users);
            setFriendsLoaded(true)
        }
    }

    useEffect(() => {
        if (props.userFriends) { 
            setUserFriends(props.userFriends)
            setFriendsLoaded(true)
        }
    }, [props.userFriends])

    useEffect(() => {
        if (privateUser && !userFriends) {
            getFriendSuggestions()
        }

    }, [privateUser])

    useEffect(() => {
        if (!friendsLoaded || !userFriends) { 
            return;
        } 
        const currSuggetions = [...userFriends]
        const updated = currSuggetions.filter((usr) => !doneMap[usr.username])
        setTimeout(() => {
            setUserFriends(updated)
        }, 1000)
    }, [doneMap, friendsLoaded])


    const setUserAdded = (user) => {
        const newMap = Object.assign({}, doneMap);
        newMap[user.username] = true
        setDoneMap(newMap)
    }

    const addFriend = async (user, context = null) => {
        AnalyticsManager.logEvent(events.USER_ADD_FRIEND, { source: 'add_friend_vc', page: props.page })
        dispatch(setOnboardUserAddedFriend(true));
        if (user.friend_status && user.friend_status !== "none" && user.friend_status !== "awaiting_response") {
            console.log("Adding user when users are already friends..doing nothing.")
            setUserAdded(user)
            // finish()
        } else {
            const existingLoading = Object.assign({}, loadingMap);
            existingLoading[user.username] = true
            setLoadingMap(existingLoading)
            const friendReq = await friendRequestApi.create({ requestor: privateUser.id, target: user.id, context: context });
            console.log(friendReq);
            if (!friendReq || friendReq.error) {
                showAlert("Something went wrong while attempting to add user. Please try again");
            } else {
                setUserAdded(user)
            }
            delete existingLoading[user.username]
            const newLoading = Object.assign({}, existingLoading)
            setLoadingMap(newLoading)
        }
    }

    return <View collapsable={false}>
        {userFriends && userFriends.length > 0 && <View {...props}>
            <HStack display="flex" flexDir={"row"} alignItems="center" justifyContent="space-between" w="100%" mb="3">
                {!props.renderTitle && <Text {...props.titleProps}>Others you might know</Text>}
                {props.renderTitle && props.renderTitle()}
                {!props.noCollapse && <IconButton  onPress={() => setCollapsed(!collapsed)} icon={<Icon  p={0} m={0} as={FontAwesome} name={collapsed ? "caret-down" : "caret-up"} color="black" />} />}
            </HStack>
            {!collapsed && <ScrollView horizontal>
                {userFriends.map((friendUser) => {
                    console.log('friend in done map:', doneMap[friendUser.username])
                    return <SimpleUserView user={friendUser} viewProps={{ borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', w: '120px', mr: 2 }}
                        onPress={(usr) => props.navigation ? props.navigation.push("PublicProfile", {username: usr.username}) : null}
                        getBottomViewRender={() => <Button
                            isLoading={loadingMap[friendUser.username]}
                            isDisabled={doneMap[friendUser.username]}
                            onPress={() => addFriend(friendUser)}
                            colorScheme="rose">{doneMap[friendUser.username] ? "Added" : "Add friend"}</Button>} />
                })}
            </ScrollView> }
        </View>}
    </View>

}