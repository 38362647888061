import { Button, View } from "native-base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import MobileVerificationVC from "../controllers/MobileVerificationVC";
import { setUser } from "../store";
import { showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";


export default function MobileVerificationScreen(props) { 
    const cancellable = props.route.params?.canCancel;
    const title = props.route.params?.title
    const userApi = new GenericViewSetAPI("user")
    const dispatch = useDispatch()
    const source =  props.route.params?.source

    const user = useSelector((state: any) => state.user)


    useEffect(() => {
        if (user?.mobile_verified) {
            handleFinish();
        } else { 
            if (cancellable) { 
                props.navigation.setOptions({
                    headerRight: () => <Button variant="ghost" onPress={() => {
                        AnalyticsManager.logEvent(events.MOBILE_VERIFY_CANCEL)
                        props.navigation.goBack()
                    }}>Cancel</Button> 
                })
            }
        }
    }, [])


    const handleFinish = async () => {
        const userInfo = await userApi.nonStandard("GET", "me");
        console.log('me response is ', userInfo);
        if (userInfo && !userInfo.error) {
            dispatch(setUser(userInfo));
        } 
        if ((source && source == "Home") || cancellable) { 
            props.navigation.replace("Root");
        } else { 
            props.navigation.replace("Wishlist")
        }
    }


    return <Layout>
            <View mt={4}>
                <MobileVerificationVC onFinish={handleFinish} title={title} source={props.source}/>
            </View>
        </Layout>
}