import { Button, Checkbox, Container, HStack, View, Heading, Center, Icon, Pressable, Select } from "native-base";
import Layout from "../components/Layout";
import SocialTripsView from "../components/SocialTripsView";
import { Text } from 'native-base'
import React, { useEffect, useRef, useState } from "react";
import { AntDesign, Entypo, Ionicons } from "@expo/vector-icons";
import { getImageFromRef, isInstagramInstalled, loadGuestEngagement, sentryCaptureException, shareToIG, showAlert } from "../utils";
import { Linking, Platform, SafeAreaView } from "react-native";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import useViewType from "../hooks/useViewType";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import * as Sentry from 'sentry-expo';
import { useFocusEffect } from "@react-navigation/native";
import SimpleModal from "../components/SimpleModal";
import { LoadingModal } from "../components/LoadingModal";


export default function TripsShareScreen(props) {

    const trips = props.route.params?.trips;
    const isWishlist = props.route.params?.isWishlist;

    const shareViewRef = useRef(null);

    const [editing, setEditing] = useState(false);
    const [selectedTrips, setSelectedTrips] = useState(trips ? trips : [])
    const [formatType, setFormatType] = useState(isWishlist ? "list_1" : "list_cell")
    const [hideImagePicker, setHideImagePicker] = useState(isWishlist ? false : true);
    const [wasImageUploaded, setImageUploaded] = useState(false);
    const [adModalOpen, setAdModalOpen] = useState(false);
    const viewType = useViewType();
    const [anthem, setAnthem] = useState(null)
    const [loading, setLoading] = useState(false);

    const [IGInstalled, setIGInstalled] = useState(false);

    const [checkBoxIdsMap, setCheckBoxIdMap] = useState({})

    const downloadImageFromViewRef = async (url) => {
        AnalyticsManager.logEvent(events.USER_DOWNLOADED_CALENDER_IMAGE)

        if (Platform.OS === "web") {
            if (!hideImagePicker && !wasImageUploaded) {
                setHideImagePicker(true);
            }
            setLoading(true);
            const html2canvas = await (await import("html2canvas")).default;
            const view = document.getElementById("social-share")
            console.log(view)
            const canvas = await html2canvas(view, { proxy: `${BaseApiConfigProvider.getEnvUrl()}/proxy/v2/`, scale: 2 });
            canvas.style.display = 'none';
            var image = canvas.toDataURL("png")
            if (viewType === "mobile") {
                try {
                    const blob = await (await fetch(image)).blob();
                    const file = new File([blob], 'my_travel_calendar.png')
                    window.navigator.share({
                        text: "My summer travel wishlist ✈️😎",
                        files: [file]
                    }).then(() => {
                        setLoading(false);
                        setAdModalOpen(true);
                        AnalyticsManager.logEvent(events.WEB_API_SHARE_COMPLETE)
                        console.log("SHARING FINISHED SUCCESSFULLY")
                    }).catch((err) => {
                        if (err.contains("AbortError")) {
                            console.log("SHARING CANCELLED")
                        } else {
                            console.error(err)
                            sentryCaptureException(err)
                            createAndDownloadImage(image)
                        }
                    })
                } catch (e) {
                    console.error(e)
                    sentryCaptureException(e)
                    createAndDownloadImage(image)
                }
                setLoading(false);
                setAdModalOpen(true);
            } else {
                createAndDownloadImage(image)
            }
        } else {
            getImageFromRef(shareViewRef, true);
        }
        setHideImagePicker(false);
    }

    useFocusEffect(React.useCallback(() => {
        loadGuestEngagement(null).then((engagement) => {
            if (engagement) {
                if (engagement.anthem) {
                    setAnthem(engagement.anthem)
                }
            }
        })
    }, []))

    const createAndDownloadImage = (imageData) => {
        var a = document.createElement("a");
        a.setAttribute('download', 'my_travel_calendar.png');
        a.setAttribute('href', imageData);
        a.click();
    }

    const handleBoxPress = (trip, selected = null) => {
        const map = { ...checkBoxIdsMap }
        const val = map[trip.id]
        console.error(val)
        if (!val) {
            map[trip.id] = true
        } else {
            if (selected) {
                map[trip.id] = selected
            } else {
                map[trip.id] = !map[trip.id]
            }
        }
        console.error(map)
        setCheckBoxIdMap(map)
    }

    const handleShareToInstagram = async (url) => {
        AnalyticsManager.logEvent(events.USER_SHARED_LINK, { link_type: "calendar", share_type: 'instragram-stories' });
        const uri = await getImageFromRef(shareViewRef, false);
        await shareToIG(uri)
    }

    async function maybeShowInstagram() {
        const IGInstalled = await isInstagramInstalled();
        if (IGInstalled) {
            setIGInstalled(true);
        }
    }

    function createShareiFrame() {
        // create an invisible "sharing iframe" once
        const sharingIframe = document.createElement("iframe");
        var sharingIframeBlob = new Blob([`<!DOCTYPE html><html>`], { type: "text/html" });
        sharingIframe.src = URL.createObjectURL(sharingIframeBlob);

        sharingIframe.style.display = "none"; // make it so that it is hidden

        document.documentElement.appendChild(sharingIframe); // add it to the DOM
    }

    function shareV2(file) {
        console.log("Sharing v2")
        const sharingIframe2: any = document.getElementsByTagName("iframe")[0];
        console.log(sharingIframe2);
        console.log(sharingIframe2.contentWindow)
        sharingIframe2.contentWindow.navigator.share({files:[file]}).then(()=>{
            console.log("files shared");
            sharingIframe2.contentWindow.location.reload(true); // reload sharing iframe to fix iOS bug
        }).catch((err)=>{
            console.log(err)
            console.error("user cancelled share");
            sharingIframe2.contentWindow.location.reload(true); // reload sharing iframe to fix iOS bug
        });
    }

    function share(file) {

        // first, create temporary iframe and construct a temporary HTML file
        // this serves as a sort of modal to click thru and sits on top
        // of the main window until it is dismissed

        var iframe = document.createElement("iframe");
        var iframeText = `
        <!DOCTYPE html>
        <html style="position:absolute;width:100%;height:100%;background-color:rgba(0,0,0,0.5);">
          <div style="position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);padding:10px;background-color:rgba(255,255,255,0.5);;">
            <button id="cancel">cancel</button>
            <button id="download">download</button>
          </div>
        </html>
        `;
        var iframeBlob = new Blob([iframeText], { type: "text/html" });
        iframe.src = URL.createObjectURL(iframeBlob);

        iframe.style.all = "unset";
        iframe.style.position = "fixed";
        iframe.style.width = "100%";
        iframe.style.height = "100%";

        document.body.appendChild(iframe);

        iframe.onload = () => {

            URL.revokeObjectURL(iframe.src); // delete the object URL

            // select the elements in the iframe and add event handlers
            // to either share the file or dismiss and close the dialogue
            // (the iframe will close automatically after being dismissed
            // or if the user cancels share by tapping away)
            iframe.contentDocument.querySelector("#download").onclick = () => {
                iframe.contentWindow.navigator.share({ files: [file] }).then(() => {
                    console.log("files shared");
                    iframe.contentWindow.close();
                    iframe.remove();
                }).catch((err) => {
                    console.error("user cancelled share");
                    iframe.contentWindow.close();
                    iframe.remove();
                });
            };
            iframe.contentDocument.querySelector("#cancel").onclick = () => {
                iframe.contentWindow.close();
                iframe.remove();
            };
        };
    }



    useEffect(() => {
        // add button to top right
        props.navigation.setOptions({
            headerRight: () => <Button variant={"ghost"} onPress={() => setEditing(true)}>Edit</Button>,
            headerLeft: () => <Button onPress={() => props.navigation.goBack()}
                ml="-3" _text={{ fontSize: 'md' }} variant="ghost" leftIcon={<Icon as={Ionicons} name="arrow-back-circle" size="2xl" color="black"></Icon>}></Button>
            // headerLeft: () => <Button variant={"ghost"} onPress={() => props.navigation.goBack()}>Cancel</Button>
        })
        maybeShowInstagram();
        createShareiFrame();
    }, [])

    useEffect(() => {
        if (editing) {
            props.navigation.setOptions({
                headerRight: () => <Button variant={"ghost"} onPress={() => setEditing(false)}>Done</Button>
            })
        } else if (!editing && Object.keys(checkBoxIdsMap).length > 0) {
            const selectedTripsIds = Object.keys(checkBoxIdsMap).filter((id) => checkBoxIdsMap[id]).map((item) => parseInt(item))
            console.error(selectedTripsIds)
            // Find trips by selected trip ids
            const selectedTrips = trips.filter((trip) => selectedTripsIds.includes(trip.id))
            setSelectedTrips(selectedTrips)
            props.navigation.setOptions({
                headerRight: () => <Button variant={"ghost"} onPress={() => setEditing(true)}>Edit</Button>
            })
            // setCheckBoxIdMap({})
        }
    }, [editing])

    console.log(trips)

    return <View bg="white" flex={1}>
        {editing && <Container pl={3} pt={2}>
            <Text mb={2}>Select layout</Text>
            <Select onValueChange={(value) => setFormatType(value)} selectedValue={formatType}>
                <Select.Item label="default" value="list_cell" />
                <Select.Item label="list" value="list_1"></Select.Item>
                <Select.Item label="square" value="box"></Select.Item>
            </Select>

            <Text mb={3} mt={3}>Select trips to include in {isWishlist ? "wishlist" : "calendar"}</Text>
            {trips && trips.map((trip, index) => <Pressable onPress={() => handleBoxPress(trip)}>
                <HStack space={2} h="30">
                    <Checkbox defaultIsChecked={checkBoxIdsMap[trip.id] == true} isChecked={checkBoxIdsMap[trip.id] == true} value={"idk"} onChange={(selected) => handleBoxPress(trip, selected)}></Checkbox>
                    <Text numberOfLines={1}>{trip.place}</Text>
                </HStack>
            </Pressable>)}
        </Container>}

        {!editing && <Center flex={1} justifyContent={"flex-start"}>
            <View nativeID="social-share" ref={shareViewRef} collapsable={false} maxW="600" w="100%">
                <SocialTripsView anthem={anthem ?? null} useWish={isWishlist} trips={selectedTrips} format={formatType} hideImagePicker={hideImagePicker} onImageUploaded={() => setImageUploaded(true)} />
            </View>

            {/* {Platform.OS === "web" && <canvas id="tutorial" width="150" height="150"></canvas>} */}


            {/* Add overly view at the bottom of the screen */}
            <SafeAreaView style={{ position: 'absolute', bottom: 0, width: '100%', height: 100, backgroundColor: 'black', opacity: 0.95 }}>
                <Container pl={2} pt={2} mb={2}>
                    <Text color="white" pb={0} fontSize="md">Share options</Text>
                    <HStack space={2} mt={1}>
                        {IGInstalled && <Button onPress={handleShareToInstagram} colorScheme={"coolGray"} leftIcon={<Icon as={Entypo} name="instagram" />}>Story</Button>}
                        <Button onPress={downloadImageFromViewRef} colorScheme={"rose"} leftIcon={<Icon as={AntDesign} name="download" />}>Download </Button>
                    </HStack>
                </Container>
            </SafeAreaView>
        </Center>}

        <LoadingModal visible={loading} />

        <SimpleModal isOpen={adModalOpen} onClose={() => setAdModalOpen(false)} notClosable hideHeader size="lg">
            <Heading mb={2} pt={2} fontSize={"xl"}>Fulltrip can bring your travel wishes to life ✈️</Heading>
            <Text>Get amazing itinerary suggestions, flight and accomodation alerts, and easily collect deposits from friends - all in one amazing app.</Text>
            <HStack space={2} mt={4} mb={4}>
                <Button colorScheme={"rose"} size="lg" minW="100" variant={"subtle"} onPress={() => {
                    AnalyticsManager.logEvent(events.POST_WISHLIST_DOWNLOAD);
                    Linking.openURL("https://4vq5c.app.link/oDG2CPL8Fzb")
                }}>Download</Button>
                <Button colorScheme={"coolGray"} size="lg" minW="100" variant={"subtle"} onPress={() => {
                    AnalyticsManager.logEvent(events.POST_WISHLIST_NO_THANKS);
                    setAdModalOpen(false)
                }}>No thanks</Button>
                {/* <Pressable onPress={() => Linking.openURL("https://4vq5c.app.link/oDG2CPL8Fzb")}><Text underline>Download</Text></Pressable> */}
            </HStack>
        </SimpleModal>
    </View>
}