import { Avatar } from "native-base"
import React from "react"
import { Alert, Pressable } from "react-native"
import { getUserAvatar } from "../utils/views"


export default function GroupedAvatars(props) { 
    return <Pressable onPress={() => props.onPress ? props.onPress() : () => null}><Avatar.Group max={props.max ?? 3} {...props.groupProps} >
        {props.users.map((subItem) => getUserAvatar(subItem, { size: props.avatarSize ?? 'xs', ...props.avatarProps }))}
      </Avatar.Group></Pressable>
}