import { Button, View } from "native-base";
import { useState } from "react";
import { ActivityIndicator } from "react-native";
import WebView from "react-native-webview";


export default function ReactNativeWebViewModal(props) {

    const [loading, setLoading] = useState(true)

    const handleWebViewNavigationStateChange = (newNavState) => {
        // newNavState looks something like this:
        // {
        //   url?: string;
        //   title?: string;
        //   loading?: boolean;
        //   canGoBack?: boolean;
        //   canGoForward?: boolean;
        // }
        const { url } = newNavState;
        if (!url) return;

        // console.log(url)
    
        // one way to handle a successful form submit is via query strings
        if (props.successIdent && url.includes(props.successIdent)) {
        //   this.webview.stopLoading();
          props.onClose(true)
          // maybe close this view?
        }
    
        // one way to handle errors is via query string
        if (props.failIdent && url.includes(props.failIdent)) {
        //   this.webview.stopLoading();
          props.onClose(false)
        }
      };
    
    return <View w="100%" h="100%" bg="white" style={{position: 'absolute', top: 0, left: 0, zIndex: 100000, display: props.isOpen ? null : 'none'}}>
        {!props.hideExit && <Button onPress={() => props.onClose()}>Exit</Button>}
        
        {loading && <ActivityIndicator style={{marginTop: 20}}></ActivityIndicator>}

        {props.url && <WebView 
            javaScriptEnabled
            scalesPageToFit={true}
            mediaPlaybackRequiresUserAction={false}
            allowsInlineMediaPlayback
            source={{uri: props.url}}
            onLoad={() => setLoading(false)}
            androidLayerType="hardware"
            onMessage={(e) => props.onMessage(e.nativeEvent.data)}
            onNavigationStateChange={handleWebViewNavigationStateChange}
            />}
    </View>
}