import moment from "moment-timezone";
import { Text, View, Container, Center, Heading, HStack, Image, Divider, Button, ScrollView, Pressable, Icon, Spacer, Badge, Modal, VStack, Box } from "native-base";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ActivityIndicator, StyleSheet, Platform, FlatList, InteractionManager } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import AuthHelper from "../helper/AuthHelper";
import { Entypo, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useFocusEffect, useScrollToTop } from '@react-navigation/native';
import TEXT from '../translations.json'
import { getErrorString, getImageFromRef, meetsMinimumAppVersion, registerForPushNotificationsAsync, shareLink, showAlert } from "../utils";
import * as Notifications from 'expo-notifications';
import TripRow from "../components/TripRow";
import { setConfig, setCreatedTrip, setOnboardTripId, setUser } from "../store";
import Data from "../constants/Data";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import BranchMetrics from "../utils/BranchManager";
import * as Linking from 'expo-linking';
import SocialProfileView from "../components/SocialProfileView";
import SocialShareModal from "../components/SocialShareModal";
import Blocklist from "../utils/BlockList";
import BadgedIcon from "../components/BadgedIcon";
import Toast from 'react-native-toast-message'
import AddFriendListRowVC from "../controllers/AddFriendListRowVC";
import IGShareableViewModalVC from "../controllers/IGShareableViewModalVC";
import SocialTripRow from "../components/SocialTripRow";
import * as WebBrowser from 'expo-web-browser';
import Config from "../constants/Config";
import { LoadingModal } from "../components/LoadingModal";
import NewProductModal from "../components/NewProductModal";
import { Screens } from "../constants/Screens";
import TrendingCell from "../components/TrendingCell";
import AsyncStorage from "@react-native-async-storage/async-storage";



Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: false,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});


const testNotificationData = [
  {
    request: {
      content: {
            title: "Trip to Kyoto, Japan",
            body: 'Victor is interested in joining! awdadawdawdaw dawdawd awd aw da wd aw d aw dawdawdawdawdaw',
            data: {
              link: "PublicProfile?username=jaytravels",
            }
          }
    }
  }
]

export default function HomeScreen(props) {

  const destinationData = props.route.params?.destinationData


  const tripsApi = new GenericViewSetAPI("trip");
  const userApi = new GenericViewSetAPI("user")
  const configApi = new GenericViewSetAPI("config")
  const friendReqApi = new GenericViewSetAPI("friend_request")
  const trendingApi = new GenericViewSetAPI("trending")

  const pageSize = 10;

  const createdTrip = useSelector((s: any) => s.createdTrip)
  const onboardTripId = useSelector((s: any) => s.onboardTripId)

  const dispatch = useDispatch();
  const url = Linking.useURL();
  const userProfileRef = useRef();
  const config = useSelector((state: any) => state.config)

  var notificationListener = null
  var responseListener = null
  const [notification, setNotification] = useState(false);
  const [page, setPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [trendingData, setTrendingData] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [listData, setListData] = useState(null)
  const [trips, setTrips] = useState(null);
  const user = useSelector((s: any) => s.user)
  const [tripsResp, setTripsResp] = useState(null);
  const [showTripCreatedModal, setShowTripCreatedModal] = useState(false);
  const [localCreatedTrip, setLocalCreatedTrip] = useState(null);
  const [fullUser, setFullUser] = useState(null);
  const [notifCount, setNotifCount] = useState(0);
  const [friendSuggestions, setFriendSuggestions] = useState(null);


  const [showShareView, setShowShareView] = useState(false);
  const [shareUri, setShareUri] = useState(null);
  const [showSocialShareModal, setShowSocialShareModal] = useState(false);
  const [socialPictureLoaded, setSocialPictureLoaded] = useState(false);
  const [targetBranchData, setTargetBranchData] = useState(null);
  const [createdTripImageLoad, setCreatedTripImageLoaded] = useState(false);
  const [showTripSocialShare, setShowTripSocialShare] = useState(false);

  // Hacky stuff
  const [profileBranchOpenWait, setProfileBranchOpenWait] = useState(props.route.params?.profileBranchOpenWait ?? 0);

  const scrollToTopRef = React.useRef(null);

  useScrollToTop(scrollToTopRef);
  

  useEffect(() => {
    AuthHelper.requireUserLogin(props, null);
    if (user) { 
      AnalyticsManager.setUserId(user.username);
      AnalyticsManager.setUserProperties({"hasAccount": true, email: user.email})
    }
    
    // If home screen is loaded with profile branch open wait, don't open branch links until wait has passed..
    if (profileBranchOpenWait) { 
      setTimeout(() => {
        setProfileBranchOpenWait(0);
      }, profileBranchOpenWait)
    }

    if (destinationData) { 
      props.navigation.navigate(destinationData.page, destinationData.params)
    }

    // Test things
    // handleNotificationRecieved(testNotificationData[0])
    // setTimeout(() => {
    //   handleBranchDeepLink("PublicProfile", {username: 'jaytravels'})
    // }, 500)
    
  }, [])

  useLayoutEffect(() => {
    if (user && user.id) {  
      fetchFullUser();
    }

    if (user && !user.mobile_verified && config.force_phone_verification) { 
      props.navigation.navigate("MobileVerificationModal", {"title": "Verify your phone number to protect your account", canCancel: true});
    }

    if (onboardTripId) { 
      const newTripId = onboardTripId;
      dispatch(setOnboardTripId(null));
      props.navigation.navigate("TripDetail", {tripId: newTripId});
    }
  }, [props.navigation])


  const getFriendSuggestions = async () => {
    console.log("FETCHING FRIEND SUGGESTIONS");
    const query: any = {}
    if (props.username) { 
        query.username = props.username
    }
    const userFriendsResp = await userApi.nonStandard("GET", `friend_suggestions`, null, { ...query, expansions: ["trip_aggregation_counts", "friend_status"] })
    console.log(userFriendsResp)
    if (userFriendsResp && !userFriendsResp.error) {
        const users = userFriendsResp.results
        setFriendSuggestions(users);
    }
}


  useEffect(() =>  { 
    props.navigation.setOptions({
      headerRight: () => <View mr="4"><BadgedIcon count={notifCount}><Button leftIcon={<Icon as={FontAwesome5} name="bell" size="md" />} variant="link" colorScheme={"coolGray"} onPress={() => props.navigation.navigate("Inbox")}></Button>
      </BadgedIcon></View>
    })

  }, [notifCount])

  const reloadUser = async () => {
    const userInfo = await userApi.nonStandard("GET", "me");
    console.log('me response is ', userInfo);
    if (!userInfo.error) {
      dispatch(setUser(userInfo));
    } else {
      const errorMsg = getErrorString(userInfo.error, "Error loading user info");
      if (errorMsg.toLowerCase().includes("invalid token"))  { 
        await BaseApiConfigProvider.removeUserToken();
        await AsyncStorage.removeItem("token");
        props.navigation.navigate(Screens.Login);
      } 
      // await BaseApiConfigProvider.removeUserToken();
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      AnalyticsManager.logEvent(events.USER_VIEW_HOME);
      let isActive = true;
      console.log("FETCHING USER ON FOCUS EFFECT");
      const task = InteractionManager.runAfterInteractions(() => {
        reloadUser()

        fetchAndProcessConfig();
        // We need to reset everything on re-load
        getMyTrips(isActive, 1);
        getNotificationCount()
        getFriendSuggestions()
        getTop5Trending();

        // Set's user's last active date.
        userApi.nonStandard("GET", `ack`, null, {internal_version: Config.internalVersion});
      });
      return () => {
        isActive = false;
      };
    }, [])
  );

  async function getMyTrips(canUpdate, queryPage = 1, overwrite = true) {
    if (!user) { 
      return null
    }
    const response = await tripsApi.query({ page_size: pageSize, page: queryPage, user_id: user.id, include_friends: true, expansions: ["creator", "trip_interest_count", "trip_interest_users", "my_interest"] });
    // console.error(response)

    if (!response || response.error) {
      if (!trips || trips.length == 0) { 
        const errorStr = getErrorString(response.error, "Sorry, we couldn't load your trips. Please try again later")
        showAlert(errorStr)
      }
      setTrips([]);
      return;
    }

    if (canUpdate) {
      setTripsResp(response)
      if (overwrite) {
        setTrips(response.results)
        setPage(1);
      } else {
        const currTrips = [...trips]
        currTrips.push(...response.results)
        setTrips(currTrips)
      }
    } else {
        console.error("Sorry, we couldn't update your trips. Please try again later");
    }
  }

  const getNotificationCount = async () => { 
    const notifResp = await userApi.nonStandard("GET", "notification_count")
    console.log('notif count resp', notifResp)
    if (notifResp && !notifResp.error) { 
      setNotifCount(notifResp.count);
    }
  }

  const fetchFullUser = () => {
    userApi.getById(user.id, { expansions: ["friend_count", "trip_aggregation_counts", "travel_wishlist"] }).then(resp => {
      if (resp && !resp.error) {
        setFullUser(resp);
      }
    }).catch(err => {
      console.error(err);
      AsyncStorage.removeItem("token").then(() => {
        props.navigation.navigate(Screens.Login, {forced: true});
      })
    })
  }


  const fetchAndProcessConfig = async () => { 
    const userConfig = await configApi.query({});
    console.log(userConfig)
    if (userConfig && !userConfig.error) { 
      dispatch(setConfig(userConfig));
    }

    const meetsRequirement = meetsMinimumAppVersion(userConfig);
    if (!meetsRequirement) { 
      props.navigation.replace("UpdateRequired");
    }
  }

  const hasLessThanFriendSuggestionMax = () => {
    const max = config?.friend_suggestions_max
    if (max > 0 ) { 
      if (fullUser?.friend_count < max) { 
        return true;
      }
    }
    return false
  }


  useEffect(() => {
    if (trips) {
      const defaultItems: any[] = [{trends: true}, {discover: true}]
      if (trips.length <= 1) {
        const defaultTrips = trips ?? []
        const listData: any = [...defaultItems, ...defaultTrips]
        var showFakeData = false
        
        if (trips.length == 1 && trips[0].creator_id !== user.id) { 
          showFakeData = false
        } else { 
          showFakeData = true;
        }

        if (showFakeData) { 
          listData.push({fakeData: true})
        }

        setListData(listData)
      } else {
        setListData([...defaultItems,  ...trips])
      }
    }
}, [trips, config, fullUser, friendSuggestions])


  async function updateUserPushNotification(token: string) {
    if (user) { 
      await userApi.update(user.id, { phone_token: token })
    }
  }


  function handleNotificationRecieved(notification) { 
    console.log('notification recieved', notification);
    const content = notification.request.content;
    if (content.body.includes("are now friends")) { 
      getMyTrips(true, 1)
    }
    if (true) { 
      Toast.show({
        type: 'info',
        text1: content.title,
        text2: content.body,
        onPress: () => {
          if (content.data.link) { 
            let url = content.data.link as string
            const { path, queryParams } = Linking.parse(url);
            props.navigation.navigate(path, queryParams)
          } else if (content.data.url) { 
            WebBrowser.openBrowserAsync(content.data.url as string)
          }
        }
      });
    }
  }

  const handleBranchDeepLink = (page, pageParams) => { 
    AnalyticsManager.logEvent(events.BRANCH_DEEP_LINK_TRIGGER, {page: page, pageParams: pageParams});
    const targetBranchData = {page: page, pageParams: pageParams};
    setTargetBranchData(targetBranchData);
    return;
  }


  useEffect(() => {
    registerForPushNotificationsAsync().then(token => updateUserPushNotification(token));
    notificationListener = Notifications.addNotificationReceivedListener(notification => {
      handleNotificationRecieved(notification)
    });

    responseListener = Notifications.addNotificationResponseReceivedListener(response => {
      console.log('notification response recieved', response)
      const notification = response.notification;
      if (notification.request.content.body != null) { 
        let content = notification.request.content;
        if (content.data) {
          if (content.data.link) { 
            let url = content.data.link as string
            const { path, queryParams } = Linking.parse(url);
            props.navigation.navigate(path, queryParams)
            return;
          }
          if (content.data.url) { 
            WebBrowser.openBrowserAsync(content.data.url as string)
            return;
          }
        }
      }
    });

    const subscription = BranchMetrics.handleDeepLink(props.navigation, (page, pageParams) => handleBranchDeepLink(page, pageParams));

    return () => {
      if (notificationListener && notificationListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current);
      }
      if (responseListener && responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
      if (subscription) { 
        subscription();
      }
    };
  }, []);

  const maybeShowTripSuccessPopup = () => {
    console.log(`createdTripId: ${createdTrip.uuid}`)
    if (createdTrip) {
      setShowTripCreatedModal(true);
      setLocalCreatedTrip(createdTrip);
    }
  }

  const getTop5Trending = async () => { 
    const trendingResp = await trendingApi.query({start: 0, end: 5});
    if (trendingResp && !trendingResp.error) {
      setTrendingData(trendingResp.data)
    }
  }


  useEffect(() => {
    if (createdTrip) {
      maybeShowTripSuccessPopup();
    } else if (targetBranchData) { 
      if (targetBranchData.page === "PublicProfile" && profileBranchOpenWait !== 0) { 
        console.warn("Attempting to trigger profile while wait is active. Ignoring.");
        setTargetBranchData(null);
        return;
      }
      props.navigation.navigate(targetBranchData.page, targetBranchData.pageParams)
      setTargetBranchData(null);
    }
  }, [createdTrip, targetBranchData, profileBranchOpenWait])

  const handleItemPress = (item) => {
    props.navigation.navigate("TripDetail", { tripId: item.uuid })
  }

  const handleLoadMoreTrips = async () => {
    console.log("Loading more trips!")
    const currPage = page + 1;
    if (tripsResp.next) {
      await getMyTrips(true, currPage, false);
      setPage(currPage);
    } else {
      console.log("No more point in fetching more data");
    }
  }

  const handleSocialShareView = async () => {
    const uri = await getImageFromRef(userProfileRef);
    setShareUri(uri);
    setShowShareView(false);
  }

  const goToUser = (username) => {
    props.navigation.navigate("PublicProfile", {username: username})
  }

  useEffect(() => {
    if (showShareView) {
      if (user.picture_url && !socialPictureLoaded) { 
        return;
      }
        setTimeout(() => {
          handleSocialShareView()
         }, 300)
      }
  }, [showShareView, socialPictureLoaded, user])

  const closeCreatedTripModal = () => {
    AnalyticsManager.logEvent(events.USER_EXIT_TRIP_MODAL);
    dispatch(setCreatedTrip(null))
    setShowTripCreatedModal(false);
  }

  const handleShareProfileAd = () => {
    const val = `${BaseApiConfigProvider.getWebUrl()}/user/${user.username}?type=invite`
    shareLink(val);
    setShowSocialShareModal(false);
  }

  const refresh = async () => {
    setRefreshing(true)
    fetchAndProcessConfig();
    await getMyTrips(true, 1)
    setRefreshing(false);
  }

  const renderItem = (itemWrapper: any) => {
    const item = itemWrapper.item;
    var mainView = null
    const onItemPress = (item) => handleItemPress(item)

    if (item.trends) { 
      mainView = <View mt={3}>
        <HStack justifyContent={"space-between"}>
          <Heading mb={2} size="md">Trending trips</Heading>
          <Pressable onPress={() => {
            AnalyticsManager.logEvent(events.TRENDING_VIEW_ALL, {page: "Home"})
            props.navigation.navigate(Screens.Search)}}><Text underline>View all trends</Text></Pressable>
        </HStack>
        <ScrollView horizontal pb="5" showsHorizontalScrollIndicator={false}>
          {trendingData && trendingData.map((item, index) => <TrendingCell item={item} index={index} width="220" mr="2" 
            style={{borderRadius: 5, borderWidth: 1, borderColor: 'lightgray', padding: 10}} />
          )}
          </ScrollView>
        </View>

    } else if (item.friendsAd) {
      mainView = <View mb={4} mt={3}>
        <Heading mb={2}>Share your upcoming travel calendar ✈️ </Heading>
        <Text>Planning trips isn't easy. Share your calendar to celebrate your upcoming adventures 🍾 </Text>
        <HStack mt={3} space={2}>
          <Button colorScheme={"rose"} leftIcon={<Entypo name={config?.use_ig_for_share_icon ? "instagram" : "share-alternative"} color={"white"} />} onPress={() => {
            AnalyticsManager.logEvent(events.USER_CLICK_SHARE_TRAVEL_AD, {type: "calendar"});
            // setShowShareView(true)
            // setShowSocialShareModal(true)
            props.navigation.navigate(Screens.Trips)
          }}>Share your travel calendar</Button>

          <Button variant={"subtle"} colorScheme={"coolGray"} onPress={() => Linking.openURL("mailto:feedback@onfulltrip.com")}>Give feedback</Button>
          {/* <Button variant="subtle" colorScheme={"coolGray"}>Close</Button> */}
        </HStack>
        {/* {showShareView && fullUser && <SocialProfileView user={fullUser} ref={userProfileRef}></SocialProfileView>} */}
      </View>
    } else if (item.discover) { 
      if (!config?.guides) { 
        mainView = <View></View>;
      } else { 
        mainView = <View mt="3">
          <Heading mb="3" size="md">Guides + Inspiration</Heading>
          <ScrollView horizontal pb="10">
          {config?.guides && config.guides.map((item) => <Pressable width="220" mr="2" 
          style={{borderRadius: 5, borderWidth: 1, borderColor: 'lightgray'}}
          onPress={() => {
              AnalyticsManager.logEvent(events.USER_CLICK_INSPIRATION)
              props.navigation.navigate(Screens.Web, {url: item.link})
            }}>
            <Image source={{uri: item.picture_url}} height="100" width="300"/>
            <View p="2">
              <Text mt="2" fontSize={"md"}>{item.title}</Text>
            </View>
          </Pressable>)}
          </ScrollView>
        </View>
      }
    } else if (item.fakeData) {
      mainView = <TripRow fakeData trip={Data.fakeTrip} onItemPress={onItemPress} user={user} navigation={props.navigation} />
    } else if (item.friendSuggestions) { 
        mainView = <View mt="3" mb="3">
          <AddFriendListRowVC noCollapse navigation={props.navigation} userFriends={friendSuggestions}
          collapsed={false} page="home" renderTitle={() => <Heading mb="1" size="md">Others you may know</Heading> } />
        </View>
    } else { 
      //Real trip
      if (Blocklist.isBlocked(item.creator.id)) { 
        mainView =  null;
      } else { 
        mainView = <TripRow __text={{numberOfLines: 3}} trip={item} onItemPress={onItemPress} user={user} navigation={props.navigation}></TripRow>
      }
    }

    // We attach social view to the very last element in the list
    var targetIndex = listData.length - 1
    if (Platform.OS === "android") { 
      // 2nd index is the first non static item and where the trips list begins!
      targetIndex = 2;
    }
    if (itemWrapper.index === targetIndex) { 
      const viewWithProfile =  <>{mainView}
        {fullUser && <SocialProfileView user={fullUser} ref={userProfileRef} onPictureLoad={() => setSocialPictureLoaded(true)} visible={showShareView}></SocialProfileView>}
      </>
      return viewWithProfile
    }

    return mainView
  }

  if (url) { 
    const { hostname, path, queryParams } = Linking.parse(url);

    console.log(
      `Linked to app with hostname: ${hostname}, path: ${path} and data: ${JSON.stringify(
        queryParams
      )}`
    );

    // Alert.alert( `Linked to app with hostname: ${hostname}, path: ${path} and data: ${JSON.stringify(
    //   queryParams
    // )}`)

    // if (path) { 
    //   props.navigation.navigate(path, queryParams);
    // }
  }


  return <View bg="white" flex={1}>
    <Center w="100%">
      <Container maxW="800" w="90%">
        {tripsResp == null && <ActivityIndicator></ActivityIndicator>}
        {trips && <FlatList
          ref={scrollToTopRef}
          style={{ width: '100%', paddingTop: 5 }}
          data={listData}
          showsVerticalScrollIndicator={false}
          onRefresh={refresh}
          refreshing={refreshing}
          renderItem={renderItem}
          ItemSeparatorComponent={Divider}
          onEndReachedThreshold={0.1}
          onEndReached={() => handleLoadMoreTrips()}
        />}

        {createdTrip && <IGShareableViewModalVC 
            isOpen={showTripSocialShare} 
            showCopyLink
            linkType="trip"
            source="post-create-modal"
            onInvitePress={ createdTrip.creator_id !== user.id ? null :  () => {
              setShowTripSocialShare(false);
              props.navigation.navigate("Contacts", {trip: createdTrip, context: "trip_invite"})
            }}
            getSocialView={(ref) => <SocialTripRow 
                trip={createdTrip} 
                ref={ref}
                onImageLoad={() => setCreatedTripImageLoaded(true)} />}
            viewFinishedLoading={createdTripImageLoad} 
            link={`${BaseApiConfigProvider.getWebUrl()}/trip/${createdTrip.uuid}`} 
            onClose={() => {
              setShowTripSocialShare(false);
              closeCreatedTripModal();
            }}/>}

        <Modal isOpen={showTripCreatedModal} onClose={() => closeCreatedTripModal()}>
          <Modal.Content maxWidth="400px">
            <Modal.CloseButton />
            <Modal.Header>Your Trip was created!</Modal.Header>
            <Modal.Body>
              <Text mb={3}>Share it with friends, see who's interested in joining and start planning!</Text>
              <Button colorScheme={"rose"} leftIcon={<Entypo name={config?.use_ig_for_share_icon ? "instagram" : "share-alternative"} color={"white"} />} onPress={() => {
                  setShowTripSocialShare(true);
                  setShowTripCreatedModal(false);
                }}>Share trip</Button>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={() => closeCreatedTripModal()}>
                  Done
                </Button>
                <Button colorScheme={"coolGray"} onPress={() => {
                  closeCreatedTripModal();
                  props.navigation.navigate("TripDetail", { tripId: localCreatedTrip.uuid });
                }}>
                  Go to trip
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
        {fullUser && <SocialShareModal isOpen={showSocialShareModal} source="home_ad" onClose={() => setShowSocialShareModal(false)} uri={shareUri} onLinkShare={() => handleShareProfileAd()} />}

        {Platform.OS !== "web" && <NewProductModal productKey="trip_suggestions" title="Introducing Trip Suggestions" 
          hide={onboardTripId}
          imageUrl="https://roam-public-images.s3.amazonaws.com/system_images/suggestions.png"
          body="Now your friends can help you plan your trip by suggesting things to do!"/>}
      </Container>
    </Center>
  </View>
}

const styles = StyleSheet.create({
});
