import Config from "../constants/Config";
import { Platform } from "react-native";
import { buildURLQuery, showAlert } from ".";
import * as Linking from 'expo-linking';

class BranchManager {
    static instance = null;
    private branchSdk = null;

    constructor() {
        if (!BranchManager.instance) {
            BranchManager.instance = this
        }
        return BranchManager.instance
    }

    async initializeBranch() {
        await this.getBranchSdk();
    }

    private async getBranchSdk() {
        if (this.branchSdk) {
            return this.branchSdk;
        }
        if (Platform.OS === "web") {
            const branchWeb = await import("branch-sdk")
            const branch = branchWeb.default;
            branch.init(Config.branchApiKey);
            this.branchSdk = branch;
            return this.branchSdk;
        } else {
            const branch: any = await import("react-native-branch")
            this.branchSdk = branch.default;
            return this.branchSdk;
        }
    }

    private async createDeepLinkMobile(page, params, canocUrl) {
        const branch = await this.getBranchSdk();
        let buo = await branch.createBranchUniversalObject(canocUrl, {});

        let linkProperties = {
            feature: 'sharing',
            channel: page,
        }
        let controlParams: any = {
            page: page,
            pageParams: params
        }

        let { url } = await buo.generateShortUrl(linkProperties, controlParams);
        return url;
    }

    private async createDeepLinkWeb(page, params, canocUrl) {
        const branch = await this.getBranchSdk();
        var linkData = {
            data: {
                'page': page,
                'pageParams': params,
                '$canonical_identifier': canocUrl,	
                '$og_title': 'Test',
                '$deeplink_path': `${page}?${buildURLQuery(params)}`,
                '$og_description': 'Description',
                '$og_image_url': 'http://lorempixel.com/400/400'
            }
        };

        const link: string = await new Promise((res, rej) => {
            branch.link(linkData, function (err, link) {
                if (err && !link) {
                    console.log(err)
                    res(null);
                }
                console.log(link);
                res(link)
            });
        })
        return link;
    }

    /**
     * Deep links example
     * roamapp://link?page=TripDetail&tripId=1213awdzwdalc
     * 
     * roamapp://TripDetail?tripId=tripId=1213awdzwdalc
     * 
     * 
     */
    async createDeepLinkOnly(page, params, canocUrl): Promise<string> {
        if (Platform.OS === "web") {
            return this.createDeepLinkWeb(page, params, canocUrl);
        } else {
            return this.createDeepLinkMobile(page, params, canocUrl);
        }
    }

    async getInstallParams(overwriteParams: any = null) { 
        if (Platform.OS === "web") { 
            return null;
        }
        const branch = this.branchSdk
        let installParams = await branch.getFirstReferringParams() // params from original install
        if (overwriteParams && __DEV__) { 
            return overwriteParams
        }
        return installParams
    }

    parseLinkParams(params, navigation,  handleFn) { 
        var page = params.page
        var pageParams = params.pageParams

        if (params.url) { 
            const { path, queryParams } = Linking.parse(params.url);
            page = path
            pageParams = queryParams
            // showAlert(`${page}: ${JSON.stringify(pageParams)}`)
        }
        if (handleFn) { 
            handleFn(page, pageParams)
        } else { 
            navigation.navigate(page, pageParams);
        }
    }

    parseAndReturnLinkParams(params) { 
        var page = params.page
        var pageParams = params.pageParams

        if (params.url) { 
            const { path, queryParams } = Linking.parse(params.url);
            page = path
            pageParams = queryParams
            // showAlert(`${page}: ${JSON.stringify(pageParams)}`)
        }
        return {page: page, pageParams: pageParams} 
    }

    handleDeepLink(navigation: any, handleFn: (page, pageParams) => void): () => any {
        if (Platform.OS === "web") {
            return null;
        }

        const branch = this.branchSdk;

        return branch.subscribe(({ error, params, uri }) => {
            if (error) {
                console.error('Error from Branch: ' + error)
                return
            }
            // params will never be null if error is null
            if (!navigation && !handleFn) {
                return;
            }
            try {
                console.log(`Recieve params from subscibe :`, params)
                if (!params["+clicked_branch_link"]) {
                    return;
                }

                this.parseLinkParams(params, navigation, handleFn)

            } catch (err) {
                console.error(err)
            }
        })
    }

}

const BranchMetrics = new BranchManager();

export default BranchMetrics;