import moment from "moment";
import { Modal, View, Text, Heading, Switch, Button, Checkbox, Pressable, useBreakpointResolvedProps } from "native-base";
import React, { useState } from "react";
import * as WebBrowser from 'expo-web-browser'
import { openLinkWebAware, showAlert } from "../utils";

export default function TripPaymentModal(props) { 

    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [acceptedRefund, setAcceptedRefund] = useState(false)

    const trip = props.trip


    const handleConfirm = () => { 
        if (acceptedTerms !== true) {
            showAlert("You must accept the terms of service");
            return;
        }
        if (acceptedRefund !== true) {
            showAlert("You must accept the refund policy");
            return;
        }
        props.onConfirm()
    }

    if (!trip) { 
        return null;
    }

    const padding = 3

    return <Modal isOpen={props.isOpen} size="xl" onClose={props.onClose}>
    <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header> </Modal.Header>
        <Modal.Body w="100%">
                <Heading>Trip to {trip.place}</Heading>
                <Text mt={1} fontSize="sm" color="coolGray.600">by {trip.creator.first_name}</Text>

                <View flex={1} pt="3" pb="3" flexDir="row" >
                    <Text fontSize="sm">Deposit is for </Text>
                    <Text>"{trip.deposit_for}"</Text>
                    {/* <Switch isChecked isDisabled offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" /> */}
                </View>

                <View flex={1} pt={padding} pb={padding} flexDir="row" justifyContent="space-between">
                    <Text fontSize="sm">Deposit</Text>
                    <Text>{`$${trip.display_cost?.toFixed(2)}`}</Text>
                    {/* <Switch isChecked isDisabled offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" /> */}
                </View>

                <View flex={1} pt={padding} pb={padding}flexDir="row" justifyContent="space-between">
                    <Text fontSize="sm">Taxes and fees</Text>
                    <Text>${trip.price_context?.fees?.toFixed(2)}</Text>
                </View>

                <View flex={1} pt={padding} pb={padding} flexDir="row" justifyContent="space-between">
                    <Text fontSize="sm">Total</Text>
                    <Text>${trip.price_context?.total?.toFixed(2)}</Text>
                </View>

                {trip.refund_before && <View pt={padding} pb={padding}>
                    <View flex={1}  flexDir="row" justifyContent="space-between">
                        <Text fontSize="sm">Refundable if cancelled by</Text>
                        <Text>{moment(trip.refund_before).format('ll')}</Text>
                    </View>
                    {moment(trip.refund_before).isBefore(moment.now()) && <Text mt={1} fontSize="sm" color="rose.700">This trip is non-refundable</Text>}
                </View>
                }


                <View flex={1} pt={2} pb={padding} flexDir="row" justifyContent={"center"} alignItems="center">
                    <Checkbox  mr={2} value={acceptedRefund.toString()} size="sm" accessibilityLabel="I agree to the terms of service" defaultIsChecked={false} onChange={(e) => setAcceptedRefund(e)} ></Checkbox>
                    <Text>I agree to the refund policy</Text>
                </View>
                <View flex={1} pt={2} pb={padding} flexDir="row" justifyContent={"center"} alignItems="center">
                    <Checkbox  mr={2} value={acceptedTerms.toString()} size="sm" accessibilityLabel="I agree to the terms of service" defaultIsChecked={false} onChange={(e) => setAcceptedTerms(e)} ></Checkbox>
                    <Text>I agree to the <Pressable onPress={() => openLinkWebAware("https://www.onfulltrip.com/terms")}><Text underline>Terms of Service</Text></Pressable></Text>
                </View>

                <Button colorScheme={"rose"} onPress={handleConfirm} mt={2}>Pay and join</Button>

        </Modal.Body>
    </Modal.Content>
</Modal>
}