import moment from "moment-timezone";
import { Divider, View, Pressable, Text, Heading, Modal, Button, Input, Image, HStack, Switch, FormControl, Select, Center, Badge, KeyboardAvoidingView } from "native-base";
import React, { useEffect, useState } from "react";
import { FlatList } from 'react-native'
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import { AnalyticsManager, events as analyticEvent } from "../utils/AnalyticsManager";
import { canRecieveNotifications, copyAndSetMap, getDateHandleWeb, getErrorString, meetsInternalVersion, showAlert } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setCreatedTrip } from "../store";
import { LoadingModal } from "../components/LoadingModal";
import TripRow from "../components/TripRow";
import Config from "../constants/Config";
import ImagePickerModal from "../components/ImagePickerModal";
import TripEventCell from "../components/TripEventCell";
import TripEventModal from "../components/TripEventModal";
import NotificationReminderModal from "../components/NotificationReminderModal";
import SimpleAlertDialog from "../components/SimpleAlertDialog";
import DatePickerRow from "../components/DatePickerRow";
import { Keyboard, Platform } from "react-native";
import DateRangePicker from "react-native-daterange-picker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import DoneInputAccessory from "../components/DoneInputAccessory";
import * as WebBrowser from 'expo-web-browser';
import { Screens } from "../constants/Screens";
import TextWithPreview from "../components/TextWithPreview";
import SimpleModal from "../components/SimpleModal";
import WebPaymentsOnboardVC from "../controllers/WebPaymentOnboardVC";
import WebDatePicker from "../components/WebDatePicker";
import ConstrainedInput from "../components/ConstrainedInput";
import useViewType from "../hooks/useViewType";


export default function TripReviewScreen(props) {

    const tripApi = new GenericViewSetAPI("trip")
    const configApi = new GenericViewSetAPI("config")

    const viewType = useViewType();
    const dispatch = useDispatch();
    const events = props.route.params?.events;
    const trip = props.route.params?.trip;
    const tripId = props.route.params?.tripId;
    const placePhotos = props.route.params?.placePhotos;
    const config = useSelector((s: any) => s.config)
    const user = useSelector((s: any) => s.user)

    const [selectedEvent, setSelectedEvent] = useState(null);
    // const alreadyPublished = props.route.params?.trip?.published

    const isEdit = false;
    const [loading, setLoading] = useState(true);
    const [maxPeople, setMaxPeople] = useState(5);
    const [visibility, setVisibility] = useState("All friends");
    const [modalOpen, setIsModalOpen] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [posting, setPosting] = useState(false);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(props.route.params?.trip?.picture_url);
    const [imagePickerOpen, setImagePickerOpen] = useState(false)
    const [imageUrls, setImageUrls] = useState([])
    const [allowOthersToJoin, setAllowOthersToJoin] = useState(props.route.params?.trip?.joinable ?? true);
    const [showNotificationReminder, setShowNotificationReminder] = useState(false);
    const [tripDeposit, setTripDeposit] = useState(props.route.params?.trip?.display_cost ? `${props.route.params?.trip?.display_cost}` : "0")
    const [depositFor, setDepositFor] = useState(props.route.params?.trip?.deposit_for)
    const [tempDataMap, setTempData] = useState<any>({})
    const [notPublishedDialogOpen, setNotPublishedDialogOpen] = useState(false)
    const [showDateModal, setShowDateModal] = useState(false)
    const [displayedDate, setDisplayedDate] = useState(moment());
    const [lastJoinDate, setLastJoinDate] = useState(getDateHandleWeb(props.route.params?.trip?.join_before));
    const [refundBy, setRefundBy] = useState<Date>(getDateHandleWeb(props.route.params?.trip?.refund_before))
    const [webPaymentModalOpen, setWebPaymentModalOpen] = useState(false)
    const [webPaymentMoreDetails, setWebPaymentMoreDetails] = useState(false)

    //TODO: Add
    // const [userSuggestionsAllowed, setUserSuggestionsAllowed] = useState(true)
    // const [sendTripResources, setSendTripResources] = useState(true)

    useEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button onPress={() => setPosting(true)} variant="link" isLoading={posting} isLoadingText={"Loading..."}
            _text={{fontSize: 'md'}}
            >Publish</Button>
        })
        console.log('selected photo url', selectedPhotoUrl)
        console.log('trip is', trip)
        if (placePhotos) {
            console.log('place photos', placePhotos)
            const images = placePhotos.map((item) => Config.googleMapsPhotoRef.replace("{}", item.photo_reference))
            setImageUrls(images)
        }

        fetchAndUpdateConfig();
        
    }, [])

    useEffect(() => {
        if (posting) { 
            handlePosting()
        }
    }, [posting])


    const fetchAndUpdateConfig = async () => {
        setLoading(true);
        const userConfig = await configApi.query({});
        if (userConfig && !userConfig.error) { 
            dispatch(setConfig(userConfig));
        }
        setLoading(false);
    }

    const closeModal = (type = null) => {
        if (activeField === "cost" && type == "done") { 
            const tempDeposit = tempDataMap.tripDeposit
            const tempDepositFor = tempDataMap.depositFor
            const tempRefundBy = tempDataMap.refundBy
            // const depositInt = parseInt(tempDeposit)
            const validDeposit = tempDeposit && tempDeposit !== "0" && tempDeposit !== ""
            if (tempDeposit && tempDeposit !== "0" && tempDeposit !== "") { 
                if (!tempRefundBy) { 
                    showAlert("Refund date required");
                    return;
                }
                if (!tempDepositFor) { 
                    showAlert("Deposit reason required");
                    return;
                }
            }
            setTripDeposit(tempDeposit)
            if (!validDeposit) { 
                setRefundBy(null)
                setDepositFor(null)
            } else { 
                setRefundBy(tempRefundBy)
                setDepositFor(tempDepositFor)
            }
        }
        if (activeField === "cost" && type == "cancel") { 
            const updatedMap = copyAndSetMap("depositFor", depositFor, tempDataMap)
            setTempData(updatedMap)
            setTempData(copyAndSetMap("tripDeposit", tripDeposit, updatedMap))
        }
        
        setIsModalOpen(false);
        setActiveField(null);
    }


    const handlePosting = async() => { 
        const canRecieveNotifs = await canRecieveNotifications();
        if (!canRecieveNotifs && Platform.OS !== "web") { 
            setShowNotificationReminder(true);
        } else { 
            publishEvent()
        }
    }

    const openModalForField = (field: string) => {
        setActiveField(field);
        setIsModalOpen(true);
    }

    const goToPaymentOnboard = (moreDetails = false) => {
        if (Platform.OS === "web") { 
            setWebPaymentModalOpen(true)
            setWebPaymentMoreDetails(moreDetails)
        } else { 
            props.navigation.navigate("PaymentsOnboard", {moreDetails: moreDetails})
        }
    }

    const publishEvent = async() => {
        const updateBody: any = {
            published: true, 
            joinable: allowOthersToJoin,
            display_cost: parseInt(tripDeposit) ?? 0, 
            deposit_for: depositFor,
            refund_before: refundBy,
            join_before: lastJoinDate
        }

        if (selectedPhotoUrl) { 
            updateBody.picture_url = selectedPhotoUrl;
        }
        AnalyticsManager.logEvent(analyticEvent.USER_PUBLISHED_TRIP, {cost: parseInt(tripDeposit)})
        const publishResp = await tripApi.update(trip.id, updateBody)
        console.log('publish event resp', publishResp);
        if (!publishResp || publishResp.error) { 
            if (publishResp.error) {
                if (publishResp.error?.type == "more_details_required") { 
                    AnalyticsManager.logEvent(analyticEvent.USER_REDIRECTED_MORE_DETAILS_PAYMENTS)
                    goToPaymentOnboard(true)
                } else if (publishResp.error?.type == "account_required") { 
                    AnalyticsManager.logEvent(analyticEvent.USER_REDIRECTED_ACCOUNT_NEEDED)
                    goToPaymentOnboard()
                }
            } else {
                AnalyticsManager.logEvent(analyticEvent.UNKNOWN_ERROR)
                const errorStr = getErrorString(publishResp.error, "Sorry, something went wrong while publishing trip")
                showAlert(errorStr);
            }
        } else { 
           goToNextStep(trip, trip.published, publishResp)
        }
        setPosting(false);
    }

    function navigateHome() { 
        if (Platform.OS === "web") { 
            props.navigation.navigate(Screens.Trips);
        } else { 
            props.navigation.navigate(Screens.Root, {screen: Screens.Home});
        }
    }

    function goToNextStep(trip, alreadyLive, publishResp) {
        if (trip) {
            if (!alreadyLive && publishResp.published) {  
                dispatch(setCreatedTrip(trip));
                navigateHome();
            } else if (!publishResp.published && publishResp.display_cost > 0) { 
                AnalyticsManager.logEvent(analyticEvent.TRIP_NOT_PUBLISHED);
                setNotPublishedDialogOpen(true);
            } else { 
                navigateHome();
            }
        }
    }

    const getFieldForModal = () => {
        return <View>
            <Input placeholder={activeField}></Input>
        </View>
    }

    const getModalViewForActiveField = () => {
        if (activeField === "picture_url") { 
            return <View flex={1} flexWrap="wrap" flexDir={"row"}>
                {placePhotos.map((item) => <Pressable onPress={() => {
                    const newPicUrl = Config.googleMapsPhotoRef.replace('{}', item.photo_reference)
                   setSelectedPhotoUrl(newPicUrl)
                   trip.picture_url = newPicUrl;
                   AnalyticsManager.logEvent(analyticEvent.USER_CHANGED_TRIP_THUMBNAIL)
                   closeModal()
                }}>
                    <Image source={{uri: Config.googleMapsPhotoRef.replace('{}', item.photo_reference)}} width="100" height="100" mr="1" mb="1"/>
                    </Pressable> )}
            </View>
        } else if (activeField === "cost") { 
            if (!user.connect_acc_id) { 
                return <View>
                    <Heading mb={3} textAlign="center">Create your payment account</Heading>
                    <Text mb={3} textAlign="center">In order to accept trip deposits, you must setup your payments account! It only takes about 3 minutes</Text>
                    <Button colorScheme={"rose"} mt={2} onPress={() => goToPaymentOnboard()}>Setup payments</Button>
                </View>
            }
            return <KeyboardAvoidingView enabled  style={{ flex: 1 }}
                keyboardVerticalOffset={200}
                behavior={Platform.OS === "ios" ? "padding" : "height"}>
                <Pressable mb={3} onPress={() => WebBrowser.openBrowserAsync("https://www.onfulltrip.com/faq")}><Text underline>Payment FAQs</Text></Pressable>

                <FormControl isRequired mb={3}>
                    <FormControl.Label>Enter trip deposit amount (USD)</FormControl.Label>
                    <ConstrainedInput type="integer" inputProps={{type: "text", placeholder: "25", keyboardType: "numeric", inputAccessoryViewID: "done"}} value={tempDataMap.tripDeposit} onChangeText={(txt) => setTempData(copyAndSetMap("tripDeposit", txt, tempDataMap)) } />

                    <FormControl.HelperText>
                        We recommend adding an extra 10% incase of unexpected expenses. Must be atleast $10
                    </FormControl.HelperText>
                </FormControl>

                    <FormControl isRequired mb={3}>
                        <FormControl.Label>What will the money be used for?</FormControl.Label>
                        <Input type="text" placeholder="Airbnb, tours, dinners...etc" value={tempDataMap.depositFor} onChangeText={(txt) => setTempData(copyAndSetMap("depositFor", txt, tempDataMap))} 
                            inputAccessoryViewID={"done"}/>
                    </FormControl>

                <FormControl isRequired mb={3}>
                    <FormControl.Label>Last day to recieve refund</FormControl.Label>
                    {Platform.OS !== "web" && <Button variant={"subtle"} colorScheme="coolGray"
                        onPress={() => {
                        setShowDateModal(true)
                        setIsModalOpen(false)
                    }}>{tempDataMap.refundBy ? moment(tempDataMap.refundBy).format("ll") : "Add date"}</Button>}
                    {Platform.OS === "web" && <WebDatePicker placeholder="Last day to get refund.." date={tempDataMap.refundBy} onChange={(date) => {
                        setDate({date: date}) 
                    }} /> 
}
                    {/* <Input type="text" placeholder="Airbnb, tours, dinners...etc" value={tempDataMap.depositFor} onChangeText={(txt) => setTempData(copyAndSetMap("depositFor", txt, tempDataMap))} /> */}
                </FormControl>

                {false && <View>
                    <View flex={1} pt="4" pb="4" flexDir="row" justifyContent="space-between">
                        <FormControl.Label>Create fulltrip card</FormControl.Label>
                        <Switch isChecked offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                    </View>
                    <FormControl>
                        <FormControl.Label>Card type</FormControl.Label>
                        <Select defaultValue="virtual">
                            <Select.Item label="Virtual" value="virtual" />
                            <Select.Item label="Physical" value="physical"/>
                        </Select>
                    </FormControl>
                </View>}
            </KeyboardAvoidingView>
        }
    }

    const setDate = (dates) => { 
        if (dates.date) { 
            if (activeField === "join_by_date") { 
                setLastJoinDate(dates.date)
            } else if (activeField === "cost") { 
                // setRefundBy(dates.date)
                setTempData(copyAndSetMap("refundBy", dates.date, tempDataMap))
                setIsModalOpen(true)
            }
            // setStartDate(dates.date)
            setShowDateModal(false)
        }
        if (dates.displayedDate) {
            setDisplayedDate(dates.displayedDate)
        }
    }

    const finishAfterNotPublishedDialog = () => {
        AnalyticsManager.logEvent(analyticEvent.NOT_PUBLISHED_DIALOG_CLOSED)
        props.navigation.navigate("Home")
    }


    const handleTripClick = () => {
        if (!user.connect_acc_id) { 
            goToPaymentOnboard()
            if (Platform.OS !== "ios") { 
                return;
            }
        }
        const map = {tripDeposit: tripDeposit, depositFor: trip.deposit_for, refundBy: trip.refund_before }
        setTempData(map)
        openModalForField("cost")
    }

    const renderItem = (item: any) => {
        if (item.item === "review") {
            // TODO: Return trip detail + picture
            return <View pb="2">
                <Heading>Let's Review</Heading>
                {trip && <TripRow trip={trip} hideButtons userNameOverwrite="You" isViewOnly imageStyle={{borderRadius: 5}}/>}
                {placePhotos && placePhotos.length > 1 && <Button colorScheme={"coolGray"} 
                onPress={() => {
                    // setImagePickerOpen(true)
                    AnalyticsManager.logEvent(analyticEvent.USER_CLICKED_CHANGE_TRIP_PHOTO)
                    openModalForField("picture_url")
                }}>Change thumbnail photo</Button>}
            </View>
        }
        if (item.item === "settings") { 
            return <View>
                {config.is_paid_trips_allowed && meetsInternalVersion(config.min_paid_trips_version, false) && <View>
                <Pressable 
                    // disabled={Platform.OS === "web"} 
                    onPress={() => handleTripClick()} pt="4" pb="4">
                    <View flex={1} flexDir="row" justifyContent="space-between">
                        <Text fontSize="md">Trip deposit</Text>
                        <Text>{!tripDeposit || tripDeposit == "0" ? "Free" : `$${tripDeposit} USD`}</Text>
                    </View>
                    {!depositFor && <Text fontSize={"xs"} mt={1}>Amount people will have to pay to join the trip</Text>}
                    {depositFor && <Text fontSize={"xs"} mt={1}>{`For \"${depositFor}\"`}</Text>}
                    {refundBy && <Text fontSize={"xs"} mt={1}>Refunds available before: {moment(refundBy).format('ll')}</Text>}
                    {/* {Platform.OS === "web" && <Text mt={2} fontSize="xs" color={"rose.700"}>Disabled on web. Download the Fulltrip app to collect deposits and control more!</Text>} */}
                </Pressable>
                <Divider></Divider>
                </View>}

                {Platform.OS !== "web" && <Pressable onPress={() => {
                    setActiveField("join_by_date")
                    setShowDateModal(true)
                }} flex={1} pt="4" pb="4" flexDir="row" justifyContent="space-between">
                    <Text fontSize="md">Last day to join</Text>
                    {lastJoinDate && <Text>{moment(lastJoinDate).format('ll')}</Text>}
                </Pressable>}

                {Platform.OS === "web" && <HStack justifyContent={"space-between"} pt="4" pb="4">
                    <Text fontSize="md">Last day to join</Text>
                    <WebDatePicker placeholder="Last day to join.." date={lastJoinDate} onChange={(date) => {
                        setLastJoinDate(date)
                    }} />
                    </HStack>}
                <Divider></Divider>

                <View pt="4" pb="4">
                    <View mb={2} flexDir="row" justifyContent="space-between">
                        <Text fontSize={"md"}>Allow others to join trip</Text>
                        <Switch isChecked={allowOthersToJoin} onToggle={() => setAllowOthersToJoin(!allowOthersToJoin)} offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                    </View>
                    <Text fontSize={"xs"}>Turning this off prevents any more new people from joining the trip, essentially locking it.</Text>
                </View>
            </View>
        }

        if (item.item === "events") {
            // TODO: Add trip itinery from previous screen
            if (!events || events.length === 0) { 
                return null
            }
            return <View pb="5" pt="3">
                <Heading mb="5">Itinerary</Heading>
                
                {trip.itinerary_link && <TextWithPreview data={trip.itinerary_link} parsedTextProps={{numberOfLines: 2}} 
                    parsedTextStyle={{ fontSize: 18, marginBottom: 15, marginTop: 15 }}/> }

                <FlatList
                    style={{width: '100%'}}
                    data={events}
                    renderItem={(itemObj) => {
                        const itemData: any = itemObj.item;
                        return <TripEventCell event={itemData} onPress={(event) => setSelectedEvent(event) }></TripEventCell>
                    }}
                />
            </View>
        }
        if (item.item === "other") { 
            return <View pt="3">
                <Heading>Other settings</Heading>
                <View flex={1} pt="4" pb="4" flexDir="row" justifyContent="space-between">
                    <Text fontSize="md">Notify trip go-ers about trip updates</Text>
                    <Switch isChecked isDisabled offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                </View>
                <Divider></Divider>
                <View flex={1} pt="4" pb="4" flexDir="row" justifyContent="space-between">
                    <Text fontSize={"md"}>Add new trip goers to group chat</Text>
                    <Switch isChecked isDisabled offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                </View>
                <Divider></Divider>

                {Platform.OS === "web" && <Button mt={4} colorScheme={"rose"} onPress={() => setPosting(true)}>Finish</Button>}
            </View>
        }
        if (item.item === "buffer") { 
            return <View h="100"></View>
        }

        return null
    }

    return <Layout scrollView containerW="100%">
            {Platform.OS !== "web" && <DateRangePicker
            containerStyle={{ display: showDateModal ? null : 'none', zIndex: 5000 }}
            onChange={setDate}
            date={null}
            displayedDate={displayedDate}
            open={showDateModal}
            onOpenTriggered={() => {
                Keyboard.dismiss()
            }}
            onCloseTriggered={() => {
                setShowDateModal(false);
            }}
        >
            <Text style={{display: 'none'}} >Hi</Text>
        </DateRangePicker>} 

        <Center w="100%">
            <View w="90%">

                <FlatList
                    data={["review", "settings", "events", "other", "buffer"]}
                    style={{ width: '100%', paddingTop: 10 }}
                    renderItem={(item) => renderItem(item)}
                    ItemSeparatorComponent={Divider}
                    showsVerticalScrollIndicator={false}
                    keyboardShouldPersistTaps={"always"}
                />

                <LoadingModal visible={posting || loading}></LoadingModal>

                {/* {placePhotos && <ImagePickerModal isOpen={imagePickerOpen} images={imageUrls}/> } */}

                <Modal isOpen={modalOpen} onClose={() => closeModal(false)} size="xl" zIndex={4}>
                    <Modal.Content >
                        <Modal.CloseButton />
                        <Modal.Header>Update</Modal.Header>
                        <Modal.Body>
                            {getModalViewForActiveField()}
                        </Modal.Body>
                        {activeField !== "picture_url" && <Modal.Footer>
                            <Button.Group space={2}>
                                <Button colorScheme={"coolGray"} variant="subtle" onPress={() => {
                                    closeModal("cancel");
                                }}>
                                    Close
                                </Button>

                                <Button colorScheme="rose" onPress={() => closeModal("done")}>
                                    Done
                                </Button>
                            </Button.Group>
                        </Modal.Footer>}
                    </Modal.Content>
                </Modal>

                <SimpleAlertDialog  hideCancel
                isOpen={notPublishedDialogOpen} 
                onClose={() => finishAfterNotPublishedDialog()}
                onConfirm={() => finishAfterNotPublishedDialog()} 
                title="Your trip wasn't published" 
                body={"Your payment account may still be processing. We'll notify you when it finishes, so you can publish your trip! \n\nReach out to support@onfulltrip.com if this persists even after that"}/>
                
                <TripEventModal isOpen={selectedEvent !== null} event={selectedEvent} onClose={() => setSelectedEvent(null)} />

                <SimpleModal isOpen={webPaymentModalOpen} onClose={() => setWebPaymentModalOpen(false)} hideHeader size="lg">
                    <WebPaymentsOnboardVC  moreDetails={webPaymentMoreDetails} onClose={() => setWebPaymentModalOpen(false)}/>
                </SimpleModal>
                
                <NotificationReminderModal isOpen={showNotificationReminder} 
                body={"Without notifications, you won't know when friends want to join your trip or when they are asking questions in the trip chat!"} onClose={() => {
                    setShowNotificationReminder(false);
                    publishEvent()
                }} />
            </View>
        </Center>
        <DoneInputAccessory />
    </Layout>
}