import { Button, View, Text, Center, HStack, ScrollView } from "native-base";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { ActivityIndicator, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Layout from "../components/Layout";
import SimpleUserView from "../components/SimpleUserView";
import SocialProfileView from "../components/SocialProfileView";
import { setOnboardUserAddedFriend } from "../store";
import { showAlert } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";


export default function OnboardFriendScreen(props) {

    const userApi = new GenericViewSetAPI("user");
    const friendRequestApi = new GenericViewSetAPI("friend_request")
    const tripApi = new GenericViewSetAPI("trip")

    const dispatch = useDispatch()
    const username = props.route.params?.username
    const context = props.route.params?.context

    const privateUser = useSelector((state: any) => state.user)
    const onboardTripId = useSelector((state: any) => state.onboardTripId);

    const [user, setUser] = useState(null);
    const [primaryActionLoading, setPrimaryActionLoading] = useState(false);
    const [userFriends, setUserFriends] = useState(null);
    const [loadingMap, setLoadingMap] = useState({})
    const [doneMap, setDoneMap] = useState({})

    const getTripAndSetUser = async () => {
        let tripResp = await tripApi.nonStandard("GET", `${onboardTripId}/uuid`, null, {
            expansions: ['creator', 'trip_interest_users', 'trip_aggregation_counts']
        })
        console.warn(tripResp);
        if (tripResp && !tripResp.error) {
            setUser(tripResp.creator);
        } else {
            showAlert("Sorry we couldn't load your friends social profile. Try again later!");
            finish();
        }
    }

    const getUser = async (username) => {
        let userResp = await userApi.query({ username: username.toLowerCase(), expansions: ["friend_status", "trip_aggregation_counts"] });
        if (userResp && !userResp.error && userResp.results) {
            console.log(userResp)
            setUser(userResp.results[0])
        } else {
            showAlert("Sorry we couldn't load your friends social profile. Try again later!");
            finish();
        }

        const userFriendsResp = await userApi.nonStandard("GET", `${username}/followers`, null, { expansions: ["trip_aggregation_counts"] })
        console.log(userFriendsResp)
        if (userFriendsResp && !userFriendsResp.error) { 
            const users = userFriendsResp.results
            setUserFriends(users);
        } 
    }

    const addMainFriend = async() => {
        await addFriend(user, context)
    }

    const setUserAdded = (user) => {
        const existingDone = {...doneMap};
        existingDone[user.username] = true
        setDoneMap(existingDone)
    }

    const addFriend = async (user, context=null) => {
        AnalyticsManager.logEvent(events.USER_ADD_FRIEND, {page: 'onboard_friend'})
        dispatch(setOnboardUserAddedFriend(true));
        setPrimaryActionLoading(true);
        if (user.friend_status && user.friend_status !== "none" && user.friend_status !== "awaiting_response") {
            console.log("Adding user when users are already friends..doing nothing.")
            setUserAdded(user)
            // finish()
        } else {
            const existingLoading = {...loadingMap};
            existingLoading[user.username] = true
            setLoadingMap(existingLoading)
            const friendReq = await friendRequestApi.create({ requestor: privateUser.id, target: user.id, context: context });
            console.log(friendReq);
            if (!friendReq || friendReq.error) {
                showAlert("Something went wrong while attempting to add user. Please try again");
            } else {
                setUserAdded(user)
            }
            delete existingLoading[user.username]
            setLoadingMap(existingLoading)
        }
        setPrimaryActionLoading(false);
    }

    const finish = () => {
        if (Platform.OS !== "web") {
            props.navigation.replace("OnboardNotification");
        } else {
            props.navigation.replace("TripOnboard");
        }
    }

    useEffect(() => {
        if (onboardTripId) { 
            getTripAndSetUser()
        } else if (username) { 
            getUser(username);
        } else { 
            showAlert("Sorry, something went wrong.")
            finish();
        }
        props.navigation.setOptions({
            headerRight: () => <Button onPress={finish} variant="link">Continue</Button>
        })
    }, [])

    return <Layout>
        {/* <Heading>Add your friend</Heading> */}
        {!user && <ActivityIndicator></ActivityIndicator>}
        {user && <View w="100%" height="80%" >
            
            <Text fontSize="lg" mt="6" mb="6" textAlign={"center"}>{`We think you might know ${user.first_name}. Add them to see their upcoming trips and join in!`}</Text>
            <SocialProfileView user={user} hideIGText ref={null} visible={true} 
            containerStyle={{borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', mt:4}}
            getBottomViewRender={() => <Button 
                mb="2" 
                onPress={addMainFriend} 
                w="100%" 
                colorScheme="rose" 
                isLoading={primaryActionLoading} 
                isDisabled={doneMap[user.username]}
                isLoadingText={"Loading..."}>{doneMap[user.username] ? "Added" : "Add friend"}</Button>}
            />

            {userFriends && userFriends.length > 0 && <View mt="3">
                <Text mb="3" fontWeight={"semibold"}>Others you might know</Text>
                <ScrollView horizontal>
                {userFriends.map((friendUser) => 
                    <SimpleUserView user={friendUser} viewProps={{borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', w: '120px', mr: 2}}
                    getBottomViewRender={() => <Button 
                        isLoading={loadingMap[friendUser.username]} 
                        isDisabled={doneMap[friendUser.username]}
                        onPress={() => addFriend(friendUser)} 
                        colorScheme="rose">{doneMap[friendUser.username] ? "Added" : "Add friend"}</Button>} /> )}
            </ScrollView>
            </View>}
            {/* <Button mt="2" onPress={finish} w="100%" colorScheme={"coolGray"} variant="subtle">Skip</Button> */}
        </View>}
    </Layout>
}