import { View, Text, Box, Container, HStack, Heading, Pressable, FormControl, Select, WarningOutlineIcon, CheckIcon, Input, Icon, TextArea, ScrollView, FlatList, Button, Badge, AlertDialog, Actionsheet } from "native-base";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Platform, View as ReactView, KeyboardAvoidingView, TextInput, StyleSheet, Keyboard, createElement, Alert, InputAccessoryView } from "react-native";
import Layout from "../components/Layout";
import { AntDesign, Entypo, Ionicons } from "@expo/vector-icons";
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import { DesktopView } from "../components/ViewTypes";
import ProfilePicture from "../components/ProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardAwareFlatList, KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import TripDetailsViewWithPreview from "../components/TripDetailsViewWithPreview";
import DateRangePicker from "react-native-daterange-picker";
import moment from "moment-timezone";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import TEXT from '../translations.json'
import { getDescriptiveStringForPhase, getRandomItem, showAlert } from "../utils";
import WebDatePicker from '../components/WebDatePicker'
import { setCreatedTripId } from "../store";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import PlacesAutoComplete from "../components/PlacesAutoComplete";
import { LoadingModal } from "../components/LoadingModal";
import { useFocusEffect } from "@react-navigation/native";
import TripPhaseModal from "../components/TripPhaseModal";


export default function TripDraftScreen(props) {

    type Target = "where" | "complete"
    const inputAccessoryViewID = 'uniqueID';

    const tripApi = new GenericViewSetAPI("trip")

    const returnEarly = props.route.params?.returnEarly ?? false;
    const cancelRef = React.useRef(null);

    const dispatch = useDispatch()

    const [tripId, setTripId] = useState(props.route.params && props.route.params.tripId);
    const [trip, setTrip] = useState(null);
    const [target, setTarget] = useState("where");
    const user = useSelector((s: any) => s.user);
    const [tripDetail, setTripDetails] = useState("")
    const [tripPhase, setTripPhase] = useState(Platform.OS === "web" ? "planning" : "thinking")
    const [goingBack, setGoingBack] = useState(false);
    const [publicity, setPublicity] = useState("friends")
    const [selectedPlace, setSelectedPlace] = useState(props.route.params?.place ? props.route.params.place.title : "");
    const [tripType, setTripType] = useState("soloesque")
    const [photoReference, setPhotoReference] = useState(props.route.params?.place ? props.route.params.place.photoReference : "")
    const [selectedPlaceObj, setSelectedPlaceObj] = useState(props.route.params?.place ?? null);
    const [startDate, setStartDate] = useState<Date>(null)
    const [endDate, setEndDate] = useState<Date>(null)
    const [displayedDate, setDisplayedDate] = useState(moment())
    const [showModal, setShowModal] = useState(null);
    const [placePhotos, setPlacePhotos] = useState(props.route.params?.photos ?? null);
    const [saving, setSaving] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [placeBBox, setPlaceBBox] = useState(props.route.params?.place ? props.route.params.place.bbox : null);
    const [location, setLocation] = useState(props.route.params?.place ? props.route.params.place.location : null);
    const [pictureUrl, setPictureUrl] = useState(null);
    const [tripPhaseModalOpen, setTripPhaseModalOpen] = useState(false);
    const [visibilityActionOpen, setVisibilityActionOpen] = useState(false);

    
    async function maybeGetTrip() {

        if (tripId) {
            props.navigation.setOptions({ title: 'Edit Trip' })
            setTripId(tripId);
            const tripResp = await tripApi.nonStandard("GET", `${tripId}/uuid`, null, { expansions: ['creator'] });
            console.log(tripResp);
            if (!tripResp.error) {
                setTrip(tripResp);
                setTripPhase(tripResp.phase);
                setTripDetails(tripResp.details);
                setSelectedPlace(tripResp.place);
                setPublicity(tripResp.visibility?.toLowerCase() ?? "friends");
                setSelectedPlaceObj(tripResp);
                if (tripResp.start_date) {
                    setStartDate(new Date(tripResp.start_date));
                }
                if (tripResp.end_date) {
                    setEndDate(new Date(tripResp.end_date))
                }
            } else {
                showAlert("Sorry something went wrong while loading trip! Try again later.");
            }
        }
    }

    useEffect(() => {
        AnalyticsManager.logEvent(events.USER_LOADED_TRIP_DRAFT);
    }, [])

    useFocusEffect(React.useCallback(() => {
        maybeGetTrip();
    }, []))

    function goToNextStep(trip, extraData={}) {
        if (returnEarly) { 
            props.navigation.goBack();
        } else {
            props.navigation.navigate("TripEvents", {trip: trip, creatingTrip: true, ...extraData})
        }
    }

    function isEdit() { 
        return trip;
    }

    async function deleteTrip() {
        setDeleteLoading(true);
        const deleteResp = await tripApi.delete(trip.id);
        console.log(deleteResp);
        if (deleteResp.error) {
            showAlert("Something went wrong while canceling the trip. Try again later");
        } else {
            showAlert("Your trip has been canceled. People interested will be notified");
            props.navigation.navigate("Root", {screen: "Home"});
        }
        AnalyticsManager.logEvent(events.USER_DELETED_TRIP);
        setIsDeleteDialogOpen(false);
        setDeleteLoading(false);
    }

    async function handleOkay() {
        let canProcess = true;
        if (!selectedPlace) { 
            showAlert("Please enter a place for this trip!");
            canProcess = false;
        }

        if (!canProcess) { 
            setSaving(false);
            return;
        }

        const tripBody: any = {
            place: selectedPlace,
            phase: tripPhase.toUpperCase(),
            start_date: startDate,
            end_date: endDate,
            details: tripDetail ? tripDetail : "-",
            placeid: selectedPlaceObj.placeid ?? selectedPlaceObj.place_id,
            place_id: selectedPlaceObj.placeid ?? selectedPlaceObj.place_id,
            visibility: publicity.toUpperCase()
        }
        
        if (location) {
            tripBody.lat = location.lat
            tripBody.lng = location.lng
            tripBody.location = location;
        }
        if (placeBBox) {
            tripBody.bbox = placeBBox
        }
        if (photoReference) { 
            tripBody.photo_reference = photoReference;
            tripBody.picture_url = pictureUrl
        }

        if (!isEdit()) {
            tripBody.published = false;
            const createTripResp = await tripApi.create(tripBody, { expansions: ['creator'] });
            console.log('create trip response', createTripResp)
            if (!createTripResp.error) {
                AnalyticsManager.logEvent(events.USER_CREATED_TRIP);
                createTripResp.creator = user
                setTrip(createTripResp)
                goToNextStep(createTripResp, {tripBody: tripBody, createdTrip: createTripResp, placePhotos: placePhotos});
            } else {
                showAlert("Something went wrong when creating your trip! Try again!");
            }
        } else {
            console.log("UPDATING TRIP WITH BODY", tripBody);
            const updateResp = await tripApi.update(trip.id, tripBody);
            console.log('update trip resp', updateResp);
            var updatedTrip = updateResp;
            if (!updateResp.error) {
                AnalyticsManager.logEvent(events.USER_UPDATED_TRIP);
                const tripResp = await tripApi.nonStandard("GET", `${tripId}/uuid`, null, { expansions: ['creator'] });
                if (tripResp && !tripResp.error) { 
                    updatedTrip = tripResp
                }
                goToNextStep(updatedTrip, {tripBody: tripBody, updatedTrip: updateResp});
            } else {
                showAlert("Something went wrong when updating your trip! Try again!")
            }
        }


        console.log(tripBody)
        // goToNextStep({trip: tripBody, placePhotos: placePhotos})
        setSaving(false)
    }

    function setDates(dates) {
        if (dates.startDate) {
            setStartDate(dates.startDate)
        }

        if (dates.displayedDate != undefined) {
            setDisplayedDate(dates.displayedDate)
        }

        if (dates.endDate) {
            setEndDate(dates.endDate);
            setShowModal(false);
            setTimeout(() => {
                setShowModal(null);
            }, 1000)
        }
    }

    useEffect(() => {
        if (goingBack) { 
            if (trip && !trip.published) { 
                showAlert("Your trip has not been published and will be saved as a draft.");
                props.navigation.navigate("Root", {screen: "Trips"});
            } else { 
                props.navigation.navigate("Root")
            }
        }
    }, [goingBack])

    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button _text={{fontSize: 'md'}} isLoading={saving} variant={"link"} onPress={() => setSaving(true)}>Continue</Button>,
            headerLeft: () => <Button ml="-3" _text={{fontSize: 'md'}} leftIcon={<Icon as={Ionicons} name="arrow-back-circle" size="2xl" color="black"></Icon>} onPress={() => {
                AnalyticsManager.logEvent(events.USER_EXIT_TRIP_DRAFT);
                setGoingBack(true);
            }} variant="link"></Button>
        })
    }, [props.navigation])

    
    useEffect(() => {
        if (saving) {
            handleOkay()
        }

    }, [saving])

    function getDatesLabel() {
        //TODO: Add locale
        if (!startDate) {
            return "+ add dates"
        } else if (startDate && endDate) {
            var dateStr = moment(startDate).format("ll")
            if (!moment(startDate).isSame(endDate)) {
                dateStr += " - " + moment(endDate).format('ll')
            }
            return dateStr
        }
    }

    function handleDeleteDialogClose() {
        setIsDeleteDialogOpen(false)
    }

    const scrollEnabled = Platform.OS === "web"

    return <Layout hideFooter containerW="100%">
        {target === "where" && <KeyboardAwareScrollView
            extraHeight={100}
            extraScrollHeight={20}
            keyboardShouldPersistTaps={"always"}
            style={{ width: '100%',  margin: 0 }}
        >
            <View p="4" minH="800">
                <HStack space={2} flexDirection={"row"} mb="2" w="100%">
                    <ProfilePicture user={user}></ProfilePicture>
                    <View>
                        <Text fontSize={"lg"}>{user.first_name}</Text>
                        <Text>@{user.username}</Text>
                    </View>
                    {isEdit() && <View flex={1}>
                        <Button alignSelf={"flex-end"} colorScheme={"secondary"} isLoading={deleteLoading} onPress={() => setIsDeleteDialogOpen(true)}>{TEXT.Delete}</Button>
                    </View>}
                </HStack>

                <Pressable onPress={() => setVisibilityActionOpen(true)}><Badge maxW="90" mt={2} leftIcon={<Icon as={Ionicons} name="eye" />} colorScheme="coolGray" rounded={"lg"}>{publicity}</Badge></Pressable>
                <HStack style={{ alignItems: 'center' }} mt="2" mb="3" >
                    <Text fontSize="lg">{TEXT.Im} </Text>
                    {Platform.OS !== "web" && <><FormControl flex={1} maxW={135}>
                        <Select variant="filled" selectedValue={tripPhase ? tripPhase.toLowerCase() : 'thinking'} fontSize={"md"}
                            onValueChange={(value) => setTripPhase(value)}
                            dropdownIcon={<Icon as={AntDesign} name="caretdown" size={3} mr="1" ml="0" pl="0" color="black" />}
                            mt="1">
                            <Select.Item label={getDescriptiveStringForPhase(TEXT.THINKING)} value="thinking" ></Select.Item>
                            <Select.Item label={getDescriptiveStringForPhase(TEXT.PLANNING)} value="planning" />
                            <Select.Item label={getDescriptiveStringForPhase(TEXT.BOOKING)} value="booking" />
                        </Select>
                    </FormControl> 
                    <Pressable onPress={() => {
                                            AnalyticsManager.logEvent(events.TRIP_PHASE_INFO_CLICK)
                                            setTripPhaseModalOpen(true)
                    }}><Icon as={Entypo} size={"sm"} name="info-with-circle" mt={1.5} ml="1" mr="1" /></Pressable>
                    </>}
                    {Platform.OS === "web" && <Text fontSize="lg">planning</Text>}
                    <Text fontSize="lg"> {TEXT.aTripTo}</Text>
                </HStack> 

                 <ReactView style={{ width: '100%', flexDirection: 'row', marginBottom: 20 }} >
                    <PlacesAutoComplete 
                        selectedPlace={selectedPlace}
                        placeholder={selectedPlace ? selectedPlace : TEXT.EnterAPlace + "..."}
                        onPhotosLoaded={(photos) =>  setPlacePhotos(photos)}
                        onPlaceSelected={(dataObj) => {
                            setPhotoReference(dataObj.photoReference);
                            setSelectedPlace(dataObj.title);
                            setPlaceBBox(dataObj.bbox);
                            setLocation(dataObj.location);
                            setPictureUrl(dataObj.picture_url);
                            setSelectedPlaceObj(dataObj);
                        }}
                    />
                </ReactView> 

                {Platform.OS !== "web" && <DateRangePicker
                    containerStyle={{ marginTop: 5 }}
                    onChange={setDates}
                    startDate={startDate}
                    endDate={endDate}
                    displayedDate={displayedDate}
                    range
                    open={showModal}
                    onOpenTriggered={() => Keyboard.dismiss()}
                >
                    <Badge colorScheme={"rose"} h="8">{getDatesLabel()}</Badge>
                </DateRangePicker>} 

                {Platform.OS === "web" && <>
                    <Text mb={2} mt={0}>Dates</Text>
                    <HStack space={2} alignItems="center">
                        <WebDatePicker placeholder="Start date.." date={startDate} onChange={(date) => setStartDate(date)} /> 
                        <Text>to</Text>
                        <WebDatePicker placeholder="End date.." date={endDate} onChange={(date) => setEndDate(date)} /></HStack>
                    </>}

                <TextInput multiline style={{ marginTop: 20, fontSize: 16 }} value={tripDetail} 
                    scrollEnabled={scrollEnabled}
                    inputAccessoryViewID={inputAccessoryViewID}
                    numberOfLines={Platform.OS === "web" ? 6 : null}
                    placeholderTextColor="gray" 
                    onChangeText={(text) => setTripDetails(text)}
                    placeholder={TEXT.TellEveryoneAboutTrip}></TextInput>

                {Platform.OS === "web" && <Button w="90%" maxW="400" mt="3" onPress={() => handleOkay()}>{TEXT.continue}</Button>}

                <TripDetailsViewWithPreview data={tripDetail} doNotRenderText></TripDetailsViewWithPreview>
            </View>
        </KeyboardAwareScrollView>}

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={deleteDialogOpen} onClose={handleDeleteDialogClose}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Delete Trip</AlertDialog.Header>
                <AlertDialog.Body>
                    This will delete all data relating to this Trip. This action cannot be
                    reversed. Any deposits paid by attendees will be refunded. Deleted data can not be recovered.
                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={handleDeleteDialogClose} ref={cancelRef}>
                            Cancel
                        </Button>
                        <Button colorScheme="danger" onPress={deleteTrip}>
                            Delete
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>

        <Actionsheet isOpen={visibilityActionOpen} onClose={() => setVisibilityActionOpen(false)}>
            <Actionsheet.Content>
                <Actionsheet.Item onPress={() => {
                    setPublicity("friends");
                    setVisibilityActionOpen(false);
                }}>
                    <View>
                        <Text>Friends</Text>
                        <Text color="gray.500" fontSize="sm">Only friends can see this trip</Text>
                    </View>
                </Actionsheet.Item>

                <Actionsheet.Item onPress={() => {
                    setPublicity("unlisted");
                    setVisibilityActionOpen(false);
                }}> 
                    <View>
                        <Text>Unlisted</Text>
                        <Text color="gray.500" fontSize="sm">Only people you share this trip with will be able to see it</Text>
                    </View>
                </Actionsheet.Item>
            </Actionsheet.Content>
        </Actionsheet>

        <LoadingModal visible={saving} />
        <TripPhaseModal isOpen={tripPhaseModalOpen} onClose={() => setTripPhaseModalOpen(false)} />

        {Platform.OS == "ios" && <InputAccessoryView nativeID={inputAccessoryViewID}>
            <View w="100%" bg="white" display="flex" alignItems={"flex-end"}>
                <Button w="25%" variant="link" onPress={() => Keyboard.dismiss()}>Done</Button>
            </View>
        </InputAccessoryView>}
    </Layout>
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
    },
});
