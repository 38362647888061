import { Button, View } from "native-base";
import React from "react";
import { InputAccessoryView, Keyboard, Platform } from "react-native";

export default function DoneInputAccessory(props) { 
    if (Platform.OS !== "ios") { 
        return null;
    }

    return <InputAccessoryView nativeID={"done"}>
    <View w="100%" bg="white" display="flex" alignItems={"flex-end"}>
        <Button w="25%" variant="link" onPress={() => Keyboard.dismiss()}>Done</Button>
    </View>
</InputAccessoryView>
}