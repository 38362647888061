import { Button, Pressable, Text } from "native-base";
import React, { useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { getErrorString, showAlert } from "../utils";
import * as WebBrowser from "expo-web-browser"
import { Linking, Platform } from "react-native";

export default function StripeConnectDashboardVC(props) { 

    const [loading, setLoading] = useState(false)

    const stripeApi = new GenericViewSetAPI("stripe")

    const onPress = async () => {    
        setLoading(true)
        if (props.onLoadStart) { 
            props.onLoadStart()
        }
        const dashboardResp = await stripeApi.nonStandard("GET", "dashboard_link");
        if (dashboardResp && !dashboardResp.error) { 
            if (Platform.OS !== "web") {
                WebBrowser.openBrowserAsync(dashboardResp.link)
            } else {
                Linking.openURL(dashboardResp.link)
            }
        } else { 
            const errorStr = getErrorString(dashboardResp.error, "Sorry, something went wrong. Make sure you have completed onboarding and try again")
            showAlert(errorStr)
        }
        setLoading(false)
        if (props.onLoadEnd) { 
            props.onLoadEnd()
        }
    }
    if (props.getRender) { 
        return props.getRender(onPress)
    }

    if (props.useUnderlineText) { 
        return <Pressable disabled={loading} {...props.viewProps} onPress={onPress}><Text underline>{loading ? "Loading..." : "View payments dashboard"}</Text></Pressable>
    }
    return <Button onPress={onPress} isLoading={loading} {...props.viewProps}>View payments dashboard</Button>
}