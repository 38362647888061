import { Heading, Text, Button, View, AlertDialog } from "native-base";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../components/Layout";
import { Screens } from "../constants/Screens";
import { registerForPushNotificationsAsync } from "../utils";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";

export default function NotificationsScreen(props) {
    
    const onboardTripId = useSelector((state: any) => state.onboardTripId);
    const onboardAddedFriend = useSelector((state: any) => state.onboard.addedFriend)
    const cancelRef = React.useRef(null);

    const [deleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const ignoreNotifs = () => { 
        AnalyticsManager.logEvent(events.USER_DENIED_NOTIFICATION);
        if (onboardAddedFriend) { 
            AnalyticsManager.logEvent(events.USER_LOAD_ARE_YOU_SURE_NOTIFICATION);
            setIsDeleteDialogOpen(true);
        } else { 
            finish();
        }
    }


    const finish = () => {
        if (onboardTripId) { 
            props.navigation.replace(Screens.Root, {screen: Screens.Home, params: { profileBranchOpenWait: onboardAddedFriend ? 1000 : 0}});
        } else { 
            props.navigation.replace(Screens.TripOnboard);
        }
    }

    const turnOnNotifs = () => {
        AnalyticsManager.logEvent(events.USER_TURNED_ON_NOTIFICATION);
        registerForPushNotificationsAsync().then(token => finish());
    }

    const onCloseDialog = () => { 
        setIsDeleteDialogOpen(false);
    }

    return <Layout>
        <Heading mb="3" mt="2">Stay in the know</Heading>
        <Text fontSize="md" mb="3">We'll let you know about trip RSVPs, reminders, cancellations, refunds and other important things!</Text>
        <Text fontSize={"xs"} underline>We won't ever spam you.</Text>
        <View mt="5" w="100%">
            <Button mb="1" colorScheme={"rose"} onPress={turnOnNotifs}>Turn on Notifications</Button>
            <Button colorScheme="coolGray" variant="subtle" onPress={ignoreNotifs}>No thanks</Button>
        </View>


        <AlertDialog leastDestructiveRef={cancelRef} isOpen={deleteDialogOpen} onClose={onCloseDialog}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Are you sure?</AlertDialog.Header>
                    <AlertDialog.Body>
                        You added your friend, but without notifications we can't let you know when they accept your friend request!
                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={finish} ref={cancelRef}>
                            No thanks
                        </Button>
                        <Button colorScheme="danger" onPress={turnOnNotifs}>
                            Allow notifications
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>


    </Layout>
}