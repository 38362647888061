import AsyncStorage from "@react-native-async-storage/async-storage";


class BlockList { 

    static instance = null;
    private blockList = {}

    constructor() { 
        if (!BlockList.instance) { 
            BlockList.instance = this;
        } 
        return BlockList.instance;
    }

    blockUser(userId) { 
        this.blockList[userId] = true;
        this.saveBlockList();
    }

    unblockUser(userId) {
        delete this.blockList[userId];
        this.saveBlockList();
    }

    isBlocked(userId) { 
        return userId in this.blockList;
    }

    saveBlockList() { 
        AsyncStorage.setItem("block_list", JSON.stringify(this.blockList))
    }

    async loadBlocklist() { 
        let blockListStr = await AsyncStorage.getItem("block_list");
        if (blockListStr) { 
            this.blockList = JSON.parse(blockListStr);
        }
    }
}

const Blocklist = new BlockList();
export default Blocklist;