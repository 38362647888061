import { FormControl, HStack, Select, Text, View, Icon, ScrollView, Image, Button, Pressable } from "native-base"
import React, { useEffect, useState } from "react"
import { ActivityIndicator, Dimensions, Platform } from "react-native"
import NumberOfStars from "./NumberOfStars"
import { AnalyticsManager, events as analyticEvent } from "../utils/AnalyticsManager";
import { AntDesign } from "@expo/vector-icons"
import { parseTripFromPlacesJson } from "../utils";
import TripCell from "./TripListCell";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import { maybeWrapWithWtihWebLink } from "../utils/views";


export default function SuggestionRow(props) { 

    const categorySuggestionLabelMap = {
        "tourist_attraction": "attractions"
    }

    const [suggestionCategory, setSuggestionCategory] = useState("tourist_attraction")
    const [suggestionLoading, setSuggestionLoading] = useState(false)
    const [suggestions, setSuggestions] = useState(props.suggestions ?? [])
    const [currentSuggestPlace, setCurrentSuggestPlace] = useState(props.currentSuggestPlace)
    const [placeMap, setPlaceMap] = useState({})

    const getSuggestions = async () => {
        setSuggestionLoading(true)
        var url = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=top+${categorySuggestionLabelMap[suggestionCategory] ?? suggestionCategory}+in+${props.place}+&type=${suggestionCategory}&key=AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac&location=${props.lat},${props.lng}`
        if (Platform.OS === "web") { 
            url = `${BaseApiConfigProvider.getEnvUrl()}/proxy/?json=true&base64=${btoa(url)}`;
        }
        const suggestions = await fetch(url);
        console.log('suggestions resp', suggestions)
        if (suggestions) {
            let json = await suggestions.json();
            if (json) {
                var sorted = json.results;
                sorted = sorted.filter((item) => item.photos && item.photos.length > 0)
                const topFive = sorted.slice(0, 10)
                const suggestions = topFive.map((item) => parseTripFromPlacesJson(item))
                console.log(suggestions)
                setSuggestions(suggestions)
            }
        }
        setSuggestionLoading(false);
    }

    useEffect(() => {
        getSuggestions()
        
    }, [suggestionCategory])


    return <View mb="2" >
    <HStack space={2} style={{ alignItems: 'center' }} mb="2">
        <Text fontSize="md" color="coolGray.700">Suggestions</Text>
        <FormControl flex={1} maxW={135}>
            <Select variant="filled" selectedValue={suggestionCategory} fontSize={"sm"}
                onValueChange={(value) => setSuggestionCategory(value)}
                dropdownIcon={<Icon as={AntDesign} name="caretdown" size={3} mr="1" ml="0" pl="0" color="black" />}
                mt="1">
                <Select.Item label="Food" value="restaurant" ></Select.Item>
                <Select.Item label="Attractions" value="tourist_attraction" />
                <Select.Item label="Art musuems" value="museum" />
                <Select.Item label="Nightlife" value="night_club" />
                <Select.Item label="Bars" value="bar" />
            </Select>
        </FormControl>

        {props.showSecondary && <FormControl flex={1} maxW={100}>
            <Select variant="filled" selectedValue={currentSuggestPlace?.place ?? {place: props.place, lat: props.lat, lng: props.lng}} fontSize={"sm"}
                onValueChange={(value) => {
                    AnalyticsManager.logEvent(analyticEvent.USER_CHANGED_SUGGESTION_LOCATION)
                    setCurrentSuggestPlace(props.placesMap[value])}
                    }
                    dropdownIcon={<Icon as={AntDesign} name="caretdown" size={3} mr="1" ml="0" pl="0" color="black" />}
                    mt="1">
                        <Select.Item label={props.place} value={props.place} ></Select.Item>
                        {props.events && props.events.map((e) => <Select.Item label={e.title} value={e.title} ></Select.Item>)}
            </Select>
        </FormControl>}

    </HStack>
    {suggestionLoading && <ActivityIndicator></ActivityIndicator>}
    <View maxH="300">
        {!suggestionLoading && <ScrollView horizontal pb="2" showsHorizontalScrollIndicator={false}>
            {suggestions.map((item) => <Pressable pr="2" maxWidth="250" onPress={() => {
                AnalyticsManager.logEvent(analyticEvent.USER_CLICKED_TRIP_EVENT_SUGGESTION, {"source": props.source, "isSubmission": props.submit})
                if (props.onSuggestionPressed) { 
                    props.onSuggestionPressed(item)
                }
                }}>
                <Image source={{ uri: item.picture_url }} height={180} width={250} borderRadius={"md"} resizeMode="cover" />
                <Text pt="2" numberOfLines={1}>{item.title}</Text>
                <HStack>
                    <NumberOfStars rating={item.rating} />
                    <Text fontSize="xs">({item.reviews})</Text>
                </HStack>
                {props.deepLinkUrl && maybeWrapWithWtihWebLink(
                    <Button mt="2" colorScheme="rose" variant="subtle" onPress={() => {
                        props.onSuggestionSelected(item)
                    }}>{props.submit ? "Suggest" : "Add to trip"}</Button>, 
                    props.deepLinkUrl)}
                {!props.deepLinkUrl && <Button mt="2" colorScheme="rose" variant="subtle" onPress={() => {
                        props.onSuggestionSelected(item)
                    }}>{props.submit ? "Suggest" : "Add to trip"}</Button>}
            </Pressable>)}
            </ScrollView>}
        </View>
</View>

}


