

export enum Screens { 
    Inbox = "Inbox",
    Web = "Web",
    MobileVerification = "MobileVerification",
    UpdateRequired = "UpdateRequired",
    Contacts = "Contacts",
    TripReview = "TripReview",
    TripEvents = "TripEvents",
    OnboardNotification = "OnboardNotification",
    OnboardFriend = "OnboardFriend",
    PublicProfile = "PublicProfile",
    CountriesVisited = "CountriesVisited",
    Persona = "Persona",
    PersonaQuiz = "PersonaQuiz",
    UserList = "UserList",
    ChatScreen = "ChatScreen",
    TripOnboard = "TripOnboard",
    Wishlist = "Wishlist",
    EditProfile = "EditProfile",
    PublicTripDetail = "PublicTripDetail",
    TripDetail = "TripDetail",
    WishlistShare = "WishlistShare",
    CreateModal = "CreateModal",
    MobileVerificationModal = "MobileVerificationModal",
    PaymentsOnboard = "PaymentsOnboard",
    TripsShareScreen = "TripsShareScreen",
    Home = "Home",
    Login = "Login",
    Profile = "Profile",
    Root = "Root",
    Trips = "Trips",
    Search = "Search",
    Create = "Create"
}