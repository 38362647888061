import { AntDesign } from "@expo/vector-icons";
import { Center, HStack, View, Text, Button, Heading, Modal, ScrollView, Image, Icon, Select, FormControl, Switch, Pressable, Input, TextArea, Badge, Container, FlatList } from "native-base";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { ActivityIndicator, InputAccessoryView, Keyboard, SafeAreaView, View as ReactView, Platform  } from "react-native";
import Layout from "../components/Layout";
import PlacesAutoComplete from "../components/PlacesAutoComplete";
import useViewType from "../hooks/useViewType";
import { openBrowserLinkForPlaceId, parseTripFromPlacesJson, showAlert } from "../utils";
import DateRangePicker from "react-native-daterange-picker";
import moment from "moment-timezone";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import DoneInputAccessory from "../components/DoneInputAccessory";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { generateRandom } from "expo-auth-session/build/PKCE";
import { useFocusEffect } from "@react-navigation/native";
import { LoadingModal } from "../components/LoadingModal";
import { AnalyticsManager, events as analyticEvent } from "../utils/AnalyticsManager";
import NumberOfStars from "../components/NumberOfStars";
import * as WebBrowser from 'expo-web-browser';
import { useSelector } from "react-redux";
import TripEventCell from "../components/TripEventCell";
import SuggestionResponseRowVC from "../controllers/SuggestionResponseRowVC";
import IGShareableViewModalVC from "../controllers/IGShareableViewModalVC";
import SocialTripRow from "../components/SocialTripRow";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import SuggestionRow from "../components/SuggestionRow";
import WebDatePicker from "../components/WebDatePicker";


export default function TripEventsScreen(props) {
    const tripEventApi = new GenericViewSetAPI("trip_event")
    const tripApi = new GenericViewSetAPI("trip")

    const categorySuggestionLabelMap = {
        "tourist_attraction": "attractions"
    }

    const user = useSelector((state: any) => state.user)

    const creatingTrip = props.route.params?.creatingTrip
    const tripId = props.route.params?.tripId
    const returnEarly = props.route.params?.returnEarly ?? false;

    const [trip, setTrip] = useState(props.route.params?.trip);
    const [itineraryLink, setItineraryLink] = useState(props.route.params?.itineraryLink ?? props.route.params?.trip?.itinerary_link ?? null);
    const placePhotos = props.route.params?.placePhotos
    const [suggestions, setSuggestions] = useState([]);
    const [events, setEvents] = useState([])
    const [modalOpen, setIsModalOpen] = useState(props.route.params?.selectedPlaceObj ? true : false);
    const [eventDetails, setEventDetails] = useState('');
    const [suggestionCategory, setSuggestionCategory] = useState("tourist_attraction")
    const [showDateModal, setShowDateModal] = useState(null);
    const [smallDateModalOpen, setSmallDateModalOpen] = useState(null);
    const [startDate, setStartDate] = useState<Date>(null)
    const [endDate, setEndDate] = useState<Date>(null)
    const [displayedDate, setDisplayedDate] = useState(moment())
    const [selectedPlaceObj, setSelectedPlaceObj] = useState(props.route.params?.selectedPlaceObj ?? null);
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [suggestionLoading, setSuggestionLoading] = useState(false)
    const [currentSuggestPlace, setCurrentSuggestPlace] = useState(trip ? {place: trip.place, lat: trip.lat, lng: trip.lng} : {})
    const [placesMap, setPlacesMap] = useState({})
    const [reloadSuggestionResponse, setReloadSuggestionResponse] = useState(false)
    const [selectedEventMembersOnly, setSelectedEventMembersOnly] = useState(false)
    const [showShareTripModal, setShowShareTripModal] = useState(creatingTrip ?? false)

    const isSubmission = () => {
        return trip.creator_id !== user.id
    }

    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerRight: () => <Button onPress={() => setSaving(true)}
                variant="link" _text={{fontSize: 'md'}}>Continue</Button>
        })
    }, [])

    useEffect(() => {
        const selectedObj = props.route.params?.selectedPlaceObj
        console.error('recieved select object is ', selectedObj)
        if (props.route.params?.selectedPlaceObj) { 
            viewExistingEvent(selectedObj)
        } 
    }, [])

    const handleSaving = async () => {
        if (itineraryLink !== trip.itinerary_link) { 
            console.error("ITINERARY LINK CHANGE. UPDATING TRIP.")
            // TODO: Update trip.
            const updateResp = await tripApi.update(trip.id, {itinerary_link: itineraryLink});
            console.log(updateResp)
            if (!updateResp || updateResp.error) {
                showAlert("Sorry, we failed to update the itinerary link. Try again later.")
                return;
            }
        }
        if (returnEarly) { 
            props.navigation.goBack();
        } else if (creatingTrip) { 
            props.navigation.navigate("TripReview", { events: events, trip: trip, placePhotos: placePhotos });
        } else { 
            props.navigation.goBack();
        }
        setTimeout(() => setSaving(false), 1000)
    }

    useEffect(() => {
        if (saving) { 
            handleSaving()
        }
    }, [saving])


    const getTrip = async() => {
        const tripResp = await tripApi.nonStandard("GET", `${tripId}/uuid`, null, {expansions : ["creator"]}) 
        console.log('trip RESP IS ', tripResp)
        if (tripResp && !tripResp.error) { 
            setTrip(tripResp)
            setItineraryLink(tripResp.itinerary_link)
        } else { 
            console.warn(tripResp)
            showAlert("Sorry, we failed to load the trip. Try again later")
            props.navigation.goBack()
        }
    }


    useEffect(() => {
        if (!trip) {
            if (!tripId) { 
                showAlert("Sorry, no trip found");
                props.navigation.goBack()
            } else { 
                getTrip()
            }
        }
        if (Platform.OS === "web" && tripId) { 
            getTrip();
        }

    }, [])

    useEffect(() => {
        if (trip) { 
            setCurrentSuggestPlace({place: trip.place, lat: trip.lat, lng: trip.lng})
        }

    }, [trip])

    function setDates(dates, ignoreFinish = false) {

        const finish = () => {
            setShowDateModal(false);
            setSmallDateModalOpen(false);
            setIsModalOpen(true);
            setTimeout(() => {
                setShowDateModal(null);
                setSmallDateModalOpen(null);
            }, 1000)
        }

        if (dates.startDate) {
            setStartDate(dates.startDate)
            // setEndDate(dates.startDate)
            if (!ignoreFinish){
                finish()
            }
        }

        if (dates.displayedDate != undefined) {
            setDisplayedDate(dates.displayedDate)
        }

        if (dates.endDate) {
            setEndDate(dates.endDate);
            if (!ignoreFinish) { 
                finish()
            }
        }
    }

    const getSuggestions = async () => {
        setSuggestionLoading(true)  
        var url = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=top+${categorySuggestionLabelMap[suggestionCategory] ?? suggestionCategory}+in+${currentSuggestPlace.place}+&type=${suggestionCategory}&key=AIzaSyDQ2r7QSoHIKlZJPp19Aj2dnYcZYO54Aac&location=${currentSuggestPlace.lat},${currentSuggestPlace.lng}`
        if (Platform.OS === "web") { 
            url = `${BaseApiConfigProvider.getEnvUrl()}/proxy/?json=true&base64=${btoa(url)}`;
        }
        const suggestions = await fetch(url);
        // console.log(suggestions)
        if (suggestions) {
            let json = await suggestions.json();
            if (json) {
                var sorted = json.results;
                sorted = sorted.filter((item) => item.photos && item.photos.length > 0)

                if (sorted.length === 0) { 
                    AnalyticsManager.logEvent(analyticEvent.NO_SUGGESTIONS_LOADED, {category: suggestionCategory, place: currentSuggestPlace.place})
                }
                const topFive = sorted.slice(0, 10)
                const suggestions = topFive.map((item) => parseTripFromPlacesJson(item))
                console.log(suggestions)
                setSuggestions(suggestions)
            }
        }
        setSuggestionLoading(false);
    }

    useEffect(() => {
        if (currentSuggestPlace) { 
            getSuggestions()
        }

    }, [suggestionCategory, currentSuggestPlace])


    const selectSuggestion = (suggestion) => { 
        setSelectedPlaceObj(suggestion);
        setIsModalOpen(true)
    }

    useFocusEffect(
        React.useCallback(() => {
            loadEvents();
        }, 
        [trip])
    )

    const closeModal = () => {
        setSelectedPlaceObj(null);
        setEventDetails('');
        setDates({startDate: null, endDate: null, displayedDate: null})
        setIsModalOpen(false);
        setLoading(false);
        setDeleteLoading(false)
    }

    const renderItem = (item: any) => {
        if (item.item.info) {
            return <View pb="5">
                {Platform.OS === "web" && <Button maxH="60" maxW="100" mt={2} mb={4} rounded="full" colorScheme={"rose"} onPress={() => setSaving(true)}>Continue</Button>}

                <FormControl>
                    <FormControl.Label>Itinerary link</FormControl.Label>
                    <Input value={itineraryLink} onChangeText={(val) => setItineraryLink(val)} placeholder="Link to google doc, or another website..."/>
                    <FormControl.HelperText>Add a link to an outside itinerary if you already have one</FormControl.HelperText>
                </FormControl>
                {/* <Heading>{`Trip to ${trip.place}`}</Heading> */}
            </View>
        }

        if (item.item.submittedSection) { 
            if (trip.creator_id == user.id) { 
                return <SuggestionResponseRowVC refreshSignal={reloadSuggestionResponse} onPress={(event) => viewExistingEvent(event)} allowResponse tripId={trip.uuid} hideIfEmpty renderTitle={() => <Heading fontSize="lg" mb={1}>Submitted suggestions</Heading>} />
            } else { 
                return <View></View>;
            }
        }
        
        if (item.item.suggestion) {
            return <View mb="40"  w="100%">
                {/* <View>
                <SuggestionRow showSecondary trip={trip} currentSuggestPlace={currentSuggestPlace} events={events} placesMap={placesMap}
                    place={trip.place} submit={isSubmission()} lat={trip.lat} lng={trip.lng} source="TripEvents"
                    onSuggestionPressed={(sug) => openBrowserLinkForPlaceId(sug.place_id, sug.address) }
                    onSuggestionSelected={(sug) => selectSuggestion(sug)}
                />
                </View> */}
                <HStack space={2} style={{ alignItems: 'center' }} mb="2">
                    <Text fontSize="md" color="coolGray.700">Suggestions</Text>
                    <FormControl flex={1} maxW={135}>
                        <Select variant="filled" selectedValue={suggestionCategory} fontSize={"sm"}
                            onValueChange={(value) => {
                                AnalyticsManager.logEvent(analyticEvent.USER_CHANGED_SUGGESTION_CATEGORY)
                                setSuggestionCategory(value)
                            }}
                            dropdownIcon={<Icon as={AntDesign} name="caretdown" size={3} mr="1" ml="0" pl="0" color="black" />}
                            mt="1">
                            <Select.Item label="Food" value="restaurant" ></Select.Item>
                            <Select.Item label="Attractions" value="tourist_attraction" />
                            <Select.Item label="Art musuems" value="museum" />
                            <Select.Item label="Nightlife" value="night_club" />
                            <Select.Item label="Bars" value="bar" />
                        </Select>
                    </FormControl>

                    <FormControl flex={1} maxW={100}>
                        <Select variant="filled" selectedValue={currentSuggestPlace?.place} fontSize={"sm"}
                            onValueChange={(value) => {
                                AnalyticsManager.logEvent(analyticEvent.USER_CHANGED_SUGGESTION_LOCATION)
                                setCurrentSuggestPlace(placesMap[value])}
                            }
                            dropdownIcon={<Icon as={AntDesign} name="caretdown" size={3} mr="1" ml="0" pl="0" color="black" />}
                            mt="1">
                                <Select.Item label={trip.place} value={trip.place} ></Select.Item>
                                {events.map((e) => <Select.Item label={e.title} value={e.title} ></Select.Item>)}
                        </Select>
                    </FormControl>

                </HStack>
                {suggestionLoading && <ActivityIndicator></ActivityIndicator>}
                {!suggestionLoading && <ScrollView horizontal pb="10">
                    {suggestions.map((item) => <Pressable pr="2" maxWidth="250" onPress={() => {
                        AnalyticsManager.logEvent(analyticEvent.USER_CLICKED_TRIP_EVENT_SUGGESTION)
                        openBrowserLinkForPlaceId(item.place_id, item.address)
                        }}>
                        <Image source={{ uri: item.picture_url }} height={180} width={250} borderRadius={"md"} resizeMode="cover" />
                        <Text pt="2" numberOfLines={1}>{item.title}</Text>
                        <HStack>
                            <NumberOfStars rating={item.rating} />
                            <Text fontSize="xs">({item.reviews})</Text>
                        </HStack>
                        <Button mt="2" colorScheme="rose" variant="subtle" onPress={() => {
                            AnalyticsManager.logEvent(analyticEvent.USER_ADDED_TRIP_EVENT_SUGGESTION)
                            selectSuggestion(item)
                        }}>{isSubmission() ? "Suggest" : "Add to trip"}</Button>
                    </Pressable>)}
                    </ScrollView>} 
            </View>
        }
        const itemData = item.item;
        return <TripEventCell event={itemData} onPress={() => viewExistingEvent(itemData)}/>
    }

    const loadEvents = async () => {
        if (!trip) { 
            return;
        }

        const queryData : any = {trip_id: trip.uuid, expansions: ["creator"]}
        if (isSubmission()) { 
            queryData.include_my_submitted = true
        }
        const eventsResp = await tripEventApi.query(queryData)
        console.log('load events resp', eventsResp)
        if (!eventsResp || eventsResp.error) { 
            showAlert("Something went wrong while refreshing itinerary. Please try again");
            return;
        }
        setEvents(eventsResp.results);
        const newMap = {}
        eventsResp.results.map((e) => newMap[e.title] = e)
        setPlacesMap(newMap)
    }

    async function createOrUpdateEvent(extraData={}) {
        if (!selectedPlaceObj) { 
            showAlert("Please select a place from the search");
            return;
        }
        setLoading(true); 

        console.log('TRIPID =', trip.id);
        const tripEvent = {
            start_at: startDate ?? selectedPlaceObj?.start_at,
            end_at: endDate ?? selectedPlaceObj?.end_at ,
            title: selectedPlaceObj.title,
            picture_url: selectedPlaceObj.picture_url,
            address: selectedPlaceObj.address,
            place: selectedPlaceObj.title,
            lat: selectedPlaceObj.location?.lat,
            lng: selectedPlaceObj.location?.lng,
            bbox: selectedPlaceObj.bbox,
            description: eventDetails ?? selectedPlaceObj?.description,
            trip_id: trip.id,
            member_only: selectedEventMembersOnly,
            place_id: selectedPlaceObj?.place_id,
            // loading: true,
            ...extraData
        }

        console.log(tripEvent);
        if (selectedPlaceObj.id) { 
            AnalyticsManager.logEvent(analyticEvent.USER_UPDATED_TRIP_EVENT, {isSubmission: isSubmission()})
            const updateResp = await tripEventApi.update(selectedPlaceObj.id, tripEvent);
            console.log("trip event update resp", updateResp);
            if (!updateResp || updateResp.error) { 
                showAlert("Something went wrong while updating trip itinerary");
                setLoading(false);
            }
        } else { 
            AnalyticsManager.logEvent(analyticEvent.USER_ADDED_EVENT_TO_TRIP, {isSubmission: isSubmission()})
            const createEventResp = await tripEventApi.create(tripEvent);
            console.log('trip event create resp', createEventResp);
            if (!createEventResp || createEventResp.error) { 
                showAlert("Something went wrong while creating trip event. Try again later");
                setLoading(false);
            }
        }

        setReloadSuggestionResponse(!reloadSuggestionResponse)
        await loadEvents();
        closeModal()
    }

    const isUnansweredSubmission = () => {
        return selectedPlaceObj?.submitted && !selectedPlaceObj.published
    }

    const viewExistingEvent = (event) => {
        setEventDetails(event.description);
        setSelectedPlaceObj(event);
        setSelectedEventMembersOnly(event.members_only);
        var startDate = event.start_at
        var endDate = event.end_at
        console.log(startDate)
        if (Platform.OS === "web" && startDate) { 
            startDate = moment(event.start_at).toDate()
            if (endDate) { 
                endDate = moment(event.end_at).toDate()
            }
        }
        setDates({startDate: startDate, endDate: endDate});
        setIsModalOpen(true);
    }

    function getDatesLabel() {
        //TODO: Add locale
        if (!startDate) {
            return "+ add date"
        } else if (startDate) {
            var dateStr = moment(startDate).format("ll")
            if (endDate) { 
                if (!moment(startDate).isSame(endDate)) {
                    dateStr += " - " + moment(endDate).format('ll')
                }
            }
            
            return dateStr
        }
    }

    const getButtonTitle = () => {
        if (selectedPlaceObj?.id) { 
            if (isSubmission()) { 
                return "Can't update"
            } else { 
                return "Update"
            }
        } else { 
            if (isSubmission()) { 
                return "Suggest"
            } else { 
                return "Add"
            }
        }
    }

    const handleSubmissionResponse = async (accepted) => {
        const body: any = {}

        if (accepted) { 
            body.accepted_at = new Date()
        } else { 
            body.rejected_at = new Date()
        }

        AnalyticsManager.logEvent(analyticEvent.USER_RESPONDED_USER_SUGGESTION, {accepted: accepted})

        await createOrUpdateEvent(body);
    }

    const getModalActionBtn = () => {
        if (isUnansweredSubmission()) { 
            return <React.Fragment>
                <Button variant="subtle" colorScheme={"coolGray"} isLoading={loading} onPress={() => handleSubmissionResponse(false)}>Reject</Button>
                <Button colorScheme={"rose"} isLoading={loading} onPress={() => handleSubmissionResponse(true)}>Accept</Button>
            </React.Fragment>
        } else { 
            return <Button colorScheme="rose" isDisabled={!canEditSelectedEvent()} isLoading={loading} onPress={() => {
                createOrUpdateEvent();
                closeModal()
            }}>{getButtonTitle()}</Button>
        }
    }

    const canEditSelectedEvent = () => {
        if (trip.creator_id == user.id && selectedPlaceObj?.published) {
            return true
        }
        if (selectedPlaceObj?.id) {
            return selectedPlaceObj.creator_id == user.id 
        } else { 
            return true;
        }
    }

    async function deleteEvent() { 
        setDeleteLoading(true)
        AnalyticsManager.logEvent(analyticEvent.USER_DELETED_TRIP_EVENT, {isSubmission: isSubmission()})
        const deleteResp = await tripEventApi.delete(selectedPlaceObj.id);
        if (deleteResp && !deleteResp.error) { 
            await loadEvents();
            closeModal()
        } else { 
            showAlert("Something went wrong while deleting this event");
        }

        setDeleteLoading(false);
    }

    if (!trip) { 
        return <LoadingModal loading></LoadingModal>
    }

    return <Layout containerW="100%" scrollView={Platform.OS === "web"} flex={1}>

        {Platform.OS !== "web" && <DateRangePicker
            containerStyle={{ marginTop: 5, display: showDateModal ? null : 'none' }}
            onChange={setDates}
            date={startDate}
            // endDate={endDate}
            minDate={trip.start_date}
            maxDate={trip.end_date}
            displayedDate={displayedDate}
            range
            open={showDateModal}
            onOpenTriggered={() => {
                Keyboard.dismiss()
            }}
            onCloseTriggered={() => {
                setIsModalOpen(true);
            }}
        >
            <Text style={{display: 'none'}}></Text>
        </DateRangePicker>}
        
        <SafeAreaView style={{ width: '100%', position: 'absolute', left: 0, right: 0, bottom: 0, zIndex: 2, backgroundColor: 'white' }}>
            <Center w="100%" pt="2" pb="2">
            <Container w="100%">
                <View w="100%">
                    <Button style={{ width: '100%' }} colorScheme="rose" onPress={() => {
                        AnalyticsManager.logEvent(analyticEvent.USER_ADDED_MANUAL_EVENT, {isSubmission: isSubmission()})
                        setIsModalOpen(true)
                    }
                    }>Add to itinerary</Button>
                </View>
                </Container>
            </Center>
        </SafeAreaView>
        <View pl="4" pr="4" maxW="100%">
            <FlatList
                data={[{ info: true }, {submittedSection: true}, ...events, { suggestion: true }]}
                showsVerticalScrollIndicator={false}
                renderItem={renderItem}
                style={{ width: '100%', paddingTop: 10 }}
                keyboardShouldPersistTaps={"always"}
            />
        </View>

        <Modal size="lg" isOpen={modalOpen} onClose={() => closeModal()} useRNModal>
            <Modal.Content >
                <Modal.CloseButton />
                <Modal.Header>{isUnansweredSubmission() ? "Respond to suggestion" : "Add to itinerary"}</Modal.Header>
                <Modal.Body _scrollview={{keyboardShouldPersistTaps: "always"}}>
                    {/* <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }} keyboardVerticalOffset={200}> */}
                            <View mb="2">
                                <FormControl.Label>Place</FormControl.Label>
                                <PlacesAutoComplete  onPlaceSelected={(placeObj) => {
                                    setSelectedPlaceObj(placeObj)
                                }} 
                                    selectedPlace={selectedPlaceObj?.title}
                                    location={`${trip.lat},${trip.lng}`}
                                    radius={2000}
                                />
                            </View>
{/* 
                            <FormControl mb="3">
                                <FormControl.Label>Title</FormControl.Label>
                                <Input />
                            </FormControl> */}

                            <FormControl mb="3">
                                <FormControl.Label>Details</FormControl.Label>
                                <TextArea isDisabled={!canEditSelectedEvent()} inputAccessoryViewID="done" placeholder="What to expect, time, etc..." autoCompleteType={undefined} 
                                    onChangeText={(val) => setEventDetails(val)} value={eventDetails} />
                            </FormControl>


                            {Platform.OS !== "web" && <DateRangePicker
                                containerStyle={{ marginTop: 5, position: 'absolute', top: 0, left: 0, height: '100%'}}
                                onChange={setDates}
                                startDate={startDate}
                                endDate={endDate}
                                displayedDate={displayedDate}
                                range
                                open={smallDateModalOpen}
                                backdropStyle={{ position: 'absolute', top: 0, left: 0, height: '100%'}}
                                onOpenTriggered={() => {
                                    setShowDateModal(true);
                                    setIsModalOpen(false);
                                    Keyboard.dismiss()
                                }}
                            >
                                <Badge colorScheme={"rose"} h="8">{getDatesLabel()}</Badge>
                            </DateRangePicker>}

                            {Platform.OS === "web" && <WebDatePicker placeholder="Enter date..." date={startDate} onChange={(date) => {
                                setDates({startDate: date}, true)
                            }} />}

                            <View mb={4} mt={2}>
                                <View flex={1} pt="2" pb="2" flexDir="row" justifyContent="space-between">
                                    <Text fontSize={"sm"}>Trip goers only</Text>
                                    <Switch size={'sm'} isChecked={selectedEventMembersOnly} onToggle={() => setSelectedEventMembersOnly(!selectedEventMembersOnly)} offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                                </View>
                                <Text fontSize="2xs">Only people who have joined the trip will be able to see this event</Text>
                            </View>


                        {/* </KeyboardAvoidingView> */}
                            
                            {selectedPlaceObj?.place_id && <Button  mb="2" variant="link" onPress={() => openBrowserLinkForPlaceId(selectedPlaceObj.place_id, selectedPlaceObj?.address)}>View details</Button>}
                        
                </Modal.Body>
                <Modal.Footer>
                    <View w="100%" display={"flex"} flexDir="row" justifyContent={"space-between"}>
                    
                        {selectedPlaceObj?.id && canEditSelectedEvent() && <Button colorScheme={"darkBlue"} isDisabled={!canEditSelectedEvent()} isLoading={deleteLoading} onPress={deleteEvent}>Delete</Button>}
                        
                        <Button.Group space={2} >
                            {getModalActionBtn()}
                        </Button.Group>
                    </View>                   
                </Modal.Footer>
            </Modal.Content>
        </Modal>
            
        <LoadingModal loading={saving}></LoadingModal>

        {showShareTripModal && Platform.OS !== "web" && <IGShareableViewModalVC 
            title="Your trip is ready to be shared!"
            isOpen={true} 
            hideIG
            showCopyLink
            linkType="trip"
            source="post-draft-create-modal"
            onInvitePress={ trip.creator_id !== user.id ? null :  () => {
              setShowShareTripModal(false);
              props.navigation.navigate("Contacts", {trip: trip, context: "trip_invite"})
            }}
            getSocialView={(ref) => <SocialTripRow 
                trip={trip} 
                ref={ref}
                onImageLoad={() => null } />}
            // viewFinishedLoading={createdTripImageLoad} 
            link={`${BaseApiConfigProvider.getWebUrl()}/trip/${trip.uuid}`} 
            onClose={() => {
              setShowShareTripModal(false);
            }}/>}


        <DoneInputAccessory></DoneInputAccessory>
    </Layout>
}