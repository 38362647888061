import AsyncStorage from "@react-native-async-storage/async-storage";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { setTargetScreen } from "../store";


export default class AuthHelper { 

    static userApi : GenericViewSetAPI  = new GenericViewSetAPI("user");

    static async requireUserLogin(pageProps, referenceScreen) { 
        const isUserLoggedIn = await this.isUserLoggedIn();
        if (!isUserLoggedIn) {
           pageProps.navigation.replace("Login", {target: referenceScreen});
       }
    }

    static async getUser(token: string) { 
        if (token) { 
            return await this.userApi.nonStandard("GET", "me", null);
        } else {
            console.log("User is not logged in!");
            return false;
        }
    }

    static isUserLoggedIn() { 
        const token = BaseApiConfigProvider.getAuth()
        if (token) { 
            return true;
        } else {
            console.log("User is not logged in!");
            return false;
        }
    }
}