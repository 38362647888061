import { Platform } from "react-native";


class ReactNativeShare { 
    static instance = null;
    private lib = null;

    constructor() {
        if (!ReactNativeShare.instance) {
            ReactNativeShare.instance = this
        }
        return ReactNativeShare.instance
    }

    async initialize() {
        await this.getLib();
    }

    private async getLib(): Promise<any> {
        if (this.lib) {
            return this.lib;
        }
        if (Platform.OS === "web") {
            this.lib = null
        } else {
            this.lib = await (await import("react-native-share")).default;
        }
        return this.lib
    }

    public async shareToIG(uri, backgroundColor) { 
        await this.initialize();
        if (!this.lib) { 
            return;
        }
        await this.lib.shareSingle({
                stickerImage: uri, //or you can use "data:" link
                backgroundBottomColor: backgroundColor,
                backgroundTopColor: backgroundColor,
                attributionURL: 'fulltrip://',
                social: this.lib.Social.INSTAGRAM_STORIES,
                appId: '742957793182154',
            });

    }

    public async open(uri) { 
        await this.initialize();
        if (!this.lib) { 
            return;
        }
        await this.lib.open({
                url: uri
        });
    }

    public async isPackageInstalled(packageName) { 
        await this.initialize();
        if (!this.lib) { 
            return;
        }
        return await this.lib.isPackageInstalled(packageName);
    }
}

const nativeShare = new ReactNativeShare();

export default nativeShare;