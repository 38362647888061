import { View } from "native-base";
import { createElement } from "react";
import { Image, Platform }   from "react-native";

export default function NativeImageBackground(props) {


    return <View {...props.style}>
        {Platform.OS !== "web" && <Image source={{uri: props.uri}} style={{width: '100%', height: '100%' }}></Image>}
        {Platform.OS === "web" && createElement("img", {src: props.uri, style: {width: '100%', height: '100%', objectFit: 'cover' }})}
        <View style={{position: 'absolute', width: '100%', height: '100%', bottom: 0, left: 0 }}>
            {props.children}
        </View>
    </View>
}