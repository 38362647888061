import { useFocusEffect } from "@react-navigation/native";
import Layout from "../components/Layout";
import React, { useState } from "react";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { Button, Heading, Text } from "native-base";
import WebLink from "../components/WebLink";



export default function CommunityScreen(props) { 

    const communityId = props.route.params?.community_id;
    const communityApi = new GenericViewSetAPI("community");
    
    const [community, setCommunity] = useState(null);
    
    const fetchData = async () => {
        const comResp = await communityApi.getById(communityId, {expansions: ["membership_count"]});
        if (comResp && !comResp.error) {
            setCommunity(comResp);
        }

    }

    useFocusEffect(React.useCallback(() => {
        // AnalyticsManager.logEvent(events.USER_VIEW_COMMUNITY);
        fetchData();
    }, []));

    

    return <Layout flex>
        <Heading mt={6} textAlign={"center"}>{community?.name}</Heading>
        <Text textAlign={"center"} fontSize="md">{community?.description}</Text>
        <Text textAlign={"center"}>{community?.membership_count} members</Text>

        <WebLink href="https://4vq5c.app.link/zASqEw84Xzb" style={{width: '100%'}}><Button mt={4} w="100%" colorScheme={"rose"}>Join community</Button></WebLink>
    </Layout>
}