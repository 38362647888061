import { Entypo, EvilIcons, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { Heading, View, Center, Container, Text, Avatar, ScrollView, HStack, Button, Icon, AlertDialog, Pressable, Image, Input, KeyboardAvoidingView, Badge, Box, Actionsheet, FormControl, Switch } from "native-base";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ActivityIndicator, Alert, Linking, Platform, SafeAreaView, Button as RNButton, FlatList } from "react-native";
import ParsedText from "react-native-parsed-text";
import { useDispatch, useSelector } from "react-redux";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import Footer from "../components/Footer";
import TripDetailsViewWithPreview from "../components/TripDetailsViewWithPreview";
import useViewType from "../hooks/useViewType";
import { buildURLQuery, canRecieveNotifications, generateUUID, getDatesString, getDescriptiveStringForPhase, getErrorString, getNextPhase, getTripImage, injectJavascript, injectJavascriptIntoWeb, openBrowserLinkForPlaceId, shareLink, showAlert, wait } from "../utils";
import { getUserAvatar, getUserProfile, maybeWrapWithWtihWebLink } from "../utils/views";
import TEXT from '../translations.json'
import UniversalMapView from "../components/UniversalMapView";
import TripOverviewText from "../components/TripOverviewText";
import ProfilePicture from "../components/ProfilePicture";
import { KeyboardAwareFlatList, KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Ionicons } from '@expo/vector-icons'; 
import Data from "../constants/Data";
import { AnalyticsManager, events } from "../utils/AnalyticsManager";
import BranchMetrics from "../utils/BranchManager";
import WebLink from "../components/WebLink";
import CommentCell from "../components/CommentCell";
import GroupedAvatars from "../components/GroupedAvatars";
import TripEventCell from "../components/TripEventCell";
import TripEventModal from "../components/TripEventModal";
import NotificationReminderModal from "../components/NotificationReminderModal";
import IGShareableViewModalVC from "../controllers/IGShareableViewModalVC";
import SocialTripRow from "../components/SocialTripRow";
import SuggestionRow from "../components/SuggestionRow";
import * as WebBrowser from 'expo-web-browser';
import ReactNativeWebViewModal from "../components/ReactNativeWebViewModal";
import StripeConnectDashboardVC from "../controllers/StripeConnectDashboardVC";
import TripPaymentModal from "../components/TripPaymentModal";
import { LoadingModal } from "../components/LoadingModal";
import SuggestionResponseRowVC from "../controllers/SuggestionResponseRowVC";
import { useFocusEffect } from "@react-navigation/native";
import moment, { now } from "moment-timezone";
import Config from "../constants/Config";
import SimpleModal from "../components/SimpleModal";
import PhoneInput from "react-native-phone-number-input";
import SegmentDrawer from "../components/SegmentDrawer";
import { Screens } from "../constants/Screens";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MobileVerificationVC from "../controllers/MobileVerificationVC";
import { setUser } from "../store";
import UniversalIFrame from "../components/UniversalIFrame";
import WebView from "react-native-webview";

export default function TripDetailScreen(props: any) {

    const tripId = props.route.params?.tripId;
    const isFakeEvent = props.route.params?.tripId === "FAKE_DATA"
    const inviteUUID = props.route.params?.invite_uid;
    const startActiveTab = props.route.params?.active_tab;
    var webview = useRef<WebView>();
    const [createInterestResp, setCreateInterestResp] = useState(null);
    


    const tripEventApi = new GenericViewSetAPI("trip_event")
    const tripApi = new GenericViewSetAPI("trip");
    const commentsApi = new GenericViewSetAPI("comment");
    const tripInterestApi = new GenericViewSetAPI('trip_interest')
    const tripInviteApi = new GenericViewSetAPI("trip_invite")
    const stripeApi = new GenericViewSetAPI('stripe')

    const dispatch = useDispatch()
    
    const [trip, setTrip] = useState(null);
    const [socialImageLoaded, setSocialImageLoaded] = useState(false);
    const [tripComments, setTripComments] = useState([])
    const [updateLoading, setUpdateLoading] = useState(false);
    const [canceDialogOpen, setCanceDialogOpen] = useState(false)
    const [comment, setComment] = useState("");
    const [deepLinkUrl, setDeepLinkUrl] = useState(`https://4vq5c.app.link/trip?${buildURLQuery({page: 'TripDetail', url: `TripDetail?tripId=${tripId}`, pageParams:{tripId: tripId}})}`);
    const [tripEvents, setTripEvents] = useState(isFakeEvent ? Data.fakeTripEvents.results : [])
    const [goEdit, setGoEdit] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showNotifReminder, setShowNotifReminder] = useState(false)
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [refreshSignal, setRefreshSignal] = useState(null);
    const [tripActionSheetOpen, setIsTripActionSheetOpen] = useState(false)
    const [maybeModalOpen, setMaybeModalOpen] = useState(false)
    const [formatText, setFormatText] = useState("");
    const [guestSubmitted, setGuestSubmitted] = useState(false);
    const [guestName, setGuestName] = useState("");
    const [guestSubmissionLoading, setGuestSubmissionLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(startActiveTab ?? "home")
    const [maybeInterests, setMaybeInterests] = useState(null)
    const [maybeInterestCount, setMaybeInterestCount] = useState(null);
    const [inviteRejected, setInviteRejected] = useState(null);
    const [invite, setInvite] = useState(null)
    const [userActionResponse, setUserActionResponse] = useState(null);
    const [deleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [placesMap, setPlacesMap] = useState(null)
    const [sendFlightNotifs, setSendFlightNotifs] = useState(true)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [sendStays, setSendStays] = useState(false)
    const [confirmationLoading, setConfirmationLoading] = useState(false);


    const user = useSelector((s: any) => s.user)
    const config = useSelector((s: any) => s.config)

    const viewType = useViewType()
    const cancelRef = useRef(null);


    useEffect(() => {
        console.log(props)
        AnalyticsManager.logEvent(events.USER_VIEWED_TRIP_DETAIL);
        props.navigation.setOptions({
            title: '',
            headerRight: () => <Button variant="link" onPress={() => setGoEdit(true)} colorScheme="coolGray"></Button>
        })

    }, [])

    const maybeGetTripInvite = async () => {
        if (inviteUUID) { 
            const inviteResp = await tripInviteApi.nonStandard("GET", `${inviteUUID}/uuid`, null)
            console.log(`invite resp is `, inviteResp)
            if (inviteResp && !inviteResp.error) { 
                setInvite(inviteResp)
                if (user) {
                    setInviteRejected(inviteResp.rejected_at ? true : false)
                }
            }
        }
    }

    useEffect(() => {
        if (!trip) {
            return
        }
        if (!trip.invite) { 
            maybeGetTripInvite();
        } else { 
            setInvite(trip.invite)
            setInviteRejected(trip.invite.rejected_at ? true : false)
        }
    }, [trip])

  useFocusEffect(
    React.useCallback(() => {
      AnalyticsManager.logEvent(events.USER_VIEW_HOME);
      let isActive = true;

      getTrip(tripId)
      const number = Math.random()
      setRefreshSignal(number)
      return () => {
        isActive = false;
      };
    }, [props.navigation])
  );
    
    async function getTrip(tripId: any) {
        var tripResp = null;
        if (tripId === "FAKE_DATA") {
            tripResp = Data.fakeTrip
        } else { 
            tripResp = await tripApi.nonStandard("GET", `${tripId}/uuid`, null, {
                include_interested: true,
                expansions: ['creator', 'trip_interest_count', 'trip_interest_users', 'my_interest', 'price_context', 'my_payment_status', "invite"]
            })
        }

        console.log('TRIP ID=', tripId);

        if (!tripResp.error) {
            console.log(tripResp);
            setTrip(tripResp)
            
            getTripEvents(tripResp.uuid);
            getTripComments(tripResp.id);
    
            if (tripResp.creator_id === user?.id) { 
                const maybeTripsResp = await tripInterestApi.query({trip_uid: tripId, level: "maybe", expansions: ["user"], page_size: 3})
                console.log("MAYBE TRIPS RESP...,", maybeTripsResp)
                // console.error(maybeTripsResp)
                if (maybeTripsResp && !maybeTripsResp.error) {
                    setMaybeInterestCount(maybeTripsResp.count)
                    setMaybeInterests(maybeTripsResp.results)
                }
            }
        } else {
            console.log(tripResp)
            const errorMsg = getErrorString(tripResp.error, "Something went wrong while loading trip. Please try again")
            showAlert(errorMsg)
            props.navigation.goBack()
        }
    }

    const getTripEvents = async (tripUid) => {
        if (isFakeEvent) { 
            return;
        }
        const query = {trip_id: tripUid, expansions: ["creator"]}
        if (user) {
            query["include_my_submitted"] = true
        }
        const eventsResp = await tripEventApi.query(query);
        console.log(eventsResp)
        if (!eventsResp || eventsResp.error) { 
            return;
        }
        setTripEvents(eventsResp.results);
        const newMap = {}
        eventsResp.results.map((e) => newMap[e.title] = e)
        setPlacesMap(newMap)
    }

    const getIsInvite = () => {
        if (isFakeEvent) { 
            return false;
        }
        return invite || (trip && trip.invite !== null && trip.invite.accepted_at == null && trip.invite.rejected_at == null)
    }


    useLayoutEffect(() => {
        if (Platform.OS === "web") { 
            injectJavascriptIntoWeb(Config.fullstoryJavaScript, null, null, true)
        }
    }, [])

    useLayoutEffect(() => {
        if (trip) { 
            props.navigation.setOptions({
                title: trip.place,
                headerRight: !isMyTrip() ? null :  () =>  <Button variant="link" leftIcon={<Icon as={FontAwesome} name="gear" />} onPress={() => setIsTripActionSheetOpen(true)} colorScheme="coolGray"></Button>
            })    
        }
    }, [trip])

    async function getTripComments(tripId: any) {
        var comments = null;
        if (isFakeEvent) { 
            comments = Data.fakeCommentsResp;
        } else { 
            comments = await commentsApi.query({ trip_id: tripId, expansions: ["creator"] });
        }
        if (!comments.error) {
            setTripComments(comments.results);
        }
    }

    function getFlightsString() { 
        var defaultStr = `https://stage.onfulltrip.com/flights?coords=${trip.lat},${trip.lng}`;
        if (trip.start_date && trip.end_date) { 
            defaultStr += `&start=${trip.start_date.split('T')[0]}&end=${trip.end_date.split('T')[0]}&target=_self`
        }
        return defaultStr;
    }

    async function getPaymentLink() {
        setLoading(true)
        AnalyticsManager.logEvent(events.TRIP_CHECKOUT_STARTED, {cost: trip.display_cost});
        const response = await stripeApi.nonStandard("POST", "checkout", {trip_id: trip.id})
        console.log("CHECKOUT RESP IS", response)
        if (response && !response.error) { 
            if (response.url) { 
                // props.navigation.navigate("Web", {url: response.url, title: 'Checkout'})
                if (Platform.OS !== "web") {
                    setPaymentUrl(response.url)
                    setPaymentModalOpen(true);
                } else { 
                    window.open(response.url, "_parent")
                }
            }
        } else { 
            const errorStr = getErrorString(response.error, "Sorry, we couldn't accept payment. Try again")
            showAlert(errorStr)
            
        }
        setLoading(false)
    }

    async function handleImInterestedClick() {
        if (isFakeEvent) { 
            showAlert("This trip is just for show, so you can't share your interest! Try with a real one you create or your friend creates :)")
            return;
        }

        AnalyticsManager.logEvent(events.USER_CLICKED_INTERESTED, {cost: trip.display_cost});

        if (trip.display_cost > 0) {
            setUserActionResponse("going");
            setReviewModalOpen(true);
        } else { 
            if (!user) { 
                setUserActionResponse("going");
                setMaybeModalOpen(true);
            } else { 
                createTripInterest()
            }
        } 
    }

    function handlePostInterestCreate(createResp) {
        const interest_level = createResp?.level ?? trip.my_interest?.level
        // console.error(interest_level)
        if (interest_level === "maybe") {
            showAlert("We'll keep you updated + send reminders about the trip!")
        } else if (interest_level === "going") {
            // TODO: Show pop interest popup then handle this?
            // goToChat()
            setShowConfirmationModal(true);
        }
    }

    async function createTripInterest(interestLevel = "going", privateUser=null) {
        // When there is no payment, we manually create interest.
        if (isFakeEvent) { 
            return null;
        }
        setLoading(true)
        setUpdateLoading(true);
        AnalyticsManager.logEvent(events.USER_CLICKED_INTERESTED, {interest_level: interestLevel});
        if (!privateUser) { 
            privateUser = user;
        }
        const createResp = await tripInterestApi.create({ trip: trip.id, level: interestLevel , user: privateUser.id });
        console.log('create trip interested resp', createResp)
        if (createResp.error) {
            const errorStr = getErrorString(createResp.error, "Failed to create trip interest. Please try again later!")
            showAlert(errorStr);
        } else {
            setCreateInterestResp(createResp);
            getTrip(trip.uuid)
            const canRecieveNotifs = await canRecieveNotifications();
            if (!canRecieveNotifs && Platform.OS !== "web") { 
                setShowNotifReminder(true);
            } else {
                handlePostInterestCreate(createResp)
            }
        }
        setUpdateLoading(false);
        setLoading(false);
    }


    function editTrip() {
        props.navigation.push("CreateModal", { tripId: trip.uuid, returnEarly: true })
        closeTripActionSheet();
    }

    function isMyTrip() {
        return user && user.id == trip.creator_id
    }

    function closeTripActionSheet() {
        setIsTripActionSheetOpen(false)
    }

    async function cancelInterest() {
        setUpdateLoading(true);
        AnalyticsManager.logEvent(events.USER_CANCEL_INTEREST);
        const cancelResp = await tripApi.nonStandard("DELETE", `${trip.id}/cancel_interest`, {});
        console.log("cancel trip itnerest resp", cancelResp)
        if (cancelResp.error) {
            showAlert("Something went wrong while canceling your interest in the trip. Try again");
        } else {
            await getTrip(trip.uuid);
        }
        setCanceDialogOpen(false)
        setUpdateLoading(false)
    }

    const needsPayment = () => {
        return (trip.my_payment_status && trip.my_payment_status == "needs_payment")
    }

    function notInterestedOrPaidAndNotMyTrip() {
        const alreadyInterested = trip.my_interest && trip.my_interest.level !== 'not_going'
        // console.error(alreadyInterested)
        const needsPay = needsPayment()
        return (!alreadyInterested || needsPay ) && !isMyTrip()
    }

    async function handleUpdatePhase() {
        const nextPhase = getNextPhase(trip.phase);
        AnalyticsManager.logEvent(events.USER_UPDATE_TRIP_PHASE, {phase: nextPhase});
        const updateResp = await tripApi.update(trip.id, { phase: nextPhase });
        if (!updateResp || updateResp.error) {
            showAlert("Sorry, we couldn't update your trip. Try again later");
        }
        await getTrip(trip.uuid);
    }

    function goToChat() { 
        if (!trip.chat_id) { 
            return;
        }
        props.navigation.navigate("ChatScreen", {chatId: trip.chat_id, title: trip.place})
    }

    function handleAdminRender(item) { 
        if (item.item === "overview") { 
            return <View mb={5}>
                <Heading mb={2}>Overview</Heading>
                <HStack justifyContent={"space-between"}>
                    <Text>5 going</Text> 
                    <Text>3 maybe</Text>
                </HStack>
            </View>
        } else if (item.item === "users") {
            return <>
                <Heading mb={2}>Users</Heading>
                <FlatList 
                    data={[]}
                    renderItem={() => {
                        return <Text>Test</Text>
                    }}
                />
            </>
        }
    }

    async function createMaybeInterest() { 
        setGuestSubmissionLoading(true);
        const createBody : any = {
            trip: trip.id, level: "maybe"
        }
        if (user) { 
            createBody.user = user.id;
        } else {
            createBody.guest_name = guestName;
            createBody.guest_mobile = formatText;
        }
        const createResp = await tripInterestApi.create(createBody);
        console.log('create trip interested resp', createResp)
        getTrip(trip.uuid)
        setGuestSubmissionLoading(false);
    }

    async function handleGuestSubmitted(mobileVerifResp) { 

        await BaseApiConfigProvider.saveUserToken(mobileVerifResp.resp.token)
        const foundUser = mobileVerifResp.resp.user
        dispatch(setUser(foundUser))

        if (guestSubmitted) { 
            setMaybeModalOpen(false);
        }

        AnalyticsManager.logEvent(events.GUEST_SUBMITTED_INFO, {mobile: mobileVerifResp.mobile, name: mobileVerifResp.name, trip: trip.id, trip_cost: trip.display_cost});
        
        if (userActionResponse === "reject") { 
            await rejectInvite()
        } else if (userActionResponse === "going") {
            if (trip.display_cost > 0) { 
                setMaybeModalOpen(false);
                await getPaymentLink()
            } else { 
                createTripInterest("going", foundUser)                
            }
        }else { 
            // TODO: Use createTripInterest instead and pass in "maybe"
            await createMaybeInterest()
        }
        
        setGuestSubmitted(true)
    }

    function goToTripEventWithObject(obj) { 
        if (Platform.OS !== "web") {
            // console.error("going to trip events with object", obj)
            props.navigation.navigate("TripEvents", {selectedPlaceObj: obj, trip: trip })
        }
    }

    const goToChatBtn = (minWidth, buttonProps) => <Button {...buttonProps} leftIcon={<Icon as={Ionicons} name="chatbubbles-outline" />} minW={minWidth ?? null} colorScheme="rose" mb="2"  size="lg" rounded="full" flex={1} onPress={goToChat}>Go to chat</Button>

    const getTripInterestLevel = () => {
        if (trip.my_interest) {
            return trip.my_interest.level
        } 
        return null
    }

    function getInterestInTripView() {
        var bottomActionText = getTripInterestLevel() === "maybe" ? "Join the trip" :  "🧐 I'm not sure"
        var statusText = getTripInterestLevel() === "maybe" ? "Your are not sure about this trip" : "Your are going on this trip"
        var shouldPay = needsPayment()
        var chatShown = !needsPayment() && trip.chat_id && trip.my_interest?.level == "going"

        if (shouldPay) { 
            if (getTripInterestLevel() === "maybe") {
                bottomActionText = "Pay deposit"
            } else { 
                statusText = `$${trip.display_cost} deposit needed to confirm`
            }
        } 

        return <View w="90%" mt={2}>
                <>
                    <HStack space={2} justifyContent="space-between" mb={2} mt={2}>
                        {!chatShown && <View flex={1}>
                            <Text underline pr={6}>{statusText}</Text>
                        </View>}
                        {needsPayment() && trip.my_interest?.level === "going" && <View flex={1}>
                            <Button mb="2" w="180" isLoading={updateLoading} rounded={"full"} onPress={handleImInterestedClick} leftIcon={<Icon as={FontAwesome5} name="hand-sparkles" />} alignSelf={"flex-end"} colorScheme="rose" size="lg" disabled={trip.my_interest_status}>{trip.my_interest ? "Pay deposit" : config.join_trip_str ?? "I'm interested"}</Button> 
                        </View>}
                        {chatShown && goToChatBtn('100%', {"size": "md", "mb": 2, "w": "100%"})}
                        </HStack>

                    <HStack justifyContent={"space-between"} space={1} mt={1} mb={2}>
                        <Button size="lg" mb="2" w="50%" colorScheme={trip.my_interest?.level  === "maybe" ? "rose" : "info"} rounded="full"
                            // leftIcon={<Icon as={FontAwesome5} name="mobile-alt" />} 
                            leftIcon={getTripInterestLevel() === "maybe" ? <Icon as={FontAwesome5} name="hand-sparkles" /> : null} 
                            variant={getTripInterestLevel() ==="going" ?  "subtle" : null}
                            onPress={updateMyTripInterest}>{bottomActionText}</Button>

                        <Button 
                            isLoading={updateLoading} mb={2} 
                            w="50%"
                            leftIcon={<Icon as={Entypo} name="circle-with-cross" />}
                            onPress={() => setCanceDialogOpen(true)} alignSelf={"flex-end"} colorScheme="coolGray" variant={"subtle"} size="lg" rounded={"full"}>Cancel interest</Button>
                    </HStack>
                </>
            </View>
    }

    async function handleCreateComment() { 
        if (isFakeEvent) { 
            showAlert("This trip is just for show so you can't comment! Create a trip yourself or go to a friends trip to comment!")
            return;
        }
        props.navigation.navigate("ChatScreen", {tripId: trip.id});
    }
    

    const isInterestedAndPaid = () => { 
        return trip.interested && !needsPayment()
    }


    const getDiscoverView = () => {
        const submit =  Platform.OS === "web" || (user ? trip.creator_id !== user.id : false);
        return <View mt="8" w="100%">
                    {/* <Text fontSize="xs" mb={1}>Only you can see this</Text> */}
                    <Heading fontSize="2xl">Discover {trip.place}</Heading>
                    <View >
                        <SuggestionRow
                        deepLinkUrl={deepLinkUrl} place={trip.place} submit={submit} lat={trip.lat} lng={trip.lng} source="TripDetail"
                        onSuggestionPressed={(sug) => openBrowserLinkForPlaceId(sug.place_id, sug.address) }
                        onSuggestionSelected={(sug) => goToTripEventWithObject(sug)}/>
                    </View>
                    {maybeWrapWithWtihWebLink(<Button colorScheme={"info"} variant="subtle" onPress={() => {
                        if (Platform.OS !== "web") { 
                            props.navigation.navigate("TripEvents", { trip: trip })}
                        }}>View more</Button>, deepLinkUrl)}
                </View>
    }


    function render(item) {
        if (!trip) {
            return null
        }

        var view = null

        if (item.item == "image") {
            view = <Image style={{ width: '100%', height: 200 }}
                source={{ uri: getTripImage(trip) }} size="xl" />
        } else if (item.item == "header") {
            view = <View height="100%" mt="5" w="100%">
                {/* {isMyTrip() && trip.cost > 0 && <StripeConnectDashboardVC  use/>} */}
                {Platform.OS === "web" && isMyTrip() && <Button onPress={() => setIsTripActionSheetOpen(true)} leftIcon={<Icon as={EvilIcons} name="gear" />} mb={2} colorScheme="coolGray" variant={"subtle"} size="sm" maxW="100" maxH="60" rounded="full">Edit</Button>}
                <HStack space={2} flexWrap={"wrap"} >
                    {trip.my_interest && <Badge mb={3} rounded={"full"} colorScheme={trip.my_interest.level ==="going" ? "green" : "coolGray"}>{trip.my_interest.level === "going" ? `You're going` : "You're not sure"}</Badge>}
                    {trip.joinable == false && <Badge mb="3" colorScheme={"danger"} rounded={"full"} w={120}>Full trip</Badge>}
                    {trip.join_before && trip.joinable && <Badge  colorScheme={"lightBlue"} rounded={"full"} w={160} mb={1}>{`Join by ${moment(trip.join_before).format('ll')}`}</Badge>}
                    {isFakeEvent && <Badge rounded={"full"} mb={3} colorScheme={"lightBlue"} w={80}>This is not real and just for demonstration..</Badge>}
                    {isMyTrip() && !trip.published && <Badge rounded={"full"} mb={3} colorScheme={"amber"} w={100}>Draft</Badge> }
                    {trip.visibility === "UNLISTED" && <Badge mb={3} rounded={"full"} colorScheme={"indigo"}>Unlisted</Badge>}
                </HStack>
                
                <View mb={3}>
                    <Heading size="2xl">{trip.place}</Heading>
                    {trip.display_cost > 0 && <Text mt={1.5} color={"coolGray.700"} fontSize="sm">{`$${trip.display_cost} deposit needed`}</Text>}
                    {trip.start_date && <Text mr={6} mt={1.5} color={"coolGray.700"} fontSize="md">{getDatesString(trip.start_date, trip.end_date)}</Text>}
                </View>

                <View mb={2}>
                    {!isFakeEvent && isMyTrip() &&
                        <Button 
                            colorScheme={"coolGray"} 
                            rounded="2xl"
                            maxW={"40%"}
                            mt={3}
                            leftIcon={
                                <Icon as={Entypo} name="share-alternative"  mr={1}></Icon>
                            } 
                            display={"flex"} flexDir="row" alignItems={"center"} 
                            onPress={() => {
                                AnalyticsManager.logEvent(events.USER_SHARED_TRIP, {source: 'event-page'})
                                setShowShareModal(true);
                        }}>Share</Button>}
                </View>
                <HStack pb="5"  w="100%" justifyContent="space-between" >
                    <GroupedAvatars avatarSize="md" users={[trip.creator, ...trip.interest_users]} groupProps={{pt: 4}} onPress={() => {
                        if (isFakeEvent) { 
                            return;
                        }
                        props.navigation.navigate("UserList", {tripId: trip.uuid, context: 'trip', extraUsers: [trip.creator]}) 
                        }}/>
                </HStack>


                <TripOverviewText includeInterestCount hidePlaceAndTime trip={trip}></TripOverviewText>
                {/* <Button variant="subtle" mt={3} leftIcon={<Entypo name="share-alternative" />}>Share</Button> */}
                
                {isMyTrip() && maybeInterests && maybeInterestCount > 0 && <HStack mt={4}>
                    <View alignItems={"flex-start"}>
                        <GroupedAvatars avatarSize="sm" users={maybeInterests.map((item) => item.user)} onPress={() => {
                        if (isFakeEvent) { 
                            return;
                        }
                        props.navigation.navigate("UserList", {tripId: trip.uuid, context: 'trip', tripInterestLevel: "maybe"}) 
                        }}/>
                        <Text mt={2} fontWeight="bold" fontSize={"md"} color="coolGray.800">{maybeInterestCount} not sure</Text>
                    </View>
                </HStack>}
            </View>
            if (viewType === "desktop") {
                view = <HStack justifyContent="space-between" w="100%">
                    <View w="70%">
                        {view}
                    </View>
                    <HStack mt={4} minW={300}>
                        {getMobileFooterView()}
                    </HStack>
                </HStack>
            }
        } else if (item.item == "about") {
            view = <>
                <Heading fontSize="3xl" pt="8" mb={3}>About</Heading>
                <TripDetailsViewWithPreview data={trip.details} parsedTextStyle={{fontSize: 16}}
                ></TripDetailsViewWithPreview>
            </>
        } else if (item.item == "map") {
            return <View style={{ height: 250 }} pt="8">
                <Center mb="3">
                    <Container w={"90%"} maxWidth={800}>
                        <Heading fontSize="3xl">Map</Heading>
                    </Container>
                </Center>
                <UniversalMapView place={trip.place}></UniversalMapView>
            </View> 
        } else if (item.item == "events") {
            view = <View mt="8" mb="4">
                <View flexDir={"row"} justifyContent="space-between" w="100%">
                    <Heading mb="2" fontSize="3xl">Trip Itinerary</Heading>
                    {isMyTrip() && <Button variant={"subtle"} colorScheme="coolGray" onPress={() => props.navigation.navigate("TripEvents", {trip: trip})}>{isMyTrip() ? "Edit" : "Suggest"}</Button>}
                </View>
                {(!tripEvents || tripEvents.length === 0) && !trip.itinerary_link && <Text mb={5} color={"coolGray.700"}>No itinerary yet...</Text>}
                {trip.itinerary_link && <ScrollView w="100%"><TripDetailsViewWithPreview  data={trip.itinerary_link} parsedTextStyle={{fontSize: 16}}
                ></TripDetailsViewWithPreview></ScrollView>}
                <View>
                    {tripEvents && tripEvents.map && tripEvents.map((itemData) => <TripEventCell event={itemData} onPress={(d) => setSelectedEvent(d)}/>)}
                </View>
                {isMyTrip() && <SuggestionResponseRowVC refreshSignal={refreshSignal} onPress={(data) => goToTripEventWithObject(data)} tripId={trip.uuid} renderTitle={() => <Heading fontSize="lg" mb={1}>Submitted suggestions</Heading>}/>}
            </View>

        
        } else if (item.item === "comments") {
            view = <>
                <Heading pt="8" fontSize="3xl">Comments</Heading>
                {tripComments.length === 0 && <Text color={"coolGray.700"} mt={1}>No comments yet...</Text>}
                <View mt="4" flex={1} w="100%">
                    <FlatList data={tripComments} renderItem={(subItem) => {
                        let comment = subItem.item;
                        return <View pb="10" w="100%">
                             <CommentCell comment={comment} />
                        </View>
                    }}>
                    </FlatList>
                    {user && <Button colorScheme={"info"} variant="subtle" onPress={() => handleCreateComment()}>Add comment</Button>}
                </View>
            </>
        } else if (item.item === "buffer") {
            if (Platform.OS === "web") {
                view = <Footer></Footer>
            } else {
                view = <View height={300}></View>
            }
        } else if (item.item === "discover"  && !maybeModalOpen) { 
            view = getDiscoverView();
        } else if (item.item === "flights") { 
            view = <View mt="8" w="100%" style={{height: 500}}>
                    <Heading fontSize="2xl">Flights to {trip.place}</Heading>
                    <UniversalIFrame source={getFlightsString()} minHeight={600} ref={webview}
                        onNavigationStateChange={(newNavState) => {
                            const { url } = newNavState;
                            if (!url) return;
                            // redirect somewhere else
                            if (url.includes('skyscanner')) {
                                webview.current.stopLoading();
                                AnalyticsManager.logEvent(events.USER_NAVIGATED_TO_FLIGHTS_SEARCH)
                                WebBrowser.openBrowserAsync(url);
                            }
                        }}/>
                </View>
        }

        if ((item.item == "buffer" || item.item === "image") && view) {
            return view
        } else {
            return <Center flex={1} bg="white"><Container w={"90%"} maxWidth={800} height="100%">{view}</Container></Center>
        }
    }

    async function updateMyTripInterest() { 
        var updateBody: any = {}
        if (trip.my_interest.level === "going") { 
            updateBody.level = "maybe"
        } else { 
            updateBody.level = "going"
        }

        if (updateBody.level === "going" && needsPayment()) { 
            setReviewModalOpen(true);
            return;
        }

        setLoading(true);
        const updateResp = await tripInterestApi.update(trip.my_interest.id, updateBody);
        if (updateResp && !updateResp.error) {
            await getTrip(trip.uuid);
        } else { 
            const errMsg = getErrorString(updateResp.error, "Something went wrong while updating your interest. Try again later")
            showAlert(errMsg)
        }

        setLoading(false);
    }

    function handleCancelDialogClose() {
        setCanceDialogOpen(false);
    }

    function handleMaybeInterestedClick () { 
        if (isFakeEvent) { 
            showAlert("This is a fake event so you can't join it! Try with a real one!");
            return; 
        }

        if (user) { 
            createTripInterest("maybe");
        } else { 
            AnalyticsManager.logEvent(events.GUEST_CLICK_KEEP_ME_INFORMED, {trip: trip.id, trip_cost: trip.display_cost})
            setUserActionResponse("maybe");
            setMaybeModalOpen(true)
        }
    }

    async function deleteTrip() {
        setDeleteLoading(true);
        const deleteResp = await tripApi.delete(trip.id);
        console.log(deleteResp);
        if (deleteResp.error) {
            showAlert("Something went wrong while canceling the trip. Try again later");
        } else {
            showAlert("Your trip has been canceled. People interested will be notified");
            props.navigation.navigate("Root", {screen: "Home"});
        }
        AnalyticsManager.logEvent(events.USER_DELETED_TRIP);
        setIsDeleteDialogOpen(false);
        setDeleteLoading(false);
    }

    async function rejectInvite() { 
        AnalyticsManager.logEvent(events.USER_CLICK_REJECT_INVITE, {trip: trip.id, trip_cost: trip.display_cost})
            // reject trip invite.
        const inviteId = invite?.id ? invite.id : trip.my_invite?.id;
        const updateResp = await tripInviteApi.update(inviteId, {rejected_at: new Date().toISOString()});
        if (updateResp && !updateResp.error) {
            showAlert(`Thanks, we've let ${trip.creator.first_name} know you can't make it`)
            getTrip(trip.uuid);
        }
    }


    function handleInviteRejection() { 
        if (user) { 
            rejectInvite();
        } else {
            AnalyticsManager.logEvent(events.USER_CLICK_REJECT_INVITE, {trip: trip.id, trip_cost: trip.display_cost})
            // setInviteRejected(true);
            setUserActionResponse("reject");
            setMaybeModalOpen(true);
        }
    }

    function getActionButtons() { 
        var mainButtonTextFallback = "I'm interested"
        if (getIsInvite()) { 
            mainButtonTextFallback = " Join trip"
        } 
        var isNotJoinable = false;
        const isAfterJoinDate = moment().isAfter(moment(trip.join_before))
        if (trip.joinable === false || isAfterJoinDate) { 
            mainButtonTextFallback = "This trip is full"
            if (isAfterJoinDate) {
               mainButtonTextFallback = "Not joinable" 
            }
            isNotJoinable = true
        }

        if (getIsInvite()) { 
            return <View flex={1}>
                <Button mb="2" w="100%" isLoading={updateLoading} onPress={handleImInterestedClick} leftIcon={<Icon as={FontAwesome5} name="hand-sparkles" />} alignSelf={"flex-end"} colorScheme="rose" size="lg" disabled={isNotJoinable}>{trip.interested ? "Pay deposit" : mainButtonTextFallback}</Button>
                <HStack flex={1} w="100%" justifyContent={"space-between"}>
                    <Button size="lg" mb="2" colorScheme={"coolGray"} 
                        flex={1}
                        variant="subtle" 
                        disabled={isNotJoinable}
                        onPress={handleMaybeInterestedClick}>🧐 Not sure
                    </Button>
                  
                    <Button flex={1} size="lg" mb="2" colorScheme={"coolGray"} 
                        disabled={isNotJoinable}
                        onPress={handleInviteRejection}>😢 Can't go
                    </Button>
                </HStack>
            </View>
        } else { 
        return <>
            <Button mb="2" pl={1} flex={2} isLoading={updateLoading} onPress={handleImInterestedClick} leftIcon={<Icon as={FontAwesome5} name="hand-sparkles" />} alignSelf={"flex-end"} colorScheme="rose" size="lg" 
                disabled={isNotJoinable}>{trip.interested ? "Pay deposit" : config.join_trip_str ?? mainButtonTextFallback}</Button>
            <Button size="lg" mb="2" colorScheme={"coolGray"} 
                // leftIcon={<Icon as={FontAwesome5} name="mobile-alt" />} 
                flex={1}
                variant="subtle" 
                disabled={isNotJoinable}
                onPress={handleMaybeInterestedClick}>🧐 Not sure</Button>
            </>
        }
    }

    const handleConfirmationFinish = async () => {
        console.log(`create resp is ${createInterestResp}`)
        if ((sendStays || !sendFlightNotifs) && createInterestResp) { 
            setConfirmationLoading(true);
            await tripInterestApi.update(createInterestResp.id, {notify_stays: sendStays, notify_flights: sendFlightNotifs})
        }
        setMaybeModalOpen(false);
        setConfirmationLoading(false);
        setShowConfirmationModal(false);
        goToChat();
    }

    function getMobileFooterView() { 
        if (!isInterestedInTrip() && !isMyTrip()) {
            return <View w="90%">
                    <View w="100%" mt={2}>
                        <Text fontSize="md" numberOfLines={1} fontWeight={"semibold"}>{`Trip to ${trip.place}`}</Text>
                        <Text>{getPaymentString()}</Text>
                    </View>
                    {inviteRejected && <View mb={3}>
                        <Button mt={1}>😢 You aren't going</Button>
                        <Pressable mt={1} onPress={() => setInviteRejected(false)}><Text underline>Change your answer</Text></Pressable>
                    </View>}
                    {!inviteRejected && <HStack justifyContent={"space-between"} mt={2} space={1}>
                        {getActionButtons()}
                    </HStack>}
                </View>
        } else if (isMyTrip()) { 
            return <View w="90%">
                <HStack mt={3}>
                    <View flex={1}>
                        <Text pr={2} mb="2">{`You are currently ${getDescriptiveStringForPhase(trip.phase)} this trip`}</Text>
                    </View>
                    <View flex={1}>
                        {trip.chat_id && goToChatBtn('100%', {"size": "md", "mb": 2, "w": "100%"})}
                        {getNextPhase(trip.phase) !== null && <Button size="lg" w="100%" mb={2} variant="subtle" colorScheme={"info"} alignSelf={"flex-end"} onPress={handleUpdatePhase} rounded={"full"}>{`Move to ${getDescriptiveStringForPhase(getNextPhase(trip.phase))}`}</Button>}
                    </View>
                </HStack>
        </View>
        } else if(isInterestedInTrip()) {
            return getInterestInTripView()
        }
    }

    function isInterestedInTrip() { 
        return trip.my_interest && trip.my_interest.level !== "not_going"
    }

    function getPaymentString() { 
        if (trip.display_cost > 0) { 
            return <React.Fragment><Text fontSize="md" fontWeight={"medium"}>{`$${trip.display_cost} `}</Text>deposit required</React.Fragment>
        } else {
            return TEXT.NoDepositNeeded
        }
    }

    function getTabsData() {
        var tabsData = [
            {key: "home", label: "Home", count: 0}, 
            {key: "itinerary", label: "Itinerary", count: 0},
            {key: "flights", label: "Flights", count: 0}
        ]
        if (trip && isMyTrip()) { 
            tabsData.push({key: "stays", label: "Stays", count: 0})
        }
        return tabsData
    }

    function getVrboString() { 
        if (!trip) { 
            return null;
        } 
        return `https://www.vrbo.com/search/keywords:${encodeURIComponent(trip.place)}/page:1/minSleeps:4/arrival:${trip.start_date ? trip.start_date.split("T")[0] : ""}/departure:${trip.end_date ? trip.end_date.split("T")[0] : ""}/?adultsCount=${trip.interest_count ? trip.interest_count + 1 : 1}`
    }

    function withContainerView(view) {
        return <Center flex={1} bg="white"><Container w={"90%"} maxWidth={800} height="100%">{view}</Container></Center>
    }

    return <View bg="white" flex={1}>
        {!trip && <ActivityIndicator style={{marginTop: 10}}></ActivityIndicator>}

        {trip && viewType === "mobile" && <SegmentDrawer activeTab={activeTab} tabsData={getTabsData()} 
            onActiveTabChange={(tab) => {
                AnalyticsManager.logEvent(events.TRIP_DETAIL_TAB_CHANGE, {"tab": tab})
                if (tab === "stays") { 
                    WebBrowser.openBrowserAsync(getVrboString())
                } else { 
                    setActiveTab(tab) 
                }
            } }/>}

    
        {trip && activeTab === "home" && <>
            {trip && <KeyboardAwareFlatList
                data={["image", "header", "about", "map", "events", "comments", "discover", "flights", "buffer"]}
                showsVerticalScrollIndicator={false}
                extraHeight={40}
                extraScrollHeight={40}
                style={{ maxWidth: '100%' }}
                renderItem={(item) => render(item)}
            >
            </KeyboardAwareFlatList>} 
        </>}

        {trip && activeTab === "flights" && <>
             <KeyboardAwareFlatList
                data={["flights"]}
                showsVerticalScrollIndicator={false}
                extraHeight={40}
                extraScrollHeight={40}
                style={{ maxWidth: '100%' }}
                renderItem={(item) => render(item)}
            >
            </KeyboardAwareFlatList>
        </>}

        {trip && activeTab === "itinerary" && <>
             <KeyboardAwareFlatList
                data={["events", "discover", "buffer"]}
                showsVerticalScrollIndicator={false}
                extraHeight={40}
                extraScrollHeight={40}
                style={{ maxWidth: '100%' }}
                renderItem={(item) => render(item)}
            >
            </KeyboardAwareFlatList>
        </>
        }

        {trip && activeTab === "admin" && <Center><View w="90%">
                {trip && <FlatList 
                    data={["overview", "users"]}
                    renderItem={(item) => handleAdminRender(item)}
                />}
        </View></Center>}

        {trip && viewType === "mobile" && <SafeAreaView style={{ position: 'absolute', left: 0, right: 0, bottom: 0, zIndex: 2, borderColor: 'lightgray', backgroundColor: 'white', borderWidth: 1 }}>
            <Center>
                {getMobileFooterView()}
            </Center>
        </SafeAreaView>}

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={canceDialogOpen} onClose={handleCancelDialogClose}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Cancel interest in trip</AlertDialog.Header>
                <AlertDialog.Body>
                    We'll let the trip planner know you're cancelling. You'll have to manually share interest again and you will no longer recieve updates about the trip.
                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={handleCancelDialogClose} ref={cancelRef}>
                            Go back
                        </Button>
                        <Button colorScheme="danger" isLoading={updateLoading} onPress={cancelInterest}>
                            Cancel interest
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>

        <Actionsheet isOpen={tripActionSheetOpen} onClose={() => setIsTripActionSheetOpen(false)}>
            <Actionsheet.Content>
            <Actionsheet.Item onPress={editTrip}>Edit trip details</Actionsheet.Item>
            <Actionsheet.Item onPress={() => {
                props.navigation.navigate("TripEvents", { trip: trip, returnEarly: true })
                closeTripActionSheet()
            }}>Edit trip itinerary</Actionsheet.Item>
            <Actionsheet.Item onPress={() => {
                props.navigation.navigate("TripReview", {trip: trip, events: tripEvents, returnEarly: true})
                closeTripActionSheet()
            }}>Edit trip settings</Actionsheet.Item>
            <Actionsheet.Item onPress={() => {
                setIsDeleteDialogOpen(true)
                closeTripActionSheet()
            }}>Cancel trip</Actionsheet.Item>
            </Actionsheet.Content>
      </Actionsheet>

        <TripEventModal isOpen={selectedEvent !== null} event={selectedEvent} onClose={() => setSelectedEvent(null)} />

        <NotificationReminderModal isOpen={showNotifReminder} onClose={() => {
            setShowNotifReminder(false);
            handlePostInterestCreate(null);
        }} 
        body="Without notifications, you won't be notified about important updates to the trip or new messages in the trip chat!"
        />

        <TripPaymentModal trip={trip} isOpen={reviewModalOpen} onClose={() => setReviewModalOpen(false)} onConfirm={() => {
            setReviewModalOpen(false);
            if (!user) { 
                setMaybeModalOpen(true)
            } else { 
                getPaymentLink() 
            }}} />

        <LoadingModal loading={loading} />

        <ReactNativeWebViewModal url={paymentUrl}  failIdent="https://www.onfulltrip.com/payment/response?type=failure" successIdent="https://www.onfulltrip.com/payment/response?type=sucess" isOpen={paymentModalOpen} onMessage={(msg) => showAlert(msg)} 
        onClose={(success) => {
            setPaymentModalOpen(false)
            AnalyticsManager.logEvent(events.TRIP_CHECKOUT_COMPLETE, {succeeded: success})
            if (success) { 
                createTripInterest()
            } else if (success === false) { 
                showAlert("Payment failed. Please try again or contact us if it persists")
            }
        }}/>

        {Platform.OS == "web" && <SimpleModal headerTitle={"Confirm"} isOpen={maybeModalOpen} onClose={() => setMaybeModalOpen(false)} size="lg">
            {!guestSubmitted && <>
                <MobileVerificationVC 
                useWeb
                getTitleRender={() => <Text mb={2}>{`Confirm your details to share your response with ${trip?.creator?.first_name}. We won't ever send you spam.`}</Text>} 
                showName 
                onFinish={(verifResp) => handleGuestSubmitted(verifResp)}/>
            </>}
        </SimpleModal>}

        {trip && <SimpleModal isOpen={showConfirmationModal} hideHeader size="lg" notClosable>
            
            <Heading>You're going to {trip.place}! 🎉</Heading>

            <Text mt={4} mb={4} fontSize="md">We'll take care of sending you timely reminders, alerts and updates.</Text>

            <View mb={2} flexDir="row" justifyContent="space-between">
                <Text fontSize={"md"}>Send me flight reminders and alerts</Text>
                <Switch isChecked={sendFlightNotifs} onToggle={() => setSendFlightNotifs(!sendFlightNotifs)} offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
            </View>

            <View mb={2} flexDir="row" justifyContent="space-between">
                <Text fontSize={"md"}>Send me hotels and other other stays</Text>
                <Switch isChecked={sendStays} onToggle={() => setSendStays(!sendStays)} offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
            </View>
            {/* <View mb={2} flexDir="row" justifyContent="space-between">
                <Text fontSize={"md"}>Allow others to join trip</Text>
                <Switch isChecked={allowOthersToJoin} onToggle={() => setAllowOthersToJoin(!allowOthersToJoin)} offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
            </View> */}

            {/* {Platform.OS === "web" && <Text fontSize="sm" mt={4} underline>Create your own trip</Text>} */}

            <Button mt={4} onPress={() => {
                handleConfirmationFinish()
            }} isLoading={confirmationLoading} colorScheme={"rose"}>Save</Button>
            
        </SimpleModal>}

        {trip && <IGShareableViewModalVC 
            isOpen={showShareModal} 
            showCopyLink
            linkType={"trip"}
            onInvitePress={ !isMyTrip() ? null : () => {
                setShowShareModal(false);
                props.navigation.navigate(Screens.Contacts, {trip: trip, context: 'trip_invite'})
            }}
            source="trip_detail"
            getSocialView={(ref) => <SocialTripRow 
                trip={trip} 
                ref={ref}
                onImageLoad={() => setSocialImageLoaded(true)} />}
            viewFinishedLoading={socialImageLoaded} 
            link={`${BaseApiConfigProvider.getWebUrl()}/trip/${trip.uuid}`} 
            onClose={() => {
                setShowShareModal(false)}
            }/>}

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={deleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Delete Trip</AlertDialog.Header>
                <AlertDialog.Body>
                    This will delete all data relating to this Trip. This action cannot be
                    reversed. Any deposits paid by attendees will be refunded. Deleted data can not be recovered.
                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={() => setIsDeleteDialogOpen(false)} ref={cancelRef}>
                            Cancel
                        </Button>
                        <Button colorScheme="danger" onPress={deleteTrip} isLoading={deleteLoading}>
                            Delete
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>

    </View>

}