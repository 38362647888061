import { Divider, HStack, Pressable, ScrollView, Text, View } from "native-base";
import React, { useState } from "react";
import BadgedIcon from "./BadgedIcon";


export default function SegmentDrawer(props) { 
    const {tabsData, activeTab} = props;
    const elems = <>
      {
            tabsData.map((tabData, index) => {
                return <Pressable onPress={() => props.onActiveTabChange(tabData.key)} mt={3} mb={1}>
                        <BadgedIcon right={-25} count={tabData.count}><Text bold={activeTab == tabData.key} fontSize="md">{tabData.label}</Text></BadgedIcon>
                        {activeTab == tabData.key && <Divider mt={1} bg="info.700"></Divider>}
                    </Pressable>
            })
        }</>
    

    if (props.scroll) { 
        return <View h="10" pl={3}>
            <ScrollView width={"100%"} horizontal showsHorizontalScrollIndicator={false}>
                {elems}
            </ScrollView>
        </View>
    }
    return <HStack justifyContent={"space-around"} w="100%">
        {elems}
</HStack>
}