import { View, Text, Pressable } from "native-base";
import React from "react";
import ProfilePicture from "./ProfilePicture";


export default function SimpleUserView(props) { 

    const {user, includeBorder} = props
    if (!user) { 
        return null;
    }

    var styles = {}

    if (includeBorder) { 
        styles = {borderWidth: 1, borderRadius: 5, borderColor: 'lightgray'}
    }

    return <Pressable onPress={() => props.onPress ? props.onPress(user) : () => null} {...props.viewProps} p="2" style ={styles} alignItems={"center"} styl>
        <ProfilePicture user={user} />
        <Text mt="2"  fontSize="sm">{user.first_name}</Text>
        <Text mb="3" fontSize="xs" color="gray.500">{user.username.toLowerCase()}</Text>
        {/* <Text fontSize="xs">@{user.username}</Text> */}
        {props.getBottomViewRender ? props.getBottomViewRender(user) : null}
    </Pressable>
}