

import { decode } from 'html-entities'
import moment from 'moment-timezone'
import { Avatar, theme } from 'native-base'
import { Alert, Image, Linking, Platform, Share as RNShare, Share } from 'react-native'
import { PreviewData, PreviewDataImage, Size } from '../types'
import { captureRef } from 'react-native-view-shot';
import ReactNativeShare from './ReactNativeShare'
import Constants from 'expo-constants'
import Config from '../constants/Config'
import compareVersions from 'compare-versions'
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import * as WebBrowser from 'expo-web-browser'
import * as Sentry from "sentry-expo";
import Colors from '../constants/Colors'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Dimensions } from "react-native";

enum TripPhase {
  THINKING = 0,
  PLANNING, 
  BOOKING
}

export async function wait(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export async function canRecieveNotifications() { 
  if (Platform.OS === "web") {
    return;
  }
  if (Platform.OS !== "ios") { 
    return true;
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    return existingStatus == "granted"
  } else { 
    return false
  }

}

export async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === "web") {
    return;
  }

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      console.log('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({
      experienceId: '@victorany/grouptravelui',
    })).data;
    console.log(token);
  } else {
    alert('Must use physical device for Push Notifications');
  }

  return token;
}


export function meetsMinimumAppVersion(config) {
    if (config) {
      const minVersion = config.min_internal_version;
      if (minVersion) { 
          const delta = compareVersions(minVersion, Config.internalVersion);
          if (delta === 1) { 
              return false;
          } 
      }
  }
  return true
}

export function meetsInternalVersion(minVersion, returnIfNull) { 
  if (minVersion) { 
    const delta = compareVersions(minVersion, Config.internalVersion);
    if (delta === 1) { 
        return false;
    } else { 
      return true
    }
  } else { 
    return returnIfNull
  }
}


export const getViewDataForPhase = (phase: string) => {
  if (phase === "thinking") {
    return { color: "", text: "CONSIDERING" }
  } else if (phase === "planning") {
    return { color: "", text: "PLANNING" }
  } else {
    return { color: "", text: "BOOKING" }
  }
}

export const getImageFromRef = async (ref, openShare=false) => {
  try {
      const uri = await captureRef(ref, {
          format: 'png',
          quality: 0.7
      });
      if (openShare) { 
        await ReactNativeShare.open( uri );
        // shareLink(uri)
      }
      return uri;
  } catch (err) {
      console.error(err)
      return null;
  }
}

export function textMatches(value, type, regex) { 
  var re = null
	if (regex !== null) { 
		re = regex
	}
		
	if (type === 'alphanumeric') { 
		re = /^[a-zA-Z0-9_.-]+$/
	}

	if (type === 'numeric') { 
		re = /^[0-9.]+$/
	}

	if (type === "integer")  { 
	  re = /^[0-9]+$/
	}

	if (type === "alphabet")  { 
		re = /^[a-zA-Z]+$/
	}
	
  if (re.exec(value) === null && value !== '') { 
		return false;
	} else { 
    return true;
  }
}

export function getVersionString() {
  var string = `${Constants.expoConfig.runtimeVersion}-${Config.internalVersion}`
  const extra = Constants.expoConfig.extra
  if (extra && extra.appEnv) {
    if (__DEV__) { 
      string += "-dev"
    } else { 
      string += `-${extra.appEnv}`;
    }
  }
  return string;
}

export function getSentryReleaseString() { 
  var string = `co.roamapp.roam@${Constants.expoConfig.runtimeVersion}+1`
  return string;
}

export function sentryCaptureException(e) { 
  if (Platform.OS === "web") {
    Sentry.Browser.captureException(e);
  } else { 
    Sentry.Native.captureException(e)
  }
}


export function testOrProdProvider(testVal, prodVal){
  const isTest = __DEV__ ? true : Constants.expoConfig.extra.appEnv === "preview" ? true : false
  return isTest ? testVal : prodVal;
}


export function getRandomItem(items) {
  return items[Math.floor(Math.random() * items.length)];
}

export async function isInstagramInstalled() { 
  var instagramInstalled = false;
  if (Platform.OS === "ios") {
      instagramInstalled = await Linking.canOpenURL("instagram://");
  } else if (Platform.OS === "android") { 
      instagramInstalled = await ReactNativeShare.isPackageInstalled("com.instragram.android");
  }
  return instagramInstalled;
}

export function webOrNative(webFn, nativeFn) {
  if (Platform.OS == "web" && webFn) {
    return webFn()
  } else if (nativeFn) {
    return nativeFn()
  } else {
    return null;
  }
}

export const getViewType = () => {
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  if (screen.width >= 1000) { 
    return "desktop"
  } else {
    return "mobile"
}
}


export function openBrowserLinkForPlaceId(placeId, placeName, useLinking=false) { 
  console.log(`opening browser for placeId: ${placeId} and address: ${placeName}`)
  const val = `https://www.google.com/maps/search/?${buildURLQuery({api: 1, query: placeName, query_place_id: placeId})}` 
  if (getViewType() === "desktop" || useLinking) { 
    Linking.openURL(val)
  } else { 
    WebBrowser.openBrowserAsync(val)
  }
}

export function openLinkWebAware(url) { 
  if (Platform.OS === "web") { 
    Linking.openURL(url)
  } else { 
    WebBrowser.openBrowserAsync(url)
  }
}

export const getRandomTheme = () => {
  const colorMap = { ...theme.colors }
  delete colorMap.darkText
  delete colorMap.lightText
  delete colorMap.text
  delete colorMap.trueGray
  delete colorMap.contrastThreshold
  delete colorMap.black

  const items = Object.keys(colorMap)
  const item = getRandomItem(items);
  return item
}

export async function shareLink(link: string) { 
  try {
    const options: any = {}
    if (Platform.OS === "android") {
      options.message = link
    } else { 
      options.url = link
    }
    const result = await RNShare.share(options);
    console.log(result)
    if (result.action === RNShare.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === RNShare.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    alert(error.message);
  }
}

export async function shareToIG(uri: string) { 
  await ReactNativeShare.shareToIG(uri, Colors.fulltripTheme)
}

export function injectJavascript(src, async) { 
  if (Platform.OS === "web") {
    injectJavascriptIntoWeb(null, src, async)
  }
}

export async function injectJavascriptIntoWeb(scriptText, src=null, async=false, textType=null) { 
  if (Platform.OS !== "web") { 
    return
  }
  const script = document.createElement("script")
  if (textType) { 
    script.setAttribute('type', 'text/javascript');
  }
  if (async) { 
    script.setAttribute('async', '')
  }
  if (src) {
    script.setAttribute('src', src)
  }
  if (scriptText) { 
    script.text = scriptText
  }

  const headList = document.getElementsByTagName("head");
  if (headList.length > 0) { 
      const head = headList[0]
      head.appendChild(script)
  }
}

export function getDescriptiveStringForPhase(phase: string) {
  if (phase === "THINKING") {
    return "considering"
  } else if (phase == "PLANNING") {
    return "planning"
  } else {
    return "booking"
  }
}

export function getNextPhase(currPhase: string) { 
  const number = TripPhase[currPhase];
  if (number !== null && number !== TripPhase.BOOKING) { 
    return TripPhase[number + 1];
  } else { 
    return null
  } 
}

export function getColorSchemeForPhase(phase: string) { 
  if (phase === "THINKING") {
    return "info"
  } else if (phase == "PLANNING") {
    return "success"
  } else {
    return "rose"
  }
}

export function getTripImage(trip){
  if (trip.picture_url) { 
    return trip.picture_url
  } else if (trip.photo_reference) { 
    return Config.googleMapsPhotoRef.replace("{}", trip.photo_reference)
  } else {
    return null;
  }
} 

export async function loadGuestEngagement(guest) {
  const engagement = await AsyncStorage.getItem("guestEngagement");
  const result = {
    guest: null, 
    places: null,
    communities: null,
    anthem: null
  }
  if (engagement) {
      const parsedEngagement = JSON.parse(engagement);
      console.log(parsedEngagement)
      const newPlaces = parsedEngagement.places
      result.places = newPlaces
      result.communities = parsedEngagement.communities
      result.anthem = parsedEngagement.anthem
  }
  if (!guest) { 
      const newGuest = await AsyncStorage.getItem("guest");
      if (newGuest) {
          const parsedGuest = JSON.parse(newGuest);
          console.log(parsedGuest)
          result.guest = parsedGuest
      }
  }
  return result;
}

export function getErrorString(error, defaultStr=null) {
  console.log("error manager looking at")
  console.log(error)
  if (error === null || error === undefined) { 
    console.log("Error is null or undefined. Returning")
    if (defaultStr) { 
      return defaultStr;
    }
    return "Sorry, something went wrong. We're looking into it. Try again later."
  }

  if (error.detail !== undefined) { 
    console.log("Error has a detail!")
    return error.detail
  } else { 
    try { 
      console.log("Error has no detail")
      const fieldName = Object.keys(error)[0]
      const errorMessage = error[fieldName][0]
      return `${fieldName}: ${errorMessage}`
    } 
    catch (e) { 
      console.log("Everything failed. idk.")
      return defaultStr ?? "Sorry, something went wrong. We're looking into it. Try again later."
    }
  } 
}

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function parseTripFromPlacesJson(json) {
  const viewPortJson = json.geometry?.viewport;
  return { 
    title: json.name, 
    rating: json.rating,
    reviews: json.user_ratings_total,
    reference: json.reference,
    address: json.formatted_address,
    place_id: json.place_id,
    location: {
      lat: json.geometry.location?.lat,
      lng: json.geometry.location?.lng,
    },
    bbox:`${viewPortJson.southwest.lng},${viewPortJson.southwest.lat},${viewPortJson.northeast.lng},${viewPortJson.northeast.lat}`,
    picture_url: json.photos?.length > 0 ? Config.googleMapsPhotoRef.replace('{}', json.photos[0].photo_reference) : null
  }
}

export function getColorForTripPhase(phase: string) { 
  return `${getColorSchemeForPhase(phase)}.700`
}

export function getFullDescriptiveStringForPhase(phase: string) {
  return `is ${getDescriptiveStringForPhase(phase)} a trip to `
}

export function showAlert(alertString) {
  if (Platform.OS === "web") {
    window.alert(alertString);
  } else {
    Alert.alert(alertString);
  }
}


export function convertDataUrlToFile(dataUrl, filename) {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export function getDatesString(startDate, endDate) {
  if (!startDate && !endDate) {
    return ""
  }
  if (moment(startDate).isSame(endDate) || !endDate) {
    return moment(startDate).format('ddd ll');
  } else {
    return moment(startDate).format('ddd ll') + " - " + moment(endDate).format("ddd ll")
  }
}

export function getPlaceFromData(rawPlaceData, photoRef, title) { 
  const details = rawPlaceData;
  const viewPortJson = details.geometry.viewport;
  const bbox = `${viewPortJson.southwest.lng},${viewPortJson.southwest.lat},${viewPortJson.northeast.lng},${viewPortJson.northeast.lat}`;
  const placeObj = { 
    bbox: bbox, 
    location: details.geometry.location, 
    place_id: details.place_id, 
    photoReference: photoRef,
    picture_url: Config.googleMapsPhotoRef.replace("{}", photoRef), 
    title: title,
    address: details.formatted_address
  }
  return placeObj
}

export function getCountString(str, count) { 
  
  if (!count || count == 0 || count > 1) { 
    return str + 's'
  }
  return str
}

export function buildURLQuery(obj) {
		if (obj) {
      		return Object.entries(obj)
              .filter(pair => pair[1] !== undefined && pair[1] !== null)
            	.map(pair => pair.map(encodeURIComponent).join('='))
            	.join('&');
		} else {
			return ""
		}
}

export const getActualImageUrl = (baseUrl: string, imageUrl?: string) => {
  let actualImageUrl = imageUrl?.trim()
  if (!actualImageUrl || actualImageUrl.startsWith('data')) return

  if (actualImageUrl.startsWith('//'))
    actualImageUrl = `https:${actualImageUrl}`

  if (!actualImageUrl.startsWith('http')) {
    if (baseUrl.endsWith('/') && actualImageUrl.startsWith('/')) {
      actualImageUrl = `${baseUrl.slice(0, -1)}${actualImageUrl}`
    } else if (!baseUrl.endsWith('/') && !actualImageUrl.startsWith('/')) {
      actualImageUrl = `${baseUrl}/${actualImageUrl}`
    } else {
      actualImageUrl = `${baseUrl}${actualImageUrl}`
    }
  }

  return actualImageUrl
}

export const getHtmlEntitiesDecodedText = (text?: string) => {
  const actualText = text?.trim()
  if (!actualText) return

  return decode(actualText)
}

export const getContent = (left: string, right: string, type: string) => {
  const contents = {
    [left.trim()]: right,
    [right.trim()]: left,
  }

  return contents[type]?.trim()
}

export const getDateHandleWeb = (date) => { 
  if (!date) { 
    return null
  }
  if (Platform.OS === "web") {
      return moment(date).toDate()
  } else { 
      return date
  }
}

export const getImageSize = (url: string) => {
  return new Promise<Size>((resolve, reject) => {
    Image.getSize(
      url,
      (width, height) => {
        resolve({ height, width })
      },
      // type-coverage:ignore-next-line
      (error) => reject(error)
    )
  })
}

// Functions below use functions from the same file and mocks are not working
/* istanbul ignore next */
export const getPreviewData = async (text: string, requestTimeout = 5000, proxyUrl = null) => {
  const previewData: PreviewData = {
    description: undefined,
    image: undefined,
    link: undefined,
    title: undefined,
  }

  try {
    const textWithoutEmails = text.replace(REGEX_EMAIL, '').trim()

    if (!textWithoutEmails) return previewData

    const link = textWithoutEmails.match(REGEX_LINK)?.[0]

    if (!link) return previewData

    let url = link

    if (!url.toLowerCase().startsWith('http')) {
      url = 'https://' + url
    }

    // eslint-disable-next-line no-undef
    let abortControllerTimeout: NodeJS.Timeout
    const abortController = new AbortController()

    var fullUrl = url;

    if (Platform.OS == 'web') {
      fullUrl = proxyUrl.replace("{}", url)
    }

    const request = fetch(fullUrl, {
      headers: {
        'User-Agent':
          'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36',
      },
      signal: abortController.signal,
    })

    abortControllerTimeout = setTimeout(() => {
      abortController.abort()
    }, requestTimeout)

    const response = await request

    clearTimeout(abortControllerTimeout)

    previewData.link = url

    const contentType = response.headers.get('content-type') ?? ''

    if (REGEX_IMAGE_CONTENT_TYPE.test(contentType)) {
      const image = await getPreviewDataImage(url)
      previewData.image = image
      return previewData
    }

    var html = await response.text()
    if (Platform.OS == "web") {
      html = html.replace(/\\"/g, '"');
    }

    if (!html) { console.error("NO PREVIEW") }
    // Some pages return undefined
    if (!html) return previewData

    const head = html.substring(0, html.indexOf('<body'))
    // Get page title
    const title = REGEX_TITLE.exec(head)
    previewData.title = getHtmlEntitiesDecodedText(title?.[1])

    let matches: RegExpMatchArray | null
    const meta: RegExpMatchArray[] = []
    while ((matches = REGEX_META.exec(head)) !== null) {
      meta.push([...matches])
    }


    const metaPreviewData = meta.reduce<{
      description?: string
      imageUrl?: string
      title?: string
    }>(
      (acc, curr) => {
        // Verify that we have property/name and content
        // Note that if a page will specify property, name and content in the same meta, regex will fail
        if (!curr[2] || !curr[3]) return acc

        // Only take the first occurrence
        // For description take the meta description tag into consideration
        const description =
          !acc.description &&
          (getContent(curr[2], curr[3], 'og:description') ||
            getContent(curr[2], curr[3], 'description'))
        const ogImage =
          !acc.imageUrl && getContent(curr[2], curr[3], 'og:image')
        const ogTitle = !acc.title && getContent(curr[2], curr[3], 'og:title')

        return {
          description: description
            ? getHtmlEntitiesDecodedText(description)
            : acc.description,
          imageUrl: ogImage ? getActualImageUrl(url, ogImage) : acc.imageUrl,
          title: ogTitle ? getHtmlEntitiesDecodedText(ogTitle) : acc.title,
        }
      },
      { title: previewData.title }
    )

    previewData.description = metaPreviewData.description
    previewData.image = await getPreviewDataImage(metaPreviewData.imageUrl)
    previewData.title = metaPreviewData.title

    if (!previewData.image) {
      let imageMatches: RegExpMatchArray | null
      const tags: RegExpMatchArray[] = []
      while ((imageMatches = REGEX_IMAGE_TAG.exec(html)) !== null) {
        tags.push([...imageMatches])
      }

      let images: PreviewDataImage[] = []

      for (const tag of tags
        .filter((t) => !t[1].startsWith('data'))
        .slice(0, 5)) {
        const image = await getPreviewDataImage(getActualImageUrl(url, tag[1]))

        if (!image) continue

        images = [...images, image]
      }

      previewData.image = images.sort(
        (a, b) => b.height * b.width - a.height * a.width
      )[0]
    }

    return previewData
  } catch {
    return previewData
  }
}

/* istanbul ignore next */
export const getPreviewDataImage = async (url?: string) => {
  if (!url) return

  try {
    const { height, width } = await getImageSize(url)
    const aspectRatio = width / (height || 1)

    if (height > 100 && width > 100 && aspectRatio > 0.1 && aspectRatio < 10) {
      const image: PreviewDataImage = { height, url, width }
      return image
    }
  } catch { }
}

export const oneOf =
  <T extends (...args: A) => any, U, A extends any[]>(
    truthy: T | undefined,
    falsy: U
  ) =>
    (...args: Parameters<T>): ReturnType<T> | U => {
      return truthy ? truthy(...args) : falsy
    }

export const REGEX_EMAIL = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
export const REGEX_IMAGE_CONTENT_TYPE = /image\/*/g
// Consider empty line after img tag and take only the src field, space before to not match data-src for example
export const REGEX_IMAGE_TAG = /<img[\n\r]*.*? src=["'](.*?)["']/g
export const REGEX_LINK =
  /((http|ftp|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/i
// Some pages write content before the name/property, some use single quotes instead of double
export const REGEX_META =
  /<meta.*?(property|name)=["'](.*?)["'].*?content=["'](.*?)["'].*?>/g
export const REGEX_TITLE = /<title.*?>(.*?)<\/title>/g



export function copyAndSetMap(key, value, map) { 
  const newMap = {...map}
  newMap[key] = value
  return newMap
}

export function copyAndDeleteKey(key, map) { 
  const newMap = {...map}
  delete newMap[key]
  return newMap
}