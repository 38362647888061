import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Platform } from "react-native";
import SocialShareModal from "../components/SocialShareModal";
import { getImageFromRef, sentryCaptureException, shareLink, showAlert } from "../utils";


export default function IGShareableViewModalVC(props) { 

    const [shareUri, setShareUri] = useState(null)
    const viewRef = useRef(null);

    const captureImage = async() => {
        const uri = await getImageFromRef(viewRef);
        console.log(uri)
        setShareUri(uri);
        // props.onClose();
    }

    const onLinkShare = () => {
        if (Platform.OS !== "web") { 
            shareLink(props.link)
        } else {
            try { 
                window.navigator.share({url: props.link})
            } catch (e) {
                sentryCaptureException(e)
            }
        }
        props.onClose();
        if (props.postLinkShare) { 
            props.postLinkShare(props.link);
        }
    }

    useEffect(() => {
        if (props.isOpen && props.viewFinishedLoading && viewRef?.current) { 
            setTimeout(() => {
                captureImage()
            }, 300)
        }
    }, [props.isOpen, props.viewFinishedLoading, viewRef])

    return <>
        <SocialShareModal 
        hideIG={props.hideIG}
        linkType={props.linkType}
        showCopyLink={props.showCopyLink} 
        title={props.title} 
        onInvitePress={props.onInvitePress}
        getSocialView={() => props.getSocialView(viewRef)} 
        isOpen={props.isOpen} 
        source={props.source} 
        onClose={() => props.onClose()} 
        uri={shareUri} 
        link={props.link} 
        renderFooter={props.renderFooter ?? null}
        onLinkShare={onLinkShare} />
    </>
}